import React from 'react'

import { Maybe } from 'types'

import { Source } from 'utils/task'
import { cn } from 'utils/utils'

import AssistantSources from 'components/assistant/components/assistant-sources'

export interface AssistantWorkflowSidebarProps {
  sources: Maybe<Source[]>
  isStreaming: boolean
  onSetActiveFileId: (
    fileId: string | undefined,
    sourceId?: string | undefined
  ) => void
  className?: string
  style?: React.CSSProperties
}

export function AssistantWorkflowSidebar({
  sources,
  isStreaming,
  onSetActiveFileId,
  className,
  style,
}: AssistantWorkflowSidebarProps) {
  return (
    <AssistantSources
      allDocuments={[]}
      sources={sources}
      isStreaming={isStreaming}
      onSetActiveFileId={onSetActiveFileId}
      className={cn(
        'sticky left-0 top-[68px] ml-4 mt-[42px] sm:ml-4',
        className
      )}
      // eslint-disable-next-line react/forbid-component-props
      style={style}
    />
  )
}
