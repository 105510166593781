import React from 'react'

import { FileUp, Info } from 'lucide-react'
import pluralize from 'pluralize'

import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { IntegrationType } from 'openapi/models/IntegrationType'
import {
  FileType,
  FileTypeReadableName,
  removeSubsetDuplicates,
} from 'types/file'

import { mbToReadable } from 'utils/file-utils'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import GoogleDriveButton from 'components/common/integrations/google-drive-button'
import SharepointButton from 'components/common/integrations/sharepoint-button'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import {
  MAX_EXCEL_FILE_SIZE_IN_MB,
  MAX_FILE_SIZE_IN_MB,
  MAX_TOTAL_FILE_SIZE_IN_MB,
} from 'components/vault/utils/vault'

interface VaultCreateFileDropUpload {
  acceptedFileTypes: FileType[]
  open: () => void
  onUploadFromIntegration: (
    files: File[],
    fileSource: FileUploadSource
  ) => Promise<void>
  inModal?: boolean
}

const VaultCreateFileUpload: React.FC<VaultCreateFileDropUpload> = ({
  acceptedFileTypes,
  open,
  onUploadFromIntegration,
  inModal,
}) => {
  const userInfo = useAuthUser()

  const sharepointEnabled =
    IntegrationDefinitions[IntegrationType.SHAREPOINT].available(userInfo)
  const googleDriveEnabled =
    IntegrationDefinitions[IntegrationType.GOOGLE_DRIVE].available(userInfo)

  const customFileTypes =
    mbToReadable(MAX_FILE_SIZE_IN_MB) !==
    mbToReadable(MAX_EXCEL_FILE_SIZE_IN_MB)
      ? [
          {
            fileType: FileType.EXCEL,
            maxSize: mbToReadable(MAX_EXCEL_FILE_SIZE_IN_MB),
          },
        ]
      : undefined

  const maxFileSize = mbToReadable(MAX_FILE_SIZE_IN_MB)
  const totalSize = mbToReadable(MAX_TOTAL_FILE_SIZE_IN_MB)
  const maxFiles = userInfo.workspace.getVaultFilesCountLimit(
    userInfo.vaultFeature
  )

  return (
    <div className="space-y-4">
      <div
        data-testid="dropzone"
        onClick={(e) => {
          e.stopPropagation()
          open()
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation()
            e.preventDefault()
            open()
          }
        }}
        role="button"
        tabIndex={0}
        className={cn(
          'group mt-4 flex h-[200px] w-full cursor-pointer',
          'flex-col items-center justify-center space-y-4',
          'rounded-md border border-dashed border-[hsl(var(--neutral-300))]',
          'px-20 transition hover:border-[hsl(var(--neutral-400))] hover:bg-secondary-hover',
          {
            'mt-0': inModal,
          }
        )}
      >
        <div className="flex flex-col items-center gap-1 text-center">
          <Icon
            icon={FileUp}
            size="large"
            className="text-[hsl(var(--neutral-400))] transition group-hover:text-primary"
          />
          <div className="flex items-center gap-1">
            <p className="font-medium">Drag and drop</p>
            <Tooltip>
              <TooltipTrigger>
                <Icon icon={Info} size="small" />
              </TooltipTrigger>
              <TooltipContent>
                Maximum size per file: {maxFileSize}
                {customFileTypes &&
                  ` (${customFileTypes
                    .map(
                      (fileType) =>
                        `${fileType.maxSize} for ${
                          FileTypeReadableName[fileType.fileType]
                        }`
                    )
                    .join(', ')})`}
                <br />
                Upload limit:{' '}
                {maxFiles && `${maxFiles} ${pluralize('file', maxFiles)}`}
                {totalSize && (
                  <>
                    {maxFiles && ', '}
                    {totalSize} total
                  </>
                )}
              </TooltipContent>
            </Tooltip>
          </div>
          <p className="text-xs text-muted">
            Supported file types:{' '}
            {removeSubsetDuplicates(
              acceptedFileTypes.map(
                (fileType) => FileTypeReadableName[fileType]
              )
            )
              .sort((a, b) => a.localeCompare(b))
              .join(', ')}
          </p>
        </div>
        <Button
          variant="outline"
          onClick={(e) => {
            e.stopPropagation()
            open()
          }}
        >
          Browse files
        </Button>
      </div>
      {(sharepointEnabled || googleDriveEnabled) && (
        <>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-primary" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-primary px-2 text-sm text-muted">OR</span>
            </div>
          </div>
          <div className="flex w-full flex-col items-center gap-2">
            {sharepointEnabled && (
              <SharepointButton
                acceptedFileTypes={acceptedFileTypes}
                onUploadFromSharepoint={(files) =>
                  onUploadFromIntegration(files, FileUploadSource.SHAREPOINT)
                }
                size="lg"
              />
            )}
            {googleDriveEnabled && (
              <GoogleDriveButton
                acceptedFileTypes={acceptedFileTypes}
                onUploadFromGoogleDrive={(files) =>
                  onUploadFromIntegration(files, FileUploadSource.GOOGLE_DRIVE)
                }
                size="lg"
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default VaultCreateFileUpload
