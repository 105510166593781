/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DocumentClassificationTagChild = {
    AMENDMENT: 'Amendment',
    ASSIGNMENT_AGREEMENT: 'Assignment Agreement',
    BOND: 'Bond',
    COLLABORATION_AGREEMENT: 'Collaboration Agreement',
    COLLECTIVE_BARGAINING_AGREEMENT: 'Collective Bargaining Agreement',
    COMMERCIAL_CONTRACT: 'Commercial Contract',
    CONTRACT: 'Contract',
    CONTRIBUTION_AGREEMENT: 'Contribution Agreement',
    CREDIT_AGREEMENT: 'Credit Agreement',
    DATA_PROCESSING_ADDENDUM: 'Data Processing Addendum',
    DEED: 'Deed',
    DISCLOSURE_SCHEDULE: 'Disclosure Schedule',
    EMPLOYEE_BENEFITS_AGREEMENT: 'Employee Benefits Agreement',
    EMPLOYMENT_AGREEMENT: 'Employment Agreement',
    ESCROW_AGREEMENT: 'Escrow Agreement',
    GUARANTY: 'Guaranty',
    INDENTURE: 'Indenture',
    INSURANCE_POLICY: 'Insurance Policy',
    INVESTMENT_AGREEMENT: 'Investment Agreement',
    JOINDER: 'Joinder',
    JOINT_VENTURE_AGREEMENT: 'Joint Venture Agreement',
    LEASE: 'Lease',
    LETTER_AGREEMENT: 'Letter Agreement',
    LETTER_OF_TRANSMITTAL: 'Letter of Transmittal',
    LICENSE_AGREEMENT: 'License Agreement',
    LIMITED_PARTNERSHIP_AGREEMENT: 'Limited Partnership Agreement',
    LOAN_AGREEMENT: 'Loan Agreement',
    LOCKUP_AGREEMENT: 'Lockup Agreement',
    MERGER_AGREEMENT: 'Merger Agreement',
    MORTGAGE_AGREEMENT: 'Mortgage Agreement',
    NON_DISCLOSURE_AGREEMENT: 'Non-Disclosure Agreement',
    OPERATING_AGREEMENT: 'Operating Agreement',
    ORDER_FORM: 'Order Form',
    PARTNERSHIP_AGREEMENT: 'Partnership Agreement',
    PLAN_OF_REORGANIZATION: 'Plan of Reorganization',
    PURCHASE_AGREEMENT: 'Purchase Agreement',
    REGISTRATION_RIGHTS_AGREEMENT: 'Registration Rights Agreement',
    SECURITY_AGREEMENT: 'Security Agreement',
    SHAREHOLDER_RIGHTS_AGREEMENT: 'Shareholder Rights Agreement',
    STOCK_OPTION_GRANTS: 'Stock Option Grants',
    STOCK_PURCHASE_AGREEMENT: 'Stock Purchase Agreement',
    TERMS_OF_SERVICE: 'Terms of Service',
    TRANSITION_SERVICES_AGREEMENT: 'Transition Services Agreement',
    VOTING_AGREEMENT: 'Voting Agreement',
    SIDE_LETTER: 'Side Letter',
    INVESTOR_RIGHTS_AGREEMENT: 'Investor Rights Agreement',
    SEVERANCE_AGREEMENT: 'Severance Agreement',
    DISCOVERY: 'Discovery',
    EXPERT_REPORT: 'Expert Report',
    COURT_FILING: 'Court Filing',
    CASE_LAW: 'Case Law',
    ORDER: 'Order',
    PLEADING: 'Pleading',
    TRANSCRIPT: 'Transcript',
    AGENCY_COMPLAINT: 'Agency Complaint',
    AGENCY_CORRESPONDENCE: 'Agency Correspondence',
    AGENCY_OPINION: 'Agency Opinion',
    REGULATORY_FILING: 'Regulatory Filing',
    REGULATORY_LAW: 'Regulatory Law',
    PATENT: 'Patent',
    PATENT_APPLICATION: 'Patent Application',
    TAX_RETURN: 'Tax Return',
    TRADEMARK_APPLICATION: 'Trademark Application',
    TRADEMARK_REGISTRATION: 'Trademark Registration',
    BALANCE_SHEET: 'Balance Sheet',
    CAPITALIZATION_TABLE: 'Capitalization Table',
    FINANCIAL_STATEMENT: 'Financial Statement',
    STATUTORY_LAW: 'Statutory Law',
    EMAIL: 'Email',
    ADVISORY_OPINION: 'Advisory Opinion',
    ANNUAL_REPORT: 'Annual Report',
    ARTICLES_OF_INCORPORATION: 'Articles of Incorporation',
    ARTICLES_OF_ORGANIZATION: 'Articles of Organization',
    BOARD_MINUTES: 'Board Minutes',
    BYLAWS: 'Bylaws',
    COMPANY_POLICY: 'Company Policy',
    EMPLOYEE_STOCK_PURCHASE_PLAN: 'Employee Stock Purchase Plan',
    GOVERNMENT_FILING: 'Government Filing',
    INSURANCE_CERTIFICATE: 'Insurance Certificate',
    INVOICE: 'Invoice',
    LICENSE: 'License',
    MEMORANDUM: 'Memorandum',
    OTHER: 'Other',
    PROSPECTUS: 'Prospectus',
    RECEIPT: 'Receipt',
    SURVEY: 'Survey',
    TRUST: 'Trust',
    WILL: 'Will',
    WRITTEN_CONSENT: 'Written Consent',
    CERTIFICATE_OF_DESIGNATION: 'Certificate of Designation',
    COMPLAINT: 'Complaint',
    ANSWER: 'Answer',
    MOTION: 'Motion',
    BRIEF: 'Brief',
    OTHER_COURT_FILING: 'Other Court Filing',
    COURT_DECISION: 'Court Decision'
} as const;
export type DocumentClassificationTagChild = typeof DocumentClassificationTagChild[keyof typeof DocumentClassificationTagChild];


export function instanceOfDocumentClassificationTagChild(value: any): boolean {
    for (const key in DocumentClassificationTagChild) {
        if (Object.prototype.hasOwnProperty.call(DocumentClassificationTagChild, key)) {
            if (DocumentClassificationTagChild[key as keyof typeof DocumentClassificationTagChild] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DocumentClassificationTagChildFromJSON(json: any): DocumentClassificationTagChild {
    return DocumentClassificationTagChildFromJSONTyped(json, false);
}

export function DocumentClassificationTagChildFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentClassificationTagChild {
    return json as DocumentClassificationTagChild;
}

export function DocumentClassificationTagChildToJSON(value?: DocumentClassificationTagChild | null): any {
    return value as any;
}

export function DocumentClassificationTagChildToJSONTyped(value: any, ignoreDiscriminator: boolean): DocumentClassificationTagChild {
    return value as DocumentClassificationTagChild;
}

