import * as React from 'react'

import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'
import { WorkflowDraftSampleOutput } from 'openapi/models/WorkflowDraftSampleOutput'

import { MOCK_DATA } from 'components/assistant/workflows/components/preview-modal/mock-data'
import Markdown from 'components/common/markdown/markdown'
import Citation from 'components/ui/citation'
import Icon from 'components/ui/icon/icon'
import { OUTPUT_TYPE_TO_ICON } from 'components/workflows/workflow-card'

interface DraftPreviewProps {
  workflow: WorkflowDefinitionListItem
}

const DraftPreview: React.FC<DraftPreviewProps> = ({ workflow }) => {
  const icon = OUTPUT_TYPE_TO_ICON[workflow.outputType]
  let content: WorkflowDraftSampleOutput['data'] = MOCK_DATA.draft.content
  if (workflow.sampleOutput) {
    const sampleOutput = workflow.sampleOutput as WorkflowDraftSampleOutput
    content = sampleOutput.data
  }

  return (
    <div
      data-testid="draft-preview"
      className="relative size-full max-h-[380px] max-w-[404px] overflow-hidden rounded-lg border bg-primary drop-shadow-sm"
    >
      <div>
        <div className="border-b px-[10px] py-3">
          <div className="flex items-center gap-1">
            <Icon icon={icon} size="small" className="text-secondary" />
            <span className="text-xs text-secondary">{workflow.name}</span>
          </div>
        </div>
        <Markdown
          content={content}
          className="prose-xs p-6"
          width="100%"
          components={{
            span: ({ children, ...props }) => {
              if ('data-hrvy-id' in props) {
                return <Citation>{children}</Citation>
              }
              return <span {...props}>{children}</span>
            },
          }}
        />
      </div>
      <div className="absolute inset-x-0 bottom-0 h-28 w-full bg-gradient-to-t from-white to-transparent" />
    </div>
  )
}

export default DraftPreview
