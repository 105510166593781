import React from 'react'

import { cn } from 'utils/utils'

const SettingsPageHeader = ({
  title,
  subtitle,
  className,
}: {
  title: string
  subtitle: string
  className?: string
}) => {
  return (
    <div className={cn('flex flex-col space-y-1', className)}>
      <p className="text-base font-semibold">{title}</p>
      <p className="text-xs text-muted">{subtitle}</p>
    </div>
  )
}

export default SettingsPageHeader
