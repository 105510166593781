import { QueryClient } from '@tanstack/react-query'
import { GridApi, ColDef, IRowNode, ValueGetterParams } from 'ag-grid-community'
import { WritableDraft } from 'immer/dist/internal'
import { capitalize, isNil } from 'lodash'
import {
  Calendar,
  CircleDollarSign,
  Clock9,
  Hash,
  LayoutList,
  MessageSquareQuote,
  Text,
} from 'lucide-react'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { useGeneralStore } from 'stores/general-store'
import { HistoryItem } from 'types/history'
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from 'types/ui-constants'

import { SafeRecord } from 'utils/safe-types'
import { TaskStatus } from 'utils/task'
import { EM_DASH } from 'utils/utils'

import {
  CELL_LOADING_TEXT,
  LEFT_PINNED_COLUMNS,
} from 'components/vault/query-detail/vault-query-detail'
import {
  ReviewColumn,
  QueryQuestion,
  ColumnDataType,
  ReviewAnswer,
  ReviewError,
  ReviewRow,
  ReviewSource,
  COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION,
  LOADING_QUERY_TITLE,
  UNTITLED_QUERY_TITLE,
  MAX_QUESTIONS_LIMIT,
} from 'components/vault/utils/vault'
import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { FetchReviewRow } from 'components/vault/utils/vault-fetcher'
import {
  getFoldersOnPath,
  getDisplayAnswer,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import classifyIllustration from './data-grid/cells/assets/classify-example.svg'
import currencyIllustration from './data-grid/cells/assets/currency-example.svg'
import dateIllustration from './data-grid/cells/assets/date-example.svg'
import durationIllustration from './data-grid/cells/assets/duration-example.svg'
import extractionIllustration from './data-grid/cells/assets/extraction-example.svg'
import freeResponseIllustration from './data-grid/cells/assets/free-response-example.svg'
import numericIllustration from './data-grid/cells/assets/numeric-example.svg'
import { cellComparator } from './data-grid/cells/cell-comparator/cell-comparator'
import { EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN } from './vault-query-detail'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
  VaultQueryDetailState,
  VaultQueryDetailAction,
  VaultDataGridState,
  VaultDataGridAction,
  AddColumnCellState,
  AddColumnCellAction,
} from './vault-query-detail-store'

export type Pinned = boolean | 'left' | 'right' | null
export const ROOT_NODE_ID = 'ROOT_NODE_ID'
const ROW_NUMBER_COLUMN_WIDTH = 48
const NAME_COLUMN_WIDTH = 256
const DEFAULT_COLUMN_WIDTH = 320
const MIN_COLUMN_WIDTH = 64
export const ADD_COLUMN_WIDTH_WITH_TEXT = 112
export const ADD_COLUMN_WIDTH_WITH_ICON = 48
export const ADD_COLUMN_FIELD = 'addColumn'

export const HAS_REACHED_QUESTIONS_LIMIT_TOOLTIP =
  'You have reached the maximum amount of columns. To add new columns create a new review table.'
export const NO_EDIT_PERMISSIONS_TOOLTIP =
  'You do not have permissions to make edits to this review table. You are in view only mode.'
export interface QuestionColumnDef extends ColDef {
  originalQuestion: string
  questionId: string
  folderPath: string
  columnDataType: ColumnDataType
  eventId?: number
  backingReviewColumn?: ReviewColumn
  options?: string
}

const handleMouseEnter = (gridApi: GridApi, isIconOnly: boolean) => {
  if (isIconOnly) {
    const columnDefs = gridApi.getColumnDefs()
    const addColumnDef = columnDefs?.find((columnDef) => {
      const hasField = 'field' in columnDef
      return hasField && columnDef.field === ADD_COLUMN_FIELD
    }) as ColDef
    const filteredColumnDefs = columnDefs?.filter((columnDef) => {
      const hasField = 'field' in columnDef
      return hasField && columnDef.field !== ADD_COLUMN_FIELD
    })
    if (addColumnDef) {
      addColumnDef.width = ADD_COLUMN_WIDTH_WITH_TEXT
      addColumnDef.maxWidth = ADD_COLUMN_WIDTH_WITH_TEXT
      const newColumnDefs = [...(filteredColumnDefs ?? []), addColumnDef]
      gridApi.setGridOption('columnDefs', newColumnDefs)
      // TODO: the header border left only needs to be added when the user has scrolled all the way to the right
      document
        .querySelector('.ag-header-cell[col-id="addColumn"]')
        ?.classList.add('border-l', 'border-l-ring')
      document
        .querySelectorAll('.ag-cell[col-id="addColumn"]')
        .forEach(
          (cell) =>
            cell.firstElementChild?.classList.add('!border-l', '!border-l-ring')
        )
    }
  }

  const lastColumns = document.getElementsByClassName('ag-column-last')
  Array.from(lastColumns).forEach((column) => {
    const isHeaderCell = column.classList.contains('ag-header-cell')
    if (isHeaderCell) {
      const headerCellParent = column.parentElement
      // 1. add bg-skeleton-dark to the resize element (left border)
      const colIndex = column.getAttribute('aria-colindex')
      const prevColIndex = Number(colIndex) - 1
      const previousHeaderCell = headerCellParent?.querySelector(
        `[aria-colindex="${prevColIndex}"]`
      )
      // if there is a previous header cell, then add bg-skeleton-dark to the resize element
      // otherwise we need to add the bg-skeleton-dark to the pinned left header cell
      if (previousHeaderCell) {
        const resizeElement = previousHeaderCell.firstElementChild
        const isResizeElementVisible =
          !resizeElement?.classList.contains('ag-hidden')
        if (resizeElement && isResizeElementVisible) {
          resizeElement.classList.add('after:bg-skeleton-dark')
        } else {
          const headerCellCompWrapper = previousHeaderCell.querySelector(
            '.ag-header-cell-comp-wrapper'
          )
          const headerCellElement = headerCellCompWrapper?.firstElementChild
          headerCellElement?.classList.add('border-r-ring')
        }
      } else {
        const pinnedLeftHeader = document.querySelector(
          '.ag-pinned-left-header'
        )
        const pinnedHeaderRow = pinnedLeftHeader?.firstElementChild
        const lastPinnedHeaderCell = pinnedHeaderRow?.lastElementChild
        const lastPinnedHeaderCellResizeElement =
          lastPinnedHeaderCell?.firstElementChild
        lastPinnedHeaderCellResizeElement?.classList.add(
          'after:bg-skeleton-dark'
        )
      }
      // 2. add border to the header cell button (right border)
      const headerCellButton = column.lastElementChild?.firstElementChild
      headerCellButton?.classList.add('!border-r', '!border-r-ring')
      // 3. add border to the column (top border)
      column?.classList.add('!border-t', '!border-t-ring')
    } else {
      column.classList.add('!border-r', '!border-r-ring')
      // Get the previous cell
      const columnParent = column.parentElement
      const colIndex = column.getAttribute('aria-colindex')
      const prevColIndex = Number(colIndex) - 1
      const previousColumnCell = columnParent?.querySelector(
        `[aria-colindex="${prevColIndex}"]`
      )
      // if there is a previous column, then add border to it
      // otherwise, there is only the pinned left columns
      if (previousColumnCell) {
        previousColumnCell.classList.add('!border-r', '!border-r-ring')
      } else {
        const rowIndex = column.parentElement?.getAttribute('row-index')
        const leftPinnedColsContainer = document.querySelector(
          '.ag-pinned-left-cols-container'
        )
        const leftPinnedRow = leftPinnedColsContainer?.querySelector(
          `[row-index="${rowIndex}"]`
        )
        const leftPinnedCell = leftPinnedRow?.lastElementChild
        if (leftPinnedCell) {
          leftPinnedCell.classList.add('!border-r', '!border-r-ring')
        }
      }
      if (column.parentElement?.classList.contains('ag-row-last')) {
        column.classList.remove('border-b')
        column.classList.add('!border-b-ring')
      }
    }
  })
}

const handleMouseLeave = (
  e: React.MouseEvent,
  gridApi: GridApi,
  isPinned: boolean
) => {
  // if the related target is the add column cell or a group row, do nothing
  const relatedTarget = e.relatedTarget as HTMLElement
  if (relatedTarget instanceof Window) return

  let isRelatedTargetAddColumnCell = false
  let isGroupRow = false

  if (!relatedTarget) return

  if (relatedTarget.hasAttribute('col-id')) {
    isRelatedTargetAddColumnCell =
      relatedTarget.getAttribute('col-id') === ADD_COLUMN_FIELD
  } else {
    isRelatedTargetAddColumnCell =
      relatedTarget.parentElement?.getAttribute('col-id') === ADD_COLUMN_FIELD
  }
  if (relatedTarget.hasAttribute('row-id')) {
    isGroupRow =
      relatedTarget.getAttribute('row-id')?.startsWith('row-group-') ?? false
  } else {
    isGroupRow =
      relatedTarget.parentElement
        ?.getAttribute('row-id')
        ?.startsWith('row-group-') ?? false
  }
  if (isRelatedTargetAddColumnCell || isGroupRow) return

  // if the column is pinned, we need to adjust the add column cell width
  if (isPinned) {
    const columnDefs = gridApi.getColumnDefs()
    const addColumnDef = columnDefs?.find((columnDef) => {
      const hasField = 'field' in columnDef
      return hasField && columnDef.field === ADD_COLUMN_FIELD
    }) as ColDef
    const filteredColumnDefs = columnDefs?.filter((columnDef) => {
      const hasField = 'field' in columnDef
      return hasField && columnDef.field !== ADD_COLUMN_FIELD
    })
    if (addColumnDef) {
      addColumnDef.width = ADD_COLUMN_WIDTH_WITH_ICON
      addColumnDef.maxWidth = ADD_COLUMN_WIDTH_WITH_ICON
      const newColumnDefs = [...(filteredColumnDefs ?? []), addColumnDef]
      gridApi.setGridOption('columnDefs', newColumnDefs)
      document
        .querySelector('.ag-header-cell[col-id="addColumn"]')
        ?.classList.remove('border-l', 'border-l-ring')
      document
        .querySelectorAll('.ag-cell[col-id="addColumn"]')
        .forEach(
          (cell) =>
            cell.firstElementChild?.classList.remove(
              '!border-l',
              '!border-l-ring'
            )
        )
    }
  }
  handleLeftBorderReset()
}

const handleLeftBorderReset = () => {
  const lastColumns = document.getElementsByClassName('ag-column-last')
  Array.from(lastColumns).forEach((column) => {
    const isHeaderCell = column.classList.contains('ag-header-cell')
    if (isHeaderCell) {
      // 1. remove bg-skeleton-dark from the resize element (left border)
      const headerCellParent = column.parentElement
      const colIndex = column.getAttribute('aria-colindex')
      const prevColIndex = Number(colIndex) - 1
      const previousHeaderCell = headerCellParent?.querySelector(
        `[aria-colindex="${prevColIndex}"]`
      )
      if (previousHeaderCell) {
        const resizeElement = previousHeaderCell.firstElementChild
        const isResizeElementVisible =
          !resizeElement?.classList.contains('ag-hidden')
        if (resizeElement && isResizeElementVisible) {
          resizeElement.classList.remove('after:bg-skeleton-dark')
        } else {
          const headerCellCompWrapper = previousHeaderCell.querySelector(
            '.ag-header-cell-comp-wrapper'
          )
          const headerCellElement = headerCellCompWrapper?.firstElementChild
          headerCellElement?.classList.remove('border-r-ring')
        }
      } else {
        const pinnedLeftHeader = document.querySelector(
          '.ag-pinned-left-header'
        )
        const pinnedHeaderRow = pinnedLeftHeader?.firstElementChild
        const lastPinnedHeaderCell = pinnedHeaderRow?.lastElementChild
        const lastPinnedHeaderCellResizeElement =
          lastPinnedHeaderCell?.firstElementChild
        lastPinnedHeaderCellResizeElement?.classList.remove(
          'after:bg-skeleton-dark'
        )
      }
      // 2. remove border from the header cell button (right border)
      const headerCell = column.lastElementChild
      const headerCellButton = headerCell?.firstElementChild
      headerCellButton?.classList.remove('!border-r', '!border-r-ring')
      // 3. remove border from the column (top border)
      column?.classList.remove('!border-t', '!border-t-ring')
    } else {
      column.classList.remove('!border-r', '!border-r-ring')

      // Get the previous column cell
      const columnParent = column.parentElement
      const colIndex = column.getAttribute('aria-colindex')
      const prevColIndex = Number(colIndex) - 1
      const previousColumnCell = columnParent?.querySelector(
        `[aria-colindex="${prevColIndex}"]`
      )

      if (previousColumnCell) {
        previousColumnCell.classList.remove('!border-r', '!border-r-ring')
      } else {
        const rowIndex = column.parentElement?.getAttribute('row-index')
        const leftPinnedColsContainer = document.querySelector(
          '.ag-pinned-left-cols-container'
        )
        const leftPinnedRow = leftPinnedColsContainer?.querySelector(
          `[row-index="${rowIndex}"]`
        )
        const leftPinnedCell = leftPinnedRow?.lastElementChild
        if (leftPinnedCell) {
          leftPinnedCell.classList.remove('!border-r', '!border-r-ring')
        }
      }
      if (column.parentElement?.classList.contains('ag-row-last')) {
        column.classList.remove('!border-b-ring')
        column.classList.add('border-b')
      }
    }
  })
}

const computeLeftCell = (cell: HTMLElement, cellColId: string) => {
  const cellRow = cell.parentElement

  const allCellsInRow = cellRow?.querySelectorAll('.ag-cell') ?? []
  const allCellColIds = Array.from(allCellsInRow).map((cell) => {
    return cell.getAttribute('col-id')
  })

  // We have to check the aria-colindex attribute of the cells to determine the previous cell
  // When a user re-orders columns, the dom elements do not update their position under the parent element
  // instead ag-grid just updates the aria-colindex attribute of the cells
  const ariaColIndicies = Array.from(allCellsInRow).map((cell) => {
    return parseInt(cell.getAttribute('aria-colindex') ?? '0')
  })
  const sortedCellColIds = allCellColIds.sort((a, b) => {
    return (
      ariaColIndicies[allCellColIds.indexOf(a)] -
      ariaColIndicies[allCellColIds.indexOf(b)]
    )
  })
  const indexOfCellColId = sortedCellColIds.indexOf(cellColId)
  if (indexOfCellColId === 0) {
    return null
  }
  const previousCellColId = sortedCellColIds[indexOfCellColId - 1]
  return cellRow?.querySelector(`[col-id="${previousCellColId}"]`)
}

const computeTopCell = (cell: HTMLElement, cellColId: string) => {
  const cellParent = cell.parentElement
  const cellParentRowId = cellParent?.getAttribute('row-id')
  if (!cellParentRowId) return null
  const rowContainer = cell.parentElement?.parentElement

  // We have to check the aria-rowindex attribute of the rows to determine the previous row
  const allRows = rowContainer?.querySelectorAll('.ag-row') ?? []
  const allRowsIds = Array.from(allRows).map((row) => {
    return row.getAttribute('row-id')
  })
  const ariaRowIndicies = Array.from(allRows).map((row) => {
    return parseInt(row.getAttribute('aria-rowindex') ?? '0')
  })
  const sortedRowsIds = allRowsIds.sort((a, b) => {
    return (
      ariaRowIndicies[allRowsIds.indexOf(a)] -
      ariaRowIndicies[allRowsIds.indexOf(b)]
    )
  })
  const indexOfCellRow = sortedRowsIds.indexOf(cellParentRowId)
  if (indexOfCellRow === 0) {
    return null
  }
  const previousRowId = sortedRowsIds[indexOfCellRow - 1]
  const previousRow = Array.from(allRows).find(
    (row) => row.getAttribute('row-id') === previousRowId
  )
  const previousRowCell = previousRow?.querySelector(
    `.ag-cell[col-id="${cellColId}"]`
  )
  return previousRowCell
}

const invalidateQuery = (queryClient: QueryClient, queryId: string) => {
  void queryClient.invalidateQueries({
    queryKey: [
      HarvQueryKeyPrefix.VaultHistoryItemQuery,
      queryId,
      { type: 'diff' },
    ],
  })
}

const computeIsQueryLoading = (
  historyItem: HistoryItem | ReviewHistoryItem | null
) => {
  if (!historyItem) return false

  return (
    historyItem.status != TaskStatus.COMPLETED &&
    historyItem.status != TaskStatus.CANCELLED &&
    historyItem.status != TaskStatus.ERRORED
  )
}

const computeQueryTitle = (
  isNewQuery: boolean,
  historyItem: HistoryItem | ReviewHistoryItem | null
) => {
  if (isNewQuery) return UNTITLED_QUERY_TITLE
  if (historyItem && 'title' in historyItem) return historyItem.title
  if (historyItem && 'query' in historyItem) return historyItem.query
  return LOADING_QUERY_TITLE
}

const getDisplayedRows = (gridApi: GridApi) => {
  const displayedRows: string[] = []
  gridApi.forEachNodeAfterFilterAndSort((node) => {
    if (node.data && node.data.file) {
      displayedRows.push(node.data.file.id)
    } else if (node.id) {
      displayedRows.push(node.id)
    }
  })
  return displayedRows
}

export const mapReviewColumnToColumnDef = (
  eventColumn: ReviewColumn,
  eventId: number
) => {
  return {
    field: eventColumn.displayId.toString(),
    eventId: eventId,
    columnId: eventColumn.id.toString(),
    questionId: eventColumn.displayId.toString(),
    backingReviewColumn: eventColumn,
    headerName: eventColumn.header,
    columnDataType: eventColumn.dataType,
    originalQuestion: eventColumn.fullText,
    width: DEFAULT_COLUMN_WIDTH,
    minWidth: MIN_COLUMN_WIDTH,
    type:
      eventColumn.dataType === ColumnDataType.date
        ? FilterType.DATE
        : FilterType.TEXT,
    options: eventColumn.options?.join(', '),
    // eslint-disable-next-line max-params
    comparator: (
      valueA: string,
      valueB: string,
      nodeA: IRowNode<any>,
      nodeB: IRowNode<any>,
      isDescending: boolean
    ) =>
      cellComparator({
        valueA,
        valueB,
        nodeA,
        nodeB,
        isDescending,
        questionId: eventColumn.displayId.toString(),
        columnDataType: eventColumn.dataType,
      }),
  }
}

interface CreateGridColumnDefsProps {
  gridApi: GridApi
  shouldGroupRows: boolean
  historyColumns: ReviewColumn[]
  pendingQueryQuestions: QueryQuestion[]
  isQueryLoading: boolean
  eventId?: number
}

const createGridColumnDefs = ({
  gridApi,
  shouldGroupRows,
  historyColumns,
  pendingQueryQuestions,
  isQueryLoading,
  eventId,
}: CreateGridColumnDefsProps) => {
  const columnDefs: Array<ColDef | QuestionColumnDef> = [
    {
      valueGetter: (params: ValueGetterParams) => {
        // when we have row grouping, we need to get the index from the parent
        // and subtract it from the row index because group rows also have an index
        // and we don't want to count the group rows when determining the row index
        const node = params.node
        const rowIndex = node?.rowIndex ?? 0
        if (isNil(node?.parent?.rowIndex)) return rowIndex + 1
        const parentRowChildIndex = node?.parent?.childIndex ?? 0
        return rowIndex - parentRowChildIndex
      },
      field: 'row',
      headerName: '#',
      type: 'number',
      width: ROW_NUMBER_COLUMN_WIDTH,
      minWidth: ROW_NUMBER_COLUMN_WIDTH,
      resizable: false,
      pinned: 'left',
    },
    {
      field: 'name',
      headerName: 'Name',
      resizable: true,
      columnDataType: ColumnDataType.string,
      type: 'document',
      pinned: 'left',
      width: NAME_COLUMN_WIDTH,
      minWidth: NAME_COLUMN_WIDTH,
    },
  ]

  if (shouldGroupRows) {
    columnDefs.push({
      field: 'folderPath',
      headerName: 'Folder path',
      type: 'hidden',
      rowGroup: true,
    })
  }

  const hasPendingColumns = pendingQueryQuestions.length > 0
  const historyColumnsCopy = [...historyColumns]

  historyColumnsCopy
    .sort((a, b) => a.order - b.order)
    .forEach((eventColumn: ReviewColumn) => {
      if (eventColumn.isHidden || !eventId) {
        return
      }
      const column = mapReviewColumnToColumnDef(eventColumn, eventId)
      columnDefs.push({
        suppressMovable: hasPendingColumns,
        resizable: !isQueryLoading,
        ...column,
      })
    })

  pendingQueryQuestions.forEach((question: QueryQuestion) => {
    const column = {
      field: question.id,
      questionId: question.id,
      backingReviewColumn: question.backingReviewColumn,
      headerName: question.header,
      columnDataType: question.columnDataType,
      originalQuestion: question.text,
      width: DEFAULT_COLUMN_WIDTH,
      minWidth: MIN_COLUMN_WIDTH,
      suppressMovable: hasPendingColumns,
      resizable: !isQueryLoading,
      // eslint-disable-next-line max-params
      comparator: (
        valueA: string,
        valueB: string,
        nodeA: IRowNode<any>,
        nodeB: IRowNode<any>,
        isDescending: boolean
      ) =>
        cellComparator({
          valueA,
          valueB,
          nodeA,
          nodeB,
          isDescending,
          questionId: question.id,
          columnDataType:
            question.columnDataType ?? ColumnDataType.freeResponse,
        }),
      type:
        question.columnDataType === ColumnDataType.date
          ? FilterType.DATE
          : FilterType.TEXT,
    }
    columnDefs.push(column)
  })

  const addColumnDef = {
    field: ADD_COLUMN_FIELD,
    headerName: ADD_COLUMN_FIELD,
    type: ADD_COLUMN_FIELD,
    width: ADD_COLUMN_WIDTH_WITH_TEXT,
    maxWidth: ADD_COLUMN_WIDTH_WITH_TEXT,
    pinned: false as Pinned,
  }
  columnDefs.push(addColumnDef)
  updateAddColumnDef(columnDefs)
  gridApi.updateGridOptions({
    columnDefs: columnDefs,
  })
}

export enum GridTransactionAction {
  ADD = 'add',
  UPDATE = 'update',
}
interface AddFilesToGridProps {
  gridApi: GridApi
  setDisplayedRows: (displayedRows: string[]) => void
  files: VaultFile[]
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  isLoading: boolean
  gridAction: GridTransactionAction
  fileIdToAnswers?: SafeRecord<string, ReviewAnswer[]>
  fileIdToErrors?: SafeRecord<string, ReviewError[]>
  previouslyErroredFileIds?: string[]
  questions?: QueryQuestion[]
  reviewRows?: ReviewRow[]
  suppressedFileIdsSet?: Set<string>
  processedFileIdsSet?: Set<string>
}

interface GetDisplayTextProps {
  answer: ReviewAnswer | undefined
  isLoading: boolean
  error: ReviewError | undefined
  isErrorSuppressed: boolean
}

const getDisplayText = ({
  answer,
  isLoading,
  error,
  isErrorSuppressed,
}: GetDisplayTextProps) => {
  if (!answer?.text && isLoading && !isErrorSuppressed) {
    // If the answer is empty and the file is still processing, then we want to show processing text
    return CELL_LOADING_TEXT
  }
  if (error && isErrorSuppressed) {
    return EM_DASH
  }
  if (!answer?.text && isLoading) {
    return CELL_LOADING_TEXT
  }
  if (!answer?.text) {
    // If the answer is empty, then we want to show empty text
    return ''
  }
  return getDisplayAnswer(answer)
}

const addFilesToGrid = ({
  gridApi,
  setDisplayedRows,
  files,
  folderIdToVaultFolder,
  isLoading,
  gridAction,
  fileIdToAnswers,
  fileIdToErrors,
  previouslyErroredFileIds,
  suppressedFileIdsSet,
  questions,
  reviewRows,
}: AddFilesToGridProps) => {
  const rowData = files.map((file) => {
    const fileId = file.id
    const fileName = file.name

    const backingReviewRow = reviewRows?.find((row) => row.fileId === fileId)
    const backingReviewRowId = backingReviewRow?.id

    const cells: { [key: string]: string } = {}
    const answers = fileIdToAnswers ? fileIdToAnswers[fileId] || [] : []
    const errors = fileIdToErrors ? fileIdToErrors[fileId] || [] : []
    const isErrorSuppressed = suppressedFileIdsSet
      ? suppressedFileIdsSet.has(fileId)
      : false

    const folderId = file.vaultFolderId
    const foldersOnPath =
      getFoldersOnPath(folderId, folderIdToVaultFolder) ?? []

    // once we get the folders on path, we want to drop the first folder because it is the root folder
    // and we are not showing the root folder in the group row
    const folderPath = foldersOnPath
      .slice(1)
      .map((f: VaultFolder) => f.name)
      .join('/')

    questions?.forEach((question) => {
      const questionId = question.id
      const questionAnswer = answers.find(
        (answer) => answer.columnId === questionId && !answer.long
      )
      const error = errors.find((error) => error.columnId === questionId)
      const shortDisplayText = getDisplayText({
        answer: questionAnswer,
        isLoading: isLoading,
        error: error,
        isErrorSuppressed: isErrorSuppressed,
      })

      cells[questionId] = shortDisplayText
    })

    return {
      id: fileId,
      backingReviewRowId,
      name: fileName,
      file: file,
      folderPath: folderPath,
      errors: isErrorSuppressed ? [] : errors,
      answers: answers,
      ...cells,
    }
  })

  gridApi.applyTransactionAsync({ [gridAction]: rowData }, () => {
    setDisplayedRows(getDisplayedRows(gridApi))
    if (!fileIdToErrors) return
    const fileIdsWithErrors = new Set([
      ...Object.keys(fileIdToErrors),
      ...(previouslyErroredFileIds ?? []),
    ])
    if (fileIdsWithErrors.size === 0) return
    const rowsToRefresh = rowData
      .map((row) => gridApi.getRowNode(row.id))
      .filter(Boolean)
      .filter((row) => fileIdsWithErrors.has(row!.id!)) as IRowNode<any>[]
    gridApi.refreshCells({
      force: true,
      rowNodes: rowsToRefresh,
    })
  })
}

const computeNextColumnId = (columns: (QueryQuestion | ReviewColumn)[]) => {
  if (!columns || columns.length === 0) return 1
  const columnIds = columns.map((column) => {
    if ('displayId' in column) {
      return column.displayId
    }
    return parseInt(column.id)
  })
  return Math.max(...columnIds) + 1
}

const hideAddColumnColumnDef = (gridApi: GridApi) => {
  const columnDefs = gridApi.getColumnDefs() ?? []
  const addColumnColumnDef = columnDefs.find((columnDef) => {
    const hasField = 'field' in columnDef
    return hasField && columnDef.field === ADD_COLUMN_FIELD
  }) as QuestionColumnDef
  addColumnColumnDef.hide = true
  updateAddColumnDef(columnDefs)
  gridApi.setGridOption('columnDefs', columnDefs)
}

const updateAddColumnDef = (columnDefs: ColDef[]) => {
  const haveToPinAddColumn = shouldPinAddColumn(columnDefs, false)

  const addColumnDef = columnDefs.find((columnDef) => {
    const hasField = 'field' in columnDef
    return hasField && columnDef.field === ADD_COLUMN_FIELD
  }) as ColDef

  if (haveToPinAddColumn) {
    addColumnDef.pinned = 'right' as Pinned
    addColumnDef.width = ADD_COLUMN_WIDTH_WITH_ICON
    addColumnDef.maxWidth = ADD_COLUMN_WIDTH_WITH_ICON
  } else {
    addColumnDef.pinned = false
    addColumnDef.width = ADD_COLUMN_WIDTH_WITH_TEXT
    // By removing the max-width and adding flex the column will take the remaining space
    addColumnDef.flex = 1
    addColumnDef.maxWidth = undefined
  }
  const addColumnIndex = columnDefs.findIndex(
    (columnDef) => 'field' in columnDef && columnDef.field === ADD_COLUMN_FIELD
  )
  if (addColumnIndex !== -1) {
    columnDefs[addColumnIndex] = addColumnDef
  }

  return haveToPinAddColumn
}

const shouldPinAddColumn = (
  existingColumnDefs: ColDef[],
  addNewColumnWidth: boolean
) => {
  // get the available width of the viewport
  const viewportWidth = window.innerWidth

  // compute the available width for the columns
  // the unavailable width is:
  // - width of the sidebar
  // - width of the row number column
  // - width of the name column
  const sidebarWidth = useGeneralStore.getState().isSidebarOpen
    ? SIDEBAR_OPEN_WIDTH
    : SIDEBAR_CLOSED_WIDTH
  const rowNumberColumnWidth = ROW_NUMBER_COLUMN_WIDTH
  const nameColumnWidth =
    existingColumnDefs.find((columnDef) => columnDef.field === 'name')?.width ??
    NAME_COLUMN_WIDTH
  const existingDOMWidth = sidebarWidth + rowNumberColumnWidth + nameColumnWidth

  // get the width of the existing columns
  // adding 320 for the new column
  const newColumnWidth = addNewColumnWidth ? DEFAULT_COLUMN_WIDTH : 0
  const existingColumnWidths =
    newColumnWidth +
    existingColumnDefs.reduce((sum, columnDef) => {
      const hasWidth = 'width' in columnDef
      if (
        !hasWidth ||
        EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN.includes(columnDef.field!)
      )
        return sum
      return sum + (columnDef.width || 0)
    }, 0)

  return existingColumnWidths >= viewportWidth - existingDOMWidth
}

const addNewColumn = (
  gridApi: GridApi,
  allColumns: (QueryQuestion | ReviewColumn)[],
  addToPendingQueryQuestions: (queryQuestion: QueryQuestion) => void,
  newQueryQuestion?: Omit<QueryQuestion, 'id'>
  // eslint-disable-next-line max-params
): QueryQuestion => {
  // 1. add the column
  const id = computeNextColumnId(allColumns).toString()
  const queryQuestion = {
    id: id,
    text: newQueryQuestion?.text ?? '',
    header: newQueryQuestion?.header ?? 'Untitled',
    columnDataType:
      newQueryQuestion?.columnDataType ?? ColumnDataType.freeResponse,
    options: newQueryQuestion?.options,
  }
  const newColumnDef = {
    field: id.toString(),
    questionId: id,
    headerName: newQueryQuestion?.header ?? 'Untitled',
    columnDataType:
      newQueryQuestion?.columnDataType ?? ColumnDataType.freeResponse,
    options: newQueryQuestion?.options,
    originalQuestion: newQueryQuestion?.text ?? '',
    width: DEFAULT_COLUMN_WIDTH,
    minWidth: MIN_COLUMN_WIDTH,
    type: FilterType.TEXT,
  }
  const existingColumnDefs = gridApi.getColumnDefs() ?? []
  const existingColumnDefsWithoutAddColumn = existingColumnDefs.filter(
    (columnDef) => {
      const hasField = 'field' in columnDef
      if (!hasField) return true
      return columnDef.field !== ADD_COLUMN_FIELD
    }
  )
  const addColumnDef = {
    field: ADD_COLUMN_FIELD,
    headerName: ADD_COLUMN_FIELD,
    type: ADD_COLUMN_FIELD,
    width: ADD_COLUMN_WIDTH_WITH_TEXT,
    maxWidth: ADD_COLUMN_WIDTH_WITH_TEXT,
    pinned: false as Pinned,
    hide: true,
  }

  const haveToPinAddColumn = updateAddColumnDef([
    ...existingColumnDefsWithoutAddColumn,
    newColumnDef,
    addColumnDef,
  ])

  const newColumnDefs = [
    ...existingColumnDefsWithoutAddColumn,
    newColumnDef,
    addColumnDef,
  ]

  gridApi.setGridOption('columnDefs', newColumnDefs)
  gridApi.ensureColumnVisible(haveToPinAddColumn ? id : ADD_COLUMN_FIELD, 'end')

  // 2. add to pending query questions
  addToPendingQueryQuestions(queryQuestion)

  // 3. scroll to the new column
  // if we have to pin the add column, then we scroll to the new column
  // otherwise, we scroll to the add column
  gridApi.ensureColumnVisible(id, 'end')

  return queryQuestion
}

const getDisplayDataType = (dataType: ColumnDataType) => {
  const dataTypeValue = COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION.includes(
    dataType
  )
    ? ColumnDataType.freeResponse
    : dataType === ColumnDataType.numeric
    ? 'number'
    : dataType === ColumnDataType.extraction
    ? 'verbatim'
    : dataType

  return capitalize(dataTypeValue.replace(/_/g, ' '))
}

const getPlaceholderForDataType = (dataType: ColumnDataType) => {
  switch (dataType) {
    case ColumnDataType.date:
      return 'What is the signing date of this agreement?'
    case ColumnDataType.duration:
      return 'What is the cure period in the event of a breach?'
    case ColumnDataType.classify:
      return 'Are the parties required to return or destroy any confidential information?s'
    case ColumnDataType.currency:
      return 'What is the purchase price of this agreement?'
    case ColumnDataType.numeric:
      return 'How many shares are being purchased?'
    case ColumnDataType.extraction:
      return 'What is the notice provision?'
    case ColumnDataType.freeResponse:
      return 'Who is the customer?'
  }
}

const getIconForDataType = (dataType: ColumnDataType) => {
  switch (dataType) {
    case ColumnDataType.date:
      return Calendar
    case ColumnDataType.duration:
      return Clock9
    case ColumnDataType.classify:
      return LayoutList
    case ColumnDataType.currency:
      return CircleDollarSign
    case ColumnDataType.numeric:
      return Hash
    case ColumnDataType.extraction:
      return MessageSquareQuote
    case ColumnDataType.freeResponse:
    default:
      return Text
  }
}

const getDescriptionForDataType = (dataType: ColumnDataType) => {
  switch (dataType) {
    case ColumnDataType.date:
      return 'Extract a specific date from each document.'
    case ColumnDataType.duration:
      return 'Extract a period of time from each document.'
    case ColumnDataType.classify:
      return 'Classify each document into categories among a set of options, from Yes/No to more complicated choices.'
    case ColumnDataType.currency:
      return 'Extract a monetary value from each document.'
    case ColumnDataType.numeric:
      return 'Extract a number, including decimals or percentages, from each document.'
    case ColumnDataType.extraction:
      return 'Extract quoted material exactly as it appears on each document, such as a specific provision.'
    case ColumnDataType.freeResponse:
      return 'Ask any question and get a text response for each document.'
  }
}

const getIllustrationForDataType = (dataType: ColumnDataType) => {
  switch (dataType) {
    case ColumnDataType.extraction:
      return extractionIllustration
    case ColumnDataType.classify:
      return classifyIllustration
    case ColumnDataType.date:
      return dateIllustration
    case ColumnDataType.duration:
      return durationIllustration
    case ColumnDataType.currency:
      return currencyIllustration
    case ColumnDataType.numeric:
      return numericIllustration
    case ColumnDataType.freeResponse:
      return freeResponseIllustration
    default:
      return undefined
  }
}

const fetchSourcesForFileId = async (
  fileId: string,
  queryId: string,
  backingReviewRowId: string
) => {
  const addFileIdToFetchingFileIdsSources =
    useVaultQueryDetailStore.getState().addFileIdToFetchingFileIdsSources
  const addSourcesToFileId =
    useVaultQueryDetailStore.getState().addSourcesToFileId
  let fileSources: ReviewSource[] = []
  try {
    addFileIdToFetchingFileIdsSources(fileId)
    const reviewRow = await FetchReviewRow(queryId, backingReviewRowId)
    fileSources = reviewRow.sources
    addSourcesToFileId(fileId, fileSources)
  } catch (e) {
    // if we fail to fetch the sources, then we are going to remove the sources from the fileId
    addSourcesToFileId(fileId, [])
  }
  return fileSources
}

interface SetHistoryItemHelperParams {
  state: WritableDraft<
    VaultQueryDetailState &
      VaultQueryDetailAction &
      VaultDataGridState &
      VaultDataGridAction &
      AddColumnCellState &
      AddColumnCellAction
  >
  historyItem: HistoryItem | ReviewHistoryItem | null
  newQueryId: string
  isStreamingCells: boolean
}

const setHistoryItemHelper = ({
  state,
  historyItem,
  newQueryId,
  isStreamingCells,
}: SetHistoryItemHelperParams) => {
  const newIsQueryLoading =
    isStreamingCells || computeIsQueryLoading(historyItem)
  const previouslyErroredFileIds = state.historyItem
    ? Object.keys((state.historyItem as ReviewHistoryItem).errors ?? {})
    : []
  state.historyItem = historyItem
  state.queryId = newQueryId
  state.isQueryLoading = newIsQueryLoading

  // Now that we have set the historyItem, we should reset the pending query questions and fileIds
  // because we are not going to use them anymore
  // we do not want to reset when we are creating a new column or new file
  if (!state.currentPendingColumnId) {
    state.pendingQueryQuestions = null
    state.pendingQueryFileIds = null
    state.initialPendingQueryQuestions = null
    state.initialPendingQueryFileIds = null
  }

  // if we are setting the historyItem to null then we should reset our state (when component unMounts)
  state.isFetchingQuery = newQueryId === 'new' ? true : !historyItem
  // we only want to reset the shouldPollForHistoryItem if we are setting the historyItem to null
  state.shouldPollForHistoryItem =
    historyItem === null ? false : state.shouldPollForHistoryItem
  state.hasOnGridReadyExecuted =
    historyItem === null ? false : state.hasOnGridReadyExecuted
  state.queryTitle =
    historyItem === null
      ? LOADING_QUERY_TITLE
      : computeQueryTitle(newQueryId === 'new', historyItem)

  // We want to reset the initialHistoryItem to null when historyItem is null (because the component is unmounting)
  // Otherwise we only want to update initialHistoryItem if it's null (because we are setting the historyItem for the first time)
  state.initialHistoryItem =
    historyItem === null
      ? historyItem
      : state.initialHistoryItem === null
      ? historyItem
      : state.initialHistoryItem
  state.fetchingFileIdsSources =
    historyItem === null ? [] : state.fetchingFileIdsSources
  state.fileIdToSources = !historyItem ? {} : state.fileIdToSources

  const reviewEvent = historyItem as ReviewHistoryItem
  state.hasReachedQuestionsLimit = !historyItem
    ? false
    : reviewEvent.numQuestions >= MAX_QUESTIONS_LIMIT
  // when streaming we want to use the historyItem to update row data
  // only after the hasOnGridReadyExecuted is true, we know that the grid is ready
  // TODO(@sree): this might not be necessary anymore because we are changing when react query is fetching the history item
  if (state.gridApi && historyItem && state.hasOnGridReadyExecuted) {
    const setDisplayedRows =
      useVaultDataGridFilterStore.getState().setDisplayedRows
    const folderIdToVaultFolder = useVaultStore.getState().folderIdToVaultFolder
    const fileIdToVaultFile = useVaultStore.getState().fileIdToVaultFile

    // update columnDefs with columnId
    const currentColumnDefs = state.gridApi.getColumnDefs()
    const updatedColumnDefs = (currentColumnDefs
      ?.map((colDef) => {
        const isIdInColDef = 'colId' in colDef
        if (!isIdInColDef) return colDef
        const colId = colDef.colId
        const historyItemColumn = reviewEvent.columns.find(
          (column) => column.displayId.toString() === colId
        )
        if (historyItemColumn) {
          if (historyItemColumn.isHidden) {
            return null
          }
          return {
            ...colDef,
            suppressMovable: newIsQueryLoading,
            resizable: !newIsQueryLoading,
            eventId: reviewEvent.id,
            backingReviewColumn: historyItemColumn,
          }
        }
        return colDef
      })
      .filter(Boolean) ?? []) as ColDef<any, any>[]
    const newColumns = reviewEvent.columns
      .filter((column) => !column.isHidden)
      .filter(
        (column) =>
          !updatedColumnDefs?.some(
            (colDef) =>
              'colId' in colDef && colDef.colId === column.displayId.toString()
          )
      )
      .map((column) => {
        return {
          suppressMovable: newIsQueryLoading,
          resizable: !newIsQueryLoading,
          ...mapReviewColumnToColumnDef(column, reviewEvent.eventId),
        }
      })
    const allColumnDefs = [...updatedColumnDefs, ...newColumns]
    allColumnDefs.sort((a, b) => {
      // Prioritize pinned columns
      const aField = a.field ?? ''
      const bField = b.field ?? ''
      const isAPinned = LEFT_PINNED_COLUMNS.includes(aField)
      const isBPinned = LEFT_PINNED_COLUMNS.includes(bField)

      if (isAPinned && !isBPinned) return -1
      if (!isAPinned && isBPinned) return 1

      const aOrder =
        'backingReviewColumn' in a
          ? a.backingReviewColumn.order
          : // XXX: Add a large number to make sure pending columns are always at the end
            allColumnDefs.indexOf(a) + 10_000
      const bOrder =
        'backingReviewColumn' in b
          ? b.backingReviewColumn.order
          : // XXX: Add a large number to make sure pending columns are always at the end
            allColumnDefs.indexOf(b) + 10_000
      return aOrder - bOrder
    })
    state.gridApi.setGridOption('columnDefs', allColumnDefs)

    const historyFilesToAdd =
      (reviewEvent?.rows
        .filter((row) => !row.isHidden)
        .map((row) => fileIdToVaultFile[row.fileId])
        .filter(Boolean) as VaultFile[]) ?? []

    addFilesToGrid({
      gridApi: state.gridApi as GridApi<any>,
      setDisplayedRows: setDisplayedRows,
      files: historyFilesToAdd,
      folderIdToVaultFolder: folderIdToVaultFolder,
      isLoading: newIsQueryLoading,
      gridAction: GridTransactionAction.UPDATE,
      fileIdToAnswers: reviewEvent.answers,
      fileIdToErrors: reviewEvent.errors,
      previouslyErroredFileIds: previouslyErroredFileIds,
      questions: reviewEvent.questions,
      reviewRows: reviewEvent.rows,
      suppressedFileIdsSet: new Set(reviewEvent.suppressedFileIds),
    })
  }
}

interface abortSSEQueryProps {
  queryId: string
  getAbortController: (key: string) => AbortController | undefined
  removeAbortController: (key: string) => void
}
const abortSSEQuery = ({
  queryId,
  getAbortController,
  removeAbortController,
}: abortSSEQueryProps) => {
  const abortController = getAbortController(queryId)
  if (abortController) {
    abortController.abort()
    removeAbortController(queryId)
  }
}

export {
  handleMouseEnter,
  handleMouseLeave,
  handleLeftBorderReset,
  computeLeftCell,
  computeTopCell,
  invalidateQuery,
  computeIsQueryLoading,
  getDisplayedRows,
  createGridColumnDefs,
  addFilesToGrid,
  computeNextColumnId,
  hideAddColumnColumnDef,
  updateAddColumnDef,
  shouldPinAddColumn,
  addNewColumn,
  getDisplayDataType,
  getPlaceholderForDataType,
  getIconForDataType,
  getDescriptionForDataType,
  getIllustrationForDataType,
  fetchSourcesForFileId,
  setHistoryItemHelper,
  abortSSEQuery,
}
