import * as React from 'react'

import { camelCase } from 'lodash'

import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'
import { WorkflowTableSampleOutput } from 'openapi/models/WorkflowTableSampleOutput'

import { MOCK_DATA } from 'components/assistant/workflows/components/preview-modal/mock-data'
import Markdown from 'components/common/markdown/markdown'
import Citation from 'components/ui/citation'
import Icon from 'components/ui/icon/icon'
import { OUTPUT_TYPE_TO_ICON } from 'components/workflows/workflow-card'

interface TablePreviewProps {
  workflow: WorkflowDefinitionListItem
}

const TablePreview: React.FC<TablePreviewProps> = ({ workflow }) => {
  const icon = OUTPUT_TYPE_TO_ICON[workflow.outputType]
  let table: WorkflowTableSampleOutput['data'] = MOCK_DATA.table
  if (workflow.sampleOutput) {
    const sampleOutput = workflow.sampleOutput as WorkflowTableSampleOutput
    if (sampleOutput.data.orderedColumnNames) {
      table = sampleOutput.data
    }
  }

  return (
    <div
      data-testid="table-preview"
      className="relative size-full max-h-[380px] max-w-[404px] overflow-hidden rounded-lg border bg-primary drop-shadow-sm"
    >
      <div>
        <div className="border-b px-[10px] py-3">
          <div className="flex items-center gap-1">
            <Icon icon={icon} size="small" className="text-secondary" />
            <span className="text-xs text-secondary">{workflow.name}</span>
          </div>
        </div>
        <table className="w-[600px] max-w-none divide-y divide-primary">
          <thead className="m-0">
            <tr className="divide-x divide-primary">
              {table.orderedColumnNames!.map((column) => (
                <th key={column.key} scope="col" className="px-3 py-2">
                  <span className="line-clamp-1 text-xs font-medium text-secondary">
                    {column.name}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-x divide-y divide-primary">
            {table.rows.map((row) => (
              <tr key={row.data.name} className="divide-x divide-primary">
                {table.orderedColumnNames!.map((column) => (
                  <td key={column.key} className="px-3 py-2">
                    <Markdown
                      content={row.data[camelCase(column.key)]}
                      className="prose-xs line-clamp-1 font-normal text-secondary"
                      components={{
                        span: ({ children, ...props }) => {
                          if ('data-hrvy-id' in props) {
                            return <Citation>{children}</Citation>
                          }
                          return <span {...props}>{children}</span>
                        },
                      }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-28 w-full bg-gradient-to-t from-white to-transparent" />
    </div>
  )
}

export default TablePreview
