import React, { useEffect, useMemo, useState } from 'react'
import { useMount, useUnmount } from 'react-use'

import { Folder, FolderOpen, Users } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { bytesToPreciseReadable } from 'utils/file-utils'
import { backendToReadable, cn } from 'utils/utils'

import {
  isVaultKnowledgeSource,
  KnowledgeSourceItem,
  VAULT_SOURCE_SELECT_FILES_DESCRIPTION,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { SkeletonBlock } from 'components/ui/skeleton'
import { Spinner } from 'components/ui/spinner'
import { VaultCreateProjectCard } from 'components/vault/components/new-project/vault-create-project-card'
import VaultProjectCard from 'components/vault/components/vault-project-card'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { useVaultProjectsMetadata } from 'components/vault/utils/use-vault-project-metadata'
import { newProjectPath } from 'components/vault/utils/vault'
import { VaultProjectMetadata } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

import AssistantProjectFileSelector from './assistant-project-file-selector'

interface Props {
  knowledgeSource: KnowledgeSourceItem | null
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
  onClose: () => void
  numberOfFilesLimit?: number
  numberOfFilesWarningThreshold?: number
}

const VaultKnowledgeSourcePicker = ({
  knowledgeSource,
  setKnowledgeSource,
  showDialog,
  setShowDialog,
  onClose,
  numberOfFilesLimit,
  numberOfFilesWarningThreshold,
}: Props) => {
  const [fileIdToVaultFile, currentProject, setCurrentProject] = useVaultStore(
    useShallow((s) => [
      s.fileIdToVaultFile,
      s.currentProject,
      s.setCurrentProject,
    ])
  )

  const isVaultSource = isVaultKnowledgeSource(knowledgeSource)

  const activeFileIds = useMemo(
    () => new Set(isVaultSource ? knowledgeSource.fileIds : []),
    [knowledgeSource, isVaultSource]
  )
  const activeFiles = Array.from(activeFileIds)
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]

  const {
    projects: allVaultProjects,
    isFetching,
    areProjectsLoaded,
  } = useVaultProjects(undefined, {
    includeExamples: false,
    loadAllMetadata: true,
    // activeFileIds.size !== activeFiles.length if we 'Reuse query' in new tab
    isEnabled: showDialog || activeFileIds.size !== activeFiles.length,
  })

  const handleClose = () => {
    setKnowledgeSource(null)
    setCurrentProject(null)
    onClose()
  }

  useUnmount(() => {
    setCurrentProject(null)
  })

  const handleSelectProject = (project: VaultFolder) => {
    setCurrentProject(project)
  }

  const isEmpty = areProjectsLoaded && allVaultProjects.length === 0
  const dialogTitle = currentProject
    ? currentProject.isKnowledgeBaseProject
      ? `Add folders from ${currentProject.name}`
      : `Add files from ${currentProject.name}`
    : 'Choose project'
  const dialogDescription = currentProject
    ? currentProject.isKnowledgeBaseProject
      ? 'Select folders from this knowledge base to query'
      : VAULT_SOURCE_SELECT_FILES_DESCRIPTION
    : 'Select a Vault project to browse files'
  const isCurrentProjectKnowledgeBase = currentProject?.isKnowledgeBaseProject

  return (
    <AssistantProjectFileSelector
      showDialog={showDialog}
      setShowDialog={setShowDialog}
      onClose={handleClose}
      isEmpty={isEmpty}
      dialogTitle={dialogTitle}
      dialogDescription={dialogDescription}
      isFetching={isFetching}
      allVaultProjects={allVaultProjects}
      activeFileIds={activeFileIds}
      numberOfFilesLimit={numberOfFilesLimit}
      numberOfFilesWarningThreshold={numberOfFilesWarningThreshold}
      emptyState={<EmptyProjects />}
      projectChooser={
        <ProjectChooser
          isLoaded={areProjectsLoaded}
          onSelectProject={handleSelectProject}
          projects={allVaultProjects}
        />
      }
      setKnowledgeSource={setKnowledgeSource}
      isCurrentProjectKnowledgeBase={isCurrentProjectKnowledgeBase}
    />
  )
}

export default VaultKnowledgeSourcePicker

export const ProjectChooser = ({
  isLoaded,
  onSelectProject,
  projects,
  shouldShowCreateProjectCard = true,
  shouldAllowKnowledgeBaseProjects = true,
  currentProjectId,
}: {
  isLoaded: boolean
  onSelectProject: (project: VaultFolder) => void
  projects: VaultFolder[]
  shouldShowCreateProjectCard?: boolean
  shouldAllowKnowledgeBaseProjects?: boolean
  currentProjectId?: string
}) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const sharedProjectIds = useVaultStore((s) => s.sharedProjectIds)
  const isLoadingProjects = !isLoaded && !projects.length
  const { projectsMetadata, isLoadingProjectsMetadata } =
    useVaultProjectsMetadata(projects.map((project) => project.id))
  const currentProjectIndex = useMemo(
    () =>
      !currentProjectId
        ? -1
        : projects.findIndex((p) => p.id === currentProjectId),
    [projects, currentProjectId]
  )
  const currentProject = useMemo(() => {
    if (currentProjectIndex < 0) return null
    return projects[currentProjectIndex]
  }, [projects, currentProjectIndex])
  const otherProjects = useMemo(() => {
    if (currentProjectIndex < 0) return projects
    return [
      ...projects.slice(0, currentProjectIndex),
      ...projects.slice(currentProjectIndex + 1),
    ]
  }, [projects, currentProjectIndex])

  const createProjectCard = (project: VaultFolder) => {
    return (
      <ProjectCard
        key={project.id}
        project={project}
        isLoadingMetadata={
          isLoadingProjectsMetadata || !projectsMetadata?.[project.id]
        }
        projectMetadata={projectsMetadata?.[project.id]}
        onClick={() => {
          onSelectProject(project)
          trackEvent('Vault File Selector Viewed', {
            source: 'Assistant',
          })
        }}
        isShared={sharedProjectIds.has(project.id)}
        shouldAllowKnowledgeBaseProjects={shouldAllowKnowledgeBaseProjects}
      />
    )
  }
  return (
    <div className="flex flex-col items-start">
      {currentProject && (
        <>
          <p className="px-6 pb-2 text-sm font-medium">Current project</p>
          <div className="grid w-full grid-cols-1 gap-3 p-6 pt-1 sm:grid-cols-3">
            {createProjectCard(currentProject)}
          </div>
          {otherProjects.length > 0 && (
            <p className="px-6 pb-2 text-sm font-medium">Other projects</p>
          )}
        </>
      )}
      <div className="grid w-full grid-cols-1 gap-3 p-6 pt-1 sm:grid-cols-3">
        {otherProjects.map((project) => createProjectCard(project))}
        {!isLoadingProjects && shouldShowCreateProjectCard && (
          <VaultCreateProjectCard
            size="small"
            className={cn('h-40', {
              'h-[DEFAULT_PROJECT_CARD_HEIGHT]':
                userInfo.IsKnowledgeBaseProjectUser,
            })}
          />
        )}
        {isLoadingProjects &&
          [...new Array(4)].map((_, i) => (
            <ProjectCard
              key={i}
              project={{ id: `vault-placeholder-${i}` } as VaultFolder}
              isLoadingMetadata
              projectMetadata={undefined}
              isLoading
            />
          ))}
      </div>
    </div>
  )
}

export const EmptyProjects = () => {
  const { trackEvent } = useAnalytics()

  useMount(() => {
    trackEvent('No Vault Projects Dialog Shown')
  })

  const handleNewClick = () => {
    trackEvent('Vault New Project Button Clicked', {
      source: 'use-vault-in-assistant',
    })
  }

  return (
    <div className="flex flex-col items-center px-6 py-12 text-sm">
      <FolderShieldIcon className="size-6 shrink-0" />
      <div className="my-2 text-lg font-semibold">
        Create a project to get started
      </div>
      <p className="mb-4 max-w-md text-center text-muted">
        Vault allows you to upload and store files and perform sophisticated
        analysis and search across them.
      </p>
      <Button
        onClick={handleNewClick}
        to={`${BaseAppPath.Vault}${newProjectPath}`}
      >
        New project
      </Button>
    </div>
  )
}

const ProjectCard = ({
  project,
  isLoadingMetadata,
  projectMetadata,
  onClick,
  isLoading,
  isShared,
  shouldAllowKnowledgeBaseProjects,
  isCurrentProject,
}: {
  project: VaultFolder
  isLoadingMetadata: boolean
  projectMetadata?: VaultProjectMetadata
  onClick?: () => void
  isLoading?: boolean
  isShared?: boolean
  shouldAllowKnowledgeBaseProjects?: boolean
  isCurrentProject?: boolean
}) => {
  const userInfo = useAuthUser()
  const numProjectFiles = projectMetadata?.filesCount || 0
  const projectSize = bytesToPreciseReadable(
    projectMetadata?.totalSize || 0,
    2,
    true
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: project.id,
  })

  const canUserUseProjectAsKnowledgeSource =
    doesCurrentUserHaveEditPermission ||
    (project.isKnowledgeBaseProject && userInfo.IsKnowledgeBaseProjectUser)
  const disabled =
    isLoading ||
    !canUserUseProjectAsKnowledgeSource ||
    (!shouldAllowKnowledgeBaseProjects && project.isKnowledgeBaseProject)
  const disabledTooltip = !canUserUseProjectAsKnowledgeSource
    ? 'You do not have permission to run queries on this project'
    : !shouldAllowKnowledgeBaseProjects && project.isKnowledgeBaseProject
    ? 'Cannot use a knowledge base to create Review tables. Save your desired files into a new Vault project.'
    : undefined

  if (userInfo.IsKnowledgeBaseProjectUser) {
    return (
      <VaultProjectCard
        vaultProject={project}
        isShared={isShared}
        isCurrentProject={isCurrentProject}
        isLoadingMetadata={isLoadingMetadata}
        projectMetadata={projectMetadata}
        onClick={onClick}
        disabled={disabled}
        disabledTooltip={disabledTooltip}
      />
    )
  }

  return (
    <Button
      className="flex h-40 w-full flex-col items-start justify-start p-4"
      disabled={isLoading || !canUserUseProjectAsKnowledgeSource}
      onClick={onClick}
      tooltip={
        !canUserUseProjectAsKnowledgeSource
          ? 'You do not have permission to run queries on this project'
          : undefined
      }
      variant="outline"
    >
      <div className="text-xs text-muted">
        {isLoading ? (
          <SkeletonBlock className="h-4 w-24" hasDarkBackground />
        ) : (
          `Updated ${backendToReadable(project.updatedAt)}`
        )}
      </div>
      {isLoading ? (
        <SkeletonBlock className="mt-1 h-4 w-48" hasDarkBackground />
      ) : (
        <p className="line-clamp-3 w-full text-left">{project.name}</p>
      )}
      <div className="mt-auto w-full space-y-1">
        {isShared && (
          <div className="flex items-center space-x-1">
            <Icon icon={Users} size="small" className="text-muted" />
            <p className="truncate text-xs text-muted">
              Shared{project.userEmail && ` by ${project.userEmail}`}
            </p>
          </div>
        )}
        {isCurrentProject && (
          <div className="flex items-center space-x-1 text-xs text-muted">
            <Icon icon={FolderOpen} size="small" />
            <p className="text-xs">Current project</p>
          </div>
        )}
        <div className="flex items-center space-x-1">
          <Icon icon={Folder} size="small" />
          {isLoadingMetadata ? (
            <SkeletonBlock className="h-4 w-32" hasDarkBackground />
          ) : (
            <p className="text-xs">
              {numProjectFiles === 0
                ? 'No files uploaded'
                : `${pluralizeFiles(numProjectFiles)} (${projectSize})`}
            </p>
          )}
        </div>
      </div>
    </Button>
  )
}

export const FetchingSpinner = ({ delayMs }: { delayMs: number }) => {
  const [isVisible, setIsVisible] = useState(!delayMs)

  useEffect(() => {
    if (!delayMs) return

    // Show spinner if request > 1 second
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delayMs)

    return () => clearTimeout(timer)
  })

  if (!isVisible) return null

  return <Spinner size="xxs" className="ml-3" />
}
