import React from 'react'

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { produce } from 'immer'
import { Trash2 } from 'lucide-react'

import { ExperimentQuestionWithConfig } from 'components/settings/experiment/utils/experiment-utils'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'
import { Checkbox } from 'components/ui/checkbox'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip'

interface EvaluationQuestionsTableProps {
  questions: ExperimentQuestionWithConfig[]
  setQuestions: (
    updater: (
      prev: ExperimentQuestionWithConfig[]
    ) => ExperimentQuestionWithConfig[]
  ) => void
  isSubmitting?: boolean
}

const columnHelper = createColumnHelper<ExperimentQuestionWithConfig>()

export function EvaluationQuestionsTable({
  questions,
  setQuestions,
  isSubmitting = false,
}: EvaluationQuestionsTableProps) {
  const removeEvaluationQuestion = (id: string) => {
    setQuestions((prev: ExperimentQuestionWithConfig[]) =>
      prev.filter((question) => question.question.id !== id)
    )
  }

  const updateRequiredStatus = (id: string, isRequired: boolean) => {
    setQuestions(
      produce((prev: ExperimentQuestionWithConfig[]) => {
        const question = prev.find((q) => q.id === id)
        if (question) {
          question.experimentConfig.isRequired = isRequired
        }
      })
    )
  }

  const columns = [
    columnHelper.accessor((row) => row.question.question, {
      id: 'question',
      header: 'Question',
      cell: (info) => (
        <div>
          <div className="font-medium">{info.getValue()}</div>
          <div className="text-muted-foreground text-sm">
            {info.row.original.question.questionDescription}
          </div>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.question.questionType, {
      id: 'type',
      header: 'Type',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => !row.experimentConfig.isRequired, {
      id: 'optional',
      header: 'Optional',
      cell: (info) => (
        <div className="flex items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Checkbox
                  id={`optional-${info.row.original.question.id}`}
                  checked={info.getValue()}
                  onCheckedChange={(checked: boolean) =>
                    updateRequiredStatus(
                      info.row.original.question.id,
                      !checked
                    )
                  }
                  disabled={isSubmitting}
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  If marked optional, missing responses to this question will
                  not count against the completed total.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.question.id, {
      id: 'actions',
      header: 'Actions',
      cell: (info) => (
        <Button
          variant="outline"
          size="icon"
          onClick={() => removeEvaluationQuestion(info.getValue())}
          disabled={isSubmitting}
        >
          <Trash2 className="h-4 w-4 stroke-destructive" />
        </Button>
      ),
    }),
  ]

  const table = useReactTable({
    data: questions,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Card className="mt-6">
      <CardContent>
        <ScrollArea className="min-h-[100px] w-full">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </CardContent>
    </Card>
  )
}
