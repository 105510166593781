import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { formatDistanceToNow } from 'date-fns'
import _ from 'lodash'
import { Ban, Plus, Trash } from 'lucide-react'

import {
  BulkRevokePermBundlesFromUsersInternalAdmin,
  BulkRevokePermsFromUsers,
  Perm,
  WorkspacePermBundle,
  PermSource,
  FetchWorkspacePermBundlesInternalAdmin,
} from 'models/perms'
import { Permission } from 'models/user-info'
import { instanceOfBetaPermission } from 'openapi/models/BetaPermission'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn, parseIsoString } from 'utils/utils'

import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import BetaPermBadge from 'components/settings/workspace/permissions/beta-perm-badge'
import WorkspacePermissionsDialog from 'components/settings/workspace/workspace-details/workspace-permissions-dialog'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import Expander from 'components/ui/data-table/expander'
import { Dialog } from 'components/ui/dialog'

const READABLE_SOURCE_NAME: Record<PermSource, string> = {
  [PermSource.USER]: 'User',
}

type PermBundleRowProps = {
  type: 'permBundle'
  permBundle: WorkspacePermBundle
  children: PermRowProps[]
}

type PermRowProps = {
  type: 'perm'
  perm: Perm
  isLast?: boolean
  children?: never
}

type RowProps = PermBundleRowProps | PermRowProps

interface UserPermBundlesTableProps {
  workspaceId: number
  userEmail: string
  perms: Perm[]
  refetchPerms: () => Promise<void>
}

const UserPermBundlesTable: React.FC<UserPermBundlesTableProps> = ({
  workspaceId,
  userEmail,
  perms,
  refetchPerms,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'id', desc: false },
  ])
  const [expanded, setExpanded] = useState<ExpandedState>(true)
  const [permBundles, setPermBundles] = useState<WorkspacePermBundle[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [permDialogOpen, setPermDialogOpen] = useState(false)
  const [confirmingDelete, setConfirmingDelete] = useState<{
    bundleId: PermissionBundleId
  } | null>(null)
  const [confirmingDeletePerm, setConfirmingDeletePerm] = useState<{
    permId: Permission
  } | null>(null)

  const fetchPermBundles = useCallback(async () => {
    try {
      setIsLoading(true)
      const bundles = await FetchWorkspacePermBundlesInternalAdmin(workspaceId)
      setPermBundles(bundles)
    } catch (error) {
      displayErrorMessage('Failed to fetch permission bundles')
    } finally {
      setIsLoading(false)
    }
  }, [workspaceId])

  useEffect(() => {
    void fetchPermBundles()
  }, [fetchPermBundles])

  const handleDeletePermBundle = async (bundleId: PermissionBundleId) => {
    try {
      await BulkRevokePermBundlesFromUsersInternalAdmin(
        workspaceId,
        [userEmail],
        [bundleId]
      )
      await refetchPerms()
      displaySuccessMessage(`Removed permission bundle for ${userEmail}`)
    } catch (error) {
      displayErrorMessage('Failed to remove permission bundle')
    }
  }

  const handleDeleteBetaPerm = async (permId: Permission) => {
    try {
      await BulkRevokePermsFromUsers([userEmail], [permId])
      await refetchPerms()
      displaySuccessMessage(`Removed permission ${permId} for ${userEmail}`)
    } catch (error) {
      displayErrorMessage('Failed to remove beta permission')
    }
  }

  const columns = useMemo<ColumnDef<RowProps>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return (
              <div className="flex w-[360px] items-center gap-2">
                <Expander row={row} />
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-medium">
                    {row.original.permBundle.name}
                  </span>
                  <span className="text-sm text-muted">
                    {row.original.permBundle.description}
                  </span>
                </div>
              </div>
            )
          }
          return (
            <div className="ml-10 flex flex-col gap-1">
              <span className="text-sm">{row.original.perm.name}</span>
              <span className="text-sm text-muted">
                {row.original.perm.desc}
              </span>
            </div>
          )
        },
      },
      {
        accessorKey: 'id',
        header: 'ID',
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return (
              <pre className="text-xs font-semibold">
                {row.original.permBundle.id}
              </pre>
            )
          }
          return (
            <pre className="flex items-center gap-2 text-xs">
              {row.original.perm.permId}
              {instanceOfBetaPermission(row.original.perm.permId) && (
                <BetaPermBadge />
              )}
            </pre>
          )
        },
      },
      {
        accessorKey: 'source',
        header: 'Source',
        size: 60,
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return null
          }
          const sources = row.original.perm.permSources ?? []
          return (
            <div className="flex flex-wrap">
              {sources.map((source, idx) => {
                const readableSource =
                  READABLE_SOURCE_NAME[source as PermSource] || source
                return (
                  <div key={idx} className="m-0.5">
                    <Badge variant="secondary" className="text-xs">
                      {readableSource}
                    </Badge>
                  </div>
                )
              })}
            </div>
          )
        },
      },
      {
        accessorKey: 'expiresAt',
        header: 'Expiration',
        size: 60,
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            return null
          }
          const expiresAt = row.original.perm.expiresAt
          if (!expiresAt) {
            return <span className="text-sm">Never</span>
          }
          const expiresAtDate = parseIsoString(expiresAt)
          const timeDifference = _.startCase(
            formatDistanceToNow(expiresAtDate, { addSuffix: true })
          )
          return <span className="text-sm">{timeDifference}</span>
        },
      },
      {
        id: 'actions',
        size: 24,
        header: '',
        cell: ({ row }) => {
          if (row.original.type === 'permBundle') {
            const userPermsInBundle = perms.filter(
              (perm) =>
                row.original.type === 'permBundle' &&
                row.original.permBundle.permissions.includes(
                  perm.permId as Permission
                ) &&
                (perm.permSources ?? []).includes(PermSource.USER)
            )

            const hasUserPerms = userPermsInBundle.length > 0
            return (
              <div
                className={cn('transition group-hover:visible', {
                  invisible: hasUserPerms,
                })}
              >
                {hasUserPerms ? (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      if (row.original.type === 'permBundle') {
                        setConfirmingDelete({
                          bundleId: row.original.permBundle
                            .id as PermissionBundleId,
                        })
                      }
                    }}
                  >
                    <Trash className="size-4 text-destructive" />
                  </Button>
                ) : (
                  <Button
                    variant="ghost"
                    size="icon"
                    disabled
                    className="cursor-not-allowed"
                    tooltip="All permissions in this bundle are part of the user’s assigned role and cannot be deleted."
                  >
                    <Ban className="size-4" />
                  </Button>
                )}
              </div>
            )
          }

          const perm = row.original.perm
          if (
            instanceOfBetaPermission(perm.permId) &&
            (perm.permSources ?? []).includes(PermSource.USER)
          ) {
            return (
              <div className="invisible transition group-hover:visible">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    setConfirmingDeletePerm({
                      permId: perm.permId as Permission,
                    })
                  }}
                >
                  <Trash className="size-4 text-destructive" />
                </Button>
              </div>
            )
          }

          return null
        },
      },
    ],
    [perms]
  )

  const rows = useMemo<PermBundleRowProps[]>(
    () =>
      permBundles
        .map((bundle) => {
          const bundlePerms = perms.filter((perm) =>
            bundle.permissions.includes(perm.permId as Permission)
          )
          if (bundlePerms.length === 0) {
            return null
          }

          return {
            type: 'permBundle',
            permBundle: bundle,
            children: bundlePerms.map<PermRowProps>((perm, index) => ({
              type: 'perm',
              perm,
              isLast: index === bundlePerms.length - 1,
            })),
          }
        })
        .filter((row): row is PermBundleRowProps => row !== null),
    [permBundles, perms]
  )

  const existingPermBundleIds = rows.map((row) => row.permBundle.id)
  const existingExtraPermBundleIds = rows
    .filter((row) =>
      row.children.some(
        (child) => child.perm.permSources?.includes(PermSource.USER)
      )
    )
    .map((row) => row.permBundle.id)
  const existingBetaPermIds = perms
    .filter((perm) => instanceOfBetaPermission(perm.permId))
    .map((perm) => perm.permId as Permission)

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getRowId: (row) => {
      return row.type === 'permBundle' ? row.permBundle.id : row.perm.permId
    },
    getSubRows: (row) => row.children,
    onExpandedChange: setExpanded,
    state: {
      sorting,
      expanded,
    },
  })

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-lg font-semibold">Current Permissions</h1>
        <Button onClick={() => setPermDialogOpen(true)}>
          <Plus className="mr-1 size-4" />
          Add permissions
        </Button>
      </div>
      <DataTable
        table={table}
        isLoading={isLoading}
        tableCellClassName="p-3"
        tableRowClassName={(row: Row<RowProps>) =>
          cn(row.original.type === 'permBundle' ? 'bg-secondary' : '', 'group')
        }
      />
      <div className="mt-2 flex justify-end text-sm text-muted">
        {perms.length} permissions
      </div>

      {confirmingDelete && (
        <Dialog open onOpenChange={() => setConfirmingDelete(null)}>
          <ConfirmationDialog
            title="Remove Permission Bundle"
            description={`Are you sure you want to remove this permission bundle? This will remove all the underlying user permissions for ${userEmail}.`}
            variant="destructive"
            cta={{
              label: 'Remove',
              onClick: () => handleDeletePermBundle(confirmingDelete.bundleId),
            }}
            secondaryCta={{
              label: 'Cancel',
              onClick: () => setConfirmingDelete(null),
            }}
          />
        </Dialog>
      )}

      {confirmingDeletePerm && (
        <Dialog open onOpenChange={() => setConfirmingDeletePerm(null)}>
          <ConfirmationDialog
            title="Remove Beta Permission"
            description={`Are you sure you want to remove permission ${confirmingDeletePerm.permId} for ${userEmail}?`}
            variant="destructive"
            cta={{
              label: 'Remove',
              onClick: () => handleDeleteBetaPerm(confirmingDeletePerm.permId),
            }}
            secondaryCta={{
              label: 'Cancel',
              onClick: () => setConfirmingDeletePerm(null),
            }}
          />
        </Dialog>
      )}

      {permDialogOpen && (
        <WorkspacePermissionsDialog
          isOpen
          addOnly
          skipResults
          isInternalAdmin
          includeBetaPermissions
          excludedBetaPermissions={existingBetaPermIds}
          workspaceId={workspaceId}
          onClose={() => setPermDialogOpen(false)}
          onComplete={() => {
            setPermDialogOpen(false)
            void refetchPerms()
          }}
          selectedUsers={[
            {
              email: userEmail,
              permBundleIds: existingPermBundleIds,
              extraPermBundleIds: existingExtraPermBundleIds,
            },
          ]}
        />
      )}
    </div>
  )
}

export default UserPermBundlesTable
