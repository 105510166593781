import React from 'react'

import { VaultFolder } from 'openapi/models/VaultFolder'

import {
  EmptyProjects,
  FetchingSpinner,
  ProjectChooser,
} from 'components/assistant/features/composer/assistant-vault-ks-picker'
import {
  DialogTitle,
  DialogHeader,
  DialogDescription,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import { VaultCreateProjectCard } from 'components/vault/components/new-project/vault-create-project-card'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'

const VaultWorkflowProjectChooser = ({
  selectedWorkflowName,
  handleSelectProjectForWorkflow,
  handleCreateProjectCardClick,
  currentProjectId,
}: {
  selectedWorkflowName: string
  handleSelectProjectForWorkflow: (project: VaultFolder) => void
  handleCreateProjectCardClick?: () => void
  currentProjectId?: string
}) => {
  const {
    projects: allVaultProjects,
    isFetching,
    areProjectsLoaded,
  } = useVaultProjects(undefined, {
    includeExamples: false,
    loadAllMetadata: true,
    isEnabled: true,
  })

  const handleSelectProject = (project: VaultFolder) => {
    handleSelectProjectForWorkflow(project)
  }

  const isEmpty = areProjectsLoaded && allVaultProjects.length === 0

  if (isEmpty) {
    return <EmptyProjects />
  }

  const hideCreateProjectCard = !handleCreateProjectCardClick

  return (
    <>
      <DialogHeader className="h-16 flex-none border-b border-b-primary px-6">
        <DialogTitle className="flex items-center">
          Choose project for {selectedWorkflowName}
          {isFetching && (
            <FetchingSpinner delayMs={allVaultProjects.length > 0 ? 1000 : 0} />
          )}
        </DialogTitle>
        <DialogDescription>
          {hideCreateProjectCard
            ? 'Select a Vault project'
            : 'Select a Vault project or start one from scratch'}
        </DialogDescription>
      </DialogHeader>
      <ScrollArea className="h-full">
        <div className="flex flex-col gap-y-8 pt-4">
          {!hideCreateProjectCard && (
            <div className="grid w-full grid-cols-1 justify-start gap-3 px-6 sm:grid-cols-3">
              <VaultCreateProjectCard
                onClick={handleCreateProjectCardClick}
                source="workflow-modal"
              />
            </div>
          )}
          <div className="flex flex-col gap-y-4">
            {!hideCreateProjectCard && (
              <p className="px-6 text-sm font-medium">Existing projects</p>
            )}
            <ProjectChooser
              isLoaded={areProjectsLoaded}
              onSelectProject={handleSelectProject}
              projects={allVaultProjects}
              shouldShowCreateProjectCard={false}
              shouldAllowKnowledgeBaseProjects={false}
              currentProjectId={currentProjectId}
            />
          </div>
        </div>
      </ScrollArea>
    </>
  )
}

export default VaultWorkflowProjectChooser
