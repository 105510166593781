import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { useAuth0 } from '@auth0/auth0-react'
import { useFlowEntryPointNavigator } from 'providers/modal-flow-provider/modal-flow-controls'
import { useShallow } from 'zustand/react/shallow'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import { ProfileSetupFlowType } from 'components/common/flows/profile-setup/profile-setup'
import { EntryPointType } from 'components/common/flows/profile-setup/utils/entry-point-types'
import { useUserProfileStore } from 'components/common/user-profile-store'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import SettingsPageHeader from 'components/settings/settings-page-header'
import { Button } from 'components/ui/button'

import ProfileForm from './profile-form'

const ProfilePage: React.FC = () => {
  const { user } = useAuth0()
  const {
    requiresOnboardingNewUser,
    requiresOnboardingExistingUser,
    userProfile,
  } = useUserProfileStore(
    useShallow((s) => ({
      requiresOnboardingNewUser: s.requiresOnboardingNewUser,
      requiresOnboardingExistingUser: s.requiresOnboardingExistingUser,
      userProfile: s.userProfile,
    }))
  )
  const { trackEvent } = useAnalytics()

  const enterProfileSetupFlow =
    useFlowEntryPointNavigator<ProfileSetupFlowType>('profile-setup')

  const shouldRenderProfileForm =
    !requiresOnboardingNewUser && !requiresOnboardingExistingUser && userProfile

  React.useEffect(() => {
    trackEvent('profile_settings_page_viewed')
  }, [trackEvent])
  if (!user) return <SettingsError />

  const handleContinue = () => {
    enterProfileSetupFlow('welcome-step', {
      entryPointType: EntryPointType.SETTINGS_PAGE,
    })
    trackEvent('profile_setup_started', {
      entry_point_type: EntryPointType.SETTINGS_PAGE,
    })
  }

  const renderProfileOnboarding = () => (
    <div className="space-y-4">
      <div className="flex items-center justify-between rounded-md bg-secondary p-3 pl-4">
        <p className="text-xs text-primary">
          Create your profile to personalize your Harvey experience.
        </p>
        <Button onClick={handleContinue}>Continue</Button>
      </div>
    </div>
  )

  return (
    <>
      <SettingsAppHeader />
      <SettingsLayout>
        <ErrorBoundary
          onError={(error: Error) => {
            console.error(error)
          }}
          fallbackRender={() => (
            <ErrorPage
              title={ErrorPageTitle.SOMETHING_WENT_WRONG}
              className="h-80"
            />
          )}
        >
          <div className="max-w-[600px] space-y-4">
            <SettingsPageHeader
              title="Profile"
              subtitle="This information is used to personalize your experience, with additional content tailored to your profession, practice areas, and language."
            />
            {shouldRenderProfileForm ? (
              <ProfileForm />
            ) : (
              renderProfileOnboarding()
            )}
          </div>
        </ErrorBoundary>
      </SettingsLayout>
    </>
  )
}

export default ProfilePage
