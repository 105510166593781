import React, { useMemo, useRef, useState, useEffect } from 'react'

import { FileTextIcon, SquareMousePointerIcon, Table2 } from 'lucide-react'
import pluralize from 'pluralize'

import { ReviewWorkflowCobrand } from 'openapi/models/ReviewWorkflowCobrand'
import { Tag } from 'openapi/models/Tag'
import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'
import { WorkflowOutputType } from 'openapi/models/WorkflowOutputType'

import { cn } from 'utils/utils'

import AOLogo from 'components/assistant/workflows/blocks/antitrust-filing-analysis/summary/ao-logo'
import { Card } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import { SimpleDocumentClassificationPill } from 'components/vault/components/vault-document-classification'

import { WorkflowSource } from './workflows-helpers'

export enum WorkflowCategory {
  ASSISTANT = 'assistant',
  VAULT = 'vault',
  LEGACY = 'legacy',
}

type BaseWorkflowInfo = {
  id: string
  name: string
  description: string
  category: WorkflowCategory
}

export type VaultWorkflowInfo = BaseWorkflowInfo & {
  category: WorkflowCategory.VAULT
  numColumns: number
  documentTag?: Tag
  cobrand?: ReviewWorkflowCobrand
}

export type LegacyWorkflowInfo = BaseWorkflowInfo & {
  category: WorkflowCategory.LEGACY
  path: string
}

export type AssistantWorkflowInfo = BaseWorkflowInfo & {
  slug: string
  tags: string[]
  userInputStepCount: number
  category: WorkflowCategory.ASSISTANT
  path: string
  outputType: WorkflowOutputType
  docParamsNeededToStart?: WorkflowDefinitionListItem['docParamsNeededToStart']
  sampleOutput?: WorkflowDefinitionListItem['sampleOutput']
}

export type WorkflowInfo =
  | VaultWorkflowInfo
  | LegacyWorkflowInfo
  | AssistantWorkflowInfo

type WorkflowCardProps = {
  workflowInfo: WorkflowInfo
  source: WorkflowSource
  onClick: (workflowInfo: WorkflowInfo) => void
  className?: string
}

export const OUTPUT_TYPE_TO_ICON = {
  [WorkflowOutputType.TABLE]: Table2,
  [WorkflowOutputType.DRAFT]: FileTextIcon,
  [WorkflowOutputType.ANSWER]: SquareMousePointerIcon,
}

const OUTPUT_TYPE_TO_NAME = {
  [WorkflowOutputType.TABLE]: 'Table',
  [WorkflowOutputType.DRAFT]: 'Draft',
  [WorkflowOutputType.ANSWER]: 'Output',
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({
  workflowInfo,
  source,
  onClick,
  className,
}) => {
  const nameRef = useRef<HTMLParagraphElement>(null)
  const [nameHeight, setNameHeight] = useState(0)

  useEffect(() => {
    if (!nameRef.current) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setNameHeight(entry.contentRect.height)
      }
    })

    resizeObserver.observe(nameRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <Card
      onClick={() => onClick(workflowInfo)}
      className={cn(
        'h-32 w-full items-center justify-center rounded-lg border-none bg-secondary p-4 transition hover:cursor-pointer hover:border-input-focused hover:bg-secondary-hover',
        className
      )}
    >
      <div className="flex size-full flex-col items-start justify-between gap-4">
        <div className="flex h-[72px] w-full flex-col overflow-hidden">
          <p
            ref={nameRef}
            className="mb-[2px] line-clamp-2 text-sm font-medium"
            id={`workflow-name-${workflowInfo.id}`}
          >
            {workflowInfo.name}
          </p>
          <div
            className={cn({
              // Only show one line of description if the workflow name takes up two lines
              'line-clamp-1': nameHeight > 20,
              'line-clamp-2': nameHeight <= 20,
            })}
          >
            <p className="text-xs text-muted">{workflowInfo.description}</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <WorkflowCardFooter workflowInfo={workflowInfo} source={source} />
        </div>
        {/* TODO(stella): add Vault workflow card footer once design finalized */}
      </div>
    </Card>
  )
}

const WorkflowCardFooter = ({
  workflowInfo,
  source,
}: {
  workflowInfo: WorkflowInfo
  source: WorkflowSource
}) => {
  const shouldShowVaultDocumentTags =
    workflowInfo.category === WorkflowCategory.VAULT &&
    source === WorkflowSource.VAULT_PROJECT_PAGE &&
    workflowInfo.documentTag

  const getCobrandLogo = () => {
    if (
      workflowInfo.category === WorkflowCategory.ASSISTANT &&
      workflowInfo.name === 'Antitrust Filings'
    ) {
      return <AOLogo />
    }

    if (workflowInfo.category !== WorkflowCategory.VAULT) {
      return null
    }

    switch (workflowInfo.cobrand) {
      case ReviewWorkflowCobrand.AOS:
        return <AOLogo />
      default:
        return null
    }
  }

  const workflowIcon = useMemo(() => {
    switch (workflowInfo.category) {
      case WorkflowCategory.VAULT:
        return Table2
      case WorkflowCategory.ASSISTANT:
        return OUTPUT_TYPE_TO_ICON[workflowInfo.outputType]
      default:
        return null
    }
  }, [workflowInfo])

  const workflowTypeDisplay = useMemo(() => {
    switch (workflowInfo.category) {
      case WorkflowCategory.VAULT:
        return 'Table'
      case WorkflowCategory.ASSISTANT:
        return OUTPUT_TYPE_TO_NAME[workflowInfo.outputType]
      default:
        return null
    }
  }, [workflowInfo])

  const workflowMetadata = useMemo(() => {
    switch (workflowInfo.category) {
      case WorkflowCategory.VAULT:
        return `${workflowInfo.numColumns} columns`
      case WorkflowCategory.ASSISTANT:
        return `${workflowInfo.userInputStepCount} ${pluralize(
          'step',
          workflowInfo.userInputStepCount
        )}`
      default:
        return null
    }
  }, [workflowInfo])

  if (workflowInfo.category === WorkflowCategory.LEGACY) {
    return null
  }

  return (
    <div className="flex flex-row items-center gap-[6px]">
      {getCobrandLogo() ||
        (shouldShowVaultDocumentTags ? (
          <SimpleDocumentClassificationPill
            isPill
            key={workflowInfo.documentTag!.id}
            tagName={workflowInfo.documentTag!.name}
            tagId={workflowInfo.documentTag!.id}
          />
        ) : (
          <>
            {workflowIcon && (
              <Icon className="stroke-muted" size="small" icon={workflowIcon} />
            )}
            <p className="text-xs text-muted">
              {workflowTypeDisplay} · {workflowMetadata}
            </p>
          </>
        ))}
    </div>
  )
}

export const WorkflowCardPlaceholder = () => {
  return (
    <Card className="h-32 w-full cursor-not-allowed items-center justify-center rounded-lg border-none bg-secondary p-4 opacity-50">
      <div className="flex size-full flex-col items-start justify-between gap-4">
        <div className="flex h-[72px] w-full flex-col overflow-hidden">
          <div className="line-clamp-2">
            <p className="text-sm font-medium">Coming soon</p>
          </div>
        </div>
      </div>
    </Card>
  )
}
export default WorkflowCard
