import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useInterval } from 'react-use'
import { useUnmount } from 'react-use'

import {
  PaginationState,
  Row,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Table,
} from '@tanstack/react-table'
import _ from 'lodash'
import { Search } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter, {
  DataTablePageSizes,
} from 'components/ui/data-table/data-table-footer'
import Icon from 'components/ui/icon/icon'
import Skeleton from 'components/ui/skeleton'
import {
  createShiftSelectionHandler,
  handleVaultRowClick,
  createGetPathForItem,
  createVaultTableColumns,
  createProjectDataPoller,
  prepareVaultExplorerData,
  getHiddenColumns,
  createRowSelectionHandler,
} from 'components/vault/components/file-explorer/vault-file-explorer-utils'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useDocumentClassificationStore } from 'components/vault/utils/use-document-classification-store'
import {
  VaultItem,
  VaultItemType,
  folderIdSearchParamKey,
  projectsPath,
  filesPath,
  REMOVE_PARAMS,
  VaultItemWithIndex,
} from 'components/vault/utils/vault'
import { SemanticSearch } from 'components/vault/utils/vault-fetcher'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import { isProjectShared } from 'components/vault/utils/vault-sharing-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultFileExplorer = ({
  projectId,
  selectedRows,
  setSelectedRows,
  className,
}: {
  projectId: string | undefined
  selectedRows: VaultItemWithIndex[]
  setSelectedRows: (rows: VaultItemWithIndex[]) => void
  className?: string
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const folderId = searchParams.get(folderIdSearchParamKey)
  const userInfo = useAuthUser()

  const navigate = useNavigateWithQueryParams()

  const { trackEvent } = useAnalytics()

  const [
    currentFolderId,
    folderIdToVaultFolder,
    parentIdToVaultFolderIds,
    fileIdToVaultFile,
    folderIdToVaultFileIds,
    foldersMetadata,
    currentProjectMetadata,
    requiresProjectDataRefetch,
    exampleProjectIds,
    sharedProjectIds,
    projectIdToFolderIds,
    setCurrentFolderId,
    setAreUploadButtonsDisabled,
    setCurrentProject,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    setRequiresProjectDataRefetch,
    deleteVaultFiles,
    deleteVaultFolders,
    setError,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentFolderId,
      state.folderIdToVaultFolder,
      state.parentIdToVaultFolderIds,
      state.fileIdToVaultFile,
      state.folderIdToVaultFileIds,
      state.foldersMetadata,
      state.currentProjectMetadata,
      state.requiresProjectDataRefetch,
      state.exampleProjectIds,
      state.sharedProjectIds,
      state.projectIdToFolderIds,
      state.setCurrentFolderId,
      state.setAreUploadButtonsDisabled,
      state.setCurrentProject,
      state.upsertVaultFolders,
      state.upsertVaultFiles,
      state.addToProjectsMetadata,
      state.setRequiresProjectDataRefetch,
      state.deleteVaultFiles,
      state.deleteVaultFolders,
      state.setError,
    ])
  )

  const [
    searchValue,
    isSearching,
    isRequestingSemanticSearch,
    isSemanticSearching,
    clearSearchHandler,
    setIsRequestingSemanticSearch,
    setIsSemanticSearching,
    setNumRowsFound,
  ] = useVaultFileExplorerStore(
    useShallow((state) => [
      state.searchValue,
      state.isSearching,
      state.isRequestingSemanticSearch,
      state.isSemanticSearching,
      state.clearSearchHandler,
      state.setIsRequestingSemanticSearch,
      state.setIsSemanticSearching,
      state.setNumRowsFound,
    ])
  )
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((state) => state.permissionsByProjectId)
  )

  const setOpenFileId = useDocumentClassificationStore(
    useShallow((s) => s.setOpenFileId)
  )

  const [lastSelectedRow, setLastSelectedRow] =
    useState<VaultItemWithIndex | null>(null)

  const isSharedProject = userInfo.IsVaultViewSharesUser
    ? isProjectShared(sharedProjectIds, permissionsByProjectId, projectId)
    : false

  const isFilesProcessing =
    // If there are incomplete files, we want to poll for the update
    currentProjectMetadata.completedFiles !== currentProjectMetadata.totalFiles

  const pollProjectData = useCallback(async () => {
    return await createProjectDataPoller({
      projectId,
      setCurrentProject,
      upsertVaultFolders,
      upsertVaultFiles,
      addToProjectsMetadata,
      userId: userInfo.dbId,
      exampleProjectIds,
      projectIdToFolderIds,
      folderIdToVaultFolder,
      deleteVaultFiles,
      deleteVaultFolders,
      setError,
    })
  }, [
    projectId,
    setCurrentProject,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    exampleProjectIds,
    deleteVaultFiles,
    deleteVaultFolders,
    setError,
    userInfo,
    projectIdToFolderIds,
    folderIdToVaultFolder,
  ])

  useInterval(pollProjectData, isFilesProcessing ? 10_000 : null)

  useEffect(() => {
    const refetchProjectData = async () => {
      await pollProjectData()
      setRequiresProjectDataRefetch(false)
    }
    if (requiresProjectDataRefetch) {
      void refetchProjectData()
    }
  }, [
    requiresProjectDataRefetch,
    pollProjectData,
    setRequiresProjectDataRefetch,
  ])

  // we want to use a useEffect here instead of useMount to allow the user to navigate with the browser forward & back buttons
  useEffect(() => {
    const currId = folderId ?? projectId
    setCurrentFolderId(currId ?? null)
  }, [setCurrentFolderId, folderId, projectId])

  useUnmount(() => {
    setSelectedRows([])
    setOpenFileId(null)
  })

  const {
    data: semanticSearchResults,
    isFetching,
    error,
  } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.VaultFileSearchQuery, projectId, searchValue],
    queryFn: async ({ signal }) => {
      try {
        const response = await SemanticSearch(projectId!, searchValue, signal)
        return response.fileIds
      } catch (e) {
        return []
      }
    },
    enabled:
      isSearching &&
      isRequestingSemanticSearch &&
      searchValue.trim().length > 0,
  })
  useEffect(() => {
    if (error) {
      console.error('Failed to search for files', error)
      displayErrorMessage('Failed to search for files')
    }
  }, [error])
  useEffect(() => {
    setIsSemanticSearching(isFetching)
  }, [isFetching, setIsSemanticSearching])

  // TODO: Remove isRequestingSemanticSearch as it's always true
  // This is to hide "Search more" button temporarily before we
  // figure out the design of keyword search + semantic search.
  useEffect(() => {
    setIsRequestingSemanticSearch(true)
  }, [searchValue, setIsRequestingSemanticSearch])

  const data: VaultItem[] = useMemo(() => {
    return prepareVaultExplorerData({
      userId: userInfo.dbId,
      projectId,
      currentFolderId,
      parentIdToVaultFolderIds,
      folderIdToVaultFolder,
      fileIdToVaultFile,
      folderIdToVaultFileIds,
      foldersMetadata,
      exampleProjectIds,
      existingSelectedFileIds: new Set(),
      isAddingFilesToQuery: false,
      isSharedProject,
      isSearching,
      searchValue,
      semanticSearchResults,
      setNumRowsFound,
      projectIdToFolderIds,
      shouldHideAllFiles: false,
    })
  }, [
    userInfo.dbId,
    projectId,
    currentFolderId,
    parentIdToVaultFolderIds,
    folderIdToVaultFolder,
    fileIdToVaultFile,
    folderIdToVaultFileIds,
    foldersMetadata,
    isSearching,
    searchValue,
    semanticSearchResults,
    setNumRowsFound,
    exampleProjectIds,
    isSharedProject,
    projectIdToFolderIds,
  ])

  const getPathForItem = useMemo(() => {
    return createGetPathForItem({
      data,
      foldersMetadata,
      folderIdToVaultFolder,
      currentFolderId,
      projectId,
    })
  }, [data, foldersMetadata, folderIdToVaultFolder, currentFolderId, projectId])

  // Create a memoized function that creates the shift selection handler
  const createShiftSelectionHandlerFn = useCallback(
    (row: Row<VaultItem>) => {
      if (!tableRef.current) return
      return createShiftSelectionHandler({
        table: tableRef.current,
        selectedRows,
        lastSelectedRow,
        getPathForItem,
        setSelectedRows,
      })(row)
    },
    [selectedRows, lastSelectedRow, getPathForItem, setSelectedRows]
  )

  const columns = useMemo(() => {
    return createVaultTableColumns({
      trackEvent,
      isAddingFilesToQuery: false,
      setLastSelectedRow,
      handleShiftSelection: createShiftSelectionHandlerFn,
      fileIdToVaultFile,
    })
  }, [trackEvent, createShiftSelectionHandlerFn, fileIdToVaultFile])

  const isExampleProject = useMemo(
    () => !!projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )
  const sharingPermissionsOptions = useMemo(
    () => ({
      projectId: projectId,
    }),
    [projectId]
  )
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions(
    sharingPermissionsOptions
  )

  const hideColumns = useMemo(() => {
    return getHiddenColumns({
      isSearching,
      isExampleProject,
      isAddingFilesToQuery: false,
      canCurrentUserSelectFiles: doesCurrentUserHaveEditPermission,
      isDisplayingFilesProgress: false,
    })
  }, [isSearching, isExampleProject, doesCurrentUserHaveEditPermission])

  const [sorting, setSorting] = useState<SortingState>([])
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 50,
    pageIndex: 0,
  })

  const rowSelection = useMemo(() => {
    return selectedRows.reduce(
      (acc, row) => {
        const path = getPathForItem(row)

        if (path && path.length > 0) {
          acc[path] = true
        }
        return acc
      },
      {} as Record<string, boolean>
    )
  }, [selectedRows, getPathForItem])

  // Create a ref to store the table
  const tableRef = useRef<Table<VaultItem> | null>(null)

  const table = useReactTable<VaultItem>({
    columns,
    data,
    autoResetPageIndex: false,
    getSubRows: (row) =>
      row.type !== VaultItemType.file ? row.children : undefined,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onRowSelectionChange: (
      updaterOrValue:
        | Record<string, boolean>
        | ((old: Record<string, boolean>) => Record<string, boolean>)
    ) => {
      if (!tableRef.current) return
      return createRowSelectionHandler({
        table: tableRef.current,
        rowSelection,
        setSelectedRows,
      })(updaterOrValue)
    },
    state: {
      sorting,
      pagination,
      rowSelection,
      columnVisibility: _.mapValues(_.keyBy(hideColumns), () => false),
    },
    enableSorting: true,
    enableSortingRemoval: true,
  })

  // Update the tableRef after table is created
  useEffect(() => {
    tableRef.current = table
  }, [table])

  // TODO: We might have a better way to handle these changes?
  useEffect(() => {
    // De-select all rows if we're navigating to a new folder or searching
    // This is to prevent the user from getting unexpected results.
    setSelectedRows([])
  }, [currentFolderId, isSearching, searchValue, setSelectedRows])

  const navigateToFile = useCallback(
    (fileId: string) => {
      const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${filesPath}${fileId}`
      navigate(newPath, {}, REMOVE_PARAMS)
    },
    [navigate, projectId]
  )

  const onRowClick = useCallback(
    async (row: Row<VaultItem>, e: React.MouseEvent | React.KeyboardEvent) => {
      handleVaultRowClick(row, e, {
        selectedRows,
        setSelectedRows,
        getPathForItem,
        lastSelectedRow,
        setLastSelectedRow,
        handleShiftSelection: createShiftSelectionHandlerFn,
        isAddingFilesToQuery: false,
        existingSelectedFileIds: new Set(),
        navigateToFile,
        clearSearchHandler,
        setSearchParams,
        folderIdSearchParamKey,
        isSearching,
        setAreUploadButtonsDisabled,
        trackEvent,
        shouldHideAllFiles: false,
      })
    },
    [
      navigateToFile,
      selectedRows,
      setSelectedRows,
      isSearching,
      setSearchParams,
      clearSearchHandler,
      setAreUploadButtonsDisabled,
      trackEvent,
      getPathForItem,
      lastSelectedRow,
      createShiftSelectionHandlerFn,
    ]
  )

  const entity = projectId === currentFolderId ? 'project' : 'folder'
  const emptyStateText = isSearching
    ? `No results found`
    : isSharedProject
    ? `No files have been uploaded to this ${entity} yet`
    : `You haven’t uploaded any files to this ${entity} yet`

  return (
    <div className={cn('pb-8', className)}>
      <DataTable
        caption={`${entity} files`}
        isLoading={isSemanticSearching}
        table={table}
        onRowClick={onRowClick}
        className="rounded-none"
        hideTableBorder
        tableCellClassName={cn(
          'px-1 py-2.5 first:px-0 first:py-1 last:px-0 last:py-1 font-normal select-none'
        )}
        emptyStateText={emptyStateText}
      />
      {isSearching &&
        table.getPageCount() <= 1 &&
        (isRequestingSemanticSearch ? (
          data.length > 0 && isSemanticSearching ? (
            <Skeleton rows={5} rowHeight="h-4" />
          ) : null
        ) : (
          <div className="flex w-full flex-col justify-center">
            <Button
              variant="text"
              className="font-semibold"
              onClick={() => {
                trackEvent('Vault File Semantic Search Requested', {
                  value: searchValue,
                })
                setIsRequestingSemanticSearch(true)
              }}
            >
              <Icon icon={Search} className="mr-2" />
              Search more
            </Button>
          </div>
        ))}
      {/* Only show the footer if the table has at least 10 rows (after expansion) */}
      {table.getExpandedRowModel().rows.length > DataTablePageSizes[0] && (
        <DataTableFooter table={table} />
      )}
    </div>
  )
}

export default VaultFileExplorer
