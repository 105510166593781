export type ExportOptionValue = string | string[] | boolean
type ExportOption = {
  label: string
  value: ExportOptionValue
}

type ExportOptionGroupType = 'radio' | 'toggle' | 'checkbox'
export type ExportOptionGroup = {
  id: string
  name: string
  options: ExportOption[]
  type: ExportOptionGroupType
  defaultValue?: ExportOptionValue
}

export type ExportOptionValues = { [key: string]: ExportOptionValue }

export const CHECKBOX_ALL_VALUE = 'all'

export const CITATION_OPTION_GROUP: ExportOptionGroup = {
  id: 'includeAnnotation',
  name: 'Citations',
  options: [{ label: 'Include citations', value: true }],
  type: 'toggle',
  defaultValue: true,
}

export const FILE_OPTION_GROUP: ExportOptionGroup = {
  id: 'includeFileNames',
  name: 'Files',
  options: [{ label: 'Include file names', value: true }],
  type: 'toggle',
  defaultValue: true,
}
