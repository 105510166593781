import React from 'react'

import { ChevronsUpDown } from 'lucide-react'

import { useSharingStore } from 'stores/sharing-store'
import {
  PermissionLevel,
  PermissionLevelDefinition,
  SharePopoverScreens,
  ShareType,
} from 'types/sharing'

import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'

const PermissionLevelDropdown = ({
  currentPermissionLevel,
  showRemoveOption,
  onPermissionLevelChange,
  onRemoveUserOrWorkspace,
  shareType,
  isSharingWithEveryoneAtWorkspaceEnabled,
}: {
  currentPermissionLevel: PermissionLevel
  showRemoveOption: boolean
  onPermissionLevelChange: (
    updatedPermissionLevel: PermissionLevel
  ) => Promise<void> | void
  onRemoveUserOrWorkspace?: () => Promise<void>
  shareType: ShareType
  isSharingWithEveryoneAtWorkspaceEnabled: boolean
}) => {
  const currentScreen = useSharingStore((state) => state.currentScreen)
  const availablePermissions = useSharingStore(
    (state) => state.availablePermissions
  )

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {currentScreen === SharePopoverScreens.SHARE ? (
          <Button
            variant="ghost"
            className="h-6 gap-x-1 px-1.5 py-1 text-muted"
          >
            <span className="text-xs">
              {PermissionLevelDefinition[currentPermissionLevel].name}
            </span>
            <Icon icon={ChevronsUpDown} size="small" />
          </Button>
        ) : (
          <Button
            variant="outline"
            className="flex w-full justify-between gap-x-1"
          >
            <span className="text-sm">
              {PermissionLevelDefinition[currentPermissionLevel].name}
            </span>
            <Icon icon={ChevronsUpDown} size="small" />
          </Button>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-full">
        {availablePermissions.map((permission: PermissionLevel) => (
          <DropdownMenuItem
            key={permission}
            onClick={() => onPermissionLevelChange(permission)}
          >
            <div>
              <p>{PermissionLevelDefinition[permission].name}</p>
              <p className="text-xs text-muted">
                {PermissionLevelDefinition[permission].getDescription(
                  shareType,
                  isSharingWithEveryoneAtWorkspaceEnabled
                )}
              </p>
            </div>
          </DropdownMenuItem>
        ))}
        {showRemoveOption && onRemoveUserOrWorkspace && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => onRemoveUserOrWorkspace()}>
              <p className="text-destructive">Remove</p>
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default PermissionLevelDropdown
