import React from 'react'

import { cn } from 'utils/utils'

interface HarveyLogoProps {
  className?: string
}

const HarveyLogo: React.FC<HarveyLogoProps> = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 28"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      className={cn('fill-primary-inverse', className)}
      data-testid="harvey-logo"
    >
      <path
        fill="currentColor"
        d="M32 28H18l4-4v-8.4H10V24l4 4H0l4-4V4L0 0h14l-4 4v7.6h12V4l-4-4h14l-4 4v20l4 4Z"
      />
    </svg>
  )
}

export default HarveyLogo
