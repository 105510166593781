import React, { useCallback, useMemo, useEffect, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams, useSearchParams } from 'react-router-dom'

import { groupBy, isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { MessageFeedback } from 'openapi/models/MessageFeedback'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { useAnnotationCaches } from 'hooks/use-annotation-caches'
import { applyFrontendAnnotations } from 'utils/pspdfkit-annotation-manipulation'
import { Source, TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { FeedbackButton } from 'components/common/feedback/feedback'
import { PdfViewerPushSheet } from 'components/common/pdf-viewer/pdf-viewer-push-sheet'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'
import { FileControls } from 'components/vault/components/vault-app-header/file-controls'
import VaultDocumentClassification from 'components/vault/components/vault-document-classification'
import Footnote from 'components/vault/components/vault-footnote'
import { useUpsertEventFeedback } from 'components/vault/hooks/use-feedback'
import {
  ADD_COLUMN_FIELD,
  hideAddColumnColumnDef,
  QuestionColumnDef,
  updateAddColumnDef,
} from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  EXPIRATION_URL_KEY,
  fileIdSearchParamKey,
  sourceIdSearchParamKey,
  questionIdSearchParamKey,
  PUSHSHEET_REVIEW_PDF_PERCENTAGE,
  PUSHSHEET_REVIEW_ANSWERS_PERCENTAGE,
  QueryQuestion,
  ReviewSource,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import useVaultFeedbackStore from 'components/vault/utils/vault-feedback-store'
import {
  FetchVaultFile,
  FetchReviewRow,
} from 'components/vault/utils/vault-fetcher'
import {
  getDisplayAnswer,
  isAnswerEmpty,
  isUrlExpired,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const SOURCE_GROUP_COLUMN_COUNT = 6

const PushSheetHeader = ({
  shouldDisplayQuestionAnswerSection,
  activeFile,
  currentFileIndex,
  fileIds,
  previousFileId,
  nextFileId,
  setSearchParamsFileId,
  resetPushSheet,
}: {
  shouldDisplayQuestionAnswerSection: boolean
  activeFile: UploadedFile | null
  currentFileIndex: number | undefined
  fileIds: string[]
  previousFileId: string | undefined
  nextFileId: string | undefined
  setSearchParamsFileId: (fileId: string) => void
  resetPushSheet: () => void
}) => {
  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )
  return (
    <div className="flex h-[49px] w-full shrink-0 items-center justify-between border-b px-4">
      <div className="flex items-center gap-1">
        <p className="truncate font-semibold">
          {!activeFile?.name ? 'Loading…' : activeFile.name}
        </p>
        {activeFile && shouldDisplayQuestionAnswerSection && (
          <VaultDocumentClassification
            shouldShowPrefixDot
            fileIds={[activeFile.id]}
            fileIdToVaultFile={fileIdToVaultFile}
            className="w-auto"
          />
        )}
      </div>
      <div className="flex items-center gap-2">
        <FileControls
          currentFileIndex={currentFileIndex}
          fileIds={fileIds}
          previousFileId={previousFileId}
          nextFileId={nextFileId}
          onNavigateToFile={setSearchParamsFileId}
          onExit={resetPushSheet}
        />
      </div>
    </div>
  )
}

const QueryReviewAnswers = ({
  activeFile,
  sources,
}: {
  activeFile: VaultFile
  sources: ReviewSource[]
}) => {
  const { projectId } = useParams()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const queryParamSourceId = searchParams.get(sourceIdSearchParamKey)
  const queryParamFileId = searchParams.get(fileIdSearchParamKey)

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const feedbacks = useVaultFeedbackStore((s) => s.feedbacks)
  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)
  const clearDocumentAnnotation = usePDFViewerStore(
    (s) => s.clearDocumentAnnotation
  )

  const [queryId, historyItem] = useVaultQueryDetailStore(
    useShallow((s) => [s.queryId, s.historyItem])
  )

  const reviewEvent = historyItem as ReviewHistoryItem
  const isWorkflowRepsWarranties = reviewEvent.isWorkflowRepsWarranties
  const questions = reviewEvent.questions
  const columnHeaders = reviewEvent.columnHeaders
  const columnOrder = reviewEvent.columnOrder
  const fileAnswers = reviewEvent.answers[activeFile.id] || []

  const groupedFileAnswers = Object.entries(
    groupBy(fileAnswers, 'columnId')
  ).sort((a, b) => {
    const indexA = columnOrder.indexOf(a[0])
    const indexB = columnOrder.indexOf(b[0])
    return indexA - indexB
  })

  const isEmptyGroupedFileAnswers = useMemo(
    () => isEmpty(groupedFileAnswers),
    [groupedFileAnswers]
  )

  const scrollAreaRef = useRef<HTMLDivElement>(null)

  const onDocumentSourceClick = (source: ReviewSource) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set(sourceIdSearchParamKey, source.id)
      return newParams
    })
    setActiveDocument(activeFile)
  }

  useEffect(() => {
    clearDocumentAnnotation()
  }, [activeFile, clearDocumentAnnotation])

  const { upsertEventFeedback } = useUpsertEventFeedback()

  const handleSubmitFeedback = async (
    questionId: string,
    newFeedback: MessageFeedback
  ) => {
    if (!queryId || !projectId || !queryParamFileId) return false
    const task_type = TaskType.VAULT_REVIEW
    trackEvent('Feedback Submitted', {
      event_id: queryId,
      event_kind: task_type,
      sentiment: newFeedback.sentiment,
      comments: newFeedback.selectedComments,
      vaultFolderId: projectId,
      fileId: queryParamFileId,
      questionId: questionId,
    })
    const submittedFeedback = await upsertEventFeedback({
      eventId: Number(queryId),
      vaultFolderId: projectId,
      fileId: queryParamFileId,
      questionId: questionId,
      feedbackData: newFeedback,
    })
    return submittedFeedback !== null
  }

  const reorderSourcesForRtlGrid = (sources: ReviewSource[]) => {
    const array = [...sources].reverse()
    const length = array.length
    const groups = Math.ceil(length / SOURCE_GROUP_COLUMN_COUNT)

    for (let i = 0; i < groups; i++) {
      const startIndex = Math.max(
        0,
        length - (i + 1) * SOURCE_GROUP_COLUMN_COUNT
      )
      const endIndex = length - i * SOURCE_GROUP_COLUMN_COUNT
      const group = array.slice(startIndex, endIndex)
      group.reverse()
      array.splice(startIndex, group.length, ...group)
    }

    return array.reverse()
  }

  return (
    <ScrollArea ref={scrollAreaRef} isFullHeight className="h-full">
      <div className="h-full space-y-4 divide-y p-8">
        {isEmptyGroupedFileAnswers && (
          <div className="flex h-full items-center justify-center">
            <Spinner />
          </div>
        )}
        {groupedFileAnswers.map(([key, value]) => {
          const question = questions.find(
            (question: QueryQuestion) => question.id === key
          )
          const columnHeader = columnHeaders.find((header) => header.id === key)
          const shortAnswer = value.find((answer) => !answer.long)
          const longAnswer = value.find((answer) => answer.long)
          const questionSources = sources.filter(
            (source) => source.questionId === key
          )
          const shouldShowQuestion = !(
            isWorkflowRepsWarranties &&
            (isEmpty(columnHeader?.text) ||
              columnHeader?.text === question?.text)
          )
          const shouldShowShortAnswer = !(
            isWorkflowRepsWarranties &&
            (!shortAnswer || isAnswerEmpty(shortAnswer.text))
          )

          const shortDisplayAnswer = getDisplayAnswer(shortAnswer)
          const longDisplayAnswer = getDisplayAnswer(longAnswer)

          const areAnswersEqual = longDisplayAnswer === shortDisplayAnswer

          return (
            <div
              key={key}
              id={key}
              className="flex flex-col gap-2 pt-4 first:pt-0 last:pb-8"
            >
              <div>
                <div className="flex">
                  <h3 className="mr-2 flex-1 text-sm font-semibold">
                    {columnHeader?.text ?? question?.text}
                  </h3>
                  {questionSources.length > 0 && (
                    <div
                      className="grid grid-cols-6 gap-1"
                      style={{ direction: 'rtl' }}
                    >
                      {reorderSourcesForRtlGrid(questionSources).map(
                        (source) => (
                          <Footnote
                            isCurrentSource={queryParamSourceId === source.id}
                            key={`${key}-${source.id}`}
                            text={source.footnote.toString()}
                            onClick={() => onDocumentSourceClick(source)}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
                {shouldShowQuestion && (
                  <p className="text-muted">{question?.text}</p>
                )}
              </div>
              {shouldShowShortAnswer && (
                <div className="mt-2 grid grid-cols-1 gap-1 xl:grid-cols-6 xl:gap-2 2xl:grid-cols-4 2xl:gap-2">
                  <p className="text-muted xl:col-span-2 2xl:col-span-1">
                    Summary
                  </p>
                  <p className="xl:col-span-4 2xl:col-span-3">
                    {shortDisplayAnswer.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              )}
              {!areAnswersEqual && (
                <div className="grid grid-cols-1 gap-1 xl:grid-cols-6 xl:gap-2 2xl:grid-cols-4 2xl:gap-2">
                  <p className="text-muted xl:col-span-2 2xl:col-span-1">
                    {shouldShowShortAnswer ? 'Additional Context' : 'Answer'}
                  </p>
                  <p className="xl:col-span-4 2xl:col-span-3">
                    {longDisplayAnswer.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              )}
              {userInfo.IsFeedbackUser && (
                <div className="flex items-center gap-1">
                  <FeedbackButton
                    hasDocuments
                    onSubmit={(feedback) => handleSubmitFeedback(key, feedback)}
                    sentiment={
                      feedbacks[queryId ?? '']?.[queryParamFileId ?? '']?.[
                        key ?? ''
                      ]?.sentiment ?? 0
                    }
                    sentimentValue={1}
                    buttonSize="xsIcon"
                  />
                  <FeedbackButton
                    hasDocuments
                    onSubmit={(feedback) => handleSubmitFeedback(key, feedback)}
                    sentiment={
                      feedbacks[queryId ?? '']?.[queryParamFileId ?? '']?.[
                        key ?? ''
                      ]?.sentiment ?? 0
                    }
                    sentimentValue={-1}
                    buttonSize="xsIcon"
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </ScrollArea>
  )
}

const PushSheet = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const sourceId = searchParams.get(sourceIdSearchParamKey)

  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )
  const activeDocument = useVaultStore(useShallow((s) => s.activeDocument))
  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const displayedRows = useVaultDataGridFilterStore((s) => s.displayedRows)
  const currentProjectId = useVaultStore(
    useShallow((s) => s.currentProject?.id)
  )

  const [
    gridApi,
    historyItem,
    queryId,
    fileIdToSources,
    addFileIdToFetchingFileIdsSources,
    addSourcesToFileId,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.gridApi,
      s.historyItem,
      s.queryId,
      s.fileIdToSources,
      s.addFileIdToFetchingFileIdsSources,
      s.addSourcesToFileId,
    ])
  )

  const {
    sourceAnnotationsRef,
    updateSourceAnnotationsRef,
    documentAnnotationsRef,
    updateDocumentAnnotationsRef,
  } = useAnnotationCaches()

  const isPdfLoading = usePDFViewerStore(useShallow((s) => s.isPdfLoading))
  const instance = usePDFViewerStore(useShallow((s) => s.instance))
  const setIsPdfLoading = usePDFViewerStore((s) => s.setIsPdfLoading)
  const isAnnotating = usePDFViewerStore(useShallow((s) => s.isAnnotating))
  const setIsAnnotating = usePDFViewerStore((s) => s.setIsAnnotating)

  const isNewQuery = queryId === 'new'
  const reviewEvent = historyItem as ReviewHistoryItem
  const isReviewQuery =
    isNewQuery || reviewEvent?.eventKind === EventKind.VAULT_REVIEW

  // because of how the fileId is set in the url - useSearchParams will always trigger the useEffect to run
  // this causes QueryReviewAnswers to flash blank and reset it's scrollbar because of the setActiveDocument in the useEffect
  // so we need to use a ref to check if the fileId has actually changed
  const fileIdRef = useRef<string | null>(null)
  const annotationsAppliedRef = useRef<string>('')
  const containerRef = useRef<HTMLDivElement | null>(null)

  const fileSources = useMemo(
    () => (fileId && fileIdToSources[fileId] ? fileIdToSources[fileId] : []),
    [fileIdToSources, fileId]
  )
  const currentSource = useMemo(
    () => fileSources.find((source: ReviewSource) => source.id === sourceId),
    [fileSources, sourceId]
  )

  const shouldDisplayQuestionAnswerSection =
    fileId && !isEmpty(reviewEvent?.answers[fileId])

  const isLoadingUrl = useMemo(() => {
    const isActiveFile = activeDocument !== null
    const isLoadingFileUrl =
      isActiveFile &&
      isEmpty(activeDocument.url) &&
      !isEmpty(activeDocument.path)
    const isLoadingFileDocAsPdfUrl =
      isActiveFile &&
      isEmpty(activeDocument.docAsPdfUrl) &&
      isEmpty(activeDocument.docAsPdfPath)

    return isLoadingFileUrl || isLoadingFileDocAsPdfUrl
  }, [activeDocument])

  const resetAddColumnColumnDef = useCallback(() => {
    const columnDefs = gridApi?.getColumnDefs() ?? []
    const addColumnColumnDef = columnDefs?.find(
      (c) => 'colId' in c && c.colId === ADD_COLUMN_FIELD
    ) as QuestionColumnDef
    if (addColumnColumnDef) {
      addColumnColumnDef.hide = false
      updateAddColumnDef(columnDefs)
      gridApi?.setGridOption('columnDefs', columnDefs)
    }
  }, [gridApi])

  const resetPushSheet = useCallback(() => {
    setActiveDocument(null)
    fileIdRef.current = null
    annotationsAppliedRef.current = ''
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(fileIdSearchParamKey)
      newParams.delete(sourceIdSearchParamKey)
      newParams.delete(questionIdSearchParamKey)
      return newParams
    })
    // We have to bring back the AddColumn column, because we hide it when we open the push sheet
    resetAddColumnColumnDef()

    // we need to have setSearchParams because the prev value needs to be updated esp when we go to a subfolder
    // BUG: https://github.com/remix-run/react-router/issues/9304
    // Discussion: https://www.reddit.com/r/reactjs/comments/1brbpd3/react_routers_setsearchparams_is_broken/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, setActiveDocument, setSearchParams, resetAddColumnColumnDef])

  // we have this function here to account for users that arrive directly on navigation
  const fetchFile = useCallback(async () => {
    if (!fileId) {
      setActiveDocument(null)
      fileIdRef.current = null
      resetAddColumnColumnDef()
      return
    }

    if (fileIdRef.current === fileId || !gridApi) return
    // 1. hide the AddColumn column in case we get to the file by the user navigating forward/back
    hideAddColumnColumnDef(gridApi)

    // first let's check if we have the file in our store
    // if we don't have it then we should terminate early because the file does not exist (ie it was deleted)
    const existingFile = fileIdToVaultFile[fileId]
    if (isEmpty(existingFile)) {
      displayErrorMessage('This file does not exist.')
      resetPushSheet()
      return
    }
    fileIdRef.current = fileId
    annotationsAppliedRef.current = ''
    // before we being to load the pdf we need to unload the pdf
    // this is because the pdf-viewer could have another pdf loaded
    // so we need to clean up before loading the new pdf
    // this is especially important when the user uses the forward/back browser buttons
    setIsPdfLoading(true)
    const existingFileUrl = existingFile.url || existingFile.docAsPdfUrl || ''
    const isUrlEmpty = isEmpty(existingFileUrl)

    const isExistingFileUrlValid =
      !isUrlEmpty && !isUrlExpired(existingFileUrl, EXPIRATION_URL_KEY)
    if (isExistingFileUrlValid) {
      setActiveDocument(existingFile)
      return
    }
    // setting activeDocument to empty values so that drawer will render
    // specifically setting the 'x' path value so that the drawer will show a loading bar
    setActiveDocument({ name: '', url: '', path: 'x' } as UploadedFile)
    const file = await FetchVaultFile(fileId)
    if (!isEmpty(file)) {
      upsertVaultFiles([file], currentProjectId)
      setActiveDocument(file as UploadedFile)
    } else {
      resetPushSheet()
    }
  }, [
    gridApi,
    fileId,
    fileIdToVaultFile,
    setActiveDocument,
    upsertVaultFiles,
    resetPushSheet,
    setIsPdfLoading,
    resetAddColumnColumnDef,
    currentProjectId,
  ])

  // using useEffect to account for users that navigate away or navigate back
  useEffect(() => {
    void fetchFile()
  }, [fetchFile])

  const fetchFileSources = useCallback(async () => {
    if (!queryId || !fileId || isNewQuery) return
    const hasSources =
      fileId in fileIdToSources && fileIdToSources[fileId].length > 0
    if (hasSources) return

    const reviewRow = reviewEvent.rows.find((row) => row.fileId === fileId)
    if (!reviewRow) return

    const backingReviewRowId = reviewRow.id
    addFileIdToFetchingFileIdsSources(fileId)
    const reviewRowWithSources = await FetchReviewRow(
      queryId,
      backingReviewRowId
    )
    const fileSources = reviewRowWithSources.sources
    addSourcesToFileId(fileId, fileSources)
  }, [
    isNewQuery,
    queryId,
    fileId,
    reviewEvent,
    fileIdToSources,
    addFileIdToFetchingFileIdsSources,
    addSourcesToFileId,
  ])

  useEffect(() => {
    void fetchFileSources()
  }, [fetchFileSources])

  // this use effect is used to update the annotation applied to the activeDocument
  // if we have a current source, then it will scroll to the source highlight
  // if we have no current source, then it will apply the annotations
  // we use a useEffect here instead of a callback function because the user can navigate based on query params
  // by setting source_id
  const annotationsHandler = useCallback(async () => {
    const activeDocumentFile = activeDocument as VaultFile
    if (
      !activeDocument ||
      isEmpty(activeDocument.url) ||
      fileIdRef.current !== activeDocumentFile.id ||
      isPdfLoading ||
      !instance ||
      fileSources.length === 0
    )
      return

    if (
      annotationsAppliedRef.current ===
      `${fileIdRef.current}-${currentSource?.id}`
    )
      return

    setIsAnnotating(true)
    const castedSources = fileSources.map((source) => {
      return {
        ...source,
        annotations: [],
        documentUrl: '',
        page: source.pageNumber,
        sourceType: 'source',
      }
    }) as Source[]
    const selectedSource = currentSource
      ? {
          ...currentSource,
          annotations: [],
          documentUrl: '',
          page: currentSource.pageNumber,
          sourceType: 'source',
        }
      : null
    try {
      annotationsAppliedRef.current = `${fileIdRef.current}-${currentSource?.id}`
      await applyFrontendAnnotations({
        sources: castedSources,
        pdfkitInstance: instance,
        selectedSource: selectedSource,
        cachedSourceAnnotations: sourceAnnotationsRef.current,
        updateSourceAnnotationStore: updateSourceAnnotationsRef.current,
        cachedDocumentAnnotations: documentAnnotationsRef.current,
        updateDocumentAnnotationStore: updateDocumentAnnotationsRef.current,
      })
    } catch (error) {
      displayErrorMessage('Error applying annotations for source')
    }
    setIsAnnotating(false)
  }, [
    activeDocument,
    currentSource,
    fileSources,
    instance,
    isPdfLoading,
    fileIdRef,
    annotationsAppliedRef,
    documentAnnotationsRef,
    sourceAnnotationsRef,
    updateDocumentAnnotationsRef,
    updateSourceAnnotationsRef,
    setIsAnnotating,
  ])

  useEffect(() => {
    void annotationsHandler()
  }, [annotationsHandler])

  const fileIds = useMemo(() => {
    const fileIds: string[] = []
    displayedRows.forEach((fileId) => {
      if (fileId && fileIdToVaultFile[fileId]) {
        fileIds.push(fileId)
      }
    })
    return fileIds
  }, [displayedRows, fileIdToVaultFile])
  const previousFileId = useMemo(() => {
    if (!fileId) return undefined
    const currentIndex = fileIds.indexOf(fileId)
    if (currentIndex === -1 || currentIndex === 0) return undefined
    return fileIds[currentIndex - 1]
  }, [fileId, fileIds])
  const nextFileId = useMemo(() => {
    if (!fileId) return undefined
    const currentIndex = fileIds.indexOf(fileId)
    if (currentIndex === -1 || currentIndex === fileIds.length - 1)
      return undefined
    return fileIds[currentIndex + 1]
  }, [fileId, fileIds])
  const currentFileIndex = useMemo(() => {
    if (!fileId) return undefined
    return fileIds.indexOf(fileId)
  }, [fileId, fileIds])

  const setSearchParamsFileId = (fileId: string) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set(fileIdSearchParamKey, fileId)
      newParams.delete(sourceIdSearchParamKey)
      newParams.delete(questionIdSearchParamKey)
      return newParams
    })
  }
  useHotkeys(
    'up',
    () => {
      if (previousFileId) {
        setSearchParamsFileId(previousFileId)
      }
    },
    {
      enabled: !!previousFileId,
    }
  )
  useHotkeys(
    'down',
    () => {
      if (nextFileId) {
        setSearchParamsFileId(nextFileId)
      }
    },
    {
      enabled: !!nextFileId,
    }
  )
  // when the user presses escape key we want to hide the push sheet
  useHotkeys(
    'esc',
    () => {
      resetPushSheet()
    },
    {
      enabled: !!activeDocument && !isEmpty(activeDocument.url),
    },
    [activeDocument, resetPushSheet]
  )
  return (
    <div
      tabIndex={-1}
      // the z-10 index ensures that the border-l on the push-sheet is visible
      className={cn(`z-10 flex h-full shrink-0 flex-col border-l`, {
        hidden: !activeDocument,
        'w-1/3': activeDocument && isReviewQuery,
        'w-3/4': shouldDisplayQuestionAnswerSection,
      })}
    >
      <PushSheetHeader
        shouldDisplayQuestionAnswerSection={
          !!shouldDisplayQuestionAnswerSection
        }
        activeFile={activeDocument}
        fileIds={fileIds}
        previousFileId={previousFileId}
        nextFileId={nextFileId}
        currentFileIndex={currentFileIndex}
        resetPushSheet={resetPushSheet}
        setSearchParamsFileId={setSearchParamsFileId}
      />
      <div
        className={cn('block h-full min-h-0 grow', {
          flex: shouldDisplayQuestionAnswerSection,
        })}
      >
        <div
          className="relative h-full w-full"
          style={{
            width: shouldDisplayQuestionAnswerSection
              ? `${PUSHSHEET_REVIEW_PDF_PERCENTAGE}%`
              : '100%',
            height: '100%',
          }}
        >
          {isAnnotating && (
            <div
              className="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-primary bg-opacity-75"
              style={{ pointerEvents: 'none' }}
            >
              <Spinner />
            </div>
          )}
          <PdfViewerPushSheet
            document={activeDocument}
            isLoadingUrl={isLoadingUrl}
            containerRef={containerRef}
          />
        </div>
        {activeDocument && shouldDisplayQuestionAnswerSection && (
          <div
            className="h-full border-l"
            style={{
              width: `${PUSHSHEET_REVIEW_ANSWERS_PERCENTAGE}%`,
              height: '100%',
            }}
          >
            <QueryReviewAnswers
              activeFile={activeDocument as VaultFile}
              sources={fileSources}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PushSheet
