import * as React from 'react'

import { FolderIcon, PlusIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import TriggerButton from './components/input-source-button/trigger-button'
import InputSourceButton from 'components/assistant/features/composer/components/input-source-button'
import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  isResearchKnowledgeSource,
  isVaultKnowledgeSource,
  VAULT_SOURCE_SELECT_FILES_DESCRIPTION,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { BaseAppPath } from 'components/base-app-path'
import { DropdownItemProps } from 'components/common/ks-input-dropdown/dropdown-item'
import { Button } from 'components/ui/button'
import { DropdownMenuSeparator } from 'components/ui/dropdown-menu'
import { Icon } from 'components/ui/icon/icon'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { useVaultProjectsMetadata } from 'components/vault/utils/use-vault-project-metadata'
import { newProjectPath } from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'

import AssistantProjectFileSelector from './assistant-project-file-selector'

const AssistantVaultInput = () => {
  const [currentProject, setCurrentProject] = useVaultStore(
    useShallow((s) => [s.currentProject, s.setCurrentProject])
  )
  const trackEvent = useAssistantAnalytics()

  const [
    isForking,
    documents,
    documentsUploading,
    knowledgeSource,
    setKnowledgeSource,
    showVaultDialog,
    setShowVaultDialog,
  ] = useAssistantStore(
    useShallow((s) => [
      s.isForking,
      s.documents,
      s.documentsUploading,
      s.knowledgeSource,
      s.setKnowledgeSource,
      s.showVaultDialog,
      s.setShowVaultDialog,
    ])
  )

  const isVaultSource = isVaultKnowledgeSource(knowledgeSource)
  const activeFileIds = React.useMemo(
    () => new Set(isVaultSource ? knowledgeSource.fileIds : []),
    [knowledgeSource, isVaultSource]
  )
  const {
    projects: allVaultProjects,
    isFetching,
    areProjectsLoaded,
  } = useVaultProjects(undefined, {
    includeExamples: false,
    loadAllMetadata: true,
  })

  const { projectsMetadata } = useVaultProjectsMetadata(
    allVaultProjects.map((project) => project.id)
  )

  const projectsAndMetadataHaveBeenLoaded =
    !isFetching && areProjectsLoaded && projectsMetadata

  const vaultProjectsDropdownOptions: DropdownItemProps[] =
    React.useMemo(() => {
      return projectsAndMetadataHaveBeenLoaded
        ? allVaultProjects.map((project) => {
            const totalFileCount = projectsMetadata[project.id]?.filesCount || 0
            return {
              icon: <Icon icon={FolderIcon} />,
              title: project.name,
              description: `${totalFileCount} ${pluralize(
                'file',
                totalFileCount
              )}`,
              onClick: () => {
                setCurrentProject(project)
                setShowVaultDialog(true)
              },
            }
          })
        : ([] as DropdownItemProps[])
    }, [
      allVaultProjects,
      projectsMetadata,
      projectsAndMetadataHaveBeenLoaded,
      setCurrentProject,
      setShowVaultDialog,
    ])

  const handleClose = () => {
    setKnowledgeSource(null)
    setCurrentProject(null)
  }

  const noProjects = areProjectsLoaded && allVaultProjects.length === 0

  const hasUploadedFiles =
    isForking ||
    documents.length > 0 ||
    documentsUploading.length > 0 ||
    (knowledgeSource && 'fileIds' in knowledgeSource) ||
    false

  return (
    <div className="w-full">
      {currentProject ? (
        <TriggerButton
          onClick={() => setShowVaultDialog(true)}
          title="Choose Vault project"
          description={`${allVaultProjects.length} ${pluralize(
            'project',
            allVaultProjects.length
          )}`}
          icon={FolderIcon}
        />
      ) : (
        <InputSourceButton
          title="Choose Vault project"
          description={`${allVaultProjects.length} ${pluralize(
            'project',
            allVaultProjects.length
          )}`}
          icon={FolderIcon}
          dropdownItems={vaultProjectsDropdownOptions}
          disabled={
            (hasUploadedFiles && !isVaultKnowledgeSource(knowledgeSource)) ||
            isResearchKnowledgeSource(knowledgeSource)
          }
          dataTestId="vault-project-selector"
          onClick={() =>
            trackEvent('Assistant Choose From Vault Clicked', {
              display_type: 'composer-button',
            })
          }
        >
          {noProjects && (
            <p className="line-clamp-2 max-w-64 p-2 text-xs text-muted">
              You don&apos;t have any Vault projects yet! Vault lets you
              securely store your files and query them anytime. Create your
              first project to get started.
            </p>
          )}
          <DropdownMenuSeparator className="-mx-2 my-2" />
          <Button
            variant="unstyled"
            className="flex w-full items-center justify-start gap-2"
            to={`${BaseAppPath.Vault}${newProjectPath}`}
            data-testid="create-new-vault-project-button"
          >
            Create new project <Icon icon={PlusIcon} />
          </Button>
        </InputSourceButton>
      )}
      {currentProject && (
        <AssistantProjectFileSelector
          showDialog={showVaultDialog}
          setShowDialog={setShowVaultDialog}
          onClose={handleClose}
          isEmpty={noProjects}
          dialogTitle={`Add files from ${currentProject.name}`}
          dialogDescription={VAULT_SOURCE_SELECT_FILES_DESCRIPTION}
          isFetching={isFetching}
          allVaultProjects={allVaultProjects}
          activeFileIds={activeFileIds}
          setKnowledgeSource={setKnowledgeSource}
        />
      )}
    </div>
  )
}

export default AssistantVaultInput
