import * as React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  DATABASE_SOURCE_TO_RESEARCH_AREA,
  DatabaseSource,
  KnowledgeSourceConfig,
  isResearchKnowledgeSource,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'
import Explorer from 'components/research/explorer'
import { getAllChildrenDeep } from 'components/research/research-helpers'
import {
  JURISDICTION_TITLE,
  TopLevelJurisdiction,
} from 'components/research/usacaselaw/constants'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

type Props = {
  databaseSource: DatabaseSource
  showDialog: boolean
  setShowDialog: (show: boolean) => void
  maxTopLevelSelections?: number
}

export const ResearchKnowledgeSourceSelector = ({
  databaseSource,
  showDialog,
  setShowDialog,
  maxTopLevelSelections,
}: Props) => {
  const userInfo = useAuthUser()
  const researchArea = DATABASE_SOURCE_TO_RESEARCH_AREA[databaseSource]
  const config = KnowledgeSourceConfig[databaseSource]
  const { taxonomy, isLoading: isTaxonomyLoading } = useResearchTaxonomyQuery(
    researchArea || null,
    userInfo.IsHarveyV1ResearchUser
  )

  const taxonomyIds = taxonomy.map((f) => f.id).join(',')

  const allFiltersFlattened = React.useMemo(
    () => {
      const researchFilters = taxonomy
      return researchFilters.concat(
        researchFilters.flatMap((researchFilter) =>
          getAllChildrenDeep(researchFilter)
        )
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taxonomyIds]
  )

  const [knowledgeSource, setKnowledgeSource] = useAssistantStore(
    useShallow((s) => [s.knowledgeSource, s.setKnowledgeSource])
  )

  const defaultSelectedFilters = React.useMemo(() => {
    // Set default selected filters if the knowledge source is a research knowledge source
    // and the user selected source is the same as the stored knowledge source
    if (
      isResearchKnowledgeSource(knowledgeSource) &&
      knowledgeSource.type === databaseSource
    ) {
      const filterSet = new Set(knowledgeSource.filterIds)
      const filters = allFiltersFlattened.filter((filter) =>
        filterSet.has(filter.id)
      )
      return filters
    }
    return []
  }, [knowledgeSource, allFiltersFlattened, databaseSource])

  const [selectedFilters, setSelectedFilters] = React.useState<
    ResearchFilter[]
  >(defaultSelectedFilters)

  React.useEffect(() => {
    if (!knowledgeSource) {
      setSelectedFilters([])
      return
    }
    if (!isResearchKnowledgeSource(knowledgeSource)) return
    setSelectedFilters(defaultSelectedFilters)
  }, [defaultSelectedFilters, knowledgeSource])

  const buttonDisabled = !selectedFilters.length

  const handleCancel = () => {
    setShowDialog(false)
    setSelectedFilters([])
  }

  const handleAdd = () => {
    setKnowledgeSource({
      ...knowledgeSource,
      type: databaseSource,
      filterIds: selectedFilters.flatMap((filter) => filter.id),
    })
    setShowDialog(false)
    setSelectedFilters([])
  }

  const areaTaxonomy = React.useMemo(() => {
    if (researchArea === ResearchArea.USCASELAW) {
      const federalCourts = formatCaseLawTaxonomy(
        TopLevelJurisdiction.Federal,
        taxonomy
      )
      const stateCourts = formatCaseLawTaxonomy(
        TopLevelJurisdiction.State,
        taxonomy
      )
      return [federalCourts, stateCourts]
    }
    return taxonomy
  }, [taxonomy, researchArea])

  if (!researchArea) return null

  return (
    <Dialog open={showDialog} onOpenChange={setShowDialog}>
      <DialogContent
        className="h-[70vh] max-w-[75vw]"
        // height is 70vh - header height - footer height
        // allows the borders of the explorer to be full height
        innerClassName="space-y-0 p-0 h-[calc(70vh-49px-67px)]"
      >
        <DialogHeader className="border-b px-6 py-2">
          <DialogTitle className="py-[6px] font-semibold">
            {config.label}
          </DialogTitle>
        </DialogHeader>
        <Explorer
          isOpen
          taxonomy={areaTaxonomy}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          isLoading={isTaxonomyLoading}
          researchArea={researchArea}
          maxTopLevelSelections={maxTopLevelSelections}
        />
        <DialogFooter className="space-x-3 border-t px-5 py-4">
          <Button variant="ghost" onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={buttonDisabled} onClick={handleAdd}>
            Add
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const formatCaseLawTaxonomy = (
  courtId: TopLevelJurisdiction,
  taxonomy: ResearchFilter[]
): ResearchFilter => {
  const children = taxonomy
    .filter((filter) => filter.id.includes(courtId))
    // Don't show additional filters, they will be selected by default
    .map((filter) => ({ ...filter, children: [] }))

  return {
    id: courtId,
    name: JURISDICTION_TITLE[courtId],
    exclusionTag: children[0]?.exclusionTag,
    children,
    parentId: null,
  }
}
