import React, { useEffect, useState } from 'react'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'

interface EditCaptionDialogProps {
  caption: string
  onCaptionUpdate: (newCaption: string) => void
  maxLength?: number
  title?: string
  placeholder?: string
  onCancel?: () => void
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
}

const EditCaptionDialog = ({
  caption,
  onCaptionUpdate,
  maxLength = 100,
  title = 'Edit title',
  placeholder = 'New title',
  onCancel,
  showDialog,
  setShowDialog,
}: EditCaptionDialogProps) => {
  const [editedCaption, setEditedCaption] = useState(caption)

  const handleSave = async () => {
    if (editedCaption.trim().length) {
      setShowDialog(false)
      onCaptionUpdate(editedCaption.trim())
    }
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    setShowDialog(false)
  }

  useEffect(() => {
    setEditedCaption(caption)
  }, [caption])

  return (
    <Dialog open={showDialog} onOpenChange={setShowDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="line-clamp-2 whitespace-pre-wrap text-xl font-medium">
            {title}
          </DialogTitle>
        </DialogHeader>
        <Input
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSave()
            }
          }}
          placeholder={placeholder}
          value={editedCaption}
          onChange={(e) => setEditedCaption(e.target.value)}
          maxLength={maxLength}
        />
        <DialogFooter>
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={!editedCaption.trim().length}>
            Update
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditCaptionDialog
