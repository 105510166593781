import * as React from 'react'

import { LucideIcon } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button, ButtonProps } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

interface TriggerButtonProps extends ButtonProps {
  title: string
  description: string
  icon: LucideIcon
  dataTestId?: string
}

const TriggerButton = React.forwardRef<HTMLButtonElement, TriggerButtonProps>(
  (
    { className, title, description, icon, disabled, dataTestId, ...props },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        variant="unstyled"
        className={cn(
          'group flex h-full w-full items-center justify-center gap-2 border border-transparent bg-primary py-4 pl-4 pr-3 transition hover:border-ring disabled:border-primary disabled:bg-secondary disabled:hover:border-primary',
          className
        )}
        disabled={disabled}
        data-testid={dataTestId}
        {...props}
      >
        <div className="flex-1">
          <p className="text-left text-sm font-medium group-disabled:text-inactive">
            {title}
          </p>
          {description && (
            <p className="text-left text-xs text-muted group-disabled:text-inactive">
              {description}
            </p>
          )}
        </div>
        <div className="flex size-8 items-center justify-center">
          <Icon
            icon={icon}
            className="size-4 text-muted group-disabled:text-inactive"
          />
        </div>
      </Button>
    )
  }
)

TriggerButton.displayName = 'TriggerButton'

export default TriggerButton
