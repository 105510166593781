/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BetaPermission = {
    CLIENT_ADMINMANAGE_ASSISTANT_WORKFLOWS: 'client_admin:manage_assistant_workflows',
    CLIENT_ADMINSELF_SERVE_PERMS_READONLY: 'client_admin:self_serve_perms_readonly',
    CLIENT_ADMINSELF_SERVE_PERMS_WRITE: 'client_admin:self_serve_perms_write',
    CREATEASYNC_FIELD_EXTRACTION_API: 'create:async_field_extraction_api',
    CREATECALL_SUMMARIZATION: 'create:call_summarization',
    CREATEKNOWLEDGE_BASE_PROJECT: 'create:knowledge_base_project',
    CREATEPLAYBOOK_REVIEW: 'create:playbook_review',
    CREATEUSER_PROFILES: 'create:user_profiles',
    CREATEUSER_PROFILES_OPTIONAL: 'create:user_profiles_optional',
    CREATEVAULT_WORKFLOW_LOAN_PORTFOLIO_REVIEW: 'create:vault_workflow_loan_portfolio_review',
    CREATEVAULT_WORKFLOW_REPS_WARRANTIES: 'create:vault_workflow_reps_warranties',
    USEASSISTANT_COMPOSER: 'use:assistant_composer',
    USEASSISTANT_LOCALIZATION: 'use:assistant_localization',
    USEDEALS_DISCOVERY_ASYNC_IMPL: 'use:deals_discovery_async_impl',
    USEDEALS_DISCOVERY_FROM_VAULT: 'use:deals_discovery_from_vault',
    USEDIARIZED_TRANSCRIPTION: 'use:diarized_transcription',
    USEDISALLOWED_CLIENT_MATTERS: 'use:disallowed_client_matters',
    USEDISCOVER_TAB: 'use:discover_tab',
    USEDOC_PROCESSING_RPC: 'use:doc_processing_rpc',
    USEIMANAGE_INTEGRATION: 'use:imanage_integration',
    USEKNOWLEDGE_BASE_PROJECT: 'use:knowledge_base_project',
    USELANCE_ENTERPRISE_VAULT: 'use:lance_enterprise_vault',
    USENETDOCS_EXTENSION: 'use:netdocs_extension',
    USEVAULT_PROJECT_CLIENT_MATTER: 'use:vault_project_client_matter',
    USEVAULT_WORKFLOW_BROWSER: 'use:vault_workflow_browser',
    VIEWLAST_ACTIVITY_DATE: 'view:last_activity_date',
    USEBREDIN_PRAT_KS: 'use:bredin_prat_ks',
    VIEWVAULT_WORKSPACE_PROJECTS: 'view:vault_workspace_projects'
} as const;
export type BetaPermission = typeof BetaPermission[keyof typeof BetaPermission];


export function instanceOfBetaPermission(value: any): boolean {
    for (const key in BetaPermission) {
        if (Object.prototype.hasOwnProperty.call(BetaPermission, key)) {
            if (BetaPermission[key as keyof typeof BetaPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BetaPermissionFromJSON(json: any): BetaPermission {
    return BetaPermissionFromJSONTyped(json, false);
}

export function BetaPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BetaPermission {
    return json as BetaPermission;
}

export function BetaPermissionToJSON(value?: BetaPermission | null): any {
    return value as any;
}

export function BetaPermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): BetaPermission {
    return value as BetaPermission;
}

