import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLocation, useSearchParams } from 'react-router-dom'

import { startCase, isEmpty } from 'lodash'
import { BookOpen, InfoIcon } from 'lucide-react'
import pluralize from 'pluralize'
import queryString from 'query-string'
import { useShallow } from 'zustand/react/shallow'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { bytesToPreciseReadable } from 'utils/file-utils'
import { TaskType } from 'utils/task'
import { parseIsoString } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import {
  ClientMatter,
  useClientMattersStore,
} from 'components/client-matters/client-matters-store'
import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import { Badge } from 'components/ui/badge'
import { Icon } from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import SetExampleProject from 'components/vault/components/set-example-project'
import VaultExportDialog from 'components/vault/dialogs/vault-export-dialog'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { useVaultProjectHistoryStats } from 'components/vault/utils/use-vault-project-history-stats'
import { useVaultProjectMetadata } from 'components/vault/utils/use-vault-project-metadata'
import {
  REMOVE_PARAMS,
  newProjectPathRaw,
  projectsPath,
  projectsPathRaw,
  filesPathRaw,
  queriesPathRaw,
  DOT_SEPARATOR,
  filesPath,
  isKnowledgeBaseProjectSearchParamKey,
} from 'components/vault/utils/vault'
import { LOADING_QUERY_TITLE } from 'components/vault/utils/vault'
import {
  getProjectMetadataFromVaultFolderMetadata,
  isEmptyMetadata,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

import FileBreadcrumb from './file-breadcrumb'
import { FileControls } from './file-controls'
import QueryDetailBreadcrumb from './query-detail-breadcrumb'
import VaultHomeHeader from './vault-home-header'
import VaultProjectTabs from './vault-project-tabs'
import VaultProjectTitle from './vault-project-title'
import VaultShareButton from './vault-share-button'

interface BreadcrumbPath {
  name: string
  path: string
}

const VaultAppHeader = () => {
  const location = useLocation()
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const [searchParams] = useSearchParams()

  const [clientMatters] = useClientMattersStore(
    useShallow((s) => [s.clientMatters])
  )

  const [
    currentProject,
    currentProjectMetadata,
    exampleProjectIds,
    allFileIdToVaultFile,
    allFolderIdToVaultFileIds,
  ] = useVaultStore(
    useShallow((s) => [
      s.currentProject,
      s.currentProjectMetadata,
      s.exampleProjectIds,
      s.allFileIdToVaultFile,
      s.allFolderIdToVaultFileIds,
    ])
  )
  const [
    isQueryLoading,
    queryTitle,
    shouldPollForHistoryItem,
    queryId,
    historyItem,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.isQueryLoading,
      s.queryTitle,
      s.shouldPollForHistoryItem,
      s.queryId,
      s.historyItem,
    ])
  )

  const queryIdState = historyItem as ReviewHistoryItem

  const isKnowledgeBaseProjectUser = userInfo.IsKnowledgeBaseProjectUser
  const isCreatingKnowledgeBaseProject =
    searchParams.get(isKnowledgeBaseProjectSearchParamKey) === 'true'

  const isNewQuery = queryId === 'new'
  const taskType = queryIdState?.taskType
  const queryNumFiles = queryIdState?.numFiles ?? 0
  const creatorUserEmail = queryIdState?.creatorUserEmail ?? ''

  const processedFileIds: string[] | undefined = useMemo(
    () => (queryIdState as ReviewHistoryItem)?.processedFileIds,
    [queryIdState]
  )

  const fileIds = useMemo(() => {
    const visibleReviewRows =
      queryIdState?.rows.filter((row) => !row.isHidden) ?? []
    const queryFileIds = visibleReviewRows.map((row) => row.fileId)
    return queryFileIds?.filter((id: string) => !!allFileIdToVaultFile[id])
  }, [allFileIdToVaultFile, queryIdState])

  const numFiles = useMemo(() => {
    return fileIds?.length == 0 && queryNumFiles
      ? queryNumFiles
      : fileIds?.length ?? 0
  }, [fileIds, queryNumFiles])

  const numColumns = useMemo(() => {
    return queryIdState?.columns?.length ?? 0
  }, [queryIdState])

  const startedAt = queryIdState?.startedAt
  const completedAt = queryIdState?.completedAt
  const pausedAt = queryIdState?.pausedAt
  const failedAt = queryIdState?.failedAt
  const eta = (queryIdState as ReviewHistoryItem)?.eta

  const [isSetExampleOpen, setIsSetExampleOpen] = useState<boolean>(false)

  const shouldRenderVaultHomeHeader = useMemo(() => {
    return (
      location.pathname === BaseAppPath.Vault ||
      location.pathname === `${BaseAppPath.Vault}/${projectsPathRaw}` ||
      location.pathname === `${BaseAppPath.Vault}${projectsPath}`
    )
  }, [location.pathname])

  const shouldRenderNewProjectBreadcrumb = useMemo(() => {
    const paths = location.pathname.split('/').filter(Boolean)
    return paths.length === 2 && paths[1] === newProjectPathRaw
  }, [location.pathname])

  const shouldRenderQueriesBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/queries
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 4 &&
      paths[1] === projectsPathRaw &&
      paths[3] === queriesPathRaw
    )
  }, [location.pathname])

  const shouldRenderQueryDetailBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/queries/:queryId
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 5 &&
      paths[1] === projectsPathRaw &&
      paths[3] === queriesPathRaw
    )
  }, [location.pathname])

  const shouldRenderFileBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/files/:fileId
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 5 &&
      paths[1] === projectsPathRaw &&
      paths[3] === filesPathRaw
    )
  }, [location.pathname])

  const shouldRenderProjectTabs = useMemo(() => {
    return (
      userInfo.IsVaultViewSharesUser &&
      shouldRenderVaultHomeHeader &&
      !isKnowledgeBaseProjectUser
    )
  }, [
    userInfo.IsVaultViewSharesUser,
    shouldRenderVaultHomeHeader,
    isKnowledgeBaseProjectUser,
  ])

  const isShowingProjectPage = useMemo(() => {
    // When path is /vault/projects/:projectId
    const paths = location.pathname.split('/').filter(Boolean)

    return paths.length === 3 && paths[1] === projectsPathRaw
  }, [location.pathname])

  // we cannot get the fileId from useParams because vault-app-header is not a child of the layout
  // its in the same route as the layout
  const currentFileId = useMemo(() => {
    if (!shouldRenderFileBreadcrumb) return undefined
    const paths = location.pathname.split('/').filter(Boolean)
    return paths[paths.length - 1]
  }, [location, shouldRenderFileBreadcrumb])
  const currentFile = useMemo(() => {
    if (!currentFileId) return undefined
    return allFileIdToVaultFile[currentFileId]
  }, [allFileIdToVaultFile, currentFileId])

  const {
    isOwner,
    canCurrentUserCreateShares,
    doesCurrentUserHaveEditPermission,
  } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const isExampleProject = useMemo(
    () => currentProject && exampleProjectIds.has(currentProject.id),
    [currentProject, exampleProjectIds]
  )
  const isKnowledgeBaseProject = currentProject?.isKnowledgeBaseProject ?? false
  const shouldHideShareButtonForProjectOwner =
    isOwner && !canCurrentUserCreateShares

  const breadcrumbPaths: BreadcrumbPath[] = useMemo(() => {
    // When path is either
    // 1. /vault/projects
    // 2. /vault/projects/:projectId
    // 3. /vault/projects/:projectId/queries
    // We should show the project name in the breadcrumbs
    const vaultDisplayNameWithPath = { name: 'Vault', path: BaseAppPath.Vault }
    const paths = location.pathname.split('/').filter(Boolean)

    if (shouldRenderNewProjectBreadcrumb) {
      if (isCreatingKnowledgeBaseProject) {
        return [
          vaultDisplayNameWithPath,
          { name: 'Create knowledge base', path: location.pathname },
        ]
      }
      return [
        vaultDisplayNameWithPath,
        { name: 'New project', path: location.pathname },
      ]
    }

    if (paths.length <= 2 || paths[1] !== projectsPathRaw) {
      // Since we are at vault app header, we should default to the vault projects path
      // if the path is not valid or too short
      return [vaultDisplayNameWithPath]
    }

    const projectId = paths[2]
    const projectDetailPath = `${BaseAppPath.Vault}${projectsPath}${projectId}`
    const projectDetailDisplayNameWithPath = {
      name: currentProject?.name || LOADING_QUERY_TITLE,
      path: projectDetailPath,
    }

    if (paths.length === 3) {
      return [vaultDisplayNameWithPath, projectDetailDisplayNameWithPath]
    }

    if (shouldRenderQueriesBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        { name: startCase(queriesPathRaw), path: location.pathname },
      ]
    }

    if (shouldRenderQueryDetailBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        {
          name: startCase(queriesPathRaw),
          path: `${projectDetailPath}/${queriesPathRaw}`,
        },
        { name: 'Query', path: location.pathname },
      ]
    }

    if (shouldRenderFileBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        { name: startCase(filesPathRaw), path: projectDetailPath },
        { name: 'File', path: location.pathname },
      ]
    }
    return []
  }, [
    location.pathname,
    currentProject?.name,
    shouldRenderNewProjectBreadcrumb,
    shouldRenderQueriesBreadcrumb,
    shouldRenderQueryDetailBreadcrumb,
    shouldRenderFileBreadcrumb,
    isCreatingKnowledgeBaseProject,
  ])

  const pathForIndexFunc = useCallback(
    (index: number, pathname: string, search: string) => {
      const queryParams = queryString.parse(search)
      for (const param of REMOVE_PARAMS) {
        delete queryParams[param]
      }
      if (Object.keys(queryParams).length > 0) {
        return `${breadcrumbPaths[index].path}?${queryString.stringify(
          queryParams
        )}`
      }
      return breadcrumbPaths[index].path
    },
    [breadcrumbPaths]
  )

  const overridePath = breadcrumbPaths.map((path) => path.name).join('/')
  // show project sharing button if user is currently viewing a project homepage
  const showShareButton =
    userInfo.IsVaultViewSharesUser &&
    currentProject?.id &&
    !isExampleProject &&
    !isQueryLoading &&
    isShowingProjectPage &&
    !shouldHideShareButtonForProjectOwner
  const showFileControls = !!currentFile
  const fileIdsInCurrentFolder = useMemo(() => {
    if (!currentFile?.vaultFolderId) return []
    return (allFolderIdToVaultFileIds[currentFile.vaultFolderId] ?? [])
      .map((id) => allFileIdToVaultFile[id])
      .filter((file) => !!file)
      .sort((a, b) => a!.name.localeCompare(b!.name))
      .map((file) => file!.id)
  }, [currentFile, allFolderIdToVaultFileIds, allFileIdToVaultFile])
  const previousFileId = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    const currentIndex = fileIdsInCurrentFolder.indexOf(currentFile.id)
    if (currentIndex === -1 || currentIndex === 0) return undefined
    return fileIdsInCurrentFolder[currentIndex - 1]
  }, [currentFile, fileIdsInCurrentFolder])
  const nextFileId = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    const currentIndex = fileIdsInCurrentFolder.indexOf(currentFile.id)
    if (
      currentIndex === -1 ||
      currentIndex === fileIdsInCurrentFolder.length - 1
    )
      return undefined
    return fileIdsInCurrentFolder[currentIndex + 1]
  }, [currentFile, fileIdsInCurrentFolder])
  const currentFileIndex = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    return fileIdsInCurrentFolder.indexOf(currentFile.id)
  }, [currentFile, fileIdsInCurrentFolder])

  const shouldShowClientMatterBadge =
    userInfo.IsVaultProjectClientMatterUser &&
    isShowingProjectPage &&
    currentProject &&
    currentProject.clientMatterId

  const navigateToFile = (currentProjectId: string, fileId: string) => {
    navigate(
      `${BaseAppPath.Vault}${projectsPath}${currentProjectId}${filesPath}${fileId}`,
      {},
      REMOVE_PARAMS
    )
  }
  useHotkeys(
    'up',
    () => {
      if (previousFileId) {
        navigateToFile(currentProject!.id, previousFileId)
      }
    },
    {
      enabled: showFileControls && !!currentProject && !!previousFileId,
    }
  )
  useHotkeys(
    'down',
    () => {
      if (nextFileId) {
        navigateToFile(currentProject!.id, nextFileId)
      }
    },
    {
      enabled: showFileControls && !!currentProject && !!nextFileId,
    }
  )
  const navigateToProject = (currentProjectId: string) => {
    navigate(
      `${BaseAppPath.Vault}${projectsPath}${currentProjectId}`,
      {},
      REMOVE_PARAMS
    )
  }
  // when the user presses escape key we want to hide the document previewer
  useHotkeys(
    'esc',
    () => {
      navigateToProject(currentProject!.id)
    },
    {
      enabled: showFileControls && !!currentProject,
    },
    [navigateToProject, currentProject, currentFile]
  )

  useEffect(() => {
    if (isEmpty(currentProject)) {
      document.title = 'Vault'
    } else if (currentFile) {
      document.title = currentFile.name
    } else if (!isEmpty(currentProject) && isNewQuery) {
      document.title = currentProject.name
    } else {
      document.title = queryTitle
    }

    return () => {
      document.title = 'Harvey'
    }
  }, [queryTitle, currentProject, overridePath, currentFile, isNewQuery])

  const projectFilesCountLimitDisplayText = useMemo(() => {
    return `Each project can contain up to ${userInfo.workspace
      .getVaultFilesCountLimit(userInfo.vaultFeature)
      .toLocaleString()} files`
  }, [userInfo.vaultFeature, userInfo.workspace])

  const { projectMetadata } = useVaultProjectMetadata(currentProject?.id ?? '')

  const sizeDisplayText = useMemo(() => {
    // Read current project metadata if it's loaded, otherwise read cached project metadata
    const metadata =
      currentProject && !isEmptyMetadata(currentProjectMetadata)
        ? getProjectMetadataFromVaultFolderMetadata(currentProjectMetadata)
        : projectMetadata ?? undefined
    if (!metadata) return 'Loading…'

    const numProjectFiles = metadata.filesCount
    const projectSize = bytesToPreciseReadable(metadata.totalSize, 2, true)
    if (numProjectFiles === 0) {
      return projectFilesCountLimitDisplayText
    }
    return `${pluralizeFiles(numProjectFiles)} (${projectSize})`
  }, [
    projectMetadata,
    projectFilesCountLimitDisplayText,
    currentProject,
    currentProjectMetadata,
  ])

  const { historyStats } = useVaultProjectHistoryStats(currentProject?.id ?? '')

  const queriesDisplayText = useMemo(() => {
    if (
      !historyStats ||
      historyStats.totalCount === 0 ||
      isKnowledgeBaseProject
    )
      return null
    return `${historyStats.totalCount} ${pluralize(
      'query',
      historyStats.totalCount
    )}`
  }, [historyStats, isKnowledgeBaseProject])

  const subtitleForProjectDetail = useMemo(() => {
    return [sizeDisplayText, queriesDisplayText]
      .filter(Boolean)
      .join(DOT_SEPARATOR)
  }, [sizeDisplayText, queriesDisplayText])

  const TitleComponent = useMemo(() => {
    if (shouldRenderVaultHomeHeader) {
      return <VaultHomeHeader />
    }

    if (shouldRenderFileBreadcrumb) {
      return <FileBreadcrumb currentFile={currentFile} />
    }

    if (shouldRenderQueryDetailBreadcrumb) {
      return (
        <QueryDetailBreadcrumb
          numFiles={numFiles}
          numColumns={numColumns}
          // Only show the query limit for vault review tasks
          shouldPollForHistoryItem={shouldPollForHistoryItem}
          processedFileIds={processedFileIds}
          isLoading={isQueryLoading}
          completedAt={completedAt}
          pausedAt={pausedAt}
          failedAt={failedAt}
          eta={eta ? parseIsoString(eta) : null}
          // Only show the processing started time if it's a review task, because
          // for other types of tasks, it's usually quick and not worth showing.
          startedAt={taskType === TaskType.VAULT_REVIEW ? startedAt : undefined}
          projectId={currentProject?.id}
          creatorUserEmail={creatorUserEmail}
        />
      )
    }

    if (breadcrumbPaths.length === 0) return null
    const lastBreadcrumbName = breadcrumbPaths[breadcrumbPaths.length - 1]?.name
    const subtitle = () => {
      if (shouldRenderQueriesBreadcrumb) return 'Browse your previous queries'
      if (shouldRenderNewProjectBreadcrumb) {
        if (isCreatingKnowledgeBaseProject) {
          return 'Distribute a repository of files to your organization'
        }
        return projectFilesCountLimitDisplayText
      }
      return subtitleForProjectDetail
    }
    const canEditTitle =
      !isExampleProject &&
      !isKnowledgeBaseProject &&
      doesCurrentUserHaveEditPermission

    return (
      <>
        <div className="flex space-x-2">
          <VaultProjectTitle
            title={lastBreadcrumbName}
            canEditTitle={canEditTitle}
          />
          {isExampleProject && (
            <Badge variant="secondary" className="border-0 text-muted" isPill>
              Example
              <Tooltip delayDuration={200}>
                <TooltipTrigger className="ml-1">
                  <Icon icon={InfoIcon} size="small" />
                </TooltipTrigger>
                <TooltipContent
                  className="max-w-80 text-start"
                  align="start"
                  side="right"
                >
                  This is an example project to give you a preview of how Vault
                  works. Queries cannot be run on this project. It will not
                  count towards any limits and cannot be deleted
                </TooltipContent>
              </Tooltip>
            </Badge>
          )}
          {isKnowledgeBaseProject && (
            <Badge variant="skeleton" className="border-0 p-1.5 text-muted">
              <div className="flex items-center gap-1">
                <Icon icon={BookOpen} size="small" />
                <p className="text-2xs font-medium">Knowledge base</p>
              </div>
            </Badge>
          )}
          <SetExampleProject
            isSetExampleOpen={isSetExampleOpen}
            setIsSetExampleOpen={setIsSetExampleOpen}
          />
        </div>
        <p className="truncate text-xs text-muted">{subtitle()}</p>
      </>
    )
  }, [
    currentFile,
    currentProject,
    numColumns,
    numFiles,
    taskType,
    isQueryLoading,
    processedFileIds,
    startedAt,
    completedAt,
    pausedAt,
    failedAt,
    eta,
    creatorUserEmail,
    shouldPollForHistoryItem,
    shouldRenderFileBreadcrumb,
    shouldRenderQueriesBreadcrumb,
    shouldRenderNewProjectBreadcrumb,
    shouldRenderQueryDetailBreadcrumb,
    shouldRenderVaultHomeHeader,
    breadcrumbPaths,
    projectFilesCountLimitDisplayText,
    isExampleProject,
    subtitleForProjectDetail,
    isSetExampleOpen,
    isKnowledgeBaseProject,
    isCreatingKnowledgeBaseProject,
    doesCurrentUserHaveEditPermission,
    setIsSetExampleOpen,
  ])

  return (
    <AppHeader
      titleComponent={TitleComponent}
      breadcrumbs={
        breadcrumbPaths.length === 0 ? null : (
          <RouterBreadcrumbs
            overridesDocumentTitle
            overridePath={overridePath}
            keepCaseStartingFromIndex={1}
            checkHistoryIdInPath={false}
            pathForIndexFunc={pathForIndexFunc}
          />
        )
      }
      actions={
        <div className="ml-2 flex shrink-0 grow items-center justify-end gap-2">
          {shouldShowClientMatterBadge && (
            <Badge
              variant="secondary"
              className="text-muted transition hover:bg-button-secondary"
            >
              CM#{' '}
              {
                clientMatters.find(
                  (cm: ClientMatter) =>
                    cm.id === currentProjectMetadata.clientMatterId ||
                    cm.id === currentProject.clientMatterId
                )?.name
              }
            </Badge>
          )}
          {shouldRenderQueryDetailBreadcrumb && <VaultExportDialog />}
          {showShareButton && <VaultShareButton />}
          {showFileControls && currentProject && (
            <FileControls
              currentFileIndex={currentFileIndex}
              fileIds={fileIdsInCurrentFolder}
              previousFileId={previousFileId}
              nextFileId={nextFileId}
              onNavigateToFile={(fileId: string) => {
                navigateToFile(currentProject.id, fileId)
              }}
              onExit={() => {
                navigateToProject(currentProject.id)
              }}
            />
          )}
        </div>
      }
    >
      {shouldRenderProjectTabs && (
        <div className="px-6">
          <VaultProjectTabs />
        </div>
      )}
    </AppHeader>
  )
}

export default VaultAppHeader
