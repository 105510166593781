import React from 'react'

import { SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataRequirements } from 'openapi/models/SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataRequirements'

import { Source } from 'utils/task'

import { Citations } from './citations'
import DataRow from './data-row'

const RequirementComponent: React.FC<{
  requirement: SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataRequirements
  idx: number
  handleSourceClick: (source: Source) => void
}> = ({ requirement, idx, handleSourceClick }) => {
  const formattedConclusion = requirement.requirementConclusion
    .toLowerCase()
    .replace('likely to be ', '')
  const titledConclusion =
    formattedConclusion.charAt(0).toUpperCase() + formattedConclusion.slice(1)

  return (
    <div className="space-y-2">
      <div className="mb-1 flex">
        <p className="mr-4 w-24 text-xs font-semibold">Requirement {idx + 1}</p>
        <p className="mr-1 text-xs font-semibold">{titledConclusion}</p>
        <Citations
          handleSourceClick={handleSourceClick}
          sources={requirement.sources as Source[]}
        />
      </div>

      <DataRow label="Requirement" value={requirement.requirement} />
      <DataRow label="Analysis" value={requirement.requirementAnalysis} />
    </div>
  )
}

export default RequirementComponent
