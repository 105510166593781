import React from 'react'

import { Row } from '@tanstack/react-table'
import { formatDistanceToNowStrict } from 'date-fns'
import { capitalize, startCase } from 'lodash'
import { Star } from 'lucide-react'
import pluralize from 'pluralize'

import { EventKind } from 'openapi/models/EventKind'
import { KnowledgeSourceType } from 'openapi/models/KnowledgeSourceType'
import { HistoryItem } from 'types/history'

import { cn, parseIsoString } from 'utils/utils'

import { useStarThread } from 'components/assistant/hooks/use-star-thread'
import {
  getMessageQuery,
  getMessageThreadFromMessages,
} from 'components/assistant/utils/assistant-helpers'
import {
  KnowledgeSource,
  KnowledgeSourceConfig,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { HistorySharedIcon } from 'components/history/history-cells'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface CellProps {
  row: Row<HistoryItem>
}

const BaseCell: React.FC<{
  children: React.ReactNode
  tooltip?: string
}> = ({ children, tooltip }) => {
  const content = (
    <span className="truncate text-sm text-secondary">{children}</span>
  )
  if (tooltip) {
    return (
      <Tooltip>
        <TooltipTrigger>{content}</TooltipTrigger>
        <TooltipContent>{tooltip}</TooltipContent>
      </Tooltip>
    )
  }
  return content
}

export const AssistantQueryCell: React.FC<CellProps> = ({ row }) => {
  let messages = row.original.messages ?? []
  messages = row.original.currentMessageId
    ? getMessageThreadFromMessages(messages, row.original.currentMessageId)
    : messages
  const numFollowUps = messages.length - 1

  const followUpNoun =
    row.original.kind === EventKind.ASSISTANT_DRAFT ? 'revision' : 'follow-up'

  const { isStarred, handleStar } = useStarThread(
    row.original.id,
    row.original.folderId
  )

  const name =
    row.original.userCaption ||
    messages[0]?.caption ||
    getMessageQuery(messages[0]) ||
    row.original.workflowName

  return (
    <span className="flex h-6 items-center space-x-2">
      <span className="line-clamp-1 break-all text-sm ">{name}</span>
      {numFollowUps > 0 && (
        <span className="shrink-0 rounded-full bg-button-secondary px-2 py-1 text-xs text-secondary">
          {numFollowUps} {pluralize(followUpNoun, numFollowUps)}
        </span>
      )}
      <HistorySharedIcon isShared={row.original.isShared || false} />
      <Button
        className={cn('ml-2 shrink-0 transition-opacity', {
          'opacity-0 group-hover:opacity-100': !isStarred,
        })}
        onClick={handleStar}
        size="xsIcon"
        variant="ghost"
      >
        <Icon
          icon={Star}
          className={cn({
            'fill-gold stroke-gold': isStarred,
            'stroke-primary': !isStarred,
          })}
        />
      </Button>
    </span>
  )
}

export const AssistantOwnerCell: React.FC<CellProps> = ({ row }) => {
  return (
    <BaseCell tooltip={row.original.userId}>{row.original.userId}</BaseCell>
  )
}

export const AssistantKindCell: React.FC<CellProps> = ({ row }) => {
  const kind =
    row.original.kind === EventKind.ASSISTANT_DRAFT
      ? 'Draft'
      : row.original.kind === EventKind.ASSISTANT
      ? 'Assistant Workflow'
      : 'Assist'

  return <BaseCell>{kind}</BaseCell>
}

export const AssistantSourcesCell: React.FC<CellProps> = ({ row }) => {
  const allFolderIdToVaultFolder = useVaultStore(
    (state) => state.allFolderIdToVaultFolder
  )
  const knowledgeSources = row.original.messages?.[0].knowledgeSources

  const isVaultKnowledgeSource =
    knowledgeSources?.[0]?.type === KnowledgeSourceType.VAULT
  const isKnowledgeBaseKnowledgeSource =
    isVaultKnowledgeSource &&
    !!allFolderIdToVaultFolder[knowledgeSources[0].folderId]
      ?.isKnowledgeBaseProject

  const numDocs =
    knowledgeSources?.[0]?.fileIds?.length ||
    row.original.documents?.length ||
    0

  const getText = () => {
    if (isKnowledgeBaseKnowledgeSource) {
      const knowledgeBaseName =
        allFolderIdToVaultFolder[knowledgeSources[0].folderId]?.name
      return `${knowledgeBaseName}`
    } else if (numDocs > 0) {
      return `${numDocs} ${pluralize('document', numDocs)}`
    } else if (knowledgeSources?.[0]?.type) {
      const config =
        KnowledgeSourceConfig[knowledgeSources[0].type as KnowledgeSource]
      if (config) return config.name
      return startCase(knowledgeSources[0].type)
    }
    return '-'
  }

  return <BaseCell>{getText()}</BaseCell>
}

export const AssistantTimeCell: React.FC<CellProps> = ({ row }) => {
  return (
    <BaseCell>
      {capitalize(
        formatDistanceToNowStrict(
          parseIsoString(row.original.created as unknown as string),
          { addSuffix: true }
        )
      )}
    </BaseCell>
  )
}
