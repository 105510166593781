import { useCallback, useEffect, useMemo } from 'react'

import * as Sentry from '@sentry/browser'
import {
  Query,
  QueryClient,
  QueryKey,
  useQueryClient,
} from '@tanstack/react-query'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { ReviewEventRunType } from 'openapi/models/ReviewEventRunType'
import Services from 'services'
import { Maybe } from 'types'

import { parseIsoString } from 'utils/utils'

import { ReviewEvent } from 'components/vault/utils/vault'
import { mapReviewEventToEventV1Metadata } from 'components/vault/utils/vault-helpers'

const FetchVaultHistoryItemV2 = async ({
  id,
  throwOnError = false,
  maxRetryCount = 3,
  lastUpdatedAt,
}: {
  id: Maybe<string>
  throwOnError?: boolean
  maxRetryCount?: number
  lastUpdatedAt?: string
}): Promise<ReviewEvent | null> => {
  if (!id) {
    return null
  }

  const searchParams = new URLSearchParams()
  // Always skip sources for fetching history item
  searchParams.append('skip_sources', 'true')
  if (lastUpdatedAt) {
    searchParams.append('last_updated_at', lastUpdatedAt)
  }

  const searchParamsString = searchParams.toString()

  return Services.Backend.Get<ReviewEvent>(
    `vault/v2/history/${id}${
      searchParamsString ? `?${searchParamsString}` : ''
    }`,
    {
      throwOnError: true,
      maxRetryCount: maxRetryCount,
    }
  ).catch((e) => {
    if (throwOnError) {
      throw e
    } else {
      console.warn('Failed to fetch history item', { e, id })
      return null
    }
  })
}

const vaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  throwOnError = false,
  lastUpdatedAt,
  queryClient,
}: {
  id: Maybe<string>
  vaultFolderId: string
  throwOnError: boolean
  lastUpdatedAt?: string
  queryClient?: QueryClient
}) => {
  const type: 'diff' | 'full' = lastUpdatedAt ? 'diff' : 'full'
  return {
    queryKey: [HarvQueryKeyPrefix.VaultHistoryItemQuery, id, { type }],
    queryFn: async ({ queryKey }: { queryKey: QueryKey }) => {
      let latestLastUpdatedAt = undefined
      if (type === 'diff' && queryClient) {
        // Get the latest data from cache if we are fetching the diff
        const previousData = queryClient.getQueryData(
          queryKey
        ) as ReviewEvent | null
        latestLastUpdatedAt = previousData?.lastUpdatedAt ?? lastUpdatedAt
      }

      const historyItem = await FetchVaultHistoryItemV2({
        id,
        throwOnError,
        maxRetryCount: 0,
        lastUpdatedAt: latestLastUpdatedAt,
      })
      if (historyItem && historyItem.vaultFolderId !== vaultFolderId) {
        throw new Error(
          `History item ${historyItem.id} does not belong to the vault project ${vaultFolderId}`
        )
      }
      return historyItem
    },
    initialData: null,
  }
}

export const useVaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  isEnabled = true,
  throwOnError = false,
  refetchInterval,
}: {
  id: Maybe<string>
  vaultFolderId: string
  isEnabled?: boolean
  throwOnError?: boolean
  refetchInterval?: (
    query: Query<ReviewEvent | null, Error, ReviewEvent | null, QueryKey>
  ) => number | false | undefined
}) => {
  const queryClient = useQueryClient()

  const fullHistoryItemQuery = useMemo(() => {
    return vaultHistoryItemQuery({ id, vaultFolderId, throwOnError })
  }, [id, vaultFolderId, throwOnError])

  const stableSelectFunction = useCallback((data: ReviewEvent | null) => {
    const reviewEvent = data
    if (!reviewEvent) return null
    if (reviewEvent.runs.length === 0) {
      console.warn('No runs found in history item', { reviewEvent })
      return null
    }
    const metadata = mapReviewEventToEventV1Metadata(reviewEvent)
    return {
      ...reviewEvent,
      id: reviewEvent.eventId.toString(), // This is a number in the backend
      userId: reviewEvent.eventCreatorEmail, // userId of the event v1 is the creator's email
      status: reviewEvent.eventStatus,
      query:
        reviewEvent.runs.find((run) => run.runType === ReviewEventRunType.NEW)
          ?.query ?? '',
      response: '',
      kind: reviewEvent.eventKind,
      created: parseIsoString(reviewEvent.eventCreatedAt),
      updatedAt: parseIsoString(reviewEvent.eventUpdatedAt),
      metadata: metadata,
      ...metadata,
    } as ReviewEvent
  }, [])

  // Fetch the full history item and transform it to the format we want
  const { error, data: historyItem } = useWrappedQuery({
    enabled: !!id && isEnabled,
    refetchOnWindowFocus: false,
    ...fullHistoryItemQuery,
    select: stableSelectFunction,
  })

  const diffHistoryItemQuery = useMemo(() => {
    return vaultHistoryItemQuery({
      id,
      vaultFolderId,
      throwOnError,
      lastUpdatedAt: historyItem?.lastUpdatedAt,
      queryClient,
    })
  }, [id, vaultFolderId, throwOnError, historyItem?.lastUpdatedAt, queryClient])

  // Fetch the diff history item and merge it with the full history item
  const { error: diffError, data: diffHistoryItem } = useWrappedQuery({
    enabled: !!id && isEnabled && !!historyItem?.lastUpdatedAt,
    refetchInterval,
    refetchOnWindowFocus: false,
    ...diffHistoryItemQuery,
  })

  useEffect(() => {
    // Only merge if the diff history item is more recent than the full history item
    if (
      historyItem &&
      diffHistoryItem &&
      historyItem.lastUpdatedAt &&
      diffHistoryItem.lastUpdatedAt &&
      historyItem.lastUpdatedAt < diffHistoryItem.lastUpdatedAt
    ) {
      const mergeArraysById = <T extends { id: string | number }>({
        existing,
        incoming,
        sortFn,
        dedupeKeyFn,
      }: {
        existing: T[]
        incoming: T[]
        // Sort the arrays by the X field when merging
        sortFn?: (a: T, b: T) => number
        // Dedupe the arrays by the Y field when merging
        dedupeKeyFn?: (item: T) => string
      }): T[] => {
        const existingMap = dedupeKeyFn
          ? new Map(existing.map((item) => [dedupeKeyFn(item), item]))
          : new Map(existing.map((item) => [item.id, item]))

        incoming.forEach((item) => {
          const key = dedupeKeyFn ? dedupeKeyFn(item) : item.id
          existingMap.set(key, item)
        })

        const merged = Array.from(existingMap.values())
        return sortFn ? merged.sort(sortFn) : merged
      }

      const mergedHistoryItem = {
        ...historyItem,
        ...diffHistoryItem,
        runs: mergeArraysById({
          existing: historyItem.runs,
          incoming: diffHistoryItem.runs,
          // Sort the runs by the updatedAt field
          sortFn: (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        }),
        columns: mergeArraysById({
          existing: historyItem.columns,
          incoming: diffHistoryItem.columns,
          // Sort the columns by the order field
          sortFn: (a, b) => a.order - b.order,
        }),
        rows: mergeArraysById({
          existing: historyItem.rows,
          incoming: diffHistoryItem.rows,
          // Sort the rows by the order field
          sortFn: (a, b) => a.order - b.order,
        }),
        cells: mergeArraysById({
          existing: historyItem.cells,
          incoming: diffHistoryItem.cells,
          // Dedupe the cells by the reviewColumnId and reviewRowId fields
          dedupeKeyFn: (cell) => `${cell.reviewColumnId}-${cell.reviewRowId}`,
        }),
        sources: mergeArraysById({
          existing: historyItem.sources,
          incoming: diffHistoryItem.sources,
        }),
      }
      // Update the full history item in the react query cache to be returned by the hook, and re-used next time
      queryClient.setQueryData(
        [HarvQueryKeyPrefix.VaultHistoryItemQuery, id, { type: 'full' }],
        mergedHistoryItem
      )
    }
  }, [historyItem, diffHistoryItem, queryClient, id])

  if (diffError || error) {
    Sentry.captureException(diffError ?? error)
    Services.HoneyComb.RecordError(diffError ?? error)
  }

  return { historyItem, error: diffError ?? error }
}
