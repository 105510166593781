import React from 'react'

import _ from 'lodash'

import { Permission, UserInfo } from 'models/user-info'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { Maybe } from 'types'

import { Env } from 'utils/env'
import { environment } from 'utils/server-data'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import SettingsPageHeader from 'components/settings/settings-page-header'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Textarea } from 'components/ui/text-area'

import IntegrationAdminCard from './integration-admin-card'
import { IntegrationDefinitions } from './integration-definitions'
import {
  authenticateSynclyAuthClient,
  getSynclyConnectorId,
} from './syncly-utils'
import { ConnectedIntegration } from './utils'

interface IntegrationAdminProps {
  userInfo: UserInfo
  connections: ConnectedIntegration[]
}

const IntegrationAdmin: React.FC<IntegrationAdminProps> = ({
  userInfo,
  connections,
}) => {
  const connectionsObj = React.useMemo(
    () => _.keyBy(connections, 'integrationType'),
    [connections]
  )
  const [synclyAuth0Token, setSynclyAuth0Token] =
    React.useState<Maybe<string>>(null)
  const [synclyConnectorId, setSynclyConnectorId] =
    React.useState<Maybe<string>>(null)

  const onClickGenerateSynclyAuth0Token = async () => {
    const token = await authenticateSynclyAuthClient(userInfo.id)
    setSynclyAuth0Token(token)
  }

  const onClickCopySynclyAuth0Token = () => {
    navigator.clipboard.writeText(synclyAuth0Token || '')
    displaySuccessMessage('Copied to clipboard')
  }

  const onGetSynclyConnectorId = async (connectorType: IntegrationType) => {
    if (!synclyAuth0Token) {
      displayErrorMessage('Please generate a Syncly Auth0 token first')
      return
    }
    const connectorId = await getSynclyConnectorId(
      connectorType,
      synclyAuth0Token
    )
    setSynclyConnectorId(connectorId)
  }

  return (
    <div>
      <SettingsPageHeader
        title="Integrations"
        subtitle="Allow anyone in your workspace to authenticate into enabled integrations."
      />
      {Object.entries(IntegrationDefinitions)
        .filter(
          ([, definition]) =>
            userInfo.permissions.includes(definition.permission) &&
            definition.canAdminManage
        )
        .map(([key, definition]) => {
          const conn = connectionsObj[key]

          return (
            <IntegrationAdminCard
              key={key}
              integrationType={key as IntegrationType}
              integrationDefinition={definition}
              enabled={userInfo.workspace.integrations.includes(
                key as IntegrationType
              )}
              connected={!_.isNil(conn)}
              workspaceId={userInfo.workspace.id}
            />
          )
        })}
      {/* adding this section for Syncly to generate tokens and test */}
      {userInfo.permissions.includes(Permission.IMANAGE_INTEGRATION) &&
        environment !== Env.PRODUCTION && (
          <div className="mt-4">
            <Card>
              <CardHeader>
                <CardTitle>Syncly Auth0 Token Generator</CardTitle>
              </CardHeader>
              <CardContent className="mt-2 flex flex-col gap-4">
                <Button
                  onClick={onClickGenerateSynclyAuth0Token}
                  className="w-40"
                >
                  Generate token
                </Button>
                {!_.isEmpty(synclyAuth0Token) && (
                  <Textarea
                    value={synclyAuth0Token || ''}
                    className="max-h-32 w-full"
                    readOnly
                  />
                )}
                {!_.isEmpty(synclyAuth0Token) && (
                  <div className="flex justify-end space-x-2">
                    <Button
                      className="w-40"
                      onClick={() => setSynclyAuth0Token(null)}
                      variant="destructive"
                    >
                      Clear
                    </Button>
                    <Button
                      className="w-40"
                      variant="secondary"
                      onClick={onClickCopySynclyAuth0Token}
                    >
                      Copy token
                    </Button>
                    <Button
                      className="w-40"
                      variant="secondary"
                      onClick={() =>
                        onGetSynclyConnectorId(IntegrationType.I_MANAGE)
                      }
                    >
                      Get Syncly Connector ID
                    </Button>
                    {!_.isEmpty(synclyConnectorId) && (
                      <Textarea
                        value={synclyConnectorId || ''}
                        className="mt-4 max-h-32 w-full"
                        readOnly
                      />
                    )}
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        )}
    </div>
  )
}

export default IntegrationAdmin
