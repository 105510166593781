import { useRef, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const useParamsWrapper = (paramKeys: string[]) => {
  const params = useParams()
  const prevParamsRef = useRef<Record<string, string | undefined>>({})

  // Check if any of the watched params have actually changed
  const hasParamsChanged =
    paramKeys.filter((key) => prevParamsRef.current[key] !== params[key])
      .length > 0

  useEffect(() => {
    if (hasParamsChanged) {
      prevParamsRef.current = { ...params }
    }
  }, [params, hasParamsChanged])

  return useMemo(() => {
    return paramKeys.map((key) => params[key])
  }, [params, paramKeys])
}

export default useParamsWrapper
