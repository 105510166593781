import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { format, differenceInDays } from 'date-fns'
import { Info, InfoIcon, TriangleAlert } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'
import { useSharingStore } from 'stores/sharing-store'
import { PermissionLevel, ShareType } from 'types/sharing'

import { getRelativeDaysText } from 'utils/date-utils'
import { doesUserHavePermission } from 'utils/sharing-helpers'
import { cn, parseIsoString } from 'utils/utils'

import VaultFileExplorerToolbelt from './components/file-explorer/vault-file-explorer-toolbelt'
import VaultRecentQueries from './components/vault-recent-queries'
import VaultSectionBreadcrumb from './components/vault-section-breadcrumb'
import { BaseAppPath } from 'components/base-app-path'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import { WorkspaceSharingOptions } from 'components/common/sharing/workspace-sharing-options'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import VaultFileExplorerActions from 'components/vault/components/file-explorer/vault-file-explorer-actions'
import VaultFileExplorerBreadcrumbs from 'components/vault/components/file-explorer/vault-file-explorer-breadcrumbs'
import VaultProgress from 'components/vault/components/vault-progress'

import VaultQueryDuplicateTableDialog from './dialogs/vault-query-duplicate-table-dialog'
import useSharingPermissions from './hooks/use-sharing-permissions'
import { useVaultFileExplorerStore } from './utils/vault-file-explorer-store'
import { changeSharedWithWorkspace } from './utils/vault-sharing-helpers'
import { useVaultSharingStore } from './utils/vault-sharing-store'
import { useVaultStore } from './utils/vault-store'
import VaultWorkflowList from './workflows/vault-workflow-list'

const VaultProjectDetail = () => {
  const { projectId } = useParams()
  const userInfo = useAuthUser()

  const isSidebarOpen = useGeneralStore(
    useShallow((state) => state.isSidebarOpen)
  )
  const [
    exampleProjectIds,
    currentProjectMetadata,
    currentProject,
    folderIdToVaultFileIds,
    fileIdToVaultFile,
    deleteVaultFolders,
    setError,
  ] = useVaultStore(
    useShallow((state) => [
      state.exampleProjectIds,
      state.currentProjectMetadata,
      state.currentProject,
      state.folderIdToVaultFileIds,
      state.fileIdToVaultFile,
      state.deleteVaultFolders,
      state.setError,
    ])
  )

  const [currentUserPermissionByProjectId, permissionsByProjectId] =
    useVaultSharingStore(
      useShallow((s) => [
        s.currentUserPermissionByProjectId,
        s.permissionsByProjectId,
      ])
    )

  const currentUserPermissionForProject = projectId
    ? currentUserPermissionByProjectId[projectId]
    : undefined

  const [selectedRows, setSelectedRows] = useVaultFileExplorerStore(
    useShallow((s) => [s.selectedRows, s.setSelectedRows])
  )

  const isExampleProject = useMemo(
    () => projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )

  const isKnowledgeBaseProject = currentProject?.isKnowledgeBaseProject ?? false

  const [allFolderIdToVaultFolder] = useVaultStore(
    useShallow((state) => [state.allFolderIdToVaultFolder])
  )

  const retentionDate = useMemo(() => {
    if (!projectId || !allFolderIdToVaultFolder[projectId]) return null

    const folder = allFolderIdToVaultFolder[projectId]
    if (!folder || !folder.projectRetainedAt) return null

    const retentionDate = parseIsoString(folder.projectRetainedAt)

    // Only return the date if it's within 7 days from now
    const daysUntilRetention = differenceInDays(retentionDate, new Date())
    if (daysUntilRetention <= 7 && daysUntilRetention >= 0) {
      return {
        date: format(retentionDate, 'MMMM d, yyyy'),
        daysRemaining: daysUntilRetention,
        onUpdate: folder?.projectRetentionOnUpdate,
      }
    }
    return null
  }, [projectId, allFolderIdToVaultFolder])

  useEffect(() => {
    const isCurrentUserNotProjectOwner =
      currentProjectMetadata.userId.trim() !== '' &&
      currentProjectMetadata.userId !== userInfo.dbId

    const isVaultSharingEnabled = userInfo.IsVaultViewSharesUser
    if (isVaultSharingEnabled) {
      // if sharing is enabled, only show error after fetching current user's project permission level
      if (
        isCurrentUserNotProjectOwner &&
        !isExampleProject &&
        projectId &&
        currentUserPermissionForProject &&
        !doesUserHavePermission({
          currentPermissionLevel: currentUserPermissionForProject,
          requiredPermissionLevel: PermissionLevel.VIEW,
          isOwner: currentProjectMetadata.userId === userInfo.dbId,
        })
      ) {
        deleteVaultFolders([projectId], projectId)
        setError({
          message:
            'You are not authorized to access this vault project.\nContact support@harvey.ai if this issue persists.',
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
      }
    } else {
      if (isCurrentUserNotProjectOwner && !isExampleProject) {
        deleteVaultFolders([projectId!], projectId)
        setError({
          message:
            'You are not authorized to access this vault project.\nContact support@harvey.ai if this issue persists.',
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
      }
    }
  }, [
    currentProjectMetadata,
    userInfo,
    deleteVaultFolders,
    setError,
    isExampleProject,
    permissionsByProjectId,
    currentUserPermissionForProject,
    projectId,
  ])

  const {
    doesCurrentUserHaveEditPermission,
    doesCurrentUserHaveFullAccessPermission,
  } = useSharingPermissions({
    projectId,
  })

  const shouldShowWorkflows =
    doesCurrentUserHaveEditPermission &&
    !isExampleProject &&
    !isKnowledgeBaseProject

  const shouldShowLooseFilesWarningBanner = useMemo(() => {
    if (
      !isKnowledgeBaseProject ||
      !doesCurrentUserHaveEditPermission ||
      !projectId
    ) {
      return false
    }

    // Check if the project (root folder) has any files directly in it
    const fileIdsInRootFolder = folderIdToVaultFileIds[projectId] || []
    const nonDeletedFilesInRootFolder = fileIdsInRootFolder.filter(
      (fileId) => !fileIdToVaultFile[fileId]?.deletedAt
    )
    return nonDeletedFilesInRootFolder.length > 0
  }, [
    isKnowledgeBaseProject,
    folderIdToVaultFileIds,
    projectId,
    fileIdToVaultFile,
    doesCurrentUserHaveEditPermission,
  ])

  const shouldShowKnowledgeBaseQueryInAssistantBanner =
    isKnowledgeBaseProject &&
    (currentUserPermissionForProject === PermissionLevel.VIEW ||
      currentUserPermissionForProject === PermissionLevel.EDIT) &&
    !doesCurrentUserHaveFullAccessPermission

  const shouldShowKnowledgeBaseSharingPermissionsBanner =
    isKnowledgeBaseProject && doesCurrentUserHaveFullAccessPermission

  return (
    <AppMain hasContainer>
      <div className={cn('flex flex-col gap-8')}>
        {retentionDate && (
          <Badge
            variant="secondary"
            className="w-full gap-x-1 bg-secondary px-4 py-1.5 text-muted"
          >
            <Icon icon={InfoIcon} size="small" />
            <span className="text-xs">
              {retentionDate.onUpdate
                ? `This project will be automatically deleted ${getRelativeDaysText(
                    retentionDate.daysRemaining
                  )} due to inactivity per your organization's policy. Making changes to the project, files, or queries will reset the inactivity timer.`
                : `This project will be automatically deleted ${getRelativeDaysText(
                    retentionDate.daysRemaining
                  )} to comply with your organization's retention policy.`}
            </span>
          </Badge>
        )}

        {shouldShowWorkflows && <VaultWorkflowList />}
        {shouldShowKnowledgeBaseQueryInAssistantBanner && (
          <KnowledgeBaseQueryInAssistantBanner
            canEdit={currentUserPermissionForProject === PermissionLevel.EDIT}
          />
        )}
        {shouldShowKnowledgeBaseSharingPermissionsBanner && (
          <KnowledgeBaseSharingPermissionsBanner />
        )}
        {!isKnowledgeBaseProject && <VaultRecentQueries />}
        <div>
          <VaultSectionBreadcrumb
            title={<VaultFileExplorerBreadcrumbs />}
            trailingActions={[
              <VaultFileExplorerActions key="file-explorer-actions" />,
            ]}
          />
          <VaultProgress />
          {shouldShowLooseFilesWarningBanner && <LooseFilesWarningBanner />}
          <VaultFileExplorer
            projectId={projectId}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </div>
        <VaultFileExplorerToolbelt
          className={cn('fixed ml-24 max-w-[calc(100%-192px)]', {
            'ml-8 max-w-[calc(100%-64px)]': !isSidebarOpen,
          })}
        />
        <VaultQueryDuplicateTableDialog />
      </div>
    </AppMain>
  )
}

const LooseFilesWarningBanner = () => {
  return (
    <div className="mt-4 flex rounded-lg bg-secondary p-4">
      <div className="flex flex-row items-center gap-2">
        <Icon icon={TriangleAlert} size="small" />
        <p>
          Some of your files aren‘t in a folder. Move them into a folder to
          query them in Assistant.
        </p>
      </div>
      {/* TODO(stella): add help center button when link is ready */}
    </div>
  )
}

const KnowledgeBaseQueryInAssistantBanner = ({
  canEdit,
}: {
  canEdit: boolean
}) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-4 rounded-lg border p-5">
      <div className="flex flex-col">
        <p className="text-sm font-medium">
          Start querying your knowledge base
        </p>
        <p className="text-xs text-muted">
          {!canEdit && (
            <>
              You have{' '}
              <span className="font-medium text-primary">view only</span> access
              to this Knowledge Base. To ask a query, visit Assistant and select
              this project from your Vault.
            </>
          )}
          {canEdit && (
            <>
              To ask a query, visit Assistant and select this project from your
              Vault.
            </>
          )}
        </p>
      </div>
      <Button
        onClick={() => navigate(BaseAppPath.Assistant)}
        size="sm"
        className="w-fit"
      >
        Go to Assistant
      </Button>
    </div>
  )
}

const KnowledgeBaseSharingPermissionsBanner = () => {
  const [currentProject] = useVaultStore(
    useShallow((state) => [state.currentProject])
  )

  const [
    permissionsByProjectId,
    appendPermissionsForProjectId,
    setPermissionsForProjectId,
  ] = useVaultSharingStore(
    useShallow((state) => [
      state.permissionsByProjectId,
      state.appendPermissionsForProjectId,
      state.setPermissionsForProjectId,
    ])
  )

  const [setIsSharing] = useSharingStore(
    useShallow((state) => [state.setIsSharing])
  )

  const userInfo = useAuthUser()

  const isSharedWithWorkspace = useMemo(() => {
    if (!currentProject) return false
    const isWorkspaceSharingEnabled =
      userInfo.workspace.sharingSettings.vault.workspaceLevel
    return (
      (permissionsByProjectId[currentProject.id]?.permissionsByWorkspace?.some(
        (workspace) => workspace.workspaceId === userInfo.workspace.id
      ) &&
        isWorkspaceSharingEnabled) ??
      false
    )
  }, [
    currentProject,
    permissionsByProjectId,
    userInfo.workspace.id,
    userInfo.workspace.sharingSettings.vault.workspaceLevel,
  ])

  const onChangeSharedWithWorkspace = async (sharedWithWorkspace: boolean) => {
    if (!currentProject) return

    await changeSharedWithWorkspace({
      sharedWithWorkspace,
      projectId: currentProject.id,
      isWorkspaceAlreadyShared: !!isSharedWithWorkspace,
      workspaceId: userInfo.workspace.id,
      isKnowledgeBaseProject: !!currentProject.isKnowledgeBaseProject,
      userDbId: userInfo.dbId,
      setIsSharing,
      appendPermissionsForProjectId,
      setPermissionsForProjectId,
    })
  }

  return (
    <div className="flex justify-between rounded-lg bg-secondary p-4">
      <div className="flex flex-row items-center gap-2">
        <Icon icon={Info} />
        {isSharedWithWorkspace ? (
          <p>
            This project is currently{' '}
            <span className="font-medium">
              shared to everyone at Acme Corp.
            </span>{' '}
            To add additional editors or modify this setting, open the{' '}
            <span className="font-medium">Share</span> menu above.
          </p>
        ) : (
          <p>
            Update your permissions to share this project to everyone at your
            organization.
          </p>
        )}
      </div>
      {!isSharedWithWorkspace && (
        <WorkspaceSharingOptions
          shareType={ShareType.VAULT_PROJECT}
          isReadOnly={false}
          isSharedWithWorkspace={isSharedWithWorkspace}
          onChangeSharedWithWorkspace={onChangeSharedWithWorkspace}
          isSharingWithEveryoneAtWorkspaceEnabled
          showCopyLinkButton={false}
          workspaceSharingDropdownVariant="compact"
        />
      )}
    </div>
  )
}

export default VaultProjectDetail
