import React, { useMemo } from 'react'

import { ColumnDef } from '@tanstack/react-table'

import { EventStatus } from 'openapi/models/EventStatus'
import { TableSelect } from 'openapi/models/TableSelect'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { Maybe } from 'types'

import { Source } from 'utils/task'

import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
  AssistantWorkflowSourceExtractor,
} from 'components/assistant/workflows'
import { AssistantWorkflowSidebar } from 'components/assistant/workflows/components/assistant-workflow-sidebar'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import RenderBlockToolbar from 'components/assistant/workflows/components/render-block-toolbar/render-block-toolbar'
import TableSelectTable, {
  generateColumns,
} from 'components/assistant/workflows/components/table-select/table-select'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useCalcTableMargin } from 'components/assistant/workflows/hooks/use-calc-table-margin'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import { inActiveCitationsLoadingState } from 'components/assistant/workflows/utils/utils'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

type RowData = TableSelect['rows'][number]

export const AssistantWorkflowTableRendererThread: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({
  blockParams,
  loadingStates,
  paramStatus,
  feedback,
  stepId,
  exportComponents,
}) => {
  const { headerText, table } = blockParams
  const { handleSourceClick, getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })

  const columns: ColumnDef<RowData>[] = table
    ? generateColumns(table, getHrvyInfoMetadata) || []
    : []

  const negativeMargin = useCalcTableMargin(
    blockParams.sources as Maybe<Source[]>
  )

  const isCompleted = paramStatus === EventStatus.COMPLETED

  const Sidebar = useMemo(() => {
    if (
      !inActiveCitationsLoadingState(loadingStates) &&
      !blockParams.sources?.length
    ) {
      return null
    }
    return (
      <AssistantWorkflowSidebar
        sources={blockParams.sources as Maybe<Source[]>}
        isStreaming={!isCompleted}
        onSetActiveFileId={handleSourceClick}
        className="ml-4 w-72 sm:ml-4 sm:w-72"
        // eslint-disable-next-line react/forbid-component-props
        style={{ marginLeft: negativeMargin }}
      />
    )
  }, [
    blockParams.sources,
    handleSourceClick,
    isCompleted,
    loadingStates,
    negativeMargin,
  ])

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <LoadingState
          isCompleted={isCompleted}
          paramStatus={paramStatus}
          states={loadingStates}
        />
        {paramStatus === 'COMPLETED' && headerText && (
          <AssistantWorkflowThreadText
            text={headerText}
            completionStatus={paramStatus}
          />
        )}
        {(table?.rows.length || 0) > 0 && (
          <div
            className="mt-5"
            style={{
              marginLeft: `-${negativeMargin}px`,
              marginRight: `-${negativeMargin}px`,
            }}
          >
            <TableSelectTable data={table!.rows} columns={columns} />
          </div>
        )}
        {isCompleted && (
          <div className="mt-6">
            <RenderBlockToolbar
              content=""
              feedback={feedback}
              workflowStepId={stepId ?? ''}
              exportComponents={exportComponents}
            />
          </div>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTableRendererInput: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams, paramStatus, onCancel }) => {
  const { headerText } = blockParams
  const isStreaming = paramStatus === EventStatus.IN_PROGRESS
  const renderedText = isStreaming ? 'Working…' : headerText
  return (
    <WorkflowInput>
      <WorkflowInputFooter
        footer={renderedText}
        onCancel={isStreaming ? onCancel : undefined}
      />
    </WorkflowInput>
  )
}

export const AssistantWorkflowTableRendererExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams }) => {
  const { table } = blockParams
  const { getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })

  const columns: ColumnDef<RowData>[] = table
    ? generateColumns(table, getHrvyInfoMetadata) || []
    : []

  return (
    <div className="mt-5">
      <TableSelectTable data={table?.rows ?? []} columns={columns} />
    </div>
  )
}

export const tableSourceExtractor: AssistantWorkflowSourceExtractor<
  typeof WorkflowRenderComponentBlocks.TABLE
> = (blockParams) => {
  return blockParams.sources as Source[]
}
