import React, { useEffect, useRef } from 'react'
import {
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { datadogRum } from '@datadog/browser-rum'
import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { useSharingStore } from 'stores/sharing-store'

import { BaseAppPath } from 'components/base-app-path'
import { CLIENT_MATTER_URL_PARAM } from 'components/client-matters/client-matter-utils'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import RedirectWithQuery from 'components/common/redirects/redirect-with-query'
import GeneralStoreListener from 'components/general-store-listener'

import useVaultUploadFiles from './hooks/use-vault-upload-files'
import VaultQueryDetail from './query-detail/vault-query-detail'
import { useVaultFolderUpdatesSubscription } from './utils/use-vault-folder-updates-subscription'
import { projectsPath, queriesPath, filesPath } from './utils/vault'
import { useVaultCreateProjectStore } from './utils/vault-create-project-store'
import {
  FetchVaultFolderShareStatus,
  SetVaultFolderLastOpened,
} from './utils/vault-fetcher'
import { useVaultFileExplorerStore } from './utils/vault-file-explorer-store'
import { isEmptyMetadata } from './utils/vault-helpers'
import { useVaultSharingStore } from './utils/vault-sharing-store'
import { useVaultStore } from './utils/vault-store'
import VaultFilePreviewer from './vault-file-previewer'
import VaultProjectDetail from './vault-project-detail'
import VaultProjectQueries from './vault-project-queries'

interface UseVaultProjectLoadTrackingProps {
  projectMetadata: VaultFolderMetadata | undefined
}

const useVaultProjectLoadTracking = ({
  projectMetadata,
}: UseVaultProjectLoadTrackingProps) => {
  const location = useLocation()
  const hasRecordedVaultProjectInitialLoad = useRef(false)

  useEffect(() => {
    if (
      !hasRecordedVaultProjectInitialLoad.current &&
      projectMetadata &&
      !isEmptyMetadata(projectMetadata) &&
      !location.pathname.includes(queriesPath)
    ) {
      datadogRum.stopDurationVital('vaultProjectInitialLoad')
      hasRecordedVaultProjectInitialLoad.current = true
    }
  }, [location.pathname, projectMetadata])
}

const VaultProjectLayout = () => {
  useVaultUploadFiles()
  const { projectId } = useParams()
  const userInfo = useAuthUser()
  const navigate = useNavigate()
  const location = useLocation()
  const [
    allFolderIdToVaultFolder,
    projectMetadata,
    exampleProjectIds,
    areExampleProjectsLoaded,
    currentProject,
    setIsProjectLayoutLoading,
    setCurrentProject,
    setAreUploadButtonsDisabled,
    upsertVaultFolders,
  ] = useVaultStore(
    useShallow((s) => [
      s.allFolderIdToVaultFolder,
      s.allFoldersMetadata[projectId!],
      s.exampleProjectIds,
      s.areExampleProjectsLoaded,
      s.currentProject,
      s.setIsProjectLayoutLoading,
      s.setCurrentProject,
      s.setAreUploadButtonsDisabled,
      s.upsertVaultFolders,
    ])
  )

  const clearFilesToUpload = useVaultCreateProjectStore(
    (s) => s.clearFilesToUpload
  )

  const setPermissionsForProjectId = useVaultSharingStore(
    (s) => s.setPermissionsForProjectId
  )
  const setIsFetchingFolderShareStatus = useVaultSharingStore(
    (s) => s.setIsFetchingFolderShareStatus
  )
  const setDidFetchFolderShareStatusFail = useSharingStore(
    (s) => s.setDidFetchSharingStatusFail
  )
  const clearSearchHandler = useVaultFileExplorerStore(
    (s) => s.clearSearchHandler
  )

  const [
    clientMatters,
    setSelectedClientMatter,
    setClientMatterSelectDisabled,
  ] = useClientMattersStore(
    useShallow((s) => [
      s.clientMatters,
      s.setSelectedClientMatter,
      s.setClientMatterSelectDisabled,
    ])
  )

  // we need to add this useMount to update the current project
  // because the current project is cleared when the component is unmounted
  // this will ensure that the header and other components have the correct project
  useMount(async () => {
    if (!projectId) return
    setIsProjectLayoutLoading(true)

    if (location.pathname.includes(queriesPath)) {
      datadogRum.startDurationVital('vaultReviewQueryFullLoad')
    } else {
      // If the user is navigating from the Vault home page, then the currentProject will already be set
      const vitalContext = {
        context: {
          navigatingFromVaultHome: !!currentProject,
        },
      }
      datadogRum.startDurationVital('vaultProjectFullLoad', vitalContext)
      datadogRum.startDurationVital('vaultProjectInitialLoad', vitalContext)
    }

    const project = allFolderIdToVaultFolder[projectId]
    if (project) {
      setCurrentProject(project)
    }
    setIsProjectLayoutLoading(false)

    if (userInfo.IsVaultProjectClientMatterUser) {
      // set current client matter if exists on project
      const clientMatter = clientMatters.find(
        (cm) => cm.id === project?.clientMatterId
      )
      setSelectedClientMatter(clientMatter)
      if (clientMatter && !_.isEmpty(clientMatter.name)) {
        // Update the URL with the selected client matter only if there are client matters
        const searchParams = new URLSearchParams(location.search)
        searchParams.set(CLIENT_MATTER_URL_PARAM, clientMatter.name)
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        })
        setClientMatterSelectDisabled(true)
      }
    }

    // update last opened time for the project
    try {
      void SetVaultFolderLastOpened(projectId)
      if (project) {
        upsertVaultFolders(
          [{ ...project, lastOpenedAt: new Date().toISOString() }],
          userInfo.dbId,
          false,
          projectId
        )
      }
    } catch (error) {
      console.error('Error updating last opened time for the project')
    }
  })

  useUnmount(() => {
    setAreUploadButtonsDisabled(true)
    clearFilesToUpload()
    setCurrentProject(null)
    setIsProjectLayoutLoading(false)
    clearSearchHandler()
    if (userInfo.IsVaultProjectClientMatterUser) {
      setClientMatterSelectDisabled(false)
    }
  })

  useVaultProjectLoadTracking({ projectMetadata })

  useEffect(() => {
    if (!projectId) return

    async function fetchVaultFolderShareStatus() {
      if (!projectId || !areExampleProjectsLoaded) return

      try {
        setIsFetchingFolderShareStatus(true)
        const response = await FetchVaultFolderShareStatus(projectId)
        setDidFetchFolderShareStatusFail(false)
        setPermissionsForProjectId({
          projectId,
          userId: userInfo.dbId,
          workspaceId: userInfo.workspace.id,
          permissions: response.shareStatus,
        })
      } catch (error) {
        setDidFetchFolderShareStatusFail(true)
        console.error('Error fetching vault folder share status')
      }
      setIsFetchingFolderShareStatus(false)
    }

    const isExampleProject = exampleProjectIds.has(projectId)
    if (userInfo.IsVaultViewSharesUser && !isExampleProject) {
      void fetchVaultFolderShareStatus()
    }
  }, [
    exampleProjectIds,
    areExampleProjectsLoaded,
    projectId,
    userInfo.IsVaultViewSharesUser,
    userInfo.dbId,
    userInfo.workspace.id,
    setDidFetchFolderShareStatusFail,
    setIsFetchingFolderShareStatus,
    setPermissionsForProjectId,
  ])

  if (!projectId) return null

  const isKnowledgeBaseProject = currentProject?.isKnowledgeBaseProject

  if (!projectMetadata || isEmptyMetadata(projectMetadata)) {
    return (
      <AppMain className="flex w-full">
        <FullscreenLoading isLoading zIndex="z-50" />
      </AppMain>
    )
  }

  // if the user navigates to /files, let's redirect them to the project page
  return (
    <>
      <VaultFolderUpdatesSubscription projectId={projectId} />
      <GeneralStoreListener />
      <Routes>
        <Route path="/" element={<VaultProjectDetail />} />
        <Route
          path={filesPath}
          element={
            <RedirectWithQuery
              to={`${BaseAppPath.Vault}${projectsPath}${projectId}`}
            />
          }
        />
        <Route path={`${filesPath}:fileId`} element={<VaultFilePreviewer />} />
        {!isKnowledgeBaseProject && (
          <Route path={queriesPath} element={<VaultProjectQueries />} />
        )}
        {!isKnowledgeBaseProject && (
          <Route
            path={`${queriesPath}:queryId`}
            element={<VaultQueryDetail />}
          />
        )}
      </Routes>
    </>
  )
}

const VaultFolderUpdatesSubscription = ({
  projectId,
}: {
  projectId: string
}) => {
  useVaultFolderUpdatesSubscription(projectId)
  return null
}

export default VaultProjectLayout
