import React, { useState } from 'react'

import { Pencil } from 'lucide-react'

import { Button } from 'components/ui/button'

import EditCaptionDialog from './edit-caption-dialog'

type WorkflowCaptionProps = {
  caption?: string
  title: string
  onCaptionUpdate: (newCaption: string) => void
  showEditCaptionButton: boolean
  disableEditCaption: boolean
}

export const WorkflowCaption = ({
  caption,
  title,
  onCaptionUpdate,
  showEditCaptionButton,
  disableEditCaption,
}: WorkflowCaptionProps) => {
  const [showEditCaptionDialog, setShowEditCaptionDialog] = useState(false)
  const [hovered, setIsHovered] = useState(false)

  return (
    <div className="flex items-center gap-1">
      <h1 className="line-clamp-2 whitespace-pre-wrap text-xl font-medium">
        {caption || title}
      </h1>
      {showEditCaptionButton && (
        <Button
          variant="ghost"
          size="xsIcon"
          onClick={() => setShowEditCaptionDialog(true)}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
          disabled={disableEditCaption}
        >
          <Pencil
            size={12}
            stroke={
              !disableEditCaption && hovered
                ? 'currentColor'
                : 'hsl(var(--neutral-500))'
            }
          />
        </Button>
      )}

      <EditCaptionDialog
        caption={caption || title}
        onCaptionUpdate={onCaptionUpdate}
        showDialog={showEditCaptionDialog}
        setShowDialog={setShowEditCaptionDialog}
      />
    </div>
  )
}
