import * as React from 'react'

import { isNil, startCase } from 'lodash'
// import { ArrowUpRightIcon } from 'lucide-react'
import pluralize from 'pluralize'

import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'
import { FileType, FileTypeToExtension, FileTypeToIcon } from 'types/file'

import { bytesToReadable } from 'utils/file-utils'
import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import AOLogo from 'components/assistant/workflows/blocks/antitrust-filing-analysis/summary/ao-logo'
import { DEFAULT_BLOCK_PARAMS as FILE_UPLOAD_DEFAULT_BLOCK_PARAMS } from 'components/assistant/workflows/blocks/assistant-workflow-file-upload-input'
import AnswerPreview from 'components/assistant/workflows/components/preview-modal/answer-preview'
import DraftPreview from 'components/assistant/workflows/components/preview-modal/draft-preview'
import TablePreview from 'components/assistant/workflows/components/preview-modal/table-preview'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'
import Link from 'components/ui/link/link'
import Tag from 'components/ui/tag'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { WorkflowCategory } from 'components/workflows/workflow-card'

interface PreviewModalProps {
  workflow: WorkflowDefinitionListItem
  children: React.ReactNode
}

const ModalSection: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <dt className="text-xs font-medium text-inactive">{title}</dt>
      <dd>{children}</dd>
    </div>
  )
}

const CobrandSection: React.FC<{ workflow: PreviewModalProps['workflow'] }> = ({
  workflow,
}) => {
  let Logo = null
  if (workflow.name === 'Antitrust Filings') {
    Logo = <AOLogo className="h-3" />
  }

  if (!Logo) return null

  return <ModalSection title="Made by">{Logo}</ModalSection>
}

const formatFileType = (
  canFreeformText: boolean,
  acceptedFileMimeTypes: FileType[]
) => {
  if (canFreeformText) {
    return ['Paste text or add up to']
  }
  const existingUses: Record<string, number> = {}
  const finalArray: string[] = []
  acceptedFileMimeTypes.forEach((mimeType) => {
    const matchingExtension = FileTypeToExtension[mimeType]
    if (!matchingExtension) return

    matchingExtension.forEach((extension) => {
      const lowerCaseExtension = extension.toLowerCase()
      if (!existingUses[lowerCaseExtension]) {
        existingUses[lowerCaseExtension] = 0
        finalArray.push(lowerCaseExtension)
      }
      existingUses[lowerCaseExtension]++
    })
  })
  return finalArray
}

const renderDocsNeededToStart = (
  docParamsNeededToStart: WorkflowDefinitionListItem['docParamsNeededToStart']
) => {
  if (!docParamsNeededToStart) {
    // This means the workflow doesn't need any files to start
    // So do not render this section
    return null
  }

  const {
    maxFileCount,
    maxFileSizeBytes,
    acceptedFileMimeTypes,
    canFreeformText,
  } = docParamsNeededToStart
  const fileTypesArray = formatFileType(canFreeformText, acceptedFileMimeTypes)
  const numFiles: number = isNil(maxFileCount)
    ? FILE_UPLOAD_DEFAULT_BLOCK_PARAMS.maxFileCount
    : maxFileCount
  const maxFileSize = isNil(maxFileSizeBytes)
    ? FILE_UPLOAD_DEFAULT_BLOCK_PARAMS.maxFileSizeBytes
    : maxFileSizeBytes

  const supportedFileElement = (
    <span
      className={cn(
        fileTypesArray.length > 3 && 'cursor-pointer font-medium underline'
      )}
    >
      supported {pluralize('file', numFiles)}
    </span>
  )
  const numFilesString = `up to ${bytesToReadable(maxFileSize)}`

  return (
    <ModalSection title="Documents needed to start this workflow">
      <div className="flex items-center gap-2">
        <FileTypeToIcon.Unknown className="size-8 shrink-0" />
        <div>
          <p className="text-sm">An information source</p>
          <div className="flex items-center">
            <p className="flex gap-1 text-xs text-muted">
              {fileTypesArray.length < 4 ? (
                <span>{fileTypesArray.join(', ')}</span>
              ) : null}
              <span>{numFiles}</span>
              <Tooltip open={fileTypesArray.length < 4 ? false : undefined}>
                <TooltipTrigger asChild>{supportedFileElement}</TooltipTrigger>
                <TooltipContent side="bottom">
                  <p>{fileTypesArray.join(', ')}</p>
                </TooltipContent>
              </Tooltip>
              <span>{numFilesString}</span>
            </p>
          </div>
        </div>
      </div>
    </ModalSection>
  )
}

const renderPreview = (workflow: WorkflowDefinitionListItem) => {
  if (workflow.outputType === 'answer') {
    return <AnswerPreview workflow={workflow} />
  }
  if (workflow.outputType === 'draft') {
    return <DraftPreview workflow={workflow} />
  }
  return <TablePreview workflow={workflow} />
}

const PreviewModal: React.FC<PreviewModalProps> = ({ workflow, children }) => {
  const trackEvent = useAssistantAnalytics()
  const { description, tags, userInputStepCount, docParamsNeededToStart } =
    workflow

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent innerClassName="p-0 space-y-0" className="max-w-4xl">
        <DialogHeader className="border-b pb-2 pl-6 pr-3 pt-3">
          <DialogTitle className="flex h-8 items-center text-base font-medium">
            {workflow.name}
          </DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[minmax(300px,1fr)_484px]">
          <div className="flex flex-col gap-9 pb-5 pl-6 pr-10 pt-6">
            <ModalSection title="About this workflow">
              <p className="text-sm">{description}</p>
            </ModalSection>
            <ModalSection title="Practice area">
              <div className="flex flex-wrap gap-2">
                {tags.map((tag) => {
                  const tagName =
                    tag === 'Allen_and_overy'
                      ? 'General'
                      : startCase(tag.toLowerCase())
                  return <Tag key={tag} text={tagName} />
                })}
              </div>
            </ModalSection>
            {renderDocsNeededToStart(docParamsNeededToStart)}
            <div className="h-16" />

            <CobrandSection workflow={workflow} />
          </div>
          <div className="relative hidden flex-col items-center justify-center gap-3 bg-secondary p-10 md:flex">
            {renderPreview(workflow)}
            <div className="absolute bottom-3 flex justify-center">
              {/* <Button variant="ghost" size="sm" className="gap-1 text-muted">
                <span className="text-xs">Preview workflow</span>
                <ArrowUpRightIcon className="size-3" />
              </Button> */}
            </div>
          </div>
        </div>

        <DialogFooter className="flex-row border-t px-6 py-4">
          <div className="flex h-8 w-full items-center justify-between">
            <p className="text-xs text-muted">
              {userInputStepCount} {pluralize('step', userInputStepCount)}
            </p>
            <Link
              to={`/assistant/workflows/${workflow.slug}`}
              onClick={() => {
                trackEvent('Workflow Clicked', {
                  workflow_id: workflow.id,
                  workflow_name: workflow.name,
                  workflow_type: WorkflowCategory.ASSISTANT,
                })
              }}
            >
              <Button>Continue</Button>
            </Link>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default PreviewModal
