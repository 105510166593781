import React from 'react'

import { capitalize } from 'lodash'

import { SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataCountryCodeToAnalysis } from 'openapi/models/SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataCountryCodeToAnalysis'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'

import { insertPartyRevenueCombinations } from './country-detail-card'
import StatusBadge from './status-badge'
import { computeStatus } from './utils'

interface CountryCardProps {
  className?: string
  countryName: string
  analysis: SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataCountryCodeToAnalysis
  setSelectedCountry: (country: string) => void
}

const DataRow: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  const formattedConclusion = capitalize(
    value.trim().toLowerCase().replaceAll('likely to be', '').trim()
  )

  return (
    <div className="grid grid-cols-10 gap-2">
      <p className="col-span-7 text-xs text-muted">{label}</p>
      <p className="col-span-3 text-xs">{formattedConclusion}</p>
    </div>
  )
}

const CountryCard: React.FC<CountryCardProps> = ({
  className,
  countryName,
  analysis,
  setSelectedCountry,
}) => {
  return (
    <Button
      variant="unstyled"
      className={cn(
        'flex h-full transform flex-col items-start justify-start rounded border border-transparent bg-secondary px-4 py-3 text-left transition hover:border-primary',
        className
      )}
      onClick={() => setSelectedCountry(countryName)}
    >
      <div className="flex w-full items-center justify-between">
        <p className="text-base">{analysis.countryName}</p>
        <div className="flex items-center gap-2">
          {/* <Badge variant="secondary">{computeStatus(analysis)}</Badge> */}
          <StatusBadge label={computeStatus(analysis)} />
        </div>
      </div>
      <div className="mt-4 w-full space-y-4">
        <div className="w-full">
          <p className="mb-1 text-xs font-medium">Local Turnover</p>
          <div className="space-y-1">
            {insertPartyRevenueCombinations(analysis.partyRevenues, false).map(
              (partyRevenue) => (
                <DataRow
                  key={partyRevenue.label}
                  label={partyRevenue.label}
                  value={partyRevenue.value}
                />
              )
            )}
          </div>
        </div>
        <div className="w-full">
          <p className="mb-1 text-xs font-medium">Thresholds</p>
          <div className="space-y-1">
            {analysis.thresholds.map((threshold) => (
              <DataRow
                key={threshold.name}
                label={threshold.name}
                value={threshold.overallConclusion}
              />
            ))}
          </div>
        </div>
      </div>
    </Button>
  )
}

export default CountryCard
