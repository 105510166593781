import * as React from 'react'

import { WorkflowAnswerSampleOutput } from 'openapi/models/WorkflowAnswerSampleOutput'
import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'

import { AssistantWorkflowLayoutIconComponent } from 'components/assistant/workflows/blocks/assistant-workflow-block-layout'
import { AssistantWorkflowLayoutContainer } from 'components/assistant/workflows/blocks/assistant-workflow-block-layout'
import { MOCK_DATA } from 'components/assistant/workflows/components/preview-modal/mock-data'
import HarveyLogo from 'components/common/harvey-logo'
import Markdown from 'components/common/markdown/markdown'
import Citation from 'components/ui/citation'

interface AnswerPreviewProps {
  workflow: WorkflowDefinitionListItem
}

const AnswerPreview: React.FC<AnswerPreviewProps> = ({ workflow }) => {
  let content: WorkflowAnswerSampleOutput['data'] = MOCK_DATA.answer.content
  if (workflow.sampleOutput) {
    const sampleOutput = workflow.sampleOutput as WorkflowAnswerSampleOutput
    content = sampleOutput.data
  }

  return (
    <div
      data-testid="answer-preview"
      className="relative h-[276px] w-full max-w-[404px] overflow-hidden"
    >
      <AssistantWorkflowLayoutContainer>
        <AssistantWorkflowLayoutIconComponent className="bg-accent">
          <HarveyLogo className="size-3" />
        </AssistantWorkflowLayoutIconComponent>
        <div className="flex-1">
          <Markdown
            content={content}
            className="prose-xs pl-2"
            width="100%"
            components={{
              span: ({ children, ...props }) => {
                if ('data-hrvy-id' in props) {
                  return <Citation>{children}</Citation>
                }
                return <span {...props}>{children}</span>
              },
            }}
          />
        </div>
      </AssistantWorkflowLayoutContainer>

      <div className="absolute inset-x-0 bottom-0 h-28 w-full bg-gradient-to-t from-secondary to-transparent" />
    </div>
  )
}

export default AnswerPreview
