import React, { useMemo } from 'react'

import { PracticeAreaTaxonomy } from 'openapi/models/PracticeAreaTaxonomy'
import { Profession } from 'openapi/models/Profession'
import { Title } from 'openapi/models/Title'
import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'

import ExampleCardList from 'components/assistant/workflows/components/example-card-list/example-card-list'
import { useWorkflowData } from 'components/assistant/workflows/hooks/use-workflow-data'
import { useUserProfileStore } from 'components/common/user-profile-store'
import Skeleton from 'components/ui/skeleton'

type WorkflowCategoryTypes =
  | 'A&O Shearman'
  | 'For General Work'
  | 'For Transactional Work'
  | 'For In-House Work'
  | 'For Litigation Work'
  | 'For Financial Services'

type WorkflowCategory = {
  [key in WorkflowCategoryTypes]?: WorkflowDefinitionListItem[]
}

const AssistantWorkflowExamples = () => {
  const { workflows, categorizedWorkflows, isLoading } = useWorkflowData()

  const userProfile = useUserProfileStore((state) => state.userProfile)

  const visibleCategories: WorkflowCategory = useMemo(() => {
    const {
      transactional,
      litigation,
      general,
      allen_and_overy,
      financial_services,
    } = categorizedWorkflows

    const categories: WorkflowCategory = {
      'A&O Shearman': allen_and_overy,
      'For General Work': general,
      'For Transactional Work': transactional,
      'For Litigation Work': litigation,
      'For Financial Services': financial_services,
    }

    if (!userProfile) {
      return categories
    }

    const userProfileTaxonomies =
      userProfile.practiceAreas?.flatMap((pa) => pa.taxonomies) ?? []

    if (
      userProfileTaxonomies.every(
        (t) => t === PracticeAreaTaxonomy.TRANSACTIONAL
      )
    ) {
      delete categories['For Litigation Work']

      return categories
    }

    if (
      userProfileTaxonomies.every((t) => t === PracticeAreaTaxonomy.LITIGATION)
    ) {
      delete categories['For Transactional Work']

      return categories
    }

    if (userProfile.title === Title.IN_HOUSE) {
      delete categories['For Litigation Work']
      delete categories['For Transactional Work']
      categories['For In-House Work'] = transactional

      return categories
    }

    if (userProfile.primaryProfession !== Profession.LEGAL) {
      delete categories['For Transactional Work']
      delete categories['For Litigation Work']

      return categories
    }

    return categories
  }, [categorizedWorkflows, userProfile])

  if (isLoading) {
    return <Skeleton className="py-4" rows={3} rowHeight="h-4" />
  }

  if (!workflows?.length) {
    return (
      <p className="pt-6 text-center text-muted">
        Example workflows will appear here
      </p>
    )
  }

  return (
    <div className="mt-6 flex flex-col gap-6">
      {Object.keys(visibleCategories).map((category) => (
        <ExampleCardList
          key={category}
          items={visibleCategories[category as WorkflowCategoryTypes] ?? []}
          title={category}
        />
      ))}
    </div>
  )
}
export default AssistantWorkflowExamples
