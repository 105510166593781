import React, { memo } from 'react'

import { Pencil, Trash, Check, X } from 'lucide-react'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

export const RowActions = memo(function RowActionsRender({
  canEdit,
  canAddRemoveRows,
  handleEditRow,
  handleRemoveRow,
  handleSaveRow,
  handleCancelRow,
  isEditing,
  rowIdx,
}: {
  canEdit: boolean | undefined
  canAddRemoveRows: boolean | undefined
  handleEditRow: (rowIdx: number) => void
  handleRemoveRow: (rowIdx: number) => void
  handleSaveRow: (rowIdx: number) => void
  handleCancelRow: (rowIdx: number) => void
  isEditing: boolean | undefined
  rowIdx: number
  isValid?: boolean
}) {
  return (
    <div className="flex items-center gap-2">
      {!isEditing ? (
        <>
          {canEdit && (
            <Button
              className="shrink-0 transition hover:bg-secondary-hover"
              size="xsIcon"
              variant="ghost"
              aria-label="Edit row"
              onClick={() => handleEditRow(rowIdx)}
            >
              <Icon icon={Pencil} size="small" variant="secondary" />
            </Button>
          )}
          {canAddRemoveRows && (
            <Button
              className="shrink-0 transition hover:bg-secondary-hover"
              size="xsIcon"
              variant="ghost"
              aria-label="Remove row"
              onClick={() => handleRemoveRow(rowIdx)}
            >
              <Icon icon={Trash} size="small" variant="secondary" />
            </Button>
          )}
        </>
      ) : (
        <>
          <Button
            className="shrink-0 transition hover:bg-secondary-hover"
            size="xsIcon"
            variant="outline"
            aria-label="Accept Edit"
            onClick={() => handleSaveRow(rowIdx)}
          >
            <Icon icon={Check} size="small" variant="default" />
          </Button>
          <Button
            className="shrink-0 transition hover:bg-secondary-hover"
            size="xsIcon"
            variant="destructive"
            aria-label="Cancel Edit"
            onClick={() => handleCancelRow(rowIdx)}
          >
            <Icon
              icon={X}
              size="small"
              variant="secondary"
              className="fill-warning"
            />
          </Button>
        </>
      )}
    </div>
  )
})
