import React, { useCallback } from 'react'
import { FileRejection } from 'react-dropzone'
import { useWindowSize } from 'react-use'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'
import { FileTypeToExtension } from 'types/file'

import { useDropzoneWithNetdocsSupport } from 'hooks/use-dropzone-with-netdocs'
import { onDrop } from 'utils/dropzone'
import { mbToBytes } from 'utils/file-utils'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import { cn } from 'utils/utils'

import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import AddMoreFiles from 'components/common/add-more-files'
import { useAuthUser } from 'components/common/auth-context'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Spinner } from 'components/ui/spinner'
import VaultUploadInset from 'components/vault/components/vault-upload-inset'
import {
  FileToUpload,
  MAX_FILE_SIZE_IN_MB,
  MAX_TOTAL_FILE_SIZE_IN_MB,
} from 'components/vault/utils/vault'
import {
  MAX_EXCEL_FILE_SIZE_IN_MB,
  ACCEPTED_FILE_TYPES,
} from 'components/vault/utils/vault'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import {
  handleDroppedFilesOrDeletedFiles,
  sumFileSizesInBytes,
} from 'components/vault/utils/vault-helpers'

import VaultCreateFileUpload from './vault-create-file-upload'
import VaultUploadedFileList from './vault-uploaded-file-list'

const ANALYTICS_NAME = 'VAULT_PROJECT'

// 99px: height of header
// 72px: height of footer
// 40px: padding-top height
// 16px: padding bottom
// 72px: height of project name
// 40px: margin between project name and disclaimer
// 33.5px: Add more files + padding
// 8px: padding around Files heading
// 66px: height of disclaimer
// 16px: margin between disclaimer and file list
const LIST_UNAVAILABLE_HEIGHT = 99 + 72 + 40 + 16 + 72 + 40 + 29.5 + 8 + 66 + 16

const CreateProject = ({
  isKnowledgeBaseProject = false,
  inModal = false,
}: {
  isKnowledgeBaseProject?: boolean
  inModal?: boolean
}) => {
  const userInfo = useAuthUser()
  const { height } = useWindowSize()
  const [
    isDropzoneLoading,
    isSubmitting,
    newFolderName,
    filesToUpload,
    totalFileSizeInBytes,
    clientMatterName,
  ] = useVaultCreateProjectStore(
    useShallow((s) => [
      s.isDropzoneLoading,
      s.isSubmitting,
      s.newFolderName,
      s.filesToUpload,
      s.totalFileSizeInBytes,
      s.clientMatterName,
    ])
  )
  const [shouldCmLockQueries] = useClientMattersStore(
    useShallow((state) => [state.shouldCmLockQueries])
  )

  const setNewFolderName = useVaultCreateProjectStore((s) => s.setNewFolderName)
  const setDroppedFiles = useVaultCreateProjectStore((s) => s.setDroppedFiles)
  const setTotalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.setTotalFileSizeInBytes
  )
  const setFilesToUpload = useVaultCreateProjectStore((s) => s.setFilesToUpload)
  const setIsDropzoneLoading = useVaultCreateProjectStore(
    (s) => s.setIsDropzoneLoading
  )
  const setClientMatterName = useVaultCreateProjectStore(
    (s) => s.setClientMatterName
  )
  const onFolderNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const folderName = e.target.value
    setNewFolderName(folderName)
  }

  const recordFileDrop = useDropzoneTrack(ANALYTICS_NAME)

  const onFileDrop = useCallback(
    async (files: File[], fileSource?: FileUploadSource) => {
      setIsDropzoneLoading(false)
      setDroppedFiles(files)
      await handleDroppedFilesOrDeletedFiles({
        latestDroppedFiles: files,
        hasFolderName: true,
        currentTotalSizeInBytes: totalFileSizeInBytes,
        setTotalFileSizeInBytes: setTotalFileSizeInBytes,
        setFilesToUpload: setFilesToUpload,
        checkForDuplicates: true,
        existingFilesToUpload: filesToUpload,
        fileSource,
      })
      recordFileDrop(files, fileSource)
    },
    [
      setIsDropzoneLoading,
      setDroppedFiles,
      totalFileSizeInBytes,
      setTotalFileSizeInBytes,
      setFilesToUpload,
      filesToUpload,
      recordFileDrop,
    ]
  )

  const onFileDelete = useCallback(
    async (file: FileToUpload) => {
      const filesToUploadAfterDeletion = filesToUpload.filter(
        (f) => f.name !== file.name
      )
      const currentTotalSizeInBytes = await sumFileSizesInBytes(
        filesToUploadAfterDeletion.map((file) => file.file)
      )
      await handleDroppedFilesOrDeletedFiles({
        latestDroppedFiles: [],
        hasFolderName: true,
        currentTotalSizeInBytes: currentTotalSizeInBytes,
        setTotalFileSizeInBytes: setTotalFileSizeInBytes,
        setFilesToUpload: setFilesToUpload,
        checkForDuplicates: false,
        existingFilesToUpload: filesToUpload.filter(
          (f) => f.name !== file.name
        ),
      })
    },
    [filesToUpload, setFilesToUpload, setTotalFileSizeInBytes]
  )

  const handleVaultFileDropBase = useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      fileSource?: FileUploadSource
    ) => {
      setIsDropzoneLoading(true)
      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: filesToUpload.length,
        maxFiles: userInfo.workspace.getVaultFilesCountLimit(
          userInfo.vaultFeature
        ),
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(MAX_FILE_SIZE_IN_MB),
        maxExcelFileSize: mbToBytes(MAX_EXCEL_FILE_SIZE_IN_MB),
        maxZipFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE_IN_MB),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE_IN_MB),
          currentTotalFileSize: await sumFileSizesInBytes(
            filesToUpload.map((file) => file.file)
          ),
        },
        shouldSkipPasswordProtectionCheck: true,
        handleAcceptedFiles: (files) => onFileDrop(files, fileSource),
        handleRejectedFiles: () => {
          setIsDropzoneLoading(false)
        },
      })
    },
    [filesToUpload, onFileDrop, setIsDropzoneLoading, userInfo]
  )

  const onUploadFromIntegration = async (
    files: File[],
    fileSource: FileUploadSource
  ) => {
    setIsDropzoneLoading(true)
    await handleVaultFileDropBase(files, [], fileSource)
    setIsDropzoneLoading(false)
  }

  const { open, getRootProps, getInputProps, isDragActive } =
    useDropzoneWithNetdocsSupport({
      maxFiles: userInfo.workspace.getVaultFilesCountLimit(
        userInfo.vaultFeature
      ),
      noClick: true,
      onDrop: async (params) =>
        await handleVaultFileDropBase(
          params.acceptedFiles,
          params.fileRejections,
          params.fileSource
        ),
    })

  const [setSharepointPickerOpenState, setIntegrationFilePickerOpenState] =
    useIntegrationsStore(
      useShallow((s) => [
        s.setSharepointPickerOpenState,
        s.setIntegrationFilePickerOpenState,
      ])
    )

  const hasUploadedFiles = filesToUpload.length > 0

  const handleIntegrationClick = async (integrationType: IntegrationType) => {
    const acceptedFileExtensions = _.flatMap(
      ACCEPTED_FILE_TYPES,
      (type) => FileTypeToExtension[type]
    )

    if (integrationType === IntegrationType.SHAREPOINT) {
      // TODO: remove this after we merge sharepoint into overall integration state
      setSharepointPickerOpenState({
        acceptedFileTypes: acceptedFileExtensions,
        onUploadFromSharepoint: (files) =>
          onUploadFromIntegration(
            files,
            IntegrationDefinitions[integrationType].fileUploadSource
          ),
      })
    } else {
      setIntegrationFilePickerOpenState({
        integrationType,
        onUploadFromIntegration: (files) =>
          onUploadFromIntegration(
            files,
            IntegrationDefinitions[integrationType].fileUploadSource
          ),
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
      })
    }
  }

  const uploadedFilesListHeight = (() => {
    if (inModal) {
      // for smaller screens, the modal is max 0.8*vh, and we subtract padding and page content
      // 180px = padding, 72px = project name input, 32px = files header
      return height > 600 / 0.8 ? 300 : height * 0.8 - 180 - 72 - 32
    }
    if (isKnowledgeBaseProject) {
      // subtract additional 60px for knowledge base description and 40px for padding
      return height - LIST_UNAVAILABLE_HEIGHT - 100
    }
    return height - LIST_UNAVAILABLE_HEIGHT
  })()

  const shouldShowClientMatterSelect =
    userInfo.IsVaultProjectClientMatterUser && !isKnowledgeBaseProject

  const isInitialUploadFinished = isDropzoneLoading || hasUploadedFiles

  return (
    <div {...getRootProps()} className="relative h-full w-full">
      <input {...getInputProps()} />
      <VaultUploadInset isDragActive={isDragActive} />
      <div
        className={cn('container pb-4 pt-10', {
          'max-w-[622px]': !inModal,
          'px-6 pt-4': inModal,
        })}
      >
        <div
          className={cn('w-full space-y-10 px-2', {
            'space-y-4': inModal,
          })}
        >
          {isKnowledgeBaseProject && (
            <p className="text-sm font-medium text-muted">
              A Knowledge Base is a repository of files that can be shared with
              the entire organization. It allows you to organize and maintain
              relevant internal documents accessible across your team.{' '}
            </p>
          )}
          <div className="space-y-2">
            <Label
              htmlFor="project-name"
              className={cn({
                'font-normal text-muted': inModal,
              })}
            >
              {isKnowledgeBaseProject ? 'Knowledge base name' : 'Project name'}
            </Label>
            <Input
              required
              placeholder={
                isKnowledgeBaseProject
                  ? 'Choose a name for your knowledge base'
                  : 'Choose a name for your project'
              }
              value={newFolderName}
              disabled={isSubmitting}
              onChange={onFolderNameChange}
              maxLength={64}
            />
          </div>
          {shouldShowClientMatterSelect && (
            <div className="space-y-2">
              <Label
                htmlFor="CM"
                className={cn({ 'font-normal text-muted': inModal })}
              >
                Client matter
              </Label>
              <ClientMatterSelect
                className={cn({
                  'text-muted': _.isEmpty(clientMatterName),
                })}
                selectedValue={clientMatterName ?? undefined}
                setSelectedValue={setClientMatterName}
                allowAddNewItem={false}
                showClearOption={!shouldCmLockQueries}
              />
            </div>
          )}
          <div>
            <div className="flex items-center justify-between gap-1 pb-2">
              <div className="flex items-center gap-1 py-1">
                <Label
                  htmlFor="files"
                  className={cn({ 'font-normal text-muted': inModal })}
                >
                  Files
                </Label>
                {!inModal && <p className="text-muted">(Optional)</p>}
              </div>
              {isInitialUploadFinished &&
                (isDropzoneLoading ? (
                  <div className="flex h-6 items-center">
                    <Spinner size="xxs" />
                    <p className="text-xs">Adding...</p>
                  </div>
                ) : (
                  <div>
                    <AddMoreFiles
                      onUploadFromComputer={open}
                      onUploadFromIntegration={handleIntegrationClick}
                    />
                  </div>
                ))}
            </div>
            <div className={cn({ 'mt-4': !inModal })}>
              {!isInitialUploadFinished ? (
                <VaultCreateFileUpload
                  acceptedFileTypes={ACCEPTED_FILE_TYPES}
                  onUploadFromIntegration={onUploadFromIntegration}
                  open={open}
                  inModal={inModal}
                />
              ) : (
                <VaultUploadedFileList
                  height={uploadedFilesListHeight}
                  filesToUpload={filesToUpload}
                  isSubmitting={isSubmitting}
                  onFileDelete={onFileDelete}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateProject
