import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { EventStatus } from 'openapi/models/EventStatus'
import { SocketMessageResponseFilesAllOfMetadata } from 'openapi/models/SocketMessageResponseFilesAllOfMetadata'
import { SocketMessageResponseFilesAllOfMetadataFiles } from 'openapi/models/SocketMessageResponseFilesAllOfMetadataFiles'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { FileType, FileTypeReadableName } from 'types/file'

import { bytesToReadable } from 'utils/file-utils'

import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import { AssistantWorkflowFilesComponent } from 'components/assistant/workflows/components/assistant-workflow-files-component'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import { useSetViewingFile } from 'components/assistant/workflows/components/workflow-file-popover'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'
import { FileToShow } from './assistant-workflow-file-upload-input'

const extractFilesToShow = (
  metadata: SocketMessageResponseFilesAllOfMetadata | undefined
) =>
  (metadata?.files || []).reduce(
    (acc: FileToShow[], file: SocketMessageResponseFilesAllOfMetadataFiles) => {
      const fileType = file.contentType as FileType | null | undefined
      let description = ''
      if (fileType) {
        description = `${FileTypeReadableName[fileType]} document • ${
          file.size ? bytesToReadable(file.size) : ''
        }`
      } else {
        description = `Document • ${
          file.size ? bytesToReadable(file.size) : ''
        }`
      }
      acc.push({ ...file, fileType: fileType || undefined, description })
      return acc
    },
    []
  )

export const AssistantWorkflowFilesThread: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.FILES
> = ({ blockParams, completionStatus, paramStatus, loadingStates }) => {
  const { metadata, response } = blockParams.answer
  const setViewingFile = useSetViewingFile()
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)

  const onFileClick = (file: UploadedFile) => {
    setViewingFile(file)
  }

  const filesToShow = extractFilesToShow(metadata)

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <LoadingState
          isCompleted={paramStatus === 'COMPLETED'}
          paramStatus={paramStatus}
          states={loadingStates}
        />
        {response && (
          <AssistantWorkflowThreadText
            completionStatus={completionStatus}
            text={response}
          />
        )}
        <div className="w-fit">
          <AssistantWorkflowFilesComponent
            files={filesToShow}
            onFileClick={onFileClick}
            fileId={fileId}
            showDownloadButton
          />
        </div>
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowFilesRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.FILES
> = ({ blockParams, paramStatus, onCancel }) => {
  const headerText = blockParams.answer.headerText
  const isStreaming = paramStatus === EventStatus.IN_PROGRESS
  const renderedText = isStreaming ? 'Working…' : headerText
  if (!headerText) {
    return null
  }

  return (
    <WorkflowInput>
      <WorkflowInputFooter
        footer={renderedText}
        onCancel={isStreaming ? onCancel : undefined}
      />
    </WorkflowInput>
  )
}

export const AssistantWorkflowFilesRendererExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowRenderComponentBlocks.FILES
> = ({ blockParams }) => {
  const { metadata, response } = blockParams.answer
  const filesToShow = extractFilesToShow(metadata)
  return (
    <>
      <div>{response}</div>
      <div className="w-fit">
        <AssistantWorkflowFilesComponent
          files={filesToShow}
          onFileClick={() => null}
          fileId={null}
        />
      </div>
    </>
  )
}
