import React, { useState } from 'react'

import { BulkManagePermBundlesForRoles } from 'models/perms'
import { Workspace } from 'models/workspace'
import { BulkRoleAssignmentOption } from 'openapi/models/BulkRoleAssignmentOption'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import WorkspaceMultiSelect from 'components/common/workspace-multi-select'
import PermBundlesMultiSelectInline from 'components/settings/workspace/permissions/perm-bundles-multi-select-inline'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Button } from 'components/ui/button'
import { Dialog } from 'components/ui/dialog'
import { Tag, TagInput } from 'components/ui/tag-input/tag-input'

const ASSIGN_TO_OPTIONS: Tag[] = [
  {
    value: BulkRoleAssignmentOption.BASE,
    badgeDisplayText: 'Base Role',
  },
  {
    value: BulkRoleAssignmentOption.ADMIN,
    badgeDisplayText: 'Admin Role',
  },
  {
    value: BulkRoleAssignmentOption.CUSTOM_ROLES,
    badgeDisplayText: 'All Custom Roles',
  },
] as const

const NON_BULK_ASSIGNABLE_BUNDLES: PermissionBundleId[] = [
  PermissionBundleId.VAULT_ACCESS, // Should only be provisioned per workspace
]

interface ManagePermBundlesForRolesProps {
  type: 'grant' | 'revoke'
}

const ManagePermBundlesForRoles: React.FC<ManagePermBundlesForRolesProps> = ({
  type,
}) => {
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<Workspace[]>([])
  const [permBundleIds, setPermBundleIds] = useState<PermissionBundleId[]>([])
  const [assignToOptions, setAssignToOptions] = useState<
    BulkRoleAssignmentOption[]
  >([])
  const [confirmingAction, setConfirmingAction] = useState<boolean>(false)

  const validSelection =
    selectedWorkspaces.length > 0 &&
    permBundleIds.length > 0 &&
    assignToOptions.length > 0

  const resetSelections = () => {
    setSelectedWorkspaces([])
    setPermBundleIds([])
    setAssignToOptions([])
  }

  const handleConfirmAction = async () => {
    try {
      await BulkManagePermBundlesForRoles({
        isRevocation: type === 'revoke',
        workspaceIds: selectedWorkspaces.map((w) => w.id),
        permBundleIds,
        roleOptions: assignToOptions,
      })
      displaySuccessMessage(
        `Successfully updated permissions for ${selectedWorkspaces.length} workspaces`
      )
      resetSelections()
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('An error occurred while processing your request')
      }
    }
  }

  return (
    <>
      <Accordion type="single" collapsible className="rounded-md border">
        <AccordionItem value="manage-perm-bundles-for-roles">
          <AccordionTrigger className="p-4">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">
                {type === 'grant'
                  ? 'Bulk grant permissions for roles'
                  : 'Bulk revoke permissions for roles'}
              </h2>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col items-start gap-4 px-5">
              <p className="text-sm text-muted">
                {type === 'grant'
                  ? 'Add permissions for any roles across many workspaces.'
                  : 'Remove permissions from any roles across many workspaces.'}
              </p>

              <div className="w-full">
                <h3 className="mb-2 text-sm font-medium">Workspace</h3>
                <WorkspaceMultiSelect
                  selected={selectedWorkspaces}
                  setSelected={setSelectedWorkspaces}
                />
              </div>

              <div className="w-full">
                <h3 className="mb-2 text-sm font-medium">Permission bundles</h3>
                <PermBundlesMultiSelectInline
                  selectedPermissions={permBundleIds}
                  setSelectedPermissions={setPermBundleIds}
                  excludedBundles={NON_BULK_ASSIGNABLE_BUNDLES}
                />
              </div>

              <div className="w-full">
                <h3 className="mb-2 text-sm font-medium">Assign to</h3>
                <TagInput
                  size="md"
                  disabled={false}
                  sortedTags={ASSIGN_TO_OPTIONS}
                  placeholder="Select roles to assign permissions to"
                  shouldShowSuggestedTags
                  allowCreatingNewTags={false}
                  selectedTagValues={assignToOptions}
                  setSelectedTagValues={(values) =>
                    setAssignToOptions(values as BulkRoleAssignmentOption[])
                  }
                />
              </div>

              <div className="mt-12 flex w-full justify-end gap-2">
                {type === 'grant' ? (
                  <Button
                    disabled={!validSelection}
                    onClick={() => setConfirmingAction(true)}
                  >
                    Add permissions
                  </Button>
                ) : (
                  <Button
                    variant="destructive"
                    disabled={!validSelection}
                    onClick={() => setConfirmingAction(true)}
                  >
                    Remove permissions
                  </Button>
                )}
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      {confirmingAction && (
        <Dialog open onOpenChange={() => setConfirmingAction(false)}>
          <ConfirmationDialog
            title={type === 'grant' ? 'Confirm grant' : 'Confirm revocation'}
            description={`Are you sure you want to ${type} ${permBundleIds.length} permission bundles for ${selectedWorkspaces.length} workspaces for the selected roles?`}
            cta={{
              label: 'Confirm',
              onClick: () => handleConfirmAction(),
            }}
            secondaryCta={{
              label: 'Cancel',
              onClick: () => setConfirmingAction(false),
            }}
          />
        </Dialog>
      )}
    </>
  )
}

export default ManagePermBundlesForRoles
