import * as React from 'react'

import { LucideIcon } from 'lucide-react'

import { cn } from 'utils/utils'

import KsInputDropdown from 'components/common/ks-input-dropdown'
import { DropdownItemProps } from 'components/common/ks-input-dropdown/dropdown-item'

import TriggerButton from './trigger-button'

interface InputSourceButtonProps {
  className?: string
  title: string
  description: string
  icon: LucideIcon
  disabled?: boolean
  tooltip?: string
  dropdownItems: (DropdownItemProps | 'separator')[]
  children?: React.ReactNode
  dataTestId?: string
  onClick?: () => void
}

const InputSourceButton: React.FC<InputSourceButtonProps> = ({
  className,
  title,
  description,
  icon,
  disabled,
  tooltip,
  dropdownItems,
  children,
  dataTestId,
  onClick,
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  return (
    <KsInputDropdown
      triggerComponent={
        <TriggerButton
          className={cn(className, dropdownOpen && 'border-ring')}
          disabled={disabled}
          dataTestId={dataTestId}
          title={title}
          description={description}
          icon={icon}
        />
      }
      dropdownOpen={dropdownOpen}
      setDropdownOpen={(open) => {
        setDropdownOpen(open)
        if (open && onClick) onClick()
      }}
      dropdownItems={dropdownItems}
      tooltip={tooltip}
      disabled={disabled}
    >
      {children}
    </KsInputDropdown>
  )
}

export default InputSourceButton
