import {
  FileRejection,
  DropzoneOptions,
  useDropzone,
  DropEvent,
  DropzoneRootProps,
} from 'react-dropzone'

import { FileUploadSource } from 'openapi/models/FileUploadSource'

import { useNetdocsExtensionSupport } from 'hooks/use-netdocs-input'

import { useAuthUser } from 'components/common/auth-context'

interface OnDropWithNetdocsParams {
  acceptedFiles: File[]
  fileRejections: FileRejection[]
  fileSource: FileUploadSource
  dropEvent?: DropEvent
}

interface UseDropzoneWithNetdocsOptions
  extends Omit<DropzoneOptions, 'onDrop'> {
  onDrop: (params: OnDropWithNetdocsParams) => Promise<void>
}

export function useDropzoneWithNetdocsSupport(
  options: UseDropzoneWithNetdocsOptions
) {
  const userInfo = useAuthUser()

  // Handle file drops with source tracking
  const handleFileDropComputer = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    dropEvent?: DropEvent
  ) => {
    await options.onDrop({
      acceptedFiles,
      fileRejections,
      dropEvent,
      fileSource: FileUploadSource.COMPUTER,
    })
  }

  const handleFileDropNetdocs = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    dropEvent?: DropEvent
  ) => {
    await options.onDrop({
      acceptedFiles,
      fileRejections,
      dropEvent,
      fileSource: FileUploadSource.NETDOCS_EXTENSION,
    })
  }

  //   Create the standard dropzone
  const dropzoneOptions: DropzoneOptions = {
    ...options,
    onDrop: (acceptedFiles, fileRejections, dropEvent) =>
      handleFileDropComputer(acceptedFiles, fileRejections, dropEvent),
  }

  const dropzone = useDropzone(dropzoneOptions)

  // Set up NetDocs integration
  const { handleFileUploadLocalOrNetdocsExtension } =
    useNetdocsExtensionSupport({
      onUploadFromNetdocs: async (files) =>
        await handleFileDropNetdocs(files, []),
      onUploadFromComputer: async (files) =>
        await handleFileDropComputer(files, []),
    })

  // Create an enhanced open function that handles NetDocs
  const openWithNetdocsSupport = () => {
    if (userInfo.hasNetdocsExperiment) {
      handleFileUploadLocalOrNetdocsExtension()
    } else {
      dropzone.open()
    }
  }

  // Create a custom getRootProps that handles NetDocs integration automatically
  const getRootPropsWithNetdocsSupport = (props?: DropzoneRootProps) => {
    const rootProps = dropzone.getRootProps(props)

    return {
      ...rootProps,
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (userInfo.hasNetdocsExperiment) {
          // only stop propagation if the user has netdocs permission
          event.stopPropagation()
          if (!options.noClick && !(options.disabled === true)) {
            handleFileUploadLocalOrNetdocsExtension()
          }
        } else {
          rootProps.onClick?.(event)
        }
      },
      onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
          if (userInfo.hasNetdocsExperiment) {
            event.stopPropagation()
            if (!options.noClick && !(options.disabled === true)) {
              handleFileUploadLocalOrNetdocsExtension()
            }
          } else {
            rootProps.onKeyDown?.(event)
          }
        }
      },
    }
  }

  return {
    ...dropzone,
    open: openWithNetdocsSupport,
    getRootProps: getRootPropsWithNetdocsSupport,
  }
}
