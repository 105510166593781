import React from 'react'

import { Building2, ChevronsUpDown, Lock, Users } from 'lucide-react'

import { ShareType } from 'types/sharing'

import { displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'

import { SharePopoverProps } from './share-popover'

type WorkspaceSharingDropdownVariant = 'compact' | 'default'

type WorkspaceSharingOptionsProps = Pick<
  SharePopoverProps,
  | 'shareType'
  | 'isReadOnly'
  | 'onChangeSharedWithWorkspace'
  | 'isSharingWithEveryoneAtWorkspaceEnabled'
> & {
  isSharedWithWorkspace: boolean
  workspaceSharingDropdownVariant?: WorkspaceSharingDropdownVariant
  showCopyLinkButton?: boolean
}
export const WorkspaceSharingOptions = ({
  shareType,
  isReadOnly,
  isSharedWithWorkspace,
  workspaceSharingDropdownVariant = 'default',
  onChangeSharedWithWorkspace,
  isSharingWithEveryoneAtWorkspaceEnabled,
  showCopyLinkButton = true,
}: WorkspaceSharingOptionsProps) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const copyLinkToClipboard = async () => {
    trackEvent(
      shareType === ShareType.VAULT_PROJECT
        ? 'Vault project share link copied'
        : 'Assistant thread share link copied'
    )
    try {
      await navigator.clipboard.writeText(window.location.href)
      displaySuccessMessage('Link copied to clipboard')
    } catch (err) {
      console.error('Failed to copy link: ', err)
    }
  }

  const workspaceClientName = userInfo.workspace.clientName

  const icon = isSharedWithWorkspace
    ? isSharingWithEveryoneAtWorkspaceEnabled
      ? Building2
      : Users
    : Lock

  const displayText = isSharedWithWorkspace
    ? isSharingWithEveryoneAtWorkspaceEnabled
      ? `Everyone at ${workspaceClientName}`
      : `Anyone at ${workspaceClientName} with the link`
    : 'Restricted'

  const workspaceShareOptionDisplayText =
    isSharingWithEveryoneAtWorkspaceEnabled
      ? `Everyone at ${workspaceClientName}`
      : `Anyone at ${workspaceClientName} with the link`

  const vaultWorkspaceShareOptionDescription =
    isSharingWithEveryoneAtWorkspaceEnabled
      ? `This project can be accessed by any user at ${workspaceClientName}`
      : `This project can be accessed by any Harvey user at ${workspaceClientName} who has the link`

  return (
    <div
      className={cn('flex w-full justify-between gap-2', {
        'w-fit': workspaceSharingDropdownVariant === 'compact',
      })}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={isReadOnly}>
          <Button
            variant="outline"
            className={cn(
              'flex h-8 w-full items-center justify-between gap-2',
              {
                'h-auto min-h-[2rem] py-1':
                  isSharedWithWorkspace && workspaceClientName.length > 8,
                'w-fit': workspaceSharingDropdownVariant === 'compact',
              }
            )}
            disabled={isReadOnly}
          >
            <div>
              <div className="flex items-center gap-2">
                <Icon icon={icon} />
                <span className="truncate text-left text-sm">
                  {displayText}
                </span>
              </div>
            </div>
            <Icon icon={ChevronsUpDown} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-80 p-2"
          align={
            workspaceSharingDropdownVariant === 'compact' ? 'end' : 'start'
          }
        >
          <DropdownMenuItem
            onClick={() => {
              onChangeSharedWithWorkspace(false)
              trackEvent(
                shareType === ShareType.VAULT_PROJECT
                  ? 'Vault project unshared with workspace'
                  : 'Assistant thread unshared with workspace'
              )
            }}
            className="flex items-center gap-2"
          >
            <Icon icon={Lock} />
            <div className="flex flex-col">
              <p className="text-sm">Restricted</p>
              <p className="text-xs text-muted">
                {shareType === ShareType.VAULT_PROJECT
                  ? `This project is only accessible to you, and to people you've granted access`
                  : 'This page is only accessible to you'}
              </p>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              onChangeSharedWithWorkspace(true)
              trackEvent(
                shareType === ShareType.VAULT_PROJECT
                  ? 'Vault project shared with workspace'
                  : 'Assistant thread shared with workspace'
              )
            }}
            className="flex items-center gap-2"
            data-testid="share-with-workspace"
          >
            <Icon
              icon={isSharingWithEveryoneAtWorkspaceEnabled ? Building2 : Users}
            />
            <div className="flex flex-col">
              <p className="text-sm">{workspaceShareOptionDisplayText}</p>
              <p className="text-xs text-muted">
                {shareType === ShareType.VAULT_PROJECT
                  ? vaultWorkspaceShareOptionDescription
                  : `This page can be viewed by any Harvey user at ${workspaceClientName} who has the link`}
              </p>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {showCopyLinkButton && (
        <Button className="flex min-w-20" onClick={copyLinkToClipboard}>
          <span className="whitespace-nowrap text-sm">Copy link</span>
        </Button>
      )}
    </div>
  )
}
