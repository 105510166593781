import React from 'react'

import { useAuthUser } from 'components/common/auth-context'
import PWCUserAdd from 'components/settings/pwc/pwc-user-add'
import PWCUserRemove from 'components/settings/pwc/pwc-user-remove'
import RoleInspector from 'components/settings/roles/role-inspector'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import ManagePermBundlesForRoles from 'components/settings/user-management/manage-perm-bundles-for-roles'
import ManagePermConfigsForWorkspaces from 'components/settings/user-management/manage-perm-configs-for-workspaces'

import MigrateWorkspaceToPermBundlesComponent from './migrate-workspace-to-perm-bundles'
import MultiUserAddRemove from './multi-user-add-remove'
import SettingsBulkManagePerms from './settings-bulk-manage-permissions'
import SettingsBulkMoveUsers from './settings-bulk-move-users'
import SettingsUserPermissionsCard from './settings-user-pemissions-card'

const SettingsUserManagement: React.FC = () => {
  const userInfo = useAuthUser()
  const canViewEntirePage =
    userInfo.IsInternalAdminReader || userInfo.IsInternalAdminWriter

  const renderPageContent = (showEntirePage: boolean) => {
    if (showEntirePage) {
      return (
        <>
          <div>
            <SettingsUserPermissionsCard />
          </div>
          <div className="mt-4">
            <RoleInspector />
          </div>
          {userInfo.isUserManagement && (
            <div className="space-y-4">
              <div className="my-4">
                <SettingsBulkManagePerms type="grant" />
              </div>
              <div className="my-4">
                <SettingsBulkManagePerms type="revoke" />
              </div>
              {userInfo.IsInternalAdminWriter && (
                <>
                  <div>
                    <MultiUserAddRemove
                      type="remove"
                      collapsible
                      accordionTitle="Bulk remove users"
                      displayDownloadButton
                      maxNumUsers={500}
                    />
                  </div>
                  <div>
                    <SettingsBulkMoveUsers />
                  </div>
                  <div>
                    <PWCUserAdd />
                  </div>
                  <div>
                    <PWCUserRemove />
                  </div>
                  <MigrateWorkspaceToPermBundlesComponent />
                  <ManagePermBundlesForRoles type="grant" />
                  <ManagePermBundlesForRoles type="revoke" />
                  <ManagePermConfigsForWorkspaces type="enable" />
                  <ManagePermConfigsForWorkspaces type="disable" />
                </>
              )}
            </div>
          )}
        </>
      )
    } else {
      return (
        <>
          <div>
            <SettingsUserPermissionsCard />
          </div>
          <div className="mt-4">
            <RoleInspector />
          </div>
        </>
      )
    }
  }

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>{renderPageContent(canViewEntirePage)}</SettingsLayout>
    </>
  )
}

export default SettingsUserManagement
