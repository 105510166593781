import React, { useEffect, memo, useRef, useCallback } from 'react'
import { useMount, useUnmount, useWindowSize } from 'react-use'

import { useAuth0 } from '@auth0/auth0-react'
import { datadogRum } from '@datadog/browser-rum'
import _ from 'lodash'
import { useAbortController } from 'providers/abort-controller-provider'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { useGeneralStore } from 'stores/general-store'
import { HistoryItem } from 'types/history'

import { TaskStatus } from 'utils/task'
import useParamsWrapper from 'utils/use-params-wrapper'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import { ErrorPageTitle } from 'components/common/error/error'
import FullscreenLoading from 'components/common/fullscreen-loading'
import { VaultAddFilesDialog } from 'components/vault/dialogs/vault-add-files-dialog'
import { useFetchEventFeedbackSentiments } from 'components/vault/hooks/use-feedback'
import { useVaultHistoryItemQuery } from 'components/vault/hooks/use-vault-history-item'
import {
  UNTITLED_QUERY_TITLE,
  projectsPath,
  ReviewEvent,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { FetchVaultReviewQuery } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { updateAddColumnDef } from './data-grid-helpers'
import VaultDataGridHeader from './data-grid/header/vault-data-grid-header'
import { VaultColumnBuilderDialog } from './data-grid/vault-column-builder-dialog'
import VaultDataGridWrapper from './data-grid/vault-data-grid'
import { ReviewRunToastWrapper } from './review-run/review-run-toast'
import VaultPushSheet from './sheets/vault-push-sheet'
import VaultDataGridToolbelt from './toolbelt/vault-data-grid-toolbelt'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from './vault-query-detail-store'

export const CELL_LOADING_TEXT = 'Processing…'
export const LEFT_PINNED_COLUMNS = ['row', 'name']
export const EXCLUDED_HEADER_NAMES_FROM_EXPORT = ['folderPath', 'addColumn']
export const EXCLUDED_COLIDS_FROM_FITLER = ['folderPath']
export const EXCLUDED_COLIDS_FROM_RESIZE_FILTER = ['row', 'addColumn']
export const EXCLUDED_COLIDS_FROM_COLUMN_CLICK = ['row', 'name', 'addColumn']
export const EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN = [
  'row',
  'name',
  'addColumn',
  'folderPath',
]

const VaultQueryDetailLoading = ({ isLoading }: { isLoading: boolean }) => {
  const [gridApi] = useVaultQueryDetailStore(
    useShallow((state) => [state.gridApi])
  )

  const isLoadingGrid = !gridApi

  return (
    <FullscreenLoading zIndex="z-50" isLoading={isLoadingGrid || isLoading} />
  )
}

const VaultQueryDetailResizeObserver = () => {
  const { width } = useWindowSize()
  const [gridApi] = useVaultQueryDetailStore(
    useShallow((state) => [state.gridApi])
  )
  useEffect(() => {
    if (gridApi) {
      const columnDefs = gridApi.getColumnDefs() ?? []
      const haveToPinAddColumn = updateAddColumnDef(columnDefs)
      gridApi.setGridOption('columnDefs', columnDefs)
      if (haveToPinAddColumn) {
        gridApi.dispatchEvent({ type: 'bodyScroll' })
      }
    }
  }, [width, gridApi])
  return null
}

const VaultDataGridToolbarWrapper = () => {
  const isSidebarOpen = useGeneralStore(
    useShallow((state) => state.isSidebarOpen)
  )

  return (
    <VaultDataGridToolbelt
      className={cn('fixed ml-24 max-w-[calc(100%-192px)]', {
        'ml-8 max-w-[calc(100%-64px)]': !isSidebarOpen,
      })}
    />
  )
}

const VaultQueryResultsTable = memo(() => {
  const [setCurrentSortColumnId, setDisplayedRows] =
    useVaultDataGridFilterStore(
      useShallow((state) => [
        state.setCurrentSortColumnId,
        state.setDisplayedRows,
      ])
    )

  useMount(() => {
    document.body.classList.add('disable-swipe-navigation')
  })

  useUnmount(() => {
    setCurrentSortColumnId('name')
    setDisplayedRows([])
    document.body.classList.remove('disable-swipe-navigation')
  })

  return (
    <>
      <VaultAddFilesDialog />
      <VaultColumnBuilderDialog />
      <VaultQueryDetailResizeObserver />
      <div className="flex h-full w-full flex-col">
        <ReviewRunToastWrapper />
        <div className="flex h-full w-full">
          <div className="flex h-full w-full flex-col">
            <VaultDataGridHeader />
            <VaultDataGridWrapper />
            <VaultDataGridToolbarWrapper />
          </div>
          <VaultPushSheet />
        </div>
      </div>
    </>
  )
})

interface UseVaultReviewQueryLoadTrackingProps {
  isLoading: boolean
  historyItem: ReviewEvent | HistoryItem | null | undefined
}

const useVaultReviewQueryLoadTracking = ({
  isLoading,
  historyItem,
}: UseVaultReviewQueryLoadTrackingProps) => {
  const hasRecordedVaultReviewQueryFullLoad = useRef(false)

  useEffect(() => {
    if (
      !hasRecordedVaultReviewQueryFullLoad.current &&
      !isLoading &&
      _.get(historyItem, 'eventStatus') !== TaskStatus.IN_PROGRESS
    ) {
      datadogRum.stopDurationVital('vaultReviewQueryFullLoad', {
        context: {
          reviewQueryFileCount: _.get(historyItem, 'numFiles', 0),
          reviewQueryQuestionCount: _.get(historyItem, 'numQuestions', 0),
        },
      })
      hasRecordedVaultReviewQueryFullLoad.current = true
    }
  }, [isLoading, historyItem])
}

const VaultQueryDetailWrapper = () => {
  const [projectId, queryId] = useParamsWrapper(['projectId', 'queryId'])
  const { getAccessTokenSilently } = useAuth0()
  const { addAbortController, getAbortController, removeAbortController } =
    useAbortController()
  const userInfo = useAuthUser()

  const initialFetchRef = useRef(false)

  const [setError] = useVaultStore(useShallow((state) => [state.setError]))
  const [setIsSidebarOpenAndToggle, revertSidebarOpen] = useGeneralStore(
    useShallow((state) => [
      state.setIsSidebarOpenAndToggle,
      state.revertSidebarOpen,
    ])
  )

  const [
    isQueryLoading,
    isFetchingQuery,
    shouldPollForHistoryItem,
    updateQueryTitle,
    setHistoryItemFromSSE,
    setHistoryItem,
    setCellViewerData,
    setShouldPollForHistoryItem,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.isQueryLoading,
      state.isFetchingQuery,
      state.shouldPollForHistoryItem,
      state.updateQueryTitle,
      state.setHistoryItemFromSSE,
      state.setHistoryItem,
      state.setCellViewerData,
      state.setShouldPollForHistoryItem,
    ])
  )

  const isNewQuery = queryId === 'new'
  const isLoading = !isNewQuery && isFetchingQuery

  const setVaultReviewHistoryItemError = useCallback(
    (notReady?: boolean) => {
      let message = `The requested Vault Review query ${queryId} you are trying to access does not exist.\nContact support@harvey.ai if this issue persists.`
      if (notReady) {
        message = `The requested Vault Review query ${queryId} you are trying to access is not ready yet. Please check back in a few minutes.\nContact support@harvey.ai if this issue persists.`
      }
      setError({
        message,
        title: notReady
          ? ErrorPageTitle.NOT_READY_YET
          : ErrorPageTitle.PAGE_NOT_FOUND,
        cta: {
          message: 'Back to Vault project',
          redirectUri: `${BaseAppPath.Vault}${projectsPath}${projectId}`,
        },
      })
    },
    [queryId, projectId, setError]
  )

  const { historyItem: reactQueryResult, error: historyItemError } =
    useVaultHistoryItemQuery({
      id: queryId,
      vaultFolderId: projectId!,
      isEnabled: !isNewQuery && shouldPollForHistoryItem,
      throwOnError: true,
      refetchInterval: () => {
        return isQueryLoading ? 2_500 : false
      },
    })

  useEffect(() => {
    // let's only update the history item if it's not null
    // it usually is null when we hit the run button and react query does not fetch anything for new queries
    if (!reactQueryResult) return
    const isReviewEvent = reactQueryResult.eventKind === EventKind.VAULT_REVIEW
    if (isReviewEvent) {
      const reviewHistoryEvent = reactQueryResult as ReviewHistoryItem
      setHistoryItem(reviewHistoryEvent)
    }
  }, [reactQueryResult, setHistoryItem])

  useEffect(() => {
    if (historyItemError) setVaultReviewHistoryItemError()
  }, [historyItemError, queryId, projectId, setVaultReviewHistoryItemError])

  useMount(() => {
    // on mount we want to set the sidebar to false
    setIsSidebarOpenAndToggle(false)
    setCellViewerData(null)
    if (isNewQuery) {
      updateQueryTitle(UNTITLED_QUERY_TITLE)
    }

    // if the query is a new query (isNewQuery = true) then we don't want to make the SSE connection
    // isFetchingQuery will be set to false when we come to this component after submitting the new query
    // when this happens we don't want to make the SSE connection because we know it's not completed and have to poll for it (let react-query handle polling)
    // if the user navigates to this component directly, we want to make the SSE connection to get the history item and set it for the first time
    // if the query is pending or in progress, we can terminate the SSE connection and let react-query handle polling
    const isFetchingQueryCheck = queryId && isFetchingQuery
    if (!isFetchingQueryCheck || isNewQuery || initialFetchRef.current) return

    initialFetchRef.current = true
    // we need to make the SSE connection here to get the history item and set it for the first time
    // by calling setHistoryItem, we will;
    // 1. first let's check if we already have a reactQueryResult (this could be true if the user navigates forward & back)
    // if they do, then we don't have to fetch the history item again
    // 2. if they don't, then we call setHistoryItem or a version of it to mark isFetchingQuery to false & set the initialHistoryItem so that the grid is displayed
    // 3. once we receive the completed history item, shouldPollForHistoryItem will be set to true - which will enable the react-query polling
    const hasReviewQueryResult = !!reactQueryResult
    if (hasReviewQueryResult) {
      setShouldPollForHistoryItem(true)
      return
    }
    const abortController = new AbortController()
    addAbortController(queryId, abortController)
    FetchVaultReviewQuery({
      queryId,
      abortController,
      getAccessTokenSilently,
      callback: setHistoryItemFromSSE,
      errorCallback: setVaultReviewHistoryItemError,
      getAbortController,
      removeAbortController,
    })
  })

  useVaultReviewQueryLoadTracking({
    isLoading,
    historyItem: reactQueryResult,
  })

  useUnmount(() => {
    revertSidebarOpen()
  })

  // Fetch the feedback sentiments for the query
  useFetchEventFeedbackSentiments(
    !isNewQuery ? Number(queryId) : null,
    userInfo
  )

  if (historyItemError) {
    return null
  }

  return (
    <AppMain className="flex w-full">
      <VaultQueryDetailLoading isLoading={isLoading} />
      {!isLoading && <VaultQueryResultsTable />}
    </AppMain>
  )
}

VaultQueryResultsTable.displayName = 'VaultQueryResultsTable'
export default VaultQueryDetailWrapper
