import React, { useState, useMemo } from 'react'
import { useInterval } from 'react-use'

import { formatDistanceToNow } from 'date-fns'
import { Pencil } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { Maybe } from 'types'
import { TaskType } from 'types/task'

import { TodayOption, readableFormat } from 'utils/date-utils'

import { Button } from 'components/ui/button'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import { EXAMPLES_USER_EMAIL } from 'components/vault/utils/vault'
import {
  DOT_SEPARATOR,
  LOADING_QUERY_TITLE,
} from 'components/vault/utils/vault'
import {
  getEtaDisplayString,
  getFileCountHeaderString,
} from 'components/vault/utils/vault-helpers'
import { isProjectShared } from 'components/vault/utils/vault-sharing-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const QueryBreadcrumbTitle = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [currentProject, setIsEditQueryTitleDialogOpen] = useVaultStore(
    useShallow((s) => [s.currentProject, s.setIsEditQueryTitleDialogOpen])
  )
  const [queryTitle, queryId] = useVaultQueryDetailStore(
    useShallow((s) => [s.queryTitle, s.queryId])
  )
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const isNewQuery = queryId === 'new'
  const isQueryTitleLoading = queryTitle === LOADING_QUERY_TITLE
  const showEditTitleButton =
    doesCurrentUserHaveEditPermission && !isQueryTitleLoading && !isNewQuery

  return (
    <div className="flex items-center gap-1">
      <h1 className="line-clamp-2 whitespace-pre-wrap text-xl font-medium">
        {queryTitle}
      </h1>
      {showEditTitleButton && (
        <Button
          variant="ghost"
          size="xsIcon"
          onClick={() =>
            setIsEditQueryTitleDialogOpen(true, TaskType.VAULT_REVIEW)
          }
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <Pencil
            size={12}
            stroke={isHovered ? 'currentColor' : 'hsl(var(--neutral-500))'}
          />
        </Button>
      )}
    </div>
  )
}

const QueryDetailBreadcrumb = ({
  numFiles,
  numColumns,
  shouldPollForHistoryItem,
  processedFileIds,
  isLoading,
  completedAt,
  pausedAt,
  failedAt,
  eta,
  startedAt,
  projectId,
  creatorUserEmail,
}: {
  numFiles: number
  numColumns: number
  shouldPollForHistoryItem: boolean
  processedFileIds?: string[]
  isLoading: boolean
  completedAt: Maybe<Date>
  pausedAt: Maybe<Date>
  failedAt: Maybe<Date>
  eta: Maybe<Date>
  startedAt: Maybe<Date>
  projectId: Maybe<string>
  creatorUserEmail?: string
}) => {
  const isStreamingHistoryItem = !shouldPollForHistoryItem
  const sharedProjectIds = useVaultStore(useShallow((s) => s.sharedProjectIds))
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((s) => s.permissionsByProjectId)
  )
  const isSharedProject = isProjectShared(
    sharedProjectIds,
    permissionsByProjectId,
    projectId ?? undefined
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minuteCounter, setMinuteCounter] = useState(0)
  useInterval(
    () => {
      setMinuteCounter((prev) => prev + 1)
    },
    isLoading ? 60000 : null
  ) // Every 1 minute update the component to refresh the relative time

  const prefix = getFileCountHeaderString({
    numFiles,
    processedFileIds,
    shouldPollForHistoryItem,
  })
  const numColumnsText =
    numColumns > 0 ? pluralize('column', numColumns, true) : ''
  const owner =
    creatorUserEmail === EXAMPLES_USER_EMAIL ? 'Harvey' : creatorUserEmail
  const createdByText =
    creatorUserEmail && isSharedProject ? `Created by ${owner}` : ''
  const loadingText = useMemo(() => {
    if (isStreamingHistoryItem) return ''
    return eta
      ? getEtaDisplayString(eta, true)
      : startedAt
      ? `Processing started ${formatDistanceToNow(startedAt, {
          addSuffix: true,
        })}`
      : ''
  }, [isStreamingHistoryItem, eta, startedAt])

  // when we are streaming the history item isLoading will be true
  // but we don't want to show the loading text (since it will be empty - see above)
  const suffix =
    isLoading && !isStreamingHistoryItem
      ? loadingText
      : pausedAt
      ? `Paused ${readableFormat(pausedAt, TodayOption.showTime, 'short')}`
      : failedAt
      ? `Failed ${readableFormat(failedAt, TodayOption.showTime, 'short')}`
      : completedAt
      ? `Answered ${readableFormat(completedAt, TodayOption.showTime, 'short')}`
      : ''

  return (
    <div className="mr-4">
      <QueryBreadcrumbTitle />
      <div className="flex items-center gap-1">
        <p className="min-h-4 truncate text-xs text-muted">
          {[prefix, numColumnsText, createdByText, suffix]
            .filter(Boolean)
            .join(DOT_SEPARATOR)}
        </p>
      </div>
    </div>
  )
}

export default QueryDetailBreadcrumb
