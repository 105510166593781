import { SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadata } from 'openapi/models/SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadata'
import { SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataCountryCodeToAnalysis } from 'openapi/models/SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataCountryCodeToAnalysis'

import { modelMarkdownToHtmlRemark } from 'utils/markdown'
import { Source } from 'utils/task'

export const computeStatus = (
  analysis: SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataCountryCodeToAnalysis
) => {
  if (!analysis.thresholds.length) {
    return 'No merger control regime'
  }

  const metThresholds = analysis.thresholds.filter(
    (threshold) => threshold.overallConclusion === 'likely to be met'
  ).length

  const unmetThresholds = analysis.thresholds.filter(
    (threshold) => threshold.overallConclusion === 'not likely to be met'
  ).length

  if (metThresholds > 0) {
    return `${metThresholds} Threshold${metThresholds > 1 ? 's' : ''} met`
  }

  if (unmetThresholds === analysis.thresholds.length) {
    return 'Thresholds not met'
  }

  return 'More information required'
}

export interface SummaryProps {
  className?: string
  data: SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadata
  handleSourceClick: (source: Source) => void
}

export const getCountryCodesSortedByCountryName = (
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return Object.entries(data)
    .sort((a, b) => a[1].countryName.localeCompare(b[1].countryName))
    .map(([countryCode]) => countryCode)
}

export const getCountriesWithAllThresholdsMet = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    const status = computeStatus(data[countryCode])
    return status.includes('met') && !status.includes('not met')
  })
}

export const getCountriesWithAllThresholdsNotMet = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    const status = computeStatus(data[countryCode])
    return status.includes('not met')
  })
}

export const getCountriesWithMoreInfoRequired = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    return computeStatus(data[countryCode]) === 'More information required'
  })
}

/**
 * Calculate the combined domestic turnover for a country
 * @param partyRevenues Array of party revenues for a country
 * @returns The combined domestic turnover
 */
export const calculateCombinedDomesticTurnover = (
  partyRevenues: SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataCountryCodeToAnalysis['partyRevenues']
): number => {
  return partyRevenues.reduce((sum, party) => sum + party.revenue, 0)
}

/**
 * Generate HTML content for antitrust analysis report that matches the UI format
 * @param data The antitrust filings analysis metadata
 * @returns HTML string for the report
 */
export const generateAntitrustAnalysisHtml = (
  data: SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadata
): string => {
  const { countryCodeToAnalysis } = data
  const sortedCountryCodes = getCountryCodesSortedByCountryName(
    countryCodeToAnalysis
  )

  const thresholdsMet = getCountriesWithAllThresholdsMet(
    sortedCountryCodes,
    countryCodeToAnalysis
  )
  const thresholdsNotMet = getCountriesWithAllThresholdsNotMet(
    sortedCountryCodes,
    countryCodeToAnalysis
  )
  const moreInfoRequired = getCountriesWithMoreInfoRequired(
    sortedCountryCodes,
    countryCodeToAnalysis
  )

  // Add basic styles for the document
  let content = `
      <style>
        table {
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 20px;
        }
        th, td {
          border: 1px solid #000;
          padding: 6px;
          vertical-align: top;
          font-size: 10pt;
        }
        th {
          background-color: #f2f2f2;
          font-weight: bold;
        }
        h1, h2, h3 {
          page-break-after: avoid;
        }
        ol {
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 20px;
        }
        ul {
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 20px;
        }
        p {
          margin-top: 3px;
          margin-bottom: 3px;
        }
      </style>
    `

  content += '<h1>Antitrust Filing Analysis</h1>'

  // Add summary section
  content += '<h2>Executive Summary</h2>'

  if (thresholdsMet.length > 0) {
    content += '<h3>Jurisdictions Where Thresholds Are Likely Met</h3>'
    content +=
      '<p>Based on the information provided, filing requirements appear to be triggered in the following jurisdictions:</p>'
    content += '<ul>'
    thresholdsMet.forEach((countryCode) => {
      content += `<li><strong>${countryCodeToAnalysis[countryCode].countryName}</strong></li>`
    })
    content += '</ul>'
  }

  if (thresholdsNotMet.length > 0) {
    content += '<h3>Jurisdictions Where Thresholds Are Likely Not Met</h3>'
    content +=
      '<p>Based on the information provided, filing requirements appear not to be triggered in the following jurisdictions:</p>'
    content += '<ul>'
    thresholdsNotMet.forEach((countryCode) => {
      content += `<li><strong>${countryCodeToAnalysis[countryCode].countryName}</strong></li>`
    })
    content += '</ul>'
  }

  if (moreInfoRequired.length > 0) {
    content += '<h3>Jurisdictions Requiring Additional Information</h3>'
    content +=
      '<p>The following jurisdictions require additional information to determine filing requirements:</p>'
    content += '<ul>'
    moreInfoRequired.forEach((countryCode) => {
      content += `<li><strong>${countryCodeToAnalysis[countryCode].countryName}</strong></li>`
    })
    content += '</ul>'
  }

  // Add detailed analysis section
  content += '<h2>Detailed Jurisdictional Analysis</h2>'

  // Create the export table header
  content += `
      <h3>Export Table</h3>
      <table>
        <tr>
          <th>Country</th>
          <th>Revenues</th>
          <th>Thresholds Analysis</th>
          <th>Conclusion</th>
          <th>Additional Information</th>
        </tr>
    `

  // Process each country
  sortedCountryCodes.forEach((countryCode) => {
    const country = countryCodeToAnalysis[countryCode]
    const status = computeStatus(country)

    // Start row for this country
    content += '<tr>'

    // COLUMN 1: Country with status in parentheses
    content += '<td>'
    content += `<strong>${country.countryName}</strong>`
    content += ` (${status})` // Simply add status in parentheses
    content += '</td>'

    // COLUMN 2: Revenues
    content += '<td>'
    content += '<strong>Domestic Turnovers</strong><br/>'

    if (country.partyRevenues.length > 0) {
      // Find acquirer and target domestic revenues
      const acquirerDomestic = country.partyRevenues.find(
        (p) => p.partyType === 'acquirer'
      )
      const targetDomestic = country.partyRevenues.find(
        (p) => p.partyType === 'target'
      )

      // Format with thousands separators
      const acquirerValue = acquirerDomestic
        ? acquirerDomestic.revenue.toLocaleString()
        : '0'
      const targetValue = targetDomestic
        ? targetDomestic.revenue.toLocaleString()
        : '0'

      content += `Acquirer: $${acquirerValue}<br/>`
      content += `Target: $${targetValue}<br/>`

      // Calculate CDTO more simply
      const cdto = calculateCombinedDomesticTurnover(country.partyRevenues)
      content += `CDTO: $${cdto.toLocaleString()}<br/><br/>`

      // Add worldwide revenues
      content += '<strong>Worldwide Turnovers</strong><br/>'

      if (data.worldwidePartyRevenues.length > 0) {
        const acquirerWorldwide = data.worldwidePartyRevenues.find(
          (p) => p.partyType === 'acquirer'
        )
        const targetWorldwide = data.worldwidePartyRevenues.find(
          (p) => p.partyType === 'target'
        )

        // Format with thousands separators
        const acquirerWWValue = acquirerWorldwide
          ? acquirerWorldwide.revenue.toLocaleString()
          : '0'
        const targetWWValue = targetWorldwide
          ? targetWorldwide.revenue.toLocaleString()
          : '0'

        content += `Acquirer: $${acquirerWWValue}<br/>`
        content += `Target: $${targetWWValue}<br/>`

        // Calculate CWTO directly from worldwide revenues
        const cwto = data.worldwidePartyRevenues.reduce(
          (sum, party) => sum + party.revenue,
          0
        )
        content += `CWTO: $${cwto.toLocaleString()}`
      } else {
        content += 'No worldwide turnover data available'
      }
    } else {
      content += 'No turnover data available'
    }
    content += '</td>'

    // COLUMN 3: Thresholds Analysis
    content += '<td>'
    if (country.thresholds.length > 0) {
      // Process each threshold with better spacing and structure
      country.thresholds.forEach((threshold, thresholdIndex) => {
        if (thresholdIndex > 0) content += '<br/>' // Add divider between thresholds

        content += `<strong>${threshold.name}</strong><br/>`

        if (threshold.requirements.length > 0) {
          // Use divs with manual numbering instead of ordered list
          content += `<div class=“threshold-requirements” style=“margin-top: 5px; margin-bottom: 5px;”>`

          threshold.requirements.forEach((req, reqIndex) => {
            const reqText = req.requirement || `Requirement ${reqIndex + 1}`

            // Add the requirement with manual numbering
            content += `<div style="margin-bottom: 4px;">${
              reqIndex + 1
            }. ${reqText}</div>`
            // Add AND between requirements except after the last one
            if (reqIndex < threshold.requirements.length - 1) {
              content += `<br/><div style=“margin-left: 20px; margin-bottom: 4px; font-weight: bold;”>AND</div>`
            }
          })
          content += '</div>'
        } else {
          content += 'No detailed requirements available'
        }
      })
    } else if (computeStatus(country) === 'No merger control regime') {
      // Using computeStatus instead of a non-existent property
      content += '<p>No merger control regime</p>'
    } else {
      content += 'No threshold analysis available'
    }
    content += '</td>'

    // COLUMN 4: Conclusion
    content += '<td>'
    if (country.thresholds.length > 0) {
      // Process each threshold
      country.thresholds.forEach((threshold, thresholdIndex) => {
        if (thresholdIndex > 0) content += '<br/>' // Add divider between thresholds

        // Display the threshold conclusion as is, wrapped in brackets
        content += `<strong>[${threshold.overallConclusion}]</strong> ${threshold.name}<br/>`

        // Add conclusions for each requirement
        if (threshold.requirements.length > 0) {
          // Don't use an ordered list - use divs with manual numbering
          content += `<div class=“requirement-conclusions”>`

          threshold.requirements.forEach((req, reqIndex) => {
            // Display the requirement conclusion as is, wrapped in brackets
            content += `<div style="margin-bottom: 4px;">${reqIndex + 1}. [${
              req.requirementConclusion
            }] ${
              req.requirementAnalysis || 'Requirement ${reqIndex + 1}'
            }</div><br/>`
          })

          content += '</div>'
        }
      })
    } else {
      content += 'No conclusions available'
    }
    content += '</td>'

    // COLUMN 5: Additional Information
    content += '<td>'

    // Use the additionalInsights field for country-specific information
    if (country.additionalInsights) {
      // Parse or use the additionalInsights field as needed
      const asHtml = modelMarkdownToHtmlRemark(country.additionalInsights)

      content += asHtml
    } else {
      // Standard filing information if no specific insights available
      content +=
        '<p>No additional information available for this jurisdiction.</p>'
    }

    content += '</td>'
  })

  // Close the table
  content += '</table>'

  return content
}
