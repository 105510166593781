import React from 'react'

import { Workspace } from 'models/workspace'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

import MultiUserAddRemove from './multi-user-add-remove'

const MAX_NUM_USERS = 100

interface AddUsersDialogProps {
  isOpen: boolean
  onClose: () => void
  workspace?: Workspace
  onAdd?: () => Promise<void>
  customerFacing?: boolean
}

const AddUsersDialog: React.FC<AddUsersDialogProps> = ({
  isOpen,
  onClose,
  workspace,
  onAdd,
  customerFacing = false,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Add users</DialogTitle>
        </DialogHeader>
        <MultiUserAddRemove
          type="add"
          workspace={workspace}
          helpMessage={
            customerFacing ? (
              <div className="text-sm">
                Add users to Harvey by entering their email addresses, with one
                email per line (maximum {MAX_NUM_USERS}). Users will not be
                notified, but will have access to Harvey next time they attempt
                to log in.
              </div>
            ) : (
              <div className="text-sm">
                Add new user emails to create them in Harvey and Auth0. Get a
                csv of new users.
                <br /> Users will be added to <b>
                  {workspace?.clientName}
                </b>{' '}
                workspace.
              </div>
            )
          }
          customerFacing={customerFacing}
          onAdd={onAdd}
          displayDownloadButton={!customerFacing}
          maxNumUsers={MAX_NUM_USERS}
        />
      </DialogContent>
    </Dialog>
  )
}

export default AddUsersDialog
