import * as React from 'react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'

export const WorkflowInputHeader = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={cn(
        'rounded-md bg-primary px-4 py-[14px] text-sm font-medium',
        className
      )}
    >
      {children}
    </div>
  )
}

export const WorkflowInputFooter = ({
  children,
  footer,
  className,
  onCancel,
}: {
  children?: React.ReactNode
  footer: string
  className?: string
  onCancel?: () => void
}) => {
  return (
    <div
      className={cn(
        'flex min-h-8 w-full items-center justify-between',
        className
      )}
    >
      <p className="pl-3 pr-6 text-inactive">{footer}</p>
      <div className="flex items-center space-x-2">
        {children}
        {onCancel && (
          <Button
            variant="secondary"
            className="border border-primary"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  )
}

const WorkflowInput = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={cn('mx-auto w-full pb-6', className)}>
      <div className="mt-3 space-y-3 rounded-md bg-accent p-3">{children}</div>
    </div>
  )
}

export default WorkflowInput
