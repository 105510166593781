import React, { useState, useEffect } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { BaseAppPath } from 'components/base-app-path'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'
import { Dialog, DialogContent } from 'components/ui/dialog'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import { projectsPath, queriesPath } from 'components/vault/utils/vault'
import { runReviewQuery } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import VaultWorkflowAddFiles from 'components/vault/workflows/vault-workflow-add-files'
import VaultWorkflowProjectChooser from 'components/vault/workflows/vault-workflow-project-chooser'

const VaultQueryDuplicateTableDialog = () => {
  const userInfo = useAuthUser()
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)
  const queryClient = useQueryClient()
  const navigate = useNavigateWithQueryParams()
  const [selectedProject, setSelectedProject] = useState<VaultFolder | null>(
    null
  )
  const [showFileSelector, setShowFileSelector] = useState(false)

  const [
    currentProject,
    isDuplicateTableDialogOpen,
    folderIdToVaultFolder,
    fileIdToVaultFile,
    setCurrentProject,
    setIsDuplicateTableDialogOpen,
  ] = useVaultStore(
    useShallow((s) => [
      s.currentProject,
      s.isDuplicateTableDialogOpen,
      s.folderIdToVaultFolder,
      s.fileIdToVaultFile,
      s.setCurrentProject,
      s.setIsDuplicateTableDialogOpen,
    ])
  )

  const [selectedClientMatter] = useClientMattersStore(
    useShallow((s) => [s.selectedClientMatter])
  )

  const [
    queryId,
    queryTitle,
    setPendingQueryFileIds,
    setIsRunButtonLoading,
    setQueryId,
    setWorkflow,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.queryId,
      s.queryTitle,
      s.setPendingQueryFileIds,
      s.setIsRunButtonLoading,
      s.setQueryId,
      s.setWorkflow,
    ])
  )

  // Reset state when dialog opens/closes
  useEffect(() => {
    if (!isDuplicateTableDialogOpen) {
      setSelectedProject(null)
      setShowFileSelector(false)
    }
  }, [isDuplicateTableDialogOpen])

  const handleSelectProjectForDuplicate = (project: VaultFolder) => {
    setSelectedProject(project)
    setShowFileSelector(true)
  }

  const handleBackToProjectSelection = () => {
    setShowFileSelector(false)
    setSelectedProject(null)
  }

  const duplicateTableToProject = async (
    destinationFileIds: string[],
    destinationProject: VaultFolder,
    destinationProjectMetadata: VaultFolderMetadata
  ) => {
    if (!queryId || !queryTitle) {
      displayErrorMessage(
        'Unable to duplicate table: missing query information'
      )
      return
    }

    try {
      setPendingQueryFileIds(destinationFileIds)

      // Create a new review query with the same columns but no files
      const query = `Copy of ${queryTitle}`
      const clientMatterId: string | undefined =
        userInfo.IsVaultProjectClientMatterUser
          ? destinationProjectMetadata.clientMatterId
          : userInfo.isClientMattersReadUser
          ? selectedClientMatter?.id
          : undefined

      const eventId = await runReviewQuery({
        currentProject: destinationProject,
        currentProjectMetadata: destinationProjectMetadata,
        folderIdToVaultFolder,
        fileIdToVaultFile,
        hasSelectedFiles: false,
        hasNewFilesToRun: destinationFileIds.length > 0,
        hasNewQuestionsToRun: true,
        hasEmptyCells: false,
        selectedRows: [],
        pendingQueryFileIds: destinationFileIds,
        // Leave the questions empty and use the source query id to duplicate questions
        pendingQueryQuestions: [],
        sourceQueryIdForDuplicatingQuestions: queryId,
        workflow: null,
        queryId,
        query,
        isNewQuery: true,
        isRetry: false,
        allQueryFileIds: destinationFileIds,
        allQueryQuestions: [],
        clientMatterId,
        setIsRunButtonLoading,
        recordQuerySubmitted,
        setQueryId,
        setWorkflow,
        clearSelectedRows: () => {},
        queryClient,
      })
      if (!eventId) {
        return
      }
      displaySuccessMessage('Table duplicated successfully')
      setIsDuplicateTableDialogOpen(false)
      setCurrentProject(destinationProject)
      navigate(
        `${BaseAppPath.Vault}${projectsPath}${destinationProject.id}${queriesPath}${eventId}`
      )
    } catch (e) {
      console.error('Failed to duplicate table:', e)
      displayErrorMessage('Failed to duplicate table')
    }
  }

  const handleDuplicate = async (selectedFileIds: string[]) => {
    if (!selectedProject) {
      displayErrorMessage('No current project selected')
      return
    }

    // Get project metadata
    const projectMetadata =
      useVaultStore.getState().allFoldersMetadata[selectedProject.id]
    if (!projectMetadata) {
      displayErrorMessage('Project metadata not found')
      return
    }

    await duplicateTableToProject(
      selectedFileIds,
      selectedProject,
      projectMetadata
    )
  }

  return (
    <Dialog
      open={isDuplicateTableDialogOpen}
      onOpenChange={setIsDuplicateTableDialogOpen}
    >
      <DialogContent
        className="h-[600px] max-w-[960px]"
        innerClassName="flex flex-col p-0 py-5 space-y-0 h-full"
        hasContainer={false}
      >
        {showFileSelector && selectedProject ? (
          <VaultWorkflowAddFiles
            projectId={selectedProject.id}
            onBackButtonClicked={handleBackToProjectSelection}
            buttonLabel="Duplicate table"
            preselectFileIds={new Set()}
            handleSubmitVault={handleDuplicate}
          />
        ) : (
          <VaultWorkflowProjectChooser
            // XXX: Hack to show a reasonable title when reusing the project chooser
            selectedWorkflowName="duplicating table to"
            handleSelectProjectForWorkflow={handleSelectProjectForDuplicate}
            currentProjectId={currentProject?.id}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default VaultQueryDuplicateTableDialog
