import * as React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { useHandleKnowledgeSources } from 'components/assistant/features/composer/hooks'
import FilesRenderer from 'components/assistant/features/composer/knowledge-sources-renderer/files-renderer'
import ResearchRenderer from 'components/assistant/features/composer/knowledge-sources-renderer/research-renderer'
import VaultRenderer from 'components/assistant/features/composer/knowledge-sources-renderer/vault-renderer'
import { useAssistantFileUpload } from 'components/assistant/hooks/use-assistant-file-upload'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  isResearchKnowledgeSource,
  isVaultKnowledgeSource,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { Spinner } from 'components/ui/spinner'
import { useVaultStore } from 'components/vault/utils/vault-store'

const AssistantSourcesRenderer = () => {
  const [mode, documents, documentsUploading, knowledgeSource, isForking] =
    useAssistantStore(
      useShallow((s) => [
        s.mode,
        s.documents,
        s.documentsUploading,
        s.knowledgeSource,
        s.isForking,
        s.zipFiles,
        s.pstFiles,
        s.removeFilterIds,
        s.removeFileIds,
      ])
    )
  const [setCurrentProject] = useVaultStore(
    useShallow((s) => [s.setCurrentProject])
  )
  const { handleRemoveAllFiles } = useAssistantFileUpload(mode)
  const { handleRemoveKnowledgeSources } = useHandleKnowledgeSources()
  const hasUploadedFiles =
    isForking || documents.length > 0 || documentsUploading.length > 0
  const hasSources = !!(hasUploadedFiles || knowledgeSource)

  const handleCancel = () => {
    handleRemoveAllFiles()
    handleRemoveKnowledgeSources()
    setCurrentProject(null)
  }

  if (!hasSources) return null

  if (isForking) {
    return (
      <div className="px-6 py-4">
        <p className="flex items-center px-1 py-2 text-xs">
          <Spinner size="xs" className="mr-2" />
          Duplicating documents
        </p>
      </div>
    )
  }

  const shouldShowUploadedFiles = hasUploadedFiles && !knowledgeSource
  const shouldShowVaultFiles = isVaultKnowledgeSource(knowledgeSource)
  const shouldShowResearchFiles =
    isResearchKnowledgeSource(knowledgeSource) &&
    // research KS are not supported in draft mode
    mode !== AssistantMode.DRAFT

  if (shouldShowUploadedFiles) {
    return <FilesRenderer handleCancel={handleCancel} />
  } else if (shouldShowVaultFiles) {
    return <VaultRenderer handleCancel={handleCancel} />
  } else if (shouldShowResearchFiles) {
    return <ResearchRenderer handleCancel={handleCancel} />
  }

  return null
}

export default AssistantSourcesRenderer
