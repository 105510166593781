import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useLocation, useNavigate } from 'react-router-dom'

import { InfoIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { getMergedQueryPath } from 'hooks/use-navigate-with-query-params'
import { cn } from 'utils/utils'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { calculateAssistantSourceHeight } from 'components/assistant/utils/assistant-calculate-source-height'
import {
  DatabaseSource,
  FileSource,
  getDateRange,
  isResearchKnowledgeSource,
  KnowledgeSource,
  KnowledgeSourceConfig,
  KnowledgeSourceItem,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import VaultKnowledgeSourcePicker from 'components/common/vault-knowledge-source-picker'
import { ResearchDatePicker } from 'components/research/date-picker'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import AssistantFilesInput from './assistant-files-input'
import AssistantInputSourceHeader from './assistant-input-source-header'
import { AssistantMode } from './assistant-mode-select'
import { AssistantResearchKnowledgeSource } from './assistant-research-knowledge-source'

interface Props {
  knowledgeSources: Set<KnowledgeSource>
  isForkingFiles: boolean // When user chooses 'Reuse documents' from 'New thread' dropdown in a thread header
  mode: AssistantMode
  setFilesAskHarveyDisabled: (disabled: boolean) => void
  setResearchAskHarveyDisabled: (disabled: boolean) => void
  defaultKnowledgeSource?: KnowledgeSourceItem
}
const AssistantKnowledgeSourceInput = ({
  knowledgeSources,
  isForkingFiles,
  mode,
  setFilesAskHarveyDisabled,
  setResearchAskHarveyDisabled,
  defaultKnowledgeSource,
}: Props) => {
  const [documents, documentsUploading, knowledgeSource, setKnowledgeSource] =
    useAssistantStore(
      useShallow((s) => [
        s.documents,
        s.documentsUploading,
        s.knowledgeSource,
        s.setKnowledgeSource,
      ])
    )

  const { trackEvent } = useAnalytics()

  const navigate = useNavigate()
  const location = useLocation()
  const locKnowledge = location.state?.knowledge as
    | KnowledgeSourceItem
    | undefined
  const prevLocKnowledge = useRef<KnowledgeSourceItem | null>(null)

  const dateRange = getDateRange(knowledgeSource)
  const handleSetDateRange = (dateRange: DateRange | null) => {
    if (!isResearchKnowledgeSource(knowledgeSource)) return
    setKnowledgeSource({
      ...knowledgeSource,
      dateRange: dateRange ?? undefined,
    })
  }

  const isFileSource =
    isForkingFiles || documents.length > 0 || documentsUploading.length > 0

  const defaultSource =
    defaultKnowledgeSource?.type ??
    (isFileSource
      ? FileSource.FILES
      : knowledgeSource
      ? knowledgeSource.type
      : undefined)

  const [activeSource, setActiveSource] = useState<KnowledgeSource | undefined>(
    defaultSource
  )
  const handleSetSource = useCallback(
    (source: KnowledgeSource, pushState?: boolean) => {
      setActiveSource(source)
      setKnowledgeSource({ type: source } as KnowledgeSourceItem)
      if (pushState) {
        // Navigate in place with new state, so we can use the browser back to clear the source.
        navigate(getMergedQueryPath(location.pathname, location), {
          state: { knowledge: { type: source } },
        })
      }
      trackEvent('Knowledge Source Selected', {
        source_type: source,
        assistant_mode: mode,
      })
    },
    [location, mode, navigate, setActiveSource, setKnowledgeSource, trackEvent]
  )
  const handleSourceCancel = useCallback(() => {
    setActiveSource(undefined)
    setKnowledgeSource(null)
    trackEvent('Knowledge Source Selection Canceled', {
      assistant_mode: mode,
    })
    // Replace location state locKnowledge and unset ref
    navigate(getMergedQueryPath(location.pathname, location), { replace: true })
    if (prevLocKnowledge.current) {
      prevLocKnowledge.current = null
    }
  }, [
    location,
    mode,
    navigate,
    setActiveSource,
    setKnowledgeSource,
    trackEvent,
  ])

  useEffect(() => {
    if (locKnowledge) {
      prevLocKnowledge.current = locKnowledge
      if (activeSource !== locKnowledge.type) {
        handleSetSource(locKnowledge.type)
      }
    } else if (prevLocKnowledge.current) {
      handleSourceCancel()
      prevLocKnowledge.current = null
    }
  }, [activeSource, locKnowledge, handleSetSource, handleSourceCancel])

  const sourceConfig = activeSource
    ? KnowledgeSourceConfig[activeSource]
    : undefined

  const hasMode = useCallback(
    (source: KnowledgeSource) =>
      KnowledgeSourceConfig[source].modes.includes(mode),
    [mode]
  )

  const databaseSources = useMemo(
    () =>
      Object.values(DatabaseSource).filter((source) =>
        knowledgeSources.has(source)
      ),
    [knowledgeSources]
  )
  const availableDatabaseSources = useMemo(
    () => databaseSources.filter(hasMode),
    [databaseSources, hasMode]
  )
  const fileSources = useMemo(
    () =>
      Object.values(FileSource).filter((source) =>
        knowledgeSources.has(source)
      ),
    [knowledgeSources]
  )
  const availableFileSources = useMemo(
    () => fileSources.filter(hasMode),
    [fileSources, hasMode]
  )
  const internalFileSources = useMemo(
    () => fileSources.filter((source) => source !== FileSource.FILES),
    [fileSources]
  )

  // If the active source is not available, reset it
  useEffect(() => {
    const availableSources: Set<KnowledgeSource> = new Set([
      ...availableDatabaseSources,
      ...availableFileSources,
    ])
    setActiveSource(() => {
      const newSource = isFileSource
        ? FileSource.FILES
        : knowledgeSource
        ? knowledgeSource.type
        : undefined
      if (newSource && availableSources.has(newSource)) return newSource
      return undefined
    })
  }, [
    isFileSource,
    knowledgeSource,
    availableDatabaseSources,
    availableFileSources,
  ])

  const hasDatabases = availableDatabaseSources.length > 0
  const hasFiles = fileSources.length > 0

  const showDatabases = hasDatabases && !activeSource
  const showFiles =
    hasFiles && (!activeSource || activeSource === FileSource.FILES)

  const handleFileUpload = () => {
    setActiveSource(FileSource.FILES)
  }

  const dropzoneSources = internalFileSources.map((source) => {
    const config = KnowledgeSourceConfig[source]
    if (!config) return null
    return (
      <Button
        key={source}
        className="w-52 text-primary"
        onClick={(e) => {
          e.stopPropagation()
          setActiveSource(source)
          if (source === FileSource.VAULT) {
            trackEvent('Assistant Choose From Vault Clicked')
          }
        }}
        size="sm"
        variant="outline"
      >
        {React.cloneElement(config.icon, {
          className: 'h-4 w-4 shrink-0 mx-2',
        })}
        <span className="pr-2 text-sm">{config.label}</span>
      </Button>
    )
  })

  const containerHeight = calculateAssistantSourceHeight()

  return (
    <div
      className={cn(
        'box-border flex flex-col rounded-md rounded-t-none bg-secondary p-4 pt-2 ',
        {
          'border border-t-0 bg-primary p-0 shadow-sm': !!activeSource,
        }
      )}
      style={{ height: `${containerHeight}px` }}
    >
      {!activeSource ? (
        <p className="my-2 h-5 font-medium">Sources</p>
      ) : sourceConfig && (!sourceConfig.hideHeader || isForkingFiles) ? (
        <AssistantInputSourceHeader
          className="gap-x-4"
          actions={
            isForkingFiles ? null : (
              <Button
                id="knowledge-source-cancel"
                size="sm"
                variant="outline"
                onClick={handleSourceCancel}
              >
                Cancel
              </Button>
            )
          }
        >
          {sourceConfig.name}
          {sourceConfig.hasDateRange && (
            <ResearchDatePicker
              dateRange={dateRange}
              handleSetDateRange={handleSetDateRange}
            />
          )}
        </AssistantInputSourceHeader>
      ) : null}
      <div className="flex min-h-0 grow gap-4">
        {showFiles && (
          <div className="grow">
            {isForkingFiles ? (
              <div className="px-6 py-4">
                <p className="flex items-center px-1 py-2 text-xs">
                  <Spinner size="xs" className="mr-2" />
                  Duplicating documents
                </p>
              </div>
            ) : (
              <AssistantFilesInput
                dropzoneSources={dropzoneSources}
                hasFilesHeader={hasDatabases && !activeSource}
                mode={mode}
                onCancel={handleSourceCancel}
                onUpload={handleFileUpload}
                setAskHarveyDisabled={setFilesAskHarveyDisabled}
              />
            )}
          </div>
        )}
        {showDatabases && (
          <div className="flex w-64 flex-col" id="knowledge">
            {hasFiles && (
              <p className="pb-2 text-xs font-medium text-secondary">
                Knowledge
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className="ml-1 shrink-0 align-top transition hover:bg-button-secondary-hover"
                      size="xxsIcon"
                      variant="ghost"
                    >
                      <Icon icon={InfoIcon} size="small" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="text-left" side="right">
                    Explore trusted information sources across domains
                  </TooltipContent>
                </Tooltip>
              </p>
            )}
            <ScrollArea className="grow">
              <div className="space-y-2" id="knowledge-sources">
                {availableDatabaseSources.map((source) => {
                  const config = KnowledgeSourceConfig[source]
                  if (!config) return null
                  return (
                    <Button
                      key={source}
                      className="group flex h-auto w-full items-center justify-start border px-4 py-3 transition hover:bg-secondary-hover"
                      id={source}
                      onClick={() => handleSetSource(source, true)}
                      variant="unstyled"
                    >
                      {React.cloneElement(config.icon, {
                        className: 'h-4 w-4 shrink-0 mr-2',
                      })}
                      {config.label}
                      {KnowledgeSourceConfig[source].dataRefreshMessage && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              className="ml-auto h-4 w-4 shrink-0 align-top text-secondary opacity-0 transition hover:bg-button-secondary-hover group-hover:opacity-100"
                              variant="ghost"
                              size="xsIcon"
                            >
                              <Icon icon={InfoIcon} size="small" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent className="text-left" side="right">
                            {KnowledgeSourceConfig[source].dataRefreshMessage}
                          </TooltipContent>
                        </Tooltip>
                      )}
                    </Button>
                  )
                })}
              </div>
            </ScrollArea>
          </div>
        )}
        {/* TODO: Create a map for these */}
        {activeSource === FileSource.VAULT && (
          <VaultKnowledgeSourcePicker
            knowledgeSource={knowledgeSource}
            setKnowledgeSource={setKnowledgeSource}
            onClose={handleSourceCancel}
          />
        )}
        <AssistantResearchKnowledgeSource
          onClose={handleSourceCancel}
          databaseSource={activeSource as DatabaseSource}
          setAskHarveyDisabled={setResearchAskHarveyDisabled}
        />
      </div>
    </div>
  )
}

export default AssistantKnowledgeSourceInput
