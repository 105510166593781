import React from 'react'

import { isToday } from 'date-fns'
import _ from 'lodash'

import { Export } from 'models/fetchers/history-fetcher'
import { UserInfo } from 'models/user-info'
import { type StatCard as StatCardType } from 'types'

import { groupProductLabelEntries } from 'utils/task-definitions'
import {
  EM_DASH,
  ERROR_DATE,
  HARVEY_START_DATE,
  parseIsoString,
} from 'utils/utils'

import AreaChartCard from 'components/dashboard/area-chart-card'
import PieChartCard from 'components/dashboard/pie-chart-card'
import ExportButton from 'components/history/export-button'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import SettingsPageHeader from 'components/settings/settings-page-header'
import { useSettingsState } from 'components/settings/settings-store'
import useUsageData from 'components/settings/usage/use-usage-data'
import DateRangePicker from 'components/ui/date-range-picker'
import { MultiSelect } from 'components/ui/multi-select'

import StatCard from './stat-card'
import UserStatsTable from './user-stats-table'

const CARDS: Array<Pick<StatCardType, 'unit' | 'endpoint'>> = [
  {
    unit: 'Unique users',
    endpoint: 'users/graph',
  },
  {
    unit: 'Queries processed',
    endpoint: 'queries/graph',
  },
]

export const TOTAL_KEY = 'All types'

const EMPTY_STATE_TEXT = 'No results for your selected date range'

export const CHART_CONFIGS = {
  users: {
    label: 'Unique users',
    tooltipLabel: 'Users',
    areaChart: {
      title: 'Number of unique users',
      emptyStateText: EMPTY_STATE_TEXT,
    },
    pieChart: {
      title: 'Number of unique users per type',
      emptyStateText: EMPTY_STATE_TEXT,
    },
  },
  queries: {
    label: 'Queries processed',
    tooltipLabel: 'Queries',
    areaChart: {
      title: 'Number of queries',
      emptyStateText: EMPTY_STATE_TEXT,
    },
    pieChart: {
      title: 'Number of queries per type',
      emptyStateText: EMPTY_STATE_TEXT,
    },
  },
} as const

export type ChartConfigType = (typeof CHART_CONFIGS)[keyof typeof CHART_CONFIGS]

export type TimeSeriesData = {
  total: number
  [key: string]: number
} & {
  timestamp: string
}

const Dashboard = (): JSX.Element => {
  const settingsUser = useSettingsState((s) => s.settingsUser)

  if (
    !settingsUser ||
    (!settingsUser.IsUsageDashboardViewer &&
      !settingsUser.IsUsageDashboardV2Viewer)
  ) {
    return (
      <SettingsError
        description={`You don’t have access to usage statistics for ${settingsUser?.workspace.clientName}`}
      />
    )
  }

  return <DashboardContent settingsUser={settingsUser} />
}

const DashboardContent = ({ settingsUser }: { settingsUser: UserInfo }) => {
  const workspace = settingsUser.workspace

  let earliestDate = HARVEY_START_DATE
  if (!_.isNil(workspace.createdAt)) {
    earliestDate = parseIsoString(workspace.createdAt)
    if (earliestDate === ERROR_DATE) {
      earliestDate = HARVEY_START_DATE
    }
  }

  const {
    usageData,
    isLoadingUsageData,
    selectedDateRange,
    updateSelectedDateRange,
    productTypeOptions,
    selectedProductTypes,
    updateSelectedProductTypes,
  } = useUsageData({
    workspace,
    earliestDate,
  })

  const { sortedGroups, nonGroupedSortedProductTypes } =
    groupProductLabelEntries(productTypeOptions)

  const fromDate: string = _.isNil(selectedDateRange?.from)
    ? ''
    : isToday(selectedDateRange.from)
    ? 'today'
    : new Intl.DateTimeFormat(navigator.language, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).format(selectedDateRange.from)

  const toDate: string = _.isNil(selectedDateRange?.to)
    ? ''
    : isToday(selectedDateRange.to)
    ? 'today'
    : new Intl.DateTimeFormat(navigator.language, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).format(selectedDateRange.to)

  const earliestDateFormatted = new Intl.DateTimeFormat(navigator.language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(earliestDate)

  let dateRangeDescriptionString: string = ''
  if (selectedDateRange && selectedDateRange.from && selectedDateRange.to) {
    dateRangeDescriptionString = `Displaying usage data from ${fromDate} ${EM_DASH} ${toDate}.`
  } else if (selectedDateRange && selectedDateRange.from) {
    dateRangeDescriptionString = `Displaying usage data from ${fromDate}.`
  } else {
    if (isToday(earliestDate)) {
      dateRangeDescriptionString = `Displaying usage data from today.`
    } else {
      dateRangeDescriptionString = `Displaying usage data from ${earliestDateFormatted} ${EM_DASH} today. This includes all data since the workspace was created.`
    }
  }

  return (
    <>
      <SettingsAppHeader
        actions={
          <ExportButton
            type={Export.Usage}
            workspace={workspace}
            initialDateRange={selectedDateRange}
            dialogDescription="All raw data for product types that match the selected date filter will be exported."
          />
        }
      />
      <SettingsLayout>
        {!settingsUser.IsUsageDashboardV2Viewer && (
          <div className="space-y-4 pb-12">
            {CARDS.map((card, idx) => (
              <StatCard key={idx} {...card} workspaceSlug={workspace.slug} />
            ))}
          </div>
        )}

        {settingsUser.IsUsageDashboardV2Viewer && (
          <>
            <div className="flex items-center justify-between">
              <SettingsPageHeader
                title="Usage"
                subtitle={dateRangeDescriptionString}
              />
              <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-x-2 md:space-y-0">
                <DateRangePicker
                  selectedDateRange={selectedDateRange}
                  onSelectDateRange={updateSelectedDateRange}
                  disableFutureDates
                  disablePastDatesBeforeDate={earliestDate}
                />
                <MultiSelect
                  maxHeight="max-h-96"
                  placeholder="Select types"
                  selectedValues={selectedProductTypes}
                  setSelectedValues={updateSelectedProductTypes}
                  sortedGroups={sortedGroups}
                  sortedEntries={nonGroupedSortedProductTypes}
                  disabled={
                    isLoadingUsageData || productTypeOptions.length === 0
                  }
                  className="h-8 min-w-20 lg:min-w-32"
                  align="end"
                  toggleAll={{
                    toggleAllEntry: {
                      // this entry value should be different from the entries in the list
                      value: 'Select all',
                      text: 'Select all',
                    },
                    onToggleAll: () => {
                      if (
                        selectedProductTypes.length ===
                        productTypeOptions.length
                      ) {
                        updateSelectedProductTypes([])
                      } else {
                        updateSelectedProductTypes(productTypeOptions)
                      }
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-4 flex flex-col space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0">
              <div className="lg:flex-grow">
                <PieChartCard
                  usageData={usageData}
                  unit={CHART_CONFIGS.users}
                  isLoading={isLoadingUsageData}
                />
              </div>
              <div className="lg:flex-grow">
                <PieChartCard
                  usageData={usageData}
                  unit={CHART_CONFIGS.queries}
                  isLoading={isLoadingUsageData}
                />
              </div>
            </div>
            <div className="mt-4 space-y-4">
              <AreaChartCard
                usageData={usageData}
                unit={CHART_CONFIGS.users}
                isLoading={isLoadingUsageData}
              />
              <AreaChartCard
                usageData={usageData}
                unit={CHART_CONFIGS.queries}
                isLoading={isLoadingUsageData}
              />
            </div>

            <div className="mt-4">
              <UserStatsTable
                usageData={usageData}
                isLoading={isLoadingUsageData}
                emptyStateText={EMPTY_STATE_TEXT}
                selectedDateRange={selectedDateRange}
              />
            </div>
          </>
        )}
      </SettingsLayout>
    </>
  )
}

export default Dashboard
