import React, { useCallback, useEffect } from 'react'

import _ from 'lodash'

import { getRoleUsersInternalAdmin, WorkspaceRole } from 'models/roles'
import { RawUser } from 'models/users'
import { Workspace } from 'models/workspace'
import { RestrictedRole } from 'openapi/models/RestrictedRole'

import { useAuthUser } from 'components/common/auth-context'
import { Card, CardContent, CardTitle } from 'components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

import RoleBulkAddUsers from './role-bulk-add-users'
import RolesUsersTable from './role-users-table'

interface RolesUsersCardProps {
  workspaceRole: WorkspaceRole
  rolePermCount: number
  setRoleUserCount: (count: number) => void
  workspace: Workspace
}

enum RoleUserTab {
  roleUsers = 'roleUsers',
  addUsers = 'addUsers',
}

export interface RoleRawUser extends RawUser {
  dateAdded: string
}

const RolesUsersCard = ({
  workspaceRole,
  rolePermCount,
  setRoleUserCount,
  workspace,
}: RolesUsersCardProps) => {
  const userInfo = useAuthUser()
  const [roleUsers, setRoleUsers] = React.useState<RoleRawUser[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const restrictedRoles = new Set(
    Object.values(RestrictedRole).map((key) => `${workspace.slug}-${key}`)
  )
  const roleUserManagementDisabled =
    restrictedRoles.has(workspaceRole.roleId) || workspace.deletedAt

  const fetchRoleUsers = useCallback(async () => {
    setIsLoading(true)
    const roleUsers = await getRoleUsersInternalAdmin(workspaceRole.rolePk)
    setRoleUsers(roleUsers)
    setIsLoading(false)
    setRoleUserCount(roleUsers.length)
  }, [workspaceRole.rolePk, setRoleUserCount])

  useEffect(() => {
    fetchRoleUsers()
  }, [fetchRoleUsers])

  if (_.isNil(userInfo) || !userInfo.IsInternalAdminReader) return <></>

  const RoleUsersTable = (
    <RolesUsersTable
      isLoading={isLoading}
      users={_.sortBy(roleUsers, 'email').map((roleUser) => {
        return {
          userEmail: roleUser.email,
          rolePk: workspaceRole.rolePk,
          createdAt: roleUser.dateAdded,
          deletedAt: roleUser.deletedAt ?? null,
        }
      })}
      workspaceRole={workspaceRole}
      workspace={workspace}
      fetchRoleUsersCallback={fetchRoleUsers}
    />
  )

  return (
    <div className="w-full">
      <Card className="p-4">
        <CardTitle className="mb-3 ml-1">Users</CardTitle>
        <CardContent className="mt-2 px-1">
          {userInfo.IsInternalAdminWriter && !roleUserManagementDisabled ? (
            <Tabs defaultValue={RoleUserTab.roleUsers}>
              <TabsList>
                <TabsTrigger value={RoleUserTab.roleUsers}>
                  Role users
                </TabsTrigger>
                <TabsTrigger value={RoleUserTab.addUsers}>
                  Add users
                </TabsTrigger>
              </TabsList>
              <TabsContent value={RoleUserTab.roleUsers}>
                {RoleUsersTable}
              </TabsContent>
              <TabsContent value={RoleUserTab.addUsers}>
                <RoleBulkAddUsers
                  rolePermCount={rolePermCount}
                  currentUsers={
                    new Set(
                      roleUsers
                        .filter((roleUser) => !roleUser.deletedAt)
                        .map((roleUser) => {
                          return roleUser.email
                        })
                    )
                  }
                  role={workspaceRole}
                  fetchRoleUsersCallback={fetchRoleUsers}
                  workspace={workspace}
                />
              </TabsContent>
            </Tabs>
          ) : (
            <div className="mt-3">{RoleUsersTable}</div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default RolesUsersCard
