import React, { useState } from 'react'

import _ from 'lodash'

import { saveWorkspaceNote, Workspace } from 'models/workspace'

import { readableFormat } from 'utils/date-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/text-area'

interface WorkspaceDetailsNotesProps {
  workspace: Workspace
}

const WorkspaceDetailsNotes = ({ workspace }: WorkspaceDetailsNotesProps) => {
  const userInfo = useAuthUser()
  const [note, setNote] = useState(workspace.note?.note ?? '')

  const canSaveNote = userInfo.IsInternalAdminWriter

  const saveNote = async () => {
    try {
      await saveWorkspaceNote(workspace.id, note)
      displaySuccessMessage(`Note saved for ${workspace.clientName}`)
    } catch (error) {
      displayErrorMessage('Failed to save note')
    }
  }
  return (
    <div className="mt-4">
      <div className="flex items-center justify-between px-2">
        <p className="text-sm">Save working notes for {workspace.clientName}</p>
        <div className="flex items-center space-x-4">
          {!_.isNil(workspace.note) && (
            <p className="text-sm">
              Last updated at {readableFormat(workspace.note.updatedAt)} by{' '}
              {workspace.note.updatedBy}
            </p>
          )}
          {canSaveNote && (
            <Button variant="outline" onClick={saveNote}>
              Save Note
            </Button>
          )}
        </div>
      </div>
      <div className="mt-2 p-2">
        <Textarea
          className="max-h-[500px] w-full overflow-y-auto"
          rows={10}
          placeholder="Add a note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          disabled={!canSaveNote}
        />
      </div>
    </div>
  )
}

export default WorkspaceDetailsNotes
