import React from 'react'

import { Typography } from 'antd'

import { RawUser } from 'models/users'
import {
  isPwcWorkspace,
  PermUserCountByWorkspace,
  Workspace,
} from 'models/workspace'

import { useAuthUser } from 'components/common/auth-context'
import useWorkspaceUsersEnriched from 'components/settings/hooks/use-workspace-users-enriched'
import WorkspaceDetailsRoles from 'components/settings/roles/workspace-details-roles'
import MultiUserAddRemove from 'components/settings/user-management/multi-user-add-remove'
import { Tabs, TabsContent, TabsList } from 'components/ui/tabs'

import WorkspaceDetailsAuditLogs from './workspace-details-audit-logs'
import WorkspaceDetailsNotes from './workspace-details-notes'
import WorkspaceRolesTableV2 from './workspace-details-roles-v2'
import { isWorkspaceCutoverToPermBundles } from './workspace-perm-migration-status'
import WorkspacePermUserCounts from './workspace-perm-user-counts'
import { WSTabsTrigger } from './workspace-tabs'
import WorkspaceUsersInternal from './workspace-users-internal'
import WorkspaceUsersTableV2 from './workspace-users-table-v2'

interface WorkspaceDetailsUsersProps {
  workspace: Workspace
  workspaces: Workspace[]
  users: RawUser[]
  permUserCountByWorkspace: PermUserCountByWorkspace
  fetchData: () => void
}

const WorkspaceDetailsUsers = ({
  workspace,
  workspaces,
  users,
  permUserCountByWorkspace,
  fetchData,
}: WorkspaceDetailsUsersProps) => {
  const userInfo = useAuthUser()
  const { workspaceUsersEnriched, fetchWorkspaceUsersEnriched } =
    useWorkspaceUsersEnriched({
      workspaceId: workspace.id,
      isInternalAdmin: true,
    })

  const workspaceUsersTabDisabled =
    !userInfo.isUserManagement ||
    (isPwcWorkspace(workspace.slug) && !userInfo.IsInternalAdminWriter) ||
    workspace.deletedAt != null

  const renderUsersTabsContent = () => {
    if (isWorkspaceCutoverToPermBundles(workspace)) {
      return (
        <TabsContent value="1" className="pt-2">
          <WorkspaceUsersTableV2
            users={workspaceUsersEnriched}
            fetchData={fetchWorkspaceUsersEnriched}
            workspace={workspace}
          />
        </TabsContent>
      )
    }
    return (
      <>
        <TabsContent value="1">
          <WorkspaceUsersInternal
            users={users}
            fetchData={fetchData}
            workspace={workspace}
          />
        </TabsContent>
        <TabsContent value="1.1" className="pt-2">
          <WorkspaceUsersTableV2
            users={workspaceUsersEnriched}
            fetchData={fetchWorkspaceUsersEnriched}
            workspace={workspace}
          />
        </TabsContent>
      </>
    )
  }

  const renderRolesTabsContent = () => {
    if (isWorkspaceCutoverToPermBundles(workspace)) {
      return (
        <TabsContent value="4" className="pt-2">
          <WorkspaceRolesTableV2 workspace={workspace} />
        </TabsContent>
      )
    }
    return (
      <>
        <TabsContent value="4">
          <WorkspaceDetailsRoles workspace={workspace} />
        </TabsContent>
        <TabsContent value="4.1" className="pt-2">
          <WorkspaceRolesTableV2 workspace={workspace} />
        </TabsContent>
      </>
    )
  }

  return (
    <Tabs
      defaultValue="1"
      onValueChange={(value) => {
        if (value === '1' || value === '4') {
          void fetchData()
        }
      }}
    >
      <TabsList variant="minimal" className="w-full px-4">
        <WSTabsTrigger value="1">Users</WSTabsTrigger>
        {!isWorkspaceCutoverToPermBundles(workspace) && (
          <WSTabsTrigger value="1.1">Users V2</WSTabsTrigger>
        )}
        <WSTabsTrigger value="2" disabled={workspaceUsersTabDisabled}>
          Add New Users
        </WSTabsTrigger>
        <WSTabsTrigger value="3">Perm User Counts</WSTabsTrigger>
        <WSTabsTrigger value="4" variant="minimal" className="px-2">
          Roles
        </WSTabsTrigger>
        {!isWorkspaceCutoverToPermBundles(workspace) && (
          <WSTabsTrigger value="4.1" variant="minimal" className="px-2">
            Roles V2
          </WSTabsTrigger>
        )}
        <WSTabsTrigger value="5" variant="minimal" className="px-2">
          Audit Logs
        </WSTabsTrigger>
        <WSTabsTrigger value="6" variant="minimal" className="px-2">
          Notes
        </WSTabsTrigger>
      </TabsList>

      {renderUsersTabsContent()}
      <TabsContent value="2">
        <MultiUserAddRemove
          type="add"
          workspace={workspace}
          workspaces={workspaces}
          helpMessage={
            <Typography>
              Add new user emails to create them in Harvey and Auth0. Get a csv
              of new users.
              <br /> Users will be added to <b>{workspace?.clientName}</b>{' '}
              workspace.
            </Typography>
          }
          displayDownloadButton
        />
      </TabsContent>
      <TabsContent value="3">
        <WorkspacePermUserCounts
          permUserCountByWorkspace={permUserCountByWorkspace}
          workspace={workspace}
          workspaces={workspaces}
        />
      </TabsContent>
      {renderRolesTabsContent()}
      <TabsContent value="5">
        <WorkspaceDetailsAuditLogs workspace={workspace} />
      </TabsContent>
      <TabsContent value="6">
        <WorkspaceDetailsNotes workspace={workspace} />
      </TabsContent>
    </Tabs>
  )
}

export default WorkspaceDetailsUsers
