import React from 'react'

import { EventStatus } from 'openapi/models/EventStatus'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'

import { Source } from 'utils/task'

import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
  AssistantWorkflowSourceExtractor,
} from 'components/assistant/workflows'
import { AssistantWorkflowSidebar } from 'components/assistant/workflows/components/assistant-workflow-sidebar'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import RenderBlockToolbar from 'components/assistant/workflows/components/render-block-toolbar/render-block-toolbar'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import { inActiveCitationsLoadingState } from 'components/assistant/workflows/utils/utils'
import Markdown from 'components/common/markdown/markdown'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowAnswerRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({
  blockParams,
  exportComponents,
  feedback,
  loadingStates,
  onRegenerate,
  paramStatus,
  stepId,
}) => {
  const content = blockParams.answer.response
  const { getHrvyInfoMetadata, handleSourceClick } = useSourceUtils({
    sources: blockParams.answer.sources as Source[],
  })

  const isCompleted = paramStatus === EventStatus.COMPLETED

  const Sidebar = (inActiveCitationsLoadingState(loadingStates) ||
    blockParams.answer.sources.length > 0) && (
    <AssistantWorkflowSidebar
      sources={blockParams.answer.sources as Source[]}
      isStreaming={!isCompleted}
      onSetActiveFileId={handleSourceClick}
    />
  )

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <div className="space-y-5">
          <LoadingState
            isCompleted={isCompleted}
            paramStatus={paramStatus}
            states={loadingStates}
          />
          {content.length > 0 && (
            <div>
              <Markdown
                content={content}
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                width="100%"
              />
            </div>
          )}
          {isCompleted && (
            <div>
              <RenderBlockToolbar
                content={content}
                feedback={feedback}
                workflowStepId={stepId ?? ''}
                onRegenerate={onRegenerate}
                exportComponents={exportComponents}
              />
            </div>
          )}
        </div>
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowAnswerRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams, paramStatus, onCancel }) => {
  const headerText = blockParams.answer.headerText
  const isStreaming = paramStatus === EventStatus.IN_PROGRESS
  const renderedText = isStreaming ? 'Working…' : headerText

  return (
    <WorkflowInput>
      <WorkflowInputFooter
        footer={renderedText}
        onCancel={isStreaming ? onCancel : undefined}
      />
    </WorkflowInput>
  )
}

export const AssistantWorkflowAnswerRendererExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams }) => {
  const content = blockParams.answer.response
  return <Markdown content={content} />
}

export const answerSourceExtractor: AssistantWorkflowSourceExtractor<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = (blockParams) => {
  return blockParams.answer.sources as Source[]
}
