import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { PlusIcon, Trash } from 'lucide-react'
import pluralize from 'pluralize'

import {
  createWorkspaceRoleInternalAdmin,
  deleteWorkspaceRoleInternalAdmin,
  WorkspaceRole,
} from 'models/roles'
import { instanceOfDefaultRole } from 'openapi/models/DefaultRole'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { getTableDateString } from 'utils/date-utils'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Dialog } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'

import CreateRoleModal from './create-role-modal'

interface WorkspaceRolesTableProps {
  workspaceRoles: WorkspaceRole[]
  workspaceName: string
  workspaceId: number
  workspaceSlug: string
  refetchRoles: () => void
}

const WorkspaceRolesTable = ({
  workspaceRoles,
  workspaceName,
  workspaceId,
  workspaceSlug,
  refetchRoles,
}: WorkspaceRolesTableProps) => {
  const userInfo = useAuthUser()
  const [filter, setFilter] = useState<string>('')
  const [isLoading] = useState<boolean>(false)
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] =
    useState<boolean>(false)
  const [confirmingDeleteRole, setConfirmingDeleteRole] =
    useState<WorkspaceRole>()

  const navigate = useNavigateWithQueryParams()

  const columns: ColumnDef<WorkspaceRole>[] = [
    {
      accessorKey: 'rolePk',
    },
    {
      accessorKey: 'roleId',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Role ID" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Name" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'desc',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Description" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Date added"
            tooltipText="Date when this client matter # was added"
          />
        )
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'deletedAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Deleted At" />
      },
      sortingFn: (rowA, rowB) => {
        // Always put non-deleted rows (null deletedAt) first
        const aDeleted = rowA.getValue('deletedAt')
        const bDeleted = rowB.getValue('deletedAt')
        if (!aDeleted && bDeleted) return -1
        if (aDeleted && !bDeleted) return 1
        return 0
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 w-1/2 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
    },
    {
      accessorKey: 'actions',
      header: '',
      size: 1,
      meta: {
        hidden: !userInfo.IsInternalAdminWriter,
      },
      cell: ({ row }) => {
        const deletedAt = row.getValue('deletedAt')
        if (deletedAt) {
          return <></>
        }
        const roleId = row.getValue('roleId') as string
        const baseSlug = roleId.slice(workspaceSlug.length + 1)
        if (instanceOfDefaultRole(baseSlug)) {
          return <></>
        }
        return (
          <div className="float-right flex justify-end">
            <Button
              className="flex items-center justify-center rounded transition hover:bg-button-secondary-hover"
              variant="ghost"
              size="xsIcon"
              tooltip={`Remove ${row.getValue('name')}`}
              tooltipSide="left"
              aria-label={`Remove ${row.getValue('name')}`}
              onClick={(e) => {
                e.stopPropagation()
                setConfirmingDeleteRole(row.original)
              }}
            >
              <Trash className="h-4 w-4 stroke-destructive" />
            </Button>
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'deletedAt', desc: false },
    { id: 'createdAt', desc: true },
  ])

  const table = useReactTable({
    data: workspaceRoles,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        rolePk: false,
      },
    },

    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'roles',
    table.getFilteredRowModel().rows.length
  )}`

  const rowClickHandler = (row: Row<WorkspaceRole>) => {
    if (row.getValue('deletedAt')) {
      return
    }
    navigate(`${BaseAppPath.Settings}/internal_admin/role-inspector`, {
      state: {
        roleId: workspaceRoles.find((r) => r.rolePk === row.original.rolePk)
          ?.roleId,
      },
    })
  }

  const handleCreateRole = async (name: string, description: string) => {
    await createWorkspaceRoleInternalAdmin(
      workspaceId,
      {
        name,
        desc: description,
      },
      false
    )
    setIsCreateRoleModalOpen(false)
    refetchRoles()
  }

  const handleDeleteRole = async (rolePk: string) => {
    await deleteWorkspaceRoleInternalAdmin(rolePk)
    setConfirmingDeleteRole(undefined)
    refetchRoles()
  }

  return (
    <>
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
        className="mb-2"
      >
        <div className="mr-2 flex flex-1 items-center justify-between gap-4">
          <p>
            Manage roles for <b>{workspaceName}</b>, add permissions to a role
            or grant roles to users by clicking on the role below
          </p>
          {userInfo.IsInternalAdminWriter && (
            <Button
              className="shrink-0"
              variant="outline"
              onClick={() => setIsCreateRoleModalOpen(true)}
            >
              <Icon icon={PlusIcon} className="mr-1" />
              Add Role
            </Button>
          )}
        </div>
      </DataTableHeader>
      <DataTable
        table={table}
        className={cn({ 'min-h-[420px]': !isLoading })}
        useVirtual={workspaceRoles.length > 100}
        isLoading={isLoading}
        emptyStateText="No roles found"
        onRowClick={(row) => rowClickHandler(row)}
        isRowDisabled={(row) => Boolean(row.getValue('deletedAt'))}
      />
      <DataTableFooter table={table} isLoading={isLoading}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
      <CreateRoleModal
        open={isCreateRoleModalOpen}
        onOpenChange={setIsCreateRoleModalOpen}
        onAdd={handleCreateRole}
      />
      {confirmingDeleteRole && (
        <Dialog open onOpenChange={() => setConfirmingDeleteRole(undefined)}>
          <ConfirmationDialog
            title="Delete role"
            description={`Are you sure you want to delete role "${confirmingDeleteRole.name}"?`}
            cta={{
              label: 'Delete',
              onClick: () => handleDeleteRole(confirmingDeleteRole.rolePk),
            }}
            secondaryCta={{
              label: 'Cancel',
              onClick: () => {
                setConfirmingDeleteRole(undefined)
              },
            }}
            variant="destructive"
          />
        </Dialog>
      )}
    </>
  )
}

export default WorkspaceRolesTable
