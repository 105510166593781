import _ from 'lodash'

import { BulkRoleAssignmentOption } from 'openapi/models/BulkRoleAssignmentOption'
import { DefaultVisibility } from 'openapi/models/DefaultVisibility'
import { PermSource } from 'openapi/models/PermSource'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'
import { PermissionCategory } from 'openapi/models/PermissionCategory'
import Services from 'services'
import { RequestError } from 'services/backend/backend'
import { Maybe } from 'types'

import { Permission } from './user-info'

export type { PermWithSource } from 'openapi/models/PermWithSource'

export interface Perm {
  permId: string
  name: string
  desc: string
  permSources?: string[]
  expiresAt?: Maybe<string>
}

export interface PermBundle {
  id: PermissionBundleId
  name: string
  category: PermissionCategory
  description: string
  permissions: Permission[]
  defaultVisibility: DefaultVisibility
}
export interface WorkspacePermBundle extends PermBundle {
  enabledPerms: Permission[]
}

export interface BulkManagePermsUsersResult {
  userNoAction: string[]
  userManagedPerms: string[]
  errorUser: string[]
}

export async function FetchAllPerms(): Promise<Perm[]> {
  const result = await Services.Backend.Get<Perm[]>(`internal_admin/perms`)
  const perms = result ?? []
  return perms
}

export async function FetchAllPermBundles(): Promise<PermBundle[]> {
  const result = await Services.Backend.Get<PermBundle[]>(
    `client_admin/workspace/perm_bundles`
  )
  return result
}

export async function FetchAllPermBundlesInternalAdmin(): Promise<
  PermBundle[]
> {
  const result = await Services.Backend.Get<PermBundle[]>(
    `internal_admin/workspaces/perm_bundles`
  )
  return result
}

export async function FetchWorkspacePermBundles(
  workspaceId: number
): Promise<WorkspacePermBundle[]> {
  const result = await Services.Backend.Get<WorkspacePermBundle[] | null>(
    `client_admin/workspace/${workspaceId}/perm_bundles`
  )
  return result ?? []
}

export async function FetchWorkspacePermBundlesInternalAdmin(
  workspaceId: number
): Promise<WorkspacePermBundle[]> {
  const result = await Services.Backend.Get<WorkspacePermBundle[]>(
    `internal_admin/workspaces/${workspaceId}/perm_bundles`
  )
  return result ?? []
}

export async function CreatePerm(perm: Perm): Promise<string> {
  const result = await Services.Backend.Post('internal_admin/perms', perm)
  if (_.isNil(result)) {
    throw new Error(`Failed to create perm`)
  }
  return (result as Perm).permId
}

export async function UpdatePerm(perm: Perm): Promise<void> {
  await Services.Backend.Patch(`internal_admin/perms/${perm.permId}`, perm)
}

export async function DeletePerm(perm: Perm): Promise<void> {
  await Services.Backend.Delete(`internal_admin/perms/${perm.permId}`)
}

interface AddPermsToUserParams {
  userEmail: string
  perms: Permission[]
  workspaceId: number
  sensitiveDataPermReason?: string
  sensitiveDataPermGrantCategory?: string
  expiresAt?: string
}

export async function AddPermsToUser({
  userEmail,
  perms,
  workspaceId,
  sensitiveDataPermReason,
}: AddPermsToUserParams): Promise<boolean> {
  return Services.Backend.Post(
    `internal_admin/perms/add_user_perms`,
    {
      userEmail,
      perms,
      workspaceId,
      sensitiveDataPermReason,
    },
    { maxRetryCount: 0 }
  )
}

export async function DeleteUserPerm(
  userEmail: string,
  permId: Permission,
  workspaceId: number
): Promise<boolean> {
  return Services.Backend.Post(`internal_admin/perms/delete_user_perm`, {
    userEmail,
    permId,
    workspaceId,
  })
}

export interface PermBundleMigrationResult {
  [workspaceId: number]: string[]
}

export async function MigrateWorkspaceToPermBundles(
  workspaceIds: number[],
  dryRun: boolean = true
): Promise<PermBundleMigrationResult> {
  const result = await Services.Backend.Post<PermBundleMigrationResult>(
    `internal_admin/permission_bundles/migrate`,
    {
      workspaceIds,
      dryRun,
    },
    { throwOnError: true }
  )
  if (result instanceof RequestError) {
    throw result
  }
  return result
}

export async function CutoverWorkspaceToPermBundles(
  workspaceId: number
): Promise<void> {
  const result = await Services.Backend.Post(
    `internal_admin/permission_bundles/set_migrated`,
    {
      workspaceIds: [workspaceId],
    },
    { throwOnError: true }
  )
  if (result instanceof RequestError) {
    throw result
  }
}

// new endpoints should point to /settings in the backend

export async function BulkGrantPermsToUsers(
  emails: string[],
  permIds: Permission[]
): Promise<BulkManagePermsUsersResult> {
  return Services.Backend.Post(`settings/perms/bulk_grant_user_perms`, {
    userEmails: emails,
    perms: permIds,
  })
}

export async function BulkRevokePermsFromUsers(
  emails: string[],
  permIds: Permission[]
): Promise<BulkManagePermsUsersResult> {
  return Services.Backend.Post(`settings/perms/bulk_revoke_user_perms`, {
    userEmails: emails,
    perms: permIds,
  })
}

export async function BulkGrantPermBundlesToUsers(
  workspaceId: number,
  emails: string[],
  permBundleIds: PermissionBundleId[]
): Promise<BulkManagePermsUsersResult> {
  return Services.Backend.Post(
    `client_admin/workspace/${workspaceId}/perms/bulk_grant_perm_bundles`,
    {
      userEmails: emails,
      permBundleIds,
    }
  )
}
export async function BulkGrantPermBundlesToUsersInternalAdmin(
  workspaceId: number,
  emails: string[],
  permBundleIds: PermissionBundleId[]
): Promise<BulkManagePermsUsersResult> {
  return Services.Backend.Post(`settings/perms/bulk_grant_perm_bundles`, {
    workspaceId,
    userEmails: emails,
    permBundleIds,
  })
}

export async function BulkRevokePermBundlesFromUsers(
  workspaceId: number,
  emails: string[],
  permBundleIds: PermissionBundleId[]
): Promise<BulkManagePermsUsersResult> {
  return Services.Backend.Post(
    `client_admin/workspace/${workspaceId}/perms/bulk_revoke_perm_bundles`,
    {
      userEmails: emails,
      permBundleIds,
    }
  )
}

export async function BulkRevokePermBundlesFromUsersInternalAdmin(
  workspaceId: number,
  emails: string[],
  permBundleIds: PermissionBundleId[]
): Promise<BulkManagePermsUsersResult> {
  return Services.Backend.Post(`settings/perms/bulk_revoke_perm_bundles`, {
    workspaceId,
    userEmails: emails,
    permBundleIds,
  })
}

export async function BulkManagePermBundlesForRoles({
  isRevocation = false,
  workspaceIds,
  invertWorkspaceSelection = false,
  permBundleIds,
  visibility,
  roleOptions,
}: {
  isRevocation?: boolean
  workspaceIds: number[]
  /** If true, all workspaces will be selected except those in workspaceIds */
  invertWorkspaceSelection?: boolean
  permBundleIds: PermissionBundleId[]
  visibility?: DefaultVisibility
  roleOptions: BulkRoleAssignmentOption[]
}) {
  const result = await Services.Backend.Post(
    `settings/perms/bulk_manage_perm_bundles_for_roles`,
    {
      isRevocation,
      workspaceIds,
      invertWorkspaceSelection,
      permBundleIds,
      visibility,
      roleOptions,
    },
    { throwOnError: true }
  )
  if (result instanceof RequestError) {
    throw result
  }
  return result
}

export interface PermEnablementResult {
  workspaceId: number
  workspaceName: string
  success: boolean
  message?: string
}

export type PermEnablementResults = Record<number, PermEnablementResult>

export async function BulkManagePermConfigsForWorkspaces({
  isDisable = false,
  workspaceIds,
  invertWorkspaceSelection = false,
  permIds,
}: {
  isDisable?: boolean
  workspaceIds: number[]
  /** If true, all workspaces will be selected except those in workspaceIds */
  invertWorkspaceSelection?: boolean
  permIds: Permission[]
}): Promise<PermEnablementResults> {
  const result = await Services.Backend.Post<PermEnablementResults>(
    `internal_admin/perms/bulk_manage_perm_configs_for_workspaces`,
    {
      isDisable: isDisable,
      workspaceIds: workspaceIds,
      invertWorkspaceSelection: invertWorkspaceSelection,
      permIds: permIds,
    },
    { throwOnError: true }
  )
  if (result instanceof RequestError) {
    throw result
  }
  return result
}

export interface UserPermInfo {
  email: string
  workspace: string
  sources: string[]
}

export const fetchPermUsers = async (
  permId: Permission
): Promise<UserPermInfo[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('perm_id', permId)
  const result = await Services.Backend.Get<UserPermInfo[]>(
    `internal_admin/perms/users?${searchParams.toString()}`
  )
  return result
}

export const grantSensitiveDataPerm = async ({
  userEmail,
  perms,
  workspaceId,
  sensitiveDataPermReason,
  sensitiveDataPermGrantCategory,
  expiresAt,
}: AddPermsToUserParams) => {
  return Services.Backend.Post(
    `internal_admin/perms/temporary_user_perm`,
    {
      userEmail,
      perms,
      workspaceId,
      sensitiveDataPermReason,
      sensitiveDataPermGrantCategory,
      expiresAt,
    },
    { maxRetryCount: 0, throwOnError: true }
  )
}

// more exports
export { PermSource }
