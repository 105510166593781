import * as React from 'react'

import { LayersIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { UserInfo, Permission } from 'models/user-info'
import Services from 'services'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { ResearchKnowledgeSourceSelector } from 'components/assistant/features/composer/assistant-research-selector-dialog'
import InputSourceButton from 'components/assistant/features/composer/components/input-source-button'
import { NEW_KNOWLEDGE_SOURCE_CONFIG } from 'components/assistant/features/composer/config'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  DatabaseSource,
  KnowledgeSourceConfig,
  useKnowledgeSources,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'
import { DropdownItemProps } from 'components/common/ks-input-dropdown/dropdown-item'
import { Button } from 'components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

const getMaxTopLevelSelections = (
  databaseSource: DatabaseSource,
  userInfo: UserInfo
) => {
  if (
    databaseSource === DatabaseSource.EDGAR &&
    userInfo.permissions.includes(Permission.SEC_EDGAR_ALL)
  ) {
    return 4
  } else {
    return undefined
  }
}

const AssistantResearchInput = () => {
  const userInfo = useAuthUser()
  const knowledgeSources = useKnowledgeSources(userInfo)
  const [
    mode,
    isForking,
    documents,
    documentsUploading,
    knowledgeSource,
    showResearchDialog,
    setShowResearchDialog,
    researchDialogArea,
    setResearchDialogArea,
  ] = useAssistantStore(
    useShallow((s) => [
      s.mode,
      s.isForking,
      s.documents,
      s.documentsUploading,
      s.knowledgeSource,
      s.showResearchDialog,
      s.setShowResearchDialog,
      s.researchDialogArea,
      s.setResearchDialogArea,
    ])
  )

  const hasMode = React.useCallback(
    (source: DatabaseSource) =>
      KnowledgeSourceConfig[source].modes.includes(mode),
    [mode]
  )

  const databaseSources = React.useMemo(
    () =>
      Object.values(DatabaseSource).filter((source) =>
        knowledgeSources.has(source)
      ),
    [knowledgeSources]
  )
  const availableDatabaseSources = React.useMemo(
    () => databaseSources.filter(hasMode),
    [databaseSources, hasMode]
  )

  const knowledgeSourceDropdownOptions: DropdownItemProps[] =
    React.useMemo(() => {
      const tempKSConfig = NEW_KNOWLEDGE_SOURCE_CONFIG

      return availableDatabaseSources.reduce<DropdownItemProps[]>(
        (acc, source) => {
          if (!(source in tempKSConfig)) return acc
          const { icon, title, description } = tempKSConfig[source]!
          acc.push({
            icon,
            title,
            description,
            onClick: () => {
              setResearchDialogArea(source)
              setShowResearchDialog(true)
            },
          })
          return acc
        },
        []
      )
    }, [availableDatabaseSources, setShowResearchDialog, setResearchDialogArea])

  const isDraftMode = mode === AssistantMode.DRAFT
  const hasUploadedFiles =
    isForking ||
    documents.length > 0 ||
    documentsUploading.length > 0 ||
    (knowledgeSource && 'fileIds' in knowledgeSource) ||
    false

  const tooltipText = () => {
    if (isDraftMode) {
      return 'Not available in draft mode'
    }
    if (hasUploadedFiles) {
      return 'Cannot select knowledge sources when documents are added'
    }
    return undefined
  }

  const descriptionText = React.useMemo(() => {
    const availableSources = availableDatabaseSources.map(
      (source) => KnowledgeSourceConfig[source].name
    )

    if (isDraftMode) {
      return 'Not available in draft mode'
    }

    if (hasUploadedFiles) {
      return 'Cannot select knowledge sources when documents are added'
    }

    if (availableSources.length === 0) {
      return 'No knowledge sources available'
    }

    // TODO: extract this to a helper function
    // Can reuse this in descriptionText for files input
    if (availableSources.length === 1) return availableSources[0]

    if (availableSources.length === 2) return availableSources.join(' or ')

    if (availableSources.length === 3) {
      return `${availableSources
        .slice(0, 2)
        .join(', ')} or ${availableSources.slice(-1)}`
    }

    return `${availableSources.slice(0, 3).join(', ')} and more`
  }, [availableDatabaseSources, isDraftMode, hasUploadedFiles])

  React.useEffect(() => {
    const availableSources = availableDatabaseSources.map(
      (source) => KnowledgeSourceConfig[source].name
    )
    const availableSourcesSorted = availableSources.sort()
    Services.HoneyComb.Record({
      metric: 'ui.available_knowledge_sources',
      available_knowledge_sources_count: availableSourcesSorted.length,
      available_knowledge_sources: availableSourcesSorted,
    })
  }, [availableDatabaseSources])

  // If user has 1-3 knowledge sources, unpack them inline
  // Currently to collect feedback and not disrupt the composer UI too much, we restrict this only to PwC users OR Australia Tax users (In reality Australia Tax users are all PwC anyway but it lets us test internally)
  const isInline =
    (userInfo.IsPwcUser || userInfo.IsAustraliaTaxQaUser) &&
    knowledgeSourceDropdownOptions.length > 0 &&
    knowledgeSourceDropdownOptions.length <= 3
  if (isInline) {
    return (
      <div className="flex w-full items-start gap-3">
        {knowledgeSourceDropdownOptions.map((item, index) => {
          return (
            <Tooltip key={index} delayDuration={250}>
              <TooltipTrigger asChild>
                <Button
                  key={index}
                  variant="unstyled"
                  className="group flex h-full w-full items-center justify-between gap-2 border border-transparent bg-primary py-2 pl-4 pr-3 transition hover:border-ring disabled:border-primary disabled:bg-secondary disabled:hover:border-primary"
                  onClick={(e) => item.onClick(e as any)}
                >
                  <div className="flex flex-col items-start">
                    <span className="text-left text-sm font-medium">
                      {item.title}
                    </span>
                    {item.description && (
                      <span className="text-gray-500 line-clamp-2 text-left text-xs">
                        {item.description}
                      </span>
                    )}
                  </div>
                  {item.icon && (
                    <span className="text-gray-600 ml-auto h-4 w-4 flex-shrink-0">
                      {item.icon}
                    </span>
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>{item.description}</TooltipContent>
            </Tooltip>
          )
        })}
        <ResearchKnowledgeSourceSelector
          showDialog={showResearchDialog}
          setShowDialog={setShowResearchDialog}
          databaseSource={researchDialogArea}
          maxTopLevelSelections={getMaxTopLevelSelections(
            researchDialogArea,
            userInfo
          )}
        />
      </div>
    )
  }

  // If user has > 3 knowledge sources or 0, show dropdown selector
  return (
    <div className="w-full">
      <InputSourceButton
        title="Choose knowledge source"
        description={descriptionText}
        icon={LayersIcon}
        dropdownItems={knowledgeSourceDropdownOptions}
        disabled={
          isDraftMode || hasUploadedFiles || !availableDatabaseSources.length
        }
        tooltip={tooltipText()}
      />
      <ResearchKnowledgeSourceSelector
        showDialog={showResearchDialog}
        setShowDialog={setShowResearchDialog}
        databaseSource={researchDialogArea}
        maxTopLevelSelections={getMaxTopLevelSelections(
          researchDialogArea,
          userInfo
        )}
      />
    </div>
  )
}

export default AssistantResearchInput
