import React from 'react'

import { cn } from 'utils/utils'

interface StatusBadgeProps {
  label: string
  className?: string
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ label, className }) => {
  const labelLowerCase = label.toLowerCase()

  return (
    <div
      className={cn(
        'inline-flex shrink-0 items-center rounded border border-transparent bg-accent-hover px-2.5 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
        {
          'text-destructive-inverse border-transparent bg-destructive text-destructive':
            labelLowerCase.includes('s met') ||
            labelLowerCase.includes('d met'),
          'text-success-inverse border-transparent bg-success text-[#16a34a]':
            labelLowerCase.includes('not met'),
          'bg-[#dbeafe] text-[#2563eb]': labelLowerCase.includes('more info'),
        },
        className
      )}
    >
      {label}
    </div>
  )
}

export default StatusBadge
