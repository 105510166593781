import React, { useRef, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'

import { cn } from 'utils/utils'

import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import WorkflowInput, {
  WorkflowInputHeader,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { Button } from 'components/ui/button'
import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
  CommandEmpty,
  CommandGroup,
} from 'components/ui/command'
import { ScrollArea } from 'components/ui/scroll-area'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowSelectThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.SELECT
> = ({
  blockParams,
  outputData,
  completionStatus,
  setIsEditing,
  isEditing,
  paramStatus,
  loadingStates,
}) => {
  const streamInProgress = useAssistantWorkflowStore(
    useShallow((state) => state.streamInProgress)
  )
  const { headerText } = blockParams
  const isCompleted = !!outputData

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <LoadingState
          isCompleted={paramStatus === 'COMPLETED'}
          states={loadingStates}
          paramStatus={paramStatus}
        />
        <AssistantWorkflowThreadText
          completionStatus={completionStatus}
          text={headerText}
        />
      </AssistantWorkflowHarveyComponent>

      {isCompleted && (
        <AssistantWorkflowYouComponent
          onEditToggle={
            streamInProgress ? undefined : () => setIsEditing(!isEditing)
          }
        >
          <AssistantWorkflowThreadText
            completionStatus={completionStatus}
            text={outputData.selected}
          />
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowSelectInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.SELECT
> = ({
  blockParams,
  onCompleted,
  outputData,
  isEditing,
  setIsEditing,
  onUpdated,
}) => {
  const { emptyText, inputLabel, options } = blockParams
  const isCompleted = !!outputData && !isEditing
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState<string>(outputData?.selected || '')
  const [search, setSearch] = useState('')
  const [isListOpen, setIsListOpen] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)
  const isStreaming = useAssistantWorkflowStore(
    (state) => state.streamInProgress
  )

  const handleSelect = (value: string) => {
    setIsListOpen(false)
    if (inputRef.current) inputRef.current.focus()
    if (value !== selected) {
      setSelected(value)
      setSearch(value)
    }
  }

  const handleSubmit = () => {
    if (!selected) return
    setIsLoading(true)

    if (isEditing) {
      onUpdated({ selected })
      setIsEditing(false)
    } else {
      onCompleted({ selected })
    }
  }

  const handleCancelEdit = () => {
    setIsEditing(false)
  }

  const handleValueChange = (value: string) => {
    setSearch(value)
    setSelected('')
    setIsListOpen(true)
  }

  return (
    <WorkflowInput>
      <Command className="items-stretch bg-accent">
        <div
          className={cn(
            'overflow-hidden',
            isListOpen ? 'mb-3  opacity-100' : 'mb-0 hidden opacity-0'
          )}
        >
          <WorkflowInputHeader className="rounded-b-none">
            {inputLabel}
          </WorkflowInputHeader>
          <CommandList className="rounded-b-md bg-primary p-2">
            <ScrollArea className="h-[228px]">
              <CommandEmpty>{emptyText ?? 'No options found.'}</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    data-testid="select-option"
                    className={cn(
                      "-ml-1 w-full py-0 aria-[selected='true']:bg-secondary [&>*]:last:border-0",
                      selected === option && 'bg-accent'
                    )}
                    key={option}
                    value={option}
                    onSelect={handleSelect}
                    disabled={isCompleted || isLoading}
                  >
                    <div className="flex size-full items-center justify-between border-b-[.5px] py-3 text-xs text-primary">
                      {option}
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </div>

        <div className="flex min-h-8 w-full items-center justify-between pl-3">
          <CommandInput
            ref={inputRef}
            placeholder={blockParams.placeholder}
            containerClassName="w-full"
            className="h-8 w-full p-0"
            showSearchStyling={false}
            disabled={isCompleted || isLoading}
            value={search}
            onValueChange={handleValueChange}
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return
              if (!selected) setIsListOpen(true)
              else handleSubmit()
            }}
          />
          <div className="flex space-x-2">
            {isEditing && (
              <Button
                onClick={handleCancelEdit}
                tooltip="Cancel edit"
                variant="outline"
              >
                Cancel
              </Button>
            )}

            <Button
              isLoading={isLoading || isCompleted}
              disabled={!selected || isLoading || isCompleted || isStreaming}
              onClick={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit()
                }
              }}
            >
              Send
            </Button>
          </div>
        </div>
      </Command>
    </WorkflowInput>
  )
}

export const AssistantWorkflowSelectExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.SELECT
> = ({ blockParams, outputData }) => {
  const { headerText } = blockParams

  return (
    <>
      <div>{headerText}</div>
      <div>{outputData?.selected}</div>
    </>
  )
}
