import { Auth0Client } from '@auth0/auth0-spa-js'

import { IntegrationType } from 'openapi/models/IntegrationType'
import Services from 'services'

import { authDomain, authClientIdSyncly, authAudience } from 'utils/server-data'

export interface SynclyFile {
  id: string
  name: string
  type: string
  path: string
  url: string
  doc_as_pdf_url: string
  size?: number
  content_type?: string // used to display icon
  source?: string
}

interface SynclyConnectorIdResponse {
  connectorId: string
}

interface SynclyTenantResponse {
  synclyTenantUrl: string
}

export enum SynclySettingsOpenStateType {
  AUTH = 'auth',
  EDIT = 'edit',
}

export interface SynclySettingsOpenState {
  integrationType: IntegrationType
  settingsType: SynclySettingsOpenStateType
  onSuccess?: () => void
}

export async function authenticateSynclyAuthClient(email?: string) {
  const secondaryAuth0Client = new Auth0Client({
    domain: authDomain || '',
    clientId: authClientIdSyncly,
    authorizationParams: {
      login_hint: email,
      audience: authAudience,
    },
  })
  try {
    const token = await secondaryAuth0Client.getTokenSilently({
      authorizationParams: { audience: authAudience },
    })
    return token
  } catch (e) {
    await secondaryAuth0Client.loginWithPopup({
      authorizationParams: { audience: authAudience },
    })
    return await secondaryAuth0Client.getTokenSilently()
  }
}

export const getSynclyTenantUrl = async (workspaceId: number) => {
  const response = await Services.Backend.Get<SynclyTenantResponse>(
    `syncly/tenant/${workspaceId}`,
    { throwOnError: true }
  )
  return response.synclyTenantUrl
}

export async function getSynclyConnectorId(
  connectorType: IntegrationType,
  harveyUserToken: string
) {
  const synclyConnectorId =
    await Services.Backend.Post<SynclyConnectorIdResponse>(
      'syncly/connector_id',
      { authToken: harveyUserToken, connectorType },
      { throwOnError: true }
    )
  return synclyConnectorId.connectorId
}
