import React from 'react'
import { useParams } from 'react-router-dom'

import { Plus } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'

export const ManageWorkflowButton = () => {
  const { id: workflowId } = useParams()
  const [reset, currentEvent] = useAssistantWorkflowStore(
    useShallow((state) => [state.reset, state.currentEvent])
  )

  const disableRestart =
    (currentEvent?.steps ?? []).length === 1 &&
    currentEvent?.steps[0].paramStatus === 'PENDING'

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <Icon icon={Plus} />
          <p className="ml-2 hidden sm:block">New Thread</p>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem asChild>
          <Link
            className={cn(
              'flex items-center text-sm',
              disableRestart && 'cursor-not-allowed opacity-50'
            )}
            onClick={(e) => {
              if (disableRestart) {
                e.preventDefault()
                return
              }
              reset()
            }}
            to={`/assistant/workflows/${workflowId}`}
          >
            Restart workflow
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link
            className="flex items-center text-sm"
            to="/workflows?tab=assistant"
          >
            Choose another workflow
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link className="flex items-center text-sm" to="/assistant">
            New Assistant thread
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
