import React, { useMemo, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EventStatus } from 'openapi/models/EventStatus'

import { RenderWorkflowComponent } from './assistant-block-renderer'
import { useAssistantWorkflowStore } from './stores/assistant-workflow-store'
import { isFrontendBlock, stepBlockIdToWorkflowBlockDefinition } from './utils'

const EDITING_OPACITY = 0.3

export type StepCompletionHandler = (params: {
  blockId: string
  stepId: string | null
  result: any
  stepIdx: number
}) => void

export const useAssistantWorkflowComponents = (params: {
  handleCurrentStepCompletion: StepCompletionHandler
  handleExistingStepUpdated: (
    blockId: string,
    stepId: string | null,
    result: any
  ) => void
  handleExistingStepDeletion: (stepId: string | null) => void
  handleExistingStepRegenerate: (stepId: string | null) => void
  handleCurrentStepCancel: () => void
  exportComponents: React.MutableRefObject<HTMLDivElement[]>
}) => {
  const {
    handleCurrentStepCompletion,
    handleExistingStepUpdated,
    handleExistingStepDeletion,
    handleExistingStepRegenerate,
    handleCurrentStepCancel,
    exportComponents,
  } = params

  const [workflowDefinition, currentEvent] = useAssistantWorkflowStore(
    useShallow((state) => [state.workflowDefinition, state.currentEvent])
  )

  const [editingStepIdx, setEditingStepIdx] = useState<number | null>(null)

  const { completedSteps, inProgressSteps } = useMemo(() => {
    const steps = currentEvent?.steps || []

    const firstNonCompletedStepIdx = steps.findIndex(
      (step) => step.paramStatus !== EventStatus.COMPLETED
    )

    if (firstNonCompletedStepIdx === -1) {
      return {
        completedSteps: steps,
        inProgressSteps: [],
      }
    }

    return {
      completedSteps: steps.slice(0, firstNonCompletedStepIdx),
      inProgressSteps: steps.slice(firstNonCompletedStepIdx),
    }
  }, [currentEvent])

  /**
   * Build the completed steps' components, only depending on
   * completedSteps, workflowDefinition, and the handler.
   * If in-progress steps change, this memoized result will **not** update.
   */
  const completedStepsComponents = useMemo(() => {
    return completedSteps.map((step) => {
      const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
        workflowDefinition,
        step.blockId
      )
      if (!isFrontendBlock(blockDefinition)) {
        console.warn('Unexpected block definition', blockDefinition)
        return null
      }

      // Apply opacity if editingStepIdx is set and this step is below it
      const shouldApplyOpacity =
        editingStepIdx !== null && step.stepIdx > editingStepIdx
      const opacityStyle = shouldApplyOpacity
        ? { opacity: EDITING_OPACITY }
        : {}

      return (
        <div style={opacityStyle} key={`completed-${step.stepIdx}`}>
          <RenderWorkflowComponent
            stepIdx={step.stepIdx}
            stepId={step.stepId || null}
            blockType={blockDefinition.blockType as any}
            blockParams={step.blockParams as any}
            outputData={step.outputData as any}
            renderType="thread"
            onUpdated={(result: unknown) =>
              handleExistingStepUpdated(
                step.blockId,
                step.stepId || null,
                result
              )
            }
            onCompleted={(result: unknown) => {
              handleCurrentStepCompletion({
                blockId: step.blockId,
                stepId: step.stepId || null,
                result,
                stepIdx: step.stepIdx,
              })
            }}
            onCancel={() => handleCurrentStepCancel()}
            onDeleted={() => handleExistingStepDeletion(step.stepId || null)}
            onRegenerate={() =>
              handleExistingStepRegenerate(step.stepId || null)
            }
            isEditing={editingStepIdx === step.stepIdx}
            setIsEditing={(isEditing: boolean) =>
              setEditingStepIdx(isEditing ? step.stepIdx : null)
            }
            completionStatus={step.completionStatus as EventStatus}
            paramStatus={step.paramStatus as EventStatus}
            loadingStates={step.loadingStates}
            workflowName={workflowDefinition?.name ?? ''}
            feedback={step.feedback}
            exportComponents={exportComponents}
          />
        </div>
      )
    })
    // XXX: completedSteps is a new array every time, and will trigger a re-render every time. It's needed so that
    // step_id is populated once the step is completed. Find a more efficient way to do this.
  }, [
    completedSteps,
    workflowDefinition,
    handleCurrentStepCompletion,
    handleExistingStepUpdated,
    handleExistingStepDeletion,
    handleExistingStepRegenerate,
    handleCurrentStepCancel,
    exportComponents,
    editingStepIdx,
  ])

  /**
   * Build the in-progress steps' components, which *will* re-render
   * if in-progress steps change, but that won't affect completedStepsComponents.
   *
   * Also prepare an InputComponent for the "current" or last in-progress step.
   */
  const inProgressStepsComponents = useMemo(() => {
    return inProgressSteps.map((step) => {
      const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
        workflowDefinition,
        step.blockId
      )
      if (!isFrontendBlock(blockDefinition)) {
        console.warn('Unexpected block definition', blockDefinition)
        return
      }

      // Apply opacity if editingStepIdx is set and this step is below it
      const shouldApplyOpacity =
        editingStepIdx !== null && step.stepIdx > editingStepIdx
      const opacityStyle = shouldApplyOpacity
        ? { opacity: EDITING_OPACITY }
        : {}

      // Thread view for each in-progress step
      return (
        <div style={opacityStyle} key={`in-progress-${step.stepIdx}`}>
          <RenderWorkflowComponent
            stepId={step.stepId || null}
            stepIdx={step.stepIdx}
            blockType={blockDefinition.blockType as any}
            blockParams={step.blockParams as any}
            outputData={step.outputData as any}
            renderType="thread"
            onUpdated={(result: unknown) =>
              handleExistingStepUpdated(
                step.blockId,
                step.stepId || null,
                result
              )
            }
            onCompleted={(result: unknown) => {
              handleCurrentStepCompletion({
                blockId: step.blockId,
                stepId: step.stepId || null,
                result,
                stepIdx: step.stepIdx,
              })
            }}
            onDeleted={() => handleExistingStepDeletion(step.stepId || null)}
            onRegenerate={() =>
              handleExistingStepRegenerate(step.stepId || null)
            }
            isEditing={editingStepIdx === step.stepIdx}
            setIsEditing={(isEditing: boolean) =>
              setEditingStepIdx(isEditing ? step.stepIdx : null)
            }
            onCancel={() => handleCurrentStepCancel()}
            completionStatus={step.completionStatus as EventStatus}
            paramStatus={step.paramStatus as EventStatus}
            loadingStates={step.loadingStates}
            workflowName={workflowDefinition?.name ?? ''}
            feedback={undefined}
            exportComponents={exportComponents}
          />
        </div>
      )
    })
  }, [
    inProgressSteps,
    workflowDefinition,
    handleCurrentStepCompletion,
    handleExistingStepUpdated,
    handleExistingStepDeletion,
    handleExistingStepRegenerate,
    handleCurrentStepCancel,
    exportComponents,
    editingStepIdx,
  ])

  const InputComponent = useMemo(() => {
    // If editing a step, use that step for the InputComponent
    if (editingStepIdx !== null) {
      // Find the step being edited (could be in either completed or in-progress steps)
      const allSteps = [...completedSteps, ...inProgressSteps]
      const editingStep = allSteps.find(
        (step) => step.stepIdx === editingStepIdx
      )

      if (editingStep) {
        const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
          workflowDefinition,
          editingStep.blockId
        )

        return (
          <RenderWorkflowComponent
            key={`editing-input-${editingStep.stepIdx}`}
            stepId={editingStep.stepId || null}
            stepIdx={editingStep.stepIdx}
            blockType={blockDefinition.blockType as any}
            blockParams={editingStep.blockParams as any}
            outputData={editingStep.outputData as any}
            renderType="input"
            onUpdated={(result: unknown) =>
              handleExistingStepUpdated(
                editingStep.blockId,
                editingStep.stepId || null,
                result
              )
            }
            onCompleted={(result: unknown) => {
              handleCurrentStepCompletion({
                blockId: editingStep.blockId,
                stepId: editingStep.stepId || null,
                result,
                stepIdx: editingStep.stepIdx,
              })
            }}
            onDeleted={() =>
              handleExistingStepDeletion(editingStep.stepId || null)
            }
            onRegenerate={() =>
              handleExistingStepRegenerate(editingStep.stepId || null)
            }
            onCancel={() => handleCurrentStepCancel()}
            isEditing={editingStepIdx === editingStep.stepIdx}
            setIsEditing={(isEditing: boolean) =>
              setEditingStepIdx(isEditing ? step.stepIdx : null)
            }
            completionStatus={editingStep.completionStatus as EventStatus}
            paramStatus={editingStep.paramStatus as EventStatus}
            loadingStates={editingStep.loadingStates}
            workflowName={workflowDefinition?.name ?? ''}
            feedback={undefined}
            exportComponents={exportComponents}
          />
        )
      }
    }

    // Default behavior - use the last in-progress step or last completed step
    const step =
      inProgressSteps.length > 0
        ? inProgressSteps[inProgressSteps.length - 1]
        : completedSteps[completedSteps.length - 1]

    if (!step) return null

    const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
      workflowDefinition,
      step.blockId
    )

    return (
      <RenderWorkflowComponent
        key={`in-progress-input-${step.stepIdx}`}
        stepId={step.stepId || null}
        stepIdx={step.stepIdx}
        blockType={blockDefinition.blockType as any}
        blockParams={step.blockParams as any}
        outputData={step.outputData as any}
        renderType="input"
        onUpdated={(result: unknown) =>
          handleExistingStepUpdated(step.blockId, step.stepId || null, result)
        }
        onCompleted={(result: unknown) => {
          handleCurrentStepCompletion({
            blockId: step.blockId,
            stepId: step.stepId || null,
            result,
            stepIdx: step.stepIdx,
          })
        }}
        onDeleted={() => handleExistingStepDeletion(step.stepId || null)}
        onRegenerate={() => handleExistingStepRegenerate(step.stepId || null)}
        onCancel={() => handleCurrentStepCancel()}
        isEditing={editingStepIdx === step.stepIdx}
        setIsEditing={(isEditing: boolean) =>
          setEditingStepIdx(isEditing ? step.stepIdx : null)
        }
        completionStatus={step.completionStatus as EventStatus}
        paramStatus={step.paramStatus as EventStatus}
        loadingStates={step.loadingStates}
        workflowName={workflowDefinition?.name ?? ''}
        feedback={undefined}
        exportComponents={exportComponents}
      />
    )
    // XXX: Doing completedSteps.length as a dependency rather than completedSteps
    // This is because completedSteps is a new array every time, so it would trigger
    // a re-render every time, which is not necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inProgressSteps,
    completedSteps,
    workflowDefinition,
    handleCurrentStepCompletion,
    handleExistingStepUpdated,
    handleExistingStepDeletion,
    handleExistingStepRegenerate,
    exportComponents,
    editingStepIdx,
  ])

  return {
    completedStepsComponents,
    inProgressStepsComponents,
    InputComponent,
    editingStepIdx,
    setEditingStepIdx,
  }
}
