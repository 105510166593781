import React from 'react'

import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import ExplorerItem from 'components/research/explorer-item'
import { Checkbox } from 'components/ui/checkbox'
import { ScrollArea } from 'components/ui/scroll-area'
import { SkeletonBlock } from 'components/ui/skeleton'

interface ExplorerColumnProps {
  taxonomy: ResearchFilter[]
  filters: ResearchFilter[]
  depth: number
  openFilters: ResearchFilter[]
  setOpenFilters: (filters: ResearchFilter[]) => void
  selectedFilters: ResearchFilter[]
  setSelectedFilters: (filters: ResearchFilter[]) => void
  isLoading: boolean
  researchArea: ResearchArea
  maxTopLevelSelections?: number
}

const ExplorerColumn: React.FC<ExplorerColumnProps> = ({
  taxonomy,
  filters,
  depth,
  openFilters,
  setOpenFilters,
  selectedFilters,
  setSelectedFilters,
  isLoading,
  researchArea,
  maxTopLevelSelections,
}) => {
  return (
    <ScrollArea className="h-full w-72 border-r">
      <div className="h-2" />
      <div className="space-y-px">
        {isLoading
          ? [...new Array(5)].map((_, i) => (
              <div
                className="mx-4 flex h-8 items-center space-x-2 p-1.5 pl-2"
                key={i}
              >
                <Checkbox disabled />
                <SkeletonBlock className="h-4 w-4/5" />
              </div>
            ))
          : filters.map((filter) => (
              <ExplorerItem
                taxonomy={taxonomy}
                filter={filter}
                key={filter.id}
                depth={depth}
                openFilters={openFilters}
                setOpenFilters={setOpenFilters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                researchArea={researchArea}
                maxTopLevelSelections={maxTopLevelSelections}
              />
            ))}
      </div>
      <div className="h-2" />
    </ScrollArea>
  )
}

export default ExplorerColumn
