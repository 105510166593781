import * as React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage, displayWarningMessage } from 'utils/toast'
import {
  QUERY_MUST_HAVE_X_LEN_HELP_TEXT,
  UPLOAD_DOCUMENT_HELP_TEXT,
} from 'utils/tooltip-texts'
import { cn, isAnchorOrButton, isInPopup } from 'utils/utils'

import AssistantModeSelect, {
  AssistantMode,
} from 'components/assistant/components/assistant-mode-select'
import AssistantFilesInput from 'components/assistant/features/composer/assistant-files-input'
import AssistantResearchInput from 'components/assistant/features/composer/assistant-research-input'
import AssistantTipsSheet from 'components/assistant/features/composer/assistant-tips-sheet'
import AssistantVaultInput from 'components/assistant/features/composer/assistant-vault-input'
import AssistantKnowledgeSourcesRenderer from 'components/assistant/features/composer/knowledge-sources-renderer'
import { AssistantChatStreamHandler } from 'components/assistant/hooks/use-assistant-chat'
import { AssistantDraftStreamHandler } from 'components/assistant/hooks/use-assistant-draft'
import { AssistantChatLocationState } from 'components/assistant/hooks/use-location-chat-query'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { forkEventDocuments } from 'components/assistant/utils/assistant-api'
import {
  FORK_EVENT_STATE_KEY,
  PROMPT_PARAM,
  getQueryLimit,
  isQueryValid,
} from 'components/assistant/utils/assistant-helpers'
import { FileSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { useKnowledgeSources } from 'components/assistant/utils/assistant-knowledge-sources'
import { MIN_QUERY_LENGTH } from 'components/assistant/utils/constants'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import QueryLimitTooltip from 'components/common/query-limit-tooltip/query-limit-tooltip'
import useChangeHistory from 'components/common/use-change-history'
import { useNavigationQueryState } from 'components/common/use-navigation-query-state'
import { Textarea } from 'components/ui/text-area'

import { AssistantPromptButtonGroup } from './assistant-library-prompt-button-group'

interface Props {
  useChat?: AssistantChatStreamHandler
  useDraft?: AssistantDraftStreamHandler
  useChatWorkflow?: boolean
}

const AssistantComposer = ({ useChat, useDraft, useChatWorkflow }: Props) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const isDocumentUser = userInfo.isDocumentUser

  const [
    query,
    eventId,
    documentsUploading,
    documents,
    setCreatedAt,
    setDocuments,
    setEventId,
    setQuery,
    setKnowledgeSource,
    knowledgeSource,
    mode,
    setMode,
    setIsForking,
    isForking,
    isAskHarveyDisabled,
  ] = useAssistantStore(
    useShallow((s) => [
      s.query,
      s.eventId,
      s.documentsUploading,
      s.documents,
      s.setCreatedAt,
      s.setDocuments,
      s.setEventId,
      s.setQuery,
      s.setKnowledgeSource,
      s.knowledgeSource,
      s.mode,
      s.setMode,
      s.setIsForking,
      s.isForking,
      s.isAskHarveyDisabled,
    ])
  )

  const hasDocuments =
    (Array.isArray(documents) && documents.length > 0) ||
    (Array.isArray(documentsUploading) && documentsUploading.length > 0)

  const queryLimit = getQueryLimit(hasDocuments)

  const [queryPreview, setQueryPreview] = React.useState<string | null>(null)
  const handleSetQuery = React.useCallback(
    (q: string) => {
      if (q.length > queryLimit && q.length >= query.length) {
        displayWarningMessage(
          `Query length cannot exceed ${queryLimit} characters`
        )
      } else {
        setQuery(q)
        setQueryPreview('')
      }
    },
    [query, queryLimit, setQuery, setQueryPreview]
  )

  const navigate = useNavigateWithQueryParams()

  const { eventId: currentEventId } = useParams()
  const authCallback = React.useCallback(
    (eventId: string) => {
      if (eventId && eventId !== currentEventId) {
        navigate(`/assistant/${mode}/${eventId}`, {}, [PROMPT_PARAM])
      }
      setQuery('')
      setQueryPreview('')
    },
    [mode, navigate, currentEventId, setQuery, setQueryPreview]
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const isCopilotPlugin = searchParams.get('isCopilotPlugin') === 'true'

  const handleAsk = React.useCallback(
    async (prompt: string) => {
      setCreatedAt(new Date())
      // Navigate early if we have documents that are still uploading.
      // If we do this, then don't call navigate again after socket auth.
      let socketAuthCallback: ((eventId: string) => void) | undefined
      if (eventId) authCallback(eventId)
      else socketAuthCallback = authCallback

      if (mode === AssistantMode.ASSIST) {
        if (!useChat) throw new Error('useChat is required')

        if (useChatWorkflow) {
          const locationState: AssistantChatLocationState = {
            query: prompt,
            eventId,
            knowledgeSource,
          }

          navigate('/assistant/workflows/chat', {
            state: locationState,
          })
        } else {
          await useChat.initChat(prompt, eventId, socketAuthCallback, {
            ...(isCopilotPlugin && { is_via_copilot: true }),
          })
        }
      } else {
        if (!useDraft) throw new Error('useDraft is required')
        // Reset any knowledge sources, which aren't available in Draft
        setKnowledgeSource(null)
        await useDraft.initDraft(prompt, eventId, socketAuthCallback)
      }
    },
    [
      eventId,
      mode,
      authCallback,
      useChat,
      useDraft,
      isCopilotPlugin,
      setCreatedAt,
      setKnowledgeSource,
      knowledgeSource,
      navigate,
      useChatWorkflow,
    ]
  )

  const handleAskClick = () => {
    void handleAsk(query)
  }

  const isRequiredDocumentMissing = !userInfo.IsBaseUser && !hasDocuments
  const localIsAskHarveyDisabled =
    !isQueryValid(query, queryLimit) ||
    isRequiredDocumentMissing ||
    isAskHarveyDisabled ||
    isForking

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  const handleSelectPrompt = (prompt: string) => {
    setValue(prompt)
    setTimeout(() => textareaRef.current?.focus(), 0)
  }

  const { handleKeyDown, setValue } = useChangeHistory(handleSetQuery)

  const navigationState = useNavigationQueryState()
  const initializedNavState = React.useRef(false)
  React.useEffect(() => {
    if (initializedNavState.current) return
    if (navigationState?.query) {
      setValue(navigationState.query)
    }
    if (navigationState?.mode) {
      setMode(navigationState.mode as AssistantMode)
    }
    if (navigationState?.[FORK_EVENT_STATE_KEY]) {
      const forkEvent = async () => {
        if (!navigationState[FORK_EVENT_STATE_KEY]) return
        setIsForking(true)
        const forkedEvent = await forkEventDocuments(
          navigationState[FORK_EVENT_STATE_KEY]
        )
        setEventId(forkedEvent.eventId)
        setDocuments(forkedEvent.documents)
      }
      forkEvent()
        .catch(() =>
          displayErrorMessage(
            'Sorry, something wrong creating a thread with documents'
          )
        )
        .finally(() => setIsForking(false))
    }
    if (navigationState?.knowledge) {
      setKnowledgeSource(navigationState.knowledge)
    }
    if (navigationState) {
      initializedNavState.current = true
    }
  }, [
    navigationState,
    setDocuments,
    setEventId,
    setKnowledgeSource,
    setValue,
    setMode,
    setIsForking,
  ])

  const placeholder =
    queryPreview ||
    (mode === AssistantMode.DRAFT
      ? 'Ask Harvey to write a draft…'
      : 'Ask Harvey a question with documents or knowledge sources…')

  const getDisabledText = () => {
    if (query.trim().length < MIN_QUERY_LENGTH) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`at least ${MIN_QUERY_LENGTH}`)
    } else if (query.trim().length > queryLimit) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`fewer than ${queryLimit}`)
    } else if (isRequiredDocumentMissing) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (isForking) {
      return 'Waiting for documents to be duplicated'
    } else {
      return ''
    }
  }

  const handleActionsWhiteSpaceClick = (e: React.MouseEvent) => {
    const target = e.target as Element | null
    if (isAnchorOrButton(target) || isInPopup(target)) {
      e.stopPropagation()
    } else {
      textareaRef.current?.focus()
    }
  }

  const queryValue = queryPreview ? '' : query
  const textareaDisabled = !!queryPreview

  const bannerText =
    mode === AssistantMode.ASSIST
      ? 'Quickly search, analyze, or understand material, then ask follow-up questions'
      : 'Generate content like emails, contract clauses, or sections of briefs, then revise your draft'

  const eventKind =
    mode === AssistantMode.DRAFT
      ? EventKind.ASSISTANT_DRAFT
      : EventKind.ASSISTANT_CHAT

  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null)

  useMount(async () => {
    if (knowledgeSource) {
      textareaRef.current?.focus()
    }

    // Automatically run a query for a prompt provided in query params
    const prompt = searchParams.get(PROMPT_PARAM)

    if (prompt === null) return

    // setTimeout is needed in development due to StrictMode. The second call to useMount interrupts the first call's
    // webSocket connection, and the first setQuery(prompt) call is cleared out by the second component mount.
    timeoutRef.current = setTimeout(async () => {
      if (!isQueryValid(prompt, queryLimit)) {
        setQuery(prompt)
        searchParams.delete(PROMPT_PARAM)
        setSearchParams(searchParams)
        return
      }

      // Delete the prompt search param in authCallback's call to navigate.
      await handleAsk(prompt)
    }, 100)
  })

  useUnmount(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  })

  const knowledgeSources = useKnowledgeSources(userInfo)

  const fileSources = React.useMemo(
    () =>
      Object.values(FileSource).filter((source) =>
        knowledgeSources.has(source)
      ),
    [knowledgeSources]
  )

  const internalFileSources = React.useMemo(
    () => fileSources.filter((source) => source !== FileSource.FILES),
    [fileSources]
  )

  const vaultUploadEnabled = React.useMemo(
    () => internalFileSources.includes(FileSource.VAULT),
    [internalFileSources]
  )

  return (
    <div className="rounded bg-primary" id="assistant-input">
      <div className="flex items-end p-0">
        <div className="relative -bottom-px px-4">
          <AssistantModeSelect mode={mode} setMode={setMode} />
        </div>
      </div>
      <div
        className={cn('relative rounded-lg bg-accent p-0', {
          'mb-2': !isDocumentUser,
        })}
        id="assistant-text-input"
      >
        <div className="flex w-full items-center justify-between border-b-[0.5px] px-4 py-2">
          <p className="text-xs">{bannerText}</p>
          <AssistantTipsSheet
            onTriggerClick={() => trackEvent('Assistant Tips Viewed')}
          />
        </div>
        <Textarea
          className={cn(
            'max-h-96 min-h-28 resize-none rounded-none border-0 bg-transparent p-6 pb-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
            {
              'disabled:cursor-default': queryPreview,
            }
          )}
          disabled={textareaDisabled}
          isFluid={!queryPreview}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={textareaRef}
          value={queryValue}
        />
        <div className="absolute right-4 -mt-1">
          <QueryLimitTooltip query={query} queryLimit={queryLimit} />
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="flex w-full cursor-text justify-between border-b-[0.5px] p-4 pt-5"
          onClick={handleActionsWhiteSpaceClick}
        >
          <AssistantPromptButtonGroup
            query={query}
            eventKind={eventKind}
            setQuery={handleSelectPrompt}
            setQueryPreview={setQueryPreview}
          />
          <AskHarveyButton
            className="pointer-events-auto ml-auto w-auto shrink-0 whitespace-nowrap font-semibold"
            id="assistant-submit"
            handleSubmit={handleAskClick}
            tooltip={getDisabledText()}
            disabled={localIsAskHarveyDisabled}
            inputRef={textareaRef}
            shouldEnforceClientMatterSelection
          />
        </div>
        <div id="assistant-document-input" className="p-0">
          <div className="flex gap-3 p-4">
            <AssistantFilesInput />
            {vaultUploadEnabled && <AssistantVaultInput />}
            <AssistantResearchInput />
          </div>
        </div>
        <AssistantKnowledgeSourcesRenderer />
      </div>
    </div>
  )
}

export default AssistantComposer
