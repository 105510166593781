import { WorkflowBlockDefinition } from 'openapi/models/WorkflowBlockDefinition'
import { WorkflowDefinition } from 'openapi/models/WorkflowDefinition'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'

export const isFrontendBlock = (block: WorkflowBlockDefinition) => {
  return (
    Object.values(WorkflowInputComponentBlocks).includes(
      block.blockType as WorkflowInputComponentBlocks
    ) ||
    Object.values(WorkflowRenderComponentBlocks).includes(
      block.blockType as WorkflowRenderComponentBlocks
    )
  )
}

export const stepBlockIdToWorkflowBlockDefinition = (
  workflowDefinition: WorkflowDefinition | null,
  blockId: string
): WorkflowBlockDefinition => {
  if (blockId === WorkflowRenderComponentBlocks.LOADING_STATE) {
    return {
      id: blockId,
      blockType: blockId,
      blockParams: {},
    }
  }
  const res = workflowDefinition?.steps.find((step) => step.id === blockId)

  if (!res) {
    throw new Error(`Could not find block definition for blockId: ${blockId}`)
  }
  return res
}
