import React, { useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage } from 'utils/toast'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { BaseAppPath } from 'components/base-app-path'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import VaultFileExplorerInModal from 'components/vault/components/file-explorer/vault-file-explorer-in-modal'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  projectsPath,
  queriesPath,
  QueryQuestion,
  UNTITLED_QUERY_TITLE,
} from 'components/vault/utils/vault'
import {
  getSelectedFiles,
  columnToQueryQuestion,
  runReviewQueryFromWorkflow,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'
import { WorkflowCategory } from 'components/workflows/workflow-card'

/**
 * Dialog contents for adding files to an existing or new blank workflow
 */
const VaultWorkflowAddFiles = ({
  projectId,
  onBackButtonClicked,
  buttonLabel,
  preselectFileIds,
  handleSubmitVault,
  handleSubmitAssistant,
  assistantMode,
}: {
  projectId: string
  onBackButtonClicked: () => void
  buttonLabel: string
  preselectFileIds: Set<string>
  handleSubmitVault?: (selectedFileIds: string[]) => Promise<void>
  handleSubmitAssistant?: (
    selectedFileIds: string[],
    mode: AssistantMode
  ) => void
  assistantMode?: AssistantMode
}) => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const queryClient = useQueryClient()
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)
  const { trackEvent } = useAnalytics()

  const [
    currentProject,
    currentProjectMetadata,
    folderIdToVaultFolder,
    fileIdToVaultFile,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentProject,
      state.currentProjectMetadata,
      state.folderIdToVaultFolder,
      state.fileIdToVaultFile,
    ])
  )

  const [
    showDocClassificationMismatchWarning,
    selectedWorkflow,
    selectedFilesForWorkflow,
    setWorkflowModalState,
    setSelectedFilesForWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.showDocClassificationMismatchWarning,
      state.selectedWorkflow,
      state.selectedFilesForWorkflow,
      state.setWorkflowModalState,
      state.setSelectedFilesForWorkflow,
    ])
  )

  const [
    setPendingQueryQuestions,
    setPendingQueryFileIds,
    setWorkflow,
    setQueryId,
    updateQueryTitle,
    setIsRunButtonLoading,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.setPendingQueryQuestions,
      state.setPendingQueryFileIds,
      state.setWorkflow,
      state.setQueryId,
      state.updateQueryTitle,
      state.setIsRunButtonLoading,
    ])
  )
  const selectedClientMatter = useClientMattersStore(
    (s) => s.selectedClientMatter
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const selectedFiles = getSelectedFiles(selectedFilesForWorkflow)
    const selectedFileIds = selectedFiles.map((file) => file.id)
    if (selectedFileIds.length === 0) {
      displayErrorMessage('No files selected for query')
      setIsSubmitting(false)
      return
    }

    if (handleSubmitAssistant && assistantMode) {
      handleSubmitAssistant(selectedFileIds, assistantMode)
      setWorkflowModalState(WorkflowModalState.None)
      setIsSubmitting(false)
      return
    }

    if (handleSubmitVault) {
      await handleSubmitVault(selectedFileIds)
      setWorkflowModalState(WorkflowModalState.None)
      setIsSubmitting(false)
      return
    }

    setPendingQueryFileIds(selectedFileIds)
    if (selectedWorkflow) {
      trackEvent('Workflow Confirmed', {
        workflow_id: selectedWorkflow.id,
        workflow_name: selectedWorkflow.name,
        workflow_type: WorkflowCategory.VAULT,
        ...(showDocClassificationMismatchWarning && {
          mismatched_documents: true,
        }),
      })

      setWorkflow(selectedWorkflow)

      const pendingQueryQuestions = [...selectedWorkflow.columns]
        .sort((a, b) => a.displayId - b.displayId)
        .map(columnToQueryQuestion) as QueryQuestion[]

      setPendingQueryQuestions(pendingQueryQuestions)

      const clientMatterId: string | undefined =
        userInfo.IsVaultProjectClientMatterUser
          ? currentProjectMetadata.clientMatterId
          : userInfo.isClientMattersReadUser
          ? selectedClientMatter?.id
          : undefined

      await runReviewQueryFromWorkflow({
        currentProject,
        currentProjectMetadata,
        folderIdToVaultFolder,
        fileIdToVaultFile,
        selectedRows: [],
        pendingQueryFileIds: selectedFileIds,
        pendingQueryQuestions,
        workflow: selectedWorkflow,
        clientMatterId,
        queryClient,
        setIsRunButtonLoading,
        recordQuerySubmitted,
        setQueryId,
        setWorkflow,
        setWorkflowModalState,
        setIsSubmitting,
        navigate,
      })
    } else {
      trackEvent('Vault New Query Submitted')
      setIsSubmitting(false)
      updateQueryTitle(UNTITLED_QUERY_TITLE)
      navigate(
        `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}new`
      )
      setWorkflowModalState(WorkflowModalState.None)
    }
  }

  const numSelectedFiles = useMemo(() => {
    const selectedFiles = getSelectedFiles(selectedFilesForWorkflow)
    return selectedFiles.length
  }, [selectedFilesForWorkflow])

  return (
    <>
      <DialogHeader className="h-9 shrink-0 border-b border-b-primary px-6">
        <DialogTitle>Choose files</DialogTitle>
      </DialogHeader>
      <VaultFileExplorerInModal
        selectedRows={selectedFilesForWorkflow}
        showDocumentClassificationMismatchWarning={
          showDocClassificationMismatchWarning
        }
        setSelectedRows={setSelectedFilesForWorkflow}
        preselectFileIds={preselectFileIds}
        projectId={projectId}
        useModalStyling
        className="flex min-h-0 grow flex-col rounded-none pb-4"
      />
      <DialogFooter className="flex w-full flex-row items-center justify-between border-t px-6 pt-4 sm:justify-between">
        <p className="text-xs text-muted">{numSelectedFiles} files selected</p>
        <div className="flex flex-row gap-2">
          <Button variant="outline" onClick={onBackButtonClicked}>
            Back
          </Button>
          <AskHarveyButton
            buttonTitle={buttonLabel}
            disabled={selectedFilesForWorkflow.length === 0 || isSubmitting}
            tooltip={
              selectedFilesForWorkflow.length === 0
                ? 'You must select at least one file'
                : undefined
            }
            isLoading={isSubmitting}
            handleSubmit={handleSubmit}
            className="w-fit"
          />
        </div>
      </DialogFooter>
    </>
  )
}

export default VaultWorkflowAddFiles
