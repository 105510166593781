import React from 'react'

import { SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataExceptions } from 'openapi/models/SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataExceptions'

import { Source } from 'utils/task'

import { Citations } from './citations'
import DataRow from './data-row'

const ExceptionComponent: React.FC<{
  exception: SocketMessageResponseAntitrustFilingsAnalysisAllOfMetadataExceptions
  idx: number
  handleSourceClick: (source: Source) => void
}> = ({ exception, idx, handleSourceClick }) => {
  return (
    <div className="space-y-2">
      <div className="mb-1 flex">
        <p className="mr-4 w-24 text-xs font-semibold">Exception {idx + 1}</p>
        <p className="mr-1 text-xs font-semibold">
          {exception.exceptionConclusion.charAt(0).toUpperCase() +
            exception.exceptionConclusion.slice(1)}
        </p>
        <Citations
          handleSourceClick={handleSourceClick}
          sources={exception.sources as Source[]}
        />
      </div>

      <DataRow label="Requirement" value={exception.exception} />
      <DataRow label="Analysis" value={exception.exceptionAnalysis} />
    </div>
  )
}

export default ExceptionComponent
