import React, { useEffect, useMemo } from 'react'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  LoaderCircleIcon,
  CheckIcon,
  AlertCircleIcon,
} from 'lucide-react'
import pluralize from 'pluralize'

import { EventStatus } from 'openapi/models/EventStatus'
import { LoadingState as LoadingStateType } from 'openapi/models/LoadingState'
import { LoadingStateStatus } from 'openapi/models/LoadingStateStatus'

import { cn } from 'utils/utils'

import Icon from 'components/ui/icon/icon'

type LoadingStateProps = {
  states: LoadingStateType[]
  isCompleted: boolean
  paramStatus: EventStatus
}

type NestedLoadingState = LoadingStateType & {
  children: NestedLoadingState[]
}

export const LoadingState: React.FC<LoadingStateProps> = ({
  states,
  isCompleted,
  paramStatus,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true)

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const hasError = paramStatus === EventStatus.ERRORED

  useEffect(() => {
    if (isCompleted || hasError) {
      setIsExpanded(false)
    }
  }, [isCompleted, hasError])

  const nestedStates: NestedLoadingState[] = useMemo(() => {
    const topLevelStates = states.filter(
      (state) => !state.parentLoadingStateUuid
    )
    const nestedStates: NestedLoadingState[] = topLevelStates.map((state) => {
      const children = states
        .filter((s) => s.parentLoadingStateUuid === state.uuid)
        .map((child) => ({
          ...child,
          children: [],
        }))
      return { ...state, children }
    })
    return nestedStates
  }, [states])

  if (states.length === 0) {
    return null
  }

  const isCompletedOrError = isCompleted || hasError

  return (
    <div data-testid="workflow-loading-states">
      {isCompletedOrError ? (
        <button
          onClick={handleToggle}
          className="flex w-full items-center space-x-1 py-0.5 focus:outline-none"
          data-testid="workflow-loading-states-button"
        >
          {hasError ? (
            <span
              className="text-sm text-secondary"
              data-testid="workflow-loading-states-title"
            >
              Error on step {states.length}
            </span>
          ) : (
            <span
              className="text-sm text-secondary"
              data-testid="workflow-loading-states-title"
            >
              Finished in {states.length} {pluralize('step', states.length)}
            </span>
          )}
          {hasError && (
            <Icon
              icon={AlertCircleIcon}
              size="default"
              className="text-destructive"
            />
          )}
          {!hasError && isExpanded && (
            <Icon
              icon={ChevronUpIcon}
              size="default"
              className="text-secondary"
            />
          )}
          {!hasError && !isExpanded && (
            <Icon
              icon={ChevronDownIcon}
              size="default"
              className="text-secondary"
            />
          )}
        </button>
      ) : (
        <span className={cn('text-sm text-secondary', isExpanded && 'pb-1')}>
          Working…
        </span>
      )}

      {/* Expanded View */}
      {isExpanded && (
        <div className="mb-2 flex flex-col">
          {nestedStates.map((state, index) => (
            <LoadingStateItem
              key={index}
              state={state}
              index={index}
              paramStatus={paramStatus}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const LoadingStateItem: React.FC<{
  state: NestedLoadingState
  index: number
  paramStatus: EventStatus
}> = ({ state, index, paramStatus }) => {
  const hasError = paramStatus === EventStatus.ERRORED
  return (
    <div className="flex flex-col">
      <div
        key={index}
        className="flex items-start space-x-2 py-1"
        data-testid={`workflow-loading-state-${index}`}
      >
        <div className="flex h-5 shrink-0 items-center">
          {state.status === LoadingStateStatus.DONE ? (
            <Icon icon={CheckIcon} size="small" />
          ) : hasError ? (
            <Icon
              icon={AlertCircleIcon}
              size="small"
              className="text-destructive"
            />
          ) : (
            <Icon
              icon={LoaderCircleIcon}
              size="small"
              className="animate-spin"
            />
          )}
        </div>
        <span className="text-sm text-secondary">
          {state.text || state.name}
        </span>
      </div>
      {state.children.map((child, idx) => (
        <div key={idx} className="ml-5 flex items-start space-x-2">
          <span className="text-xs text-secondary">
            {child.text || child.name}
          </span>
        </div>
      ))}
    </div>
  )
}
