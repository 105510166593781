import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { datadogRum } from '@datadog/browser-rum'
import { isEmpty } from 'lodash'
import { BookOpen, Folder, SquareAsterisk, Users } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { VaultFolder } from 'openapi/models/VaultFolder'

import { bytesToPreciseReadable } from 'utils/file-utils'
import { cn, backendToReadable, parseIsoString } from 'utils/utils'

import { MenuDropdown } from './components/file-explorer/vault-cells'
import { VaultCreateProjectCard } from './components/new-project/vault-create-project-card'
import { VaultProjectTabTypes } from './components/vault-app-header/vault-project-tabs'
import VaultProjectCard from './components/vault-project-card'
import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import { Badge } from 'components/ui/badge'
import { Icon } from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import { ScrollArea } from 'components/ui/scroll-area'
import { SkeletonBlock } from 'components/ui/skeleton'
import { Spinner } from 'components/ui/spinner'
import {
  homePageTabSearchParamKey,
  projectsPath,
} from 'components/vault/utils/vault'
import {
  getProjectMetadataFromVaultFolderMetadata,
  getVaultProjects,
  isEmptyMetadata,
  projectAsItem,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import VaultProjectEditClientMatterDialog from './dialogs/vault-project-edit-client-matter-dialog'
import useSharingPermissions from './hooks/use-sharing-permissions'
import { useVaultProjectHistoryStats } from './utils/use-vault-project-history-stats'
import { useVaultProjectsMetadata } from './utils/use-vault-project-metadata'
import { VaultProjectMetadata } from './utils/vault-fetcher'
import { pluralizeFiles } from './utils/vault-text-utils'

const DEFAULT_PROJECT_CARD_HEIGHT = 246

const NoProjects = ({ tab }: { tab?: VaultProjectTabTypes }) => {
  const displayText =
    tab === VaultProjectTabTypes.SHARED_WITH_YOU
      ? `No Vault projects have been shared with you yet`
      : `You haven’t created any Vault projects yet`
  return (
    <div className="flex h-full w-full flex-col items-center justify-center space-y-6">
      <Icon icon={Folder} size="large" />
      <p>{displayText}</p>
    </div>
  )
}

const ProjectCard = ({
  vaultProject,
  isShared,
  isLoadingMetadata,
  projectMetadata,
}: {
  vaultProject: VaultFolder
  isShared?: boolean
  isLoadingMetadata: boolean
  projectMetadata?: VaultProjectMetadata
}) => {
  const location = useLocation()
  const { trackEvent } = useAnalytics()
  const [
    isEditClientMatterDialogOpen,
    exampleProjectIds,
    foldersMetadata,
    setIsEditClientMatterDialogOpen,
    setCurrentProject,
    setShowProcessingProgress,
  ] = useVaultStore(
    useShallow((s) => [
      s.isEditClientMatterDialogOpen,
      s.exampleProjectIds,
      s.foldersMetadata,
      s.setIsEditClientMatterDialogOpen,
      s.setCurrentProject,
      s.setShowProcessingProgress,
    ])
  )
  const { historyStats, isLoadingHistoryStats } = useVaultProjectHistoryStats(
    vaultProject.id
  )

  const projectRow = projectAsItem(
    vaultProject,
    projectMetadata?.totalSize,
    projectMetadata?.filesCount
  )

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const isExampleProject = useMemo(
    () => exampleProjectIds.has(vaultProject.id),
    [vaultProject.id, exampleProjectIds]
  )
  const isKnowledgeBaseProject = vaultProject.isKnowledgeBaseProject
  const { doesCurrentUserHaveFullAccessPermission } = useSharingPermissions({
    projectId: vaultProject.id,
  })

  const sizeDisplayText = useMemo(() => {
    // Read folder metadata if it's loaded, otherwise read cached project metadata
    const folderMetadata = foldersMetadata[vaultProject.id]
    const metadata =
      !!folderMetadata && !isEmptyMetadata(folderMetadata)
        ? getProjectMetadataFromVaultFolderMetadata(folderMetadata)
        : projectMetadata ?? undefined
    const numProjectFiles = metadata?.filesCount || 0
    const projectSize = bytesToPreciseReadable(
      projectMetadata?.totalSize || 0,
      2,
      true
    )
    if (numProjectFiles === 0) {
      return `No files uploaded`
    }
    return `${pluralizeFiles(numProjectFiles)} (${projectSize})`
  }, [projectMetadata, foldersMetadata, vaultProject.id])

  const destination = useMemo(() => {
    return {
      pathname: `${BaseAppPath.Vault}${projectsPath}${vaultProject.id}`,
      search: location.search,
    }
  }, [location.search, vaultProject.id])

  // projectUpdatedAt is the last time action was taken on the project
  // updatedAt is when vault folder DB record was updated. Failsafe, should not fall back.
  const projectUpdatedAt = useMemo(() => {
    const updateTime = vaultProject.projectUpdatedAt ?? vaultProject.updatedAt
    return `Updated ${backendToReadable(updateTime)}`
  }, [vaultProject])

  const shouldShowMenuDropdown =
    (isHovered || isDropdownOpen) &&
    !isExampleProject &&
    doesCurrentUserHaveFullAccessPermission

  const sharedByUserEmail = projectMetadata?.projectCreatorEmail ?? null

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        height: DEFAULT_PROJECT_CARD_HEIGHT,
      }}
      className={cn(
        'relative flex w-full flex-col justify-between rounded-lg border p-6 shadow-sm',
        {
          'cursor-pointer border-input-focused': isHovered || isDropdownOpen,
        }
      )}
    >
      <div className="max-h-12 space-y-1">
        <p className="text-xs text-muted">{projectUpdatedAt}</p>
        <Link
          to={destination}
          onClick={() => {
            setCurrentProject(vaultProject)
            if (
              projectMetadata &&
              (projectMetadata.failedFilesCount !== 0 ||
                projectMetadata.completedFilesCount !==
                  projectMetadata.filesCount)
            ) {
              setShowProcessingProgress(vaultProject.id, true)
            }
            trackEvent('Vault Project Loaded', {
              is_example_project: isExampleProject,
              is_shared_project: isShared,
            })
          }}
          className="apply-click-on-parent"
        >
          <h2 className="line-clamp-3 w-full text-left text-sm">
            {vaultProject.name}
          </h2>
        </Link>
        {isExampleProject && (
          <div>
            <Badge variant="secondary" className="border-0 text-muted" isPill>
              Example
            </Badge>
          </div>
        )}
        {isKnowledgeBaseProject && (
          <Badge
            variant="secondary"
            className="border-0 px-1 py-1.5 text-muted"
          >
            <div className="flex items-center gap-1">
              <Icon icon={BookOpen} size="small" />
              <p className="text-2xs font-medium">Knowledge base</p>
            </div>
          </Badge>
        )}
      </div>
      {shouldShowMenuDropdown && (
        <div className="absolute right-2 top-2">
          <MenuDropdown
            dropdownAlign="start"
            row={projectRow}
            onMenuDropdownChangeHandler={setIsDropdownOpen}
          />
          <VaultProjectEditClientMatterDialog
            modalOpen={isEditClientMatterDialogOpen}
            setModalOpen={setIsEditClientMatterDialogOpen}
            projectId={projectRow.data.id}
          />
        </div>
      )}
      <div className="space-y-2">
        {isShared && (
          <div className="flex items-center space-x-1">
            <Icon icon={Users} size="small" className="text-muted" />
            {sharedByUserEmail ? (
              <p className="text-xs text-muted">
                Shared by {sharedByUserEmail}
              </p>
            ) : (
              <SkeletonBlock className="h-4 w-24" />
            )}
          </div>
        )}
        <div className="space-x-2">
          <div className="flex items-center space-x-1">
            <Icon icon={Folder} size="small" />
            {!isLoadingMetadata ? (
              <p className="text-xs">{sizeDisplayText}</p>
            ) : (
              <SkeletonBlock className="h-4 w-24" />
            )}
          </div>
        </div>
        {(!historyStats || historyStats.totalCount > 0) && (
          <div className="flex items-center space-x-1">
            <Icon icon={SquareAsterisk} size="small" />
            {isLoadingHistoryStats && <SkeletonBlock className="h-4 w-16" />}
            {historyStats &&
              historyStats.totalCount - historyStats.inProgressCount > 0 && (
                <p className="text-xs">
                  {historyStats.totalCount - historyStats.inProgressCount}{' '}
                  {historyStats.totalCount - historyStats.inProgressCount > 1
                    ? 'queries'
                    : 'query'}
                </p>
              )}
            {historyStats && historyStats.inProgressCount > 0 && (
              <Badge
                variant="secondary"
                className="rounded-full border-0 px-1 text-muted"
              >
                <Spinner size="xxs" className="mx-0 mr-1 shrink-0" />
                <p className="text-xs">
                  {historyStats.inProgressCount}{' '}
                  {historyStats.inProgressCount > 1 ? 'queries' : 'query'}{' '}
                  processing
                </p>
              </Badge>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const VaultHome = () => {
  const userInfo = useAuthUser()
  const [searchParams] = useSearchParams()

  const defaultTab = VaultProjectTabTypes.YOUR_PROJECTS
  const tabParam = searchParams.get(
    homePageTabSearchParamKey
  ) as VaultProjectTabTypes | null
  const selectedTab = tabParam ?? defaultTab

  const isLayoutLoading = useVaultStore(
    useShallow((state) => state.isLayoutLoading)
  )
  const allFolderIdToVaultFolder = useVaultStore(
    useShallow((state) => state.allFolderIdToVaultFolder)
  )
  const rootVaultFolderIds = useVaultStore(
    useShallow((state) => state.rootVaultFolderIds)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )
  const sharedProjectIds = useVaultStore(
    useShallow((state) => state.sharedProjectIds)
  )

  const isVaultSharingEnabled = userInfo.IsVaultViewSharesUser
  const isKnowledgeBaseProjectUser = userInfo.IsKnowledgeBaseProjectUser

  const vaultProjectsToDisplay = useMemo(() => {
    const allVaultProjects = getVaultProjects({
      allFolderIdToVaultFolder,
      rootVaultFolderIds,
      userId: userInfo.dbId,
      exampleProjectIds,
      sharedProjectIds,
    })
    // Do not show tabs if user is a knowledge base project user
    if (!isVaultSharingEnabled || isKnowledgeBaseProjectUser) {
      return allVaultProjects
        .filter((vaultProject) =>
          // Filter out knowledge base projects if user is not a knowledge base project user
          !isKnowledgeBaseProjectUser
            ? !vaultProject.isKnowledgeBaseProject
            : true
        )
        .sort((a, b) => {
          return (
            new Date(
              parseIsoString(b.projectUpdatedAt ?? b.updatedAt)
            ).getTime() -
            new Date(
              parseIsoString(a.projectUpdatedAt ?? a.updatedAt)
            ).getTime()
          )
        })
    }

    if (selectedTab === VaultProjectTabTypes.SHARED_WITH_YOU) {
      return allVaultProjects
        .filter(
          (vaultProject) =>
            sharedProjectIds.has(vaultProject.id) &&
            !exampleProjectIds.has(vaultProject.id) &&
            !vaultProject.isKnowledgeBaseProject
        )
        .sort((a, b) => {
          return (
            new Date(
              parseIsoString(b.projectUpdatedAt ?? b.updatedAt)
            ).getTime() -
            new Date(
              parseIsoString(a.projectUpdatedAt ?? a.updatedAt)
            ).getTime()
          )
        })
    }

    return allVaultProjects
      .filter(
        (vaultProject) =>
          vaultProject.userId === userInfo.dbId ||
          exampleProjectIds.has(vaultProject.id)
      )
      .sort((a, b) => {
        // sort by last opened at time, if not set, sort by updated at time
        return (
          new Date(
            parseIsoString(b.projectUpdatedAt ?? b.updatedAt)
          ).getTime() -
          new Date(parseIsoString(a.projectUpdatedAt ?? a.updatedAt)).getTime()
        )
      })
  }, [
    isVaultSharingEnabled,
    userInfo.dbId,
    selectedTab,
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    exampleProjectIds,
    sharedProjectIds,
    isKnowledgeBaseProjectUser,
  ])

  const hasRecordedInitialLoad = useRef(false)

  useMount(() => {
    datadogRum.startDurationVital('vaultHomeInitialLoad')
  })

  useEffect(() => {
    if (
      !hasRecordedInitialLoad.current &&
      !(isLayoutLoading && isEmpty(vaultProjectsToDisplay))
    ) {
      datadogRum.stopDurationVital('vaultHomeInitialLoad')
      hasRecordedInitialLoad.current = true
    }
  }, [isLayoutLoading, vaultProjectsToDisplay])

  return (
    <AppMain>
      <FullscreenLoading
        isLoading={isLayoutLoading && isEmpty(vaultProjectsToDisplay)}
        zIndex="z-50"
      />
      <ProjectsDisplay
        vaultProjects={vaultProjectsToDisplay}
        tab={selectedTab}
      />
    </AppMain>
  )
}

const ProjectsDisplay = ({
  vaultProjects,
  tab,
}: {
  vaultProjects: VaultFolder[]
  tab?: VaultProjectTabTypes
}) => {
  const userInfo = useAuthUser()
  const sharedProjectIds = useVaultStore(
    useShallow((state) => state.sharedProjectIds)
  )

  const isKnowledgeBaseProjectUser = userInfo.IsKnowledgeBaseProjectUser

  const isKnowledgeBaseCreationDisabled =
    !userInfo.workspace.sharingSettings.vault.workspaceLevel ||
    !userInfo.IsVaultCreateSharesUser

  const knowledgeBaseCreationDisabledTooltip = (() => {
    if (!userInfo.workspace.sharingSettings.vault.workspaceLevel) {
      if (userInfo.IsVaultSharingManagementUser) {
        return 'Knowledge bases require sharing to be enabled. Visit Settings > Sharing to enable.'
      } else {
        return 'Knowledge bases require sharing to be enabled. Contact your Harvey admin.'
      }
    }
    if (!userInfo.IsVaultCreateSharesUser) {
      return 'You do not have access to share Vault projects. Contact your Harvey admin.'
    }
    return undefined
  })()

  const { projectsMetadata, isLoadingProjectsMetadata } =
    useVaultProjectsMetadata(
      vaultProjects.map((vaultProject) => vaultProject.id)
    )

  if (isKnowledgeBaseProjectUser) {
    return (
      <ScrollArea className="h-full" isFullHeight>
        <div
          data-testid="vault-container"
          className="container mx-auto h-full space-y-6 px-6 pb-4 pt-10"
        >
          <div className="flex flex-col gap-10">
            <div className="grid grid-cols-1 gap-4 pb-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
              <VaultCreateProjectCard
                id="vault-create-project-card"
                height={DEFAULT_PROJECT_CARD_HEIGHT}
              />
              {userInfo.IsCreateKnowledgeBaseProjectUser && (
                <VaultCreateProjectCard
                  height={DEFAULT_PROJECT_CARD_HEIGHT}
                  isKnowledgeBaseProject
                  disabled={isKnowledgeBaseCreationDisabled}
                  disabledTooltip={knowledgeBaseCreationDisabledTooltip}
                />
              )}
            </div>
            <div className="flex flex-col gap-4">
              <p className="text-base font-medium">Your projects</p>
              <div
                className={cn(
                  'grid grid-cols-1 gap-6 pb-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4',
                  {
                    'gap-4': isKnowledgeBaseProjectUser,
                  }
                )}
              >
                {vaultProjects.map((vaultProject) => (
                  <VaultProjectCard
                    key={vaultProject.id}
                    vaultProject={vaultProject}
                    isShared={sharedProjectIds.has(vaultProject.id)}
                    isLoadingMetadata={
                      isLoadingProjectsMetadata ||
                      !projectsMetadata?.[vaultProject.id]
                    }
                    projectMetadata={projectsMetadata?.[vaultProject.id]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </ScrollArea>
    )
  }

  return (
    <ScrollArea className="h-full" isFullHeight>
      <div
        data-testid="vault-container"
        className="container mx-auto h-full space-y-6 px-6 py-4"
      >
        {vaultProjects.length === 0 &&
          tab !== VaultProjectTabTypes.YOUR_PROJECTS && (
            <NoProjects tab={tab} />
          )}
        {(vaultProjects.length > 0 ||
          tab === VaultProjectTabTypes.YOUR_PROJECTS) && (
          <div className="grid grid-cols-1 gap-6 pb-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {tab === VaultProjectTabTypes.YOUR_PROJECTS && (
              <VaultCreateProjectCard height={DEFAULT_PROJECT_CARD_HEIGHT} />
            )}
            {vaultProjects.map((vaultProject) => (
              <ProjectCard
                key={vaultProject.id}
                vaultProject={vaultProject}
                isShared={sharedProjectIds.has(vaultProject.id)}
                isLoadingMetadata={
                  isLoadingProjectsMetadata ||
                  !projectsMetadata?.[vaultProject.id]
                }
                projectMetadata={projectsMetadata?.[vaultProject.id]}
              />
            ))}
          </div>
        )}
      </div>
    </ScrollArea>
  )
}

export default VaultHome
