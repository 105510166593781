import saveAs from 'file-saver'
import JSZip from 'jszip'

import { VaultFolder } from 'openapi/models/VaultFolder'

import { createFileName, sanitizeFileName } from './file-utils'

export const downloadUploadedFiles = async ({
  uploadedFiles,
  zippedFileName,
  shouldZipSingleFile = false,
}: {
  uploadedFiles: { id: string; name: string; url: string }[]
  zippedFileName?: string
  shouldZipSingleFile?: boolean
}) => {
  if (uploadedFiles.length === 1 && !shouldZipSingleFile) {
    const file = uploadedFiles[0]
    const response = await fetch(file.url)
    const blob = await response.blob()
    saveAs(blob, file.name)
  } else if (uploadedFiles.length > 0) {
    const zip = new JSZip()
    const namesForExistingFiles: string[] = []
    const documentIdToBlob: Record<string, Blob> = {}
    await Promise.all(
      uploadedFiles.map(async (file) => {
        const response = await fetch(file.url)
        const blob = await response.blob()
        documentIdToBlob[file.id] = blob
      })
    )
    const existingNamesSet = new Set(namesForExistingFiles)
    uploadedFiles.forEach((document) => {
      const fileName = createFileName(document.name, existingNamesSet)
      namesForExistingFiles.push(fileName)
      zip.file(fileName, documentIdToBlob[document.id])
    })
    const zipName = zippedFileName
      ? `${sanitizeFileName(zippedFileName)}.zip`
      : 'downloaded_files.zip'
    const generatedZip = await zip.generateAsync({ type: 'blob' })
    saveAs(generatedZip, zipName)
  }
}

const buildPathsForFiles = ({
  uploadedFiles,
  folders,
}: {
  uploadedFiles: {
    id: string
    name: string
    url: string
    folderId: string | null
  }[]
  folders: VaultFolder[]
}) => {
  const folderMap = new Map(folders.map((folder) => [folder.id, folder]))

  const relevantFolderIds = new Set(
    uploadedFiles.map((file) => file.folderId).filter(Boolean)
  )

  const folderPaths = new Map<string, string>()
  relevantFolderIds.forEach((folderId) => {
    if (folderId) {
      const parts: string[] = []
      let currentFolder = folderMap.get(folderId)

      while (currentFolder) {
        parts.unshift(sanitizeFileName(currentFolder.name))
        currentFolder = currentFolder.parentId
          ? folderMap.get(currentFolder.parentId)
          : undefined
      }

      folderPaths.set(folderId, parts.join('/'))
    }
  })

  const files = uploadedFiles.map((document) => {
    const folderPath = document.folderId
      ? folderPaths.get(document.folderId)
      : ''
    const fullPath = folderPath
      ? `${folderPath}/${document.name}`
      : document.name
    return {
      id: document.id,
      name: fullPath,
      url: document.url,
    }
  })
  return files
}

export const downloadVaultFiles = async ({
  uploadedFiles,
  folders,
  downloadFileName,
}: {
  uploadedFiles: {
    id: string
    name: string
    url: string
    folderId: string | null
  }[]
  folders: VaultFolder[]
  downloadFileName?: string
}) => {
  const files = buildPathsForFiles({ uploadedFiles, folders })
  await downloadUploadedFiles({
    uploadedFiles: files,
    zippedFileName: downloadFileName,
    shouldZipSingleFile: true,
  })
}
