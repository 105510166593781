import React, { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { capitalize } from 'lodash'
import { Search } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { WorkflowType } from 'types/workflows'

import { cn } from 'utils/utils'

import PreviewModal from 'components/assistant/workflows/components/preview-modal/preview-modal'
import { useWorkflowData as useAssistantWorkflowData } from 'components/assistant/workflows/hooks/use-workflow-data'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import SearchInput from 'components/ui/search-input'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'
import VaultWorkflowBuilder from 'components/vault/workflows/vault-workflow-builder'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

import { getWorkflowDescriptions } from './constants'
import { useVaultWorkflowSetup } from './hooks/use-vault-workflow-setup'
import WorkflowCard, {
  LegacyWorkflowInfo,
  WorkflowCategory,
  WorkflowInfo,
  AssistantWorkflowInfo,
} from './workflow-card'
import { WorkflowTypeToDetails } from './workflow-definitions'
import { WorkflowSource } from './workflows-helpers'
import { userHasPermissionForWorkflow } from './workflows-utils'

export const WorkflowTab = {
  ALL: 'all',
  ...WorkflowCategory,
} as const

type WorkflowTab = (typeof WorkflowTab)[keyof typeof WorkflowTab]

const getWorkflowTabLabel = (
  tab: WorkflowTab,
  isDiscoverTabUser: boolean,
  shouldShowTabs: boolean
) => {
  if (!shouldShowTabs) return undefined
  if (tab === WorkflowTab.LEGACY)
    return isDiscoverTabUser ? 'Other' : 'Standalone'
  return capitalize(tab)
}

const WorkflowsList: React.FC = () => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const [searchParams, setSearchParams] = useSearchParams()
  const initialTab = searchParams.get('tab')
    ? (searchParams.get('tab') as WorkflowTab)
    : WorkflowTab.ALL

  const [selectedTab, setSelectedTab] = useState<WorkflowTab>(initialTab)
  const [searchValue, setSearchValue] = useState('')
  const [vaultWorkflows, setWorkflowModalState, setSelectedWorkflow] =
    useVaultWorkflowStore(
      useShallow((state) => [
        state.workflows,
        state.setWorkflowModalState,
        state.setSelectedWorkflow,
      ])
    )

  const {
    categorizedWorkflows: assistantCategorizedWorkflows,
    isLoading: isLoadingAssistantWorkflows,
  } = useAssistantWorkflowData()
  const assistantWorkflowInfo: AssistantWorkflowInfo[] = useMemo(() => {
    const newInfos: AssistantWorkflowInfo[] = []
    const categories = [
      'allen_and_overy',
      'general',
      'transactional',
      'litigation',
      'financial_services',
    ] as const
    categories.forEach((category) => {
      assistantCategorizedWorkflows[category].forEach((workflow) => {
        if (newInfos.find((w) => w.id === workflow.id)) {
          return
        }
        newInfos.push({
          id: workflow.id,
          slug: workflow.slug,
          tags: workflow.tags,
          name: workflow.name,
          description: workflow.description,
          category: WorkflowCategory.ASSISTANT,
          path: `/assistant/workflows/${workflow.slug}`,
          userInputStepCount: workflow.userInputStepCount,
          outputType: workflow.outputType,
          sampleOutput: workflow.sampleOutput || undefined,
          docParamsNeededToStart: workflow.docParamsNeededToStart,
        })
      })
    })
    return newInfos
  }, [assistantCategorizedWorkflows])
  const filteredAssistantWorkflowInfo = assistantWorkflowInfo.filter(
    (workflow) =>
      workflow.name.toLowerCase().includes(searchValue.toLowerCase())
  )

  const userHasTranslateWorkflow = useMemo(() => {
    return assistantWorkflowInfo.some((workflow) =>
      workflow.slug.includes('translate')
    )
  }, [assistantWorkflowInfo])

  const legacyWorkflows = Object.values(WorkflowType)
    .filter((workflow) => userHasPermissionForWorkflow(userInfo, workflow))
    .map((workflow) => WorkflowTypeToDetails[workflow])
  const legacyWorkflowDescriptions = getWorkflowDescriptions(true)
  const legacyWorkflowInfo: LegacyWorkflowInfo[] = legacyWorkflows.reduce<
    LegacyWorkflowInfo[]
  >((acc, workflow) => {
    if (
      userHasTranslateWorkflow &&
      workflow.type === WorkflowType.TRANSLATION
    ) {
      return acc
    }
    return [
      ...acc,
      {
        id: workflow.name, // TODO(stella): improve typing once workflow cards finalized
        name: legacyWorkflowDescriptions[workflow.type].header,
        description: legacyWorkflowDescriptions[workflow.type].body,
        category: WorkflowCategory.LEGACY,
        path: workflow.path,
      },
    ]
  }, [])

  const filteredLegacyWorkflowInfo = legacyWorkflowInfo.filter((workflow) =>
    workflow.name.toLowerCase().includes(searchValue.toLowerCase())
  )

  const { isFetchingVaultWorkflows, vaultWorkflowInfo } =
    useVaultWorkflowSetup()

  const filteredVaultWorkflowInfo = vaultWorkflowInfo.filter((workflow) =>
    workflow.name.toLowerCase().includes(searchValue.toLowerCase())
  )

  const isShowingAllWorkflows = selectedTab === WorkflowTab.ALL

  const { doesSearchHaveResultsInCurrentTab, otherTabWithSearchResults } =
    useMemo(() => {
      if (isShowingAllWorkflows) {
        return {
          doesSearchHaveResultsInCurrentTab:
            filteredLegacyWorkflowInfo.length > 0 ||
            filteredVaultWorkflowInfo.length > 0 ||
            filteredAssistantWorkflowInfo.length > 0,
          doesSearchHaveResultsInOtherTab: false,
        }
      }
      if (selectedTab === WorkflowTab.ASSISTANT) {
        return {
          doesSearchHaveResultsInCurrentTab:
            filteredAssistantWorkflowInfo.length > 0,
          otherTabWithSearchResults:
            filteredLegacyWorkflowInfo.length > 0
              ? WorkflowTab.LEGACY
              : filteredVaultWorkflowInfo.length > 0
              ? WorkflowTab.VAULT
              : undefined,
        }
      }
      if (selectedTab === WorkflowTab.VAULT) {
        return {
          doesSearchHaveResultsInCurrentTab:
            filteredVaultWorkflowInfo.length > 0,
          otherTabWithSearchResults:
            filteredLegacyWorkflowInfo.length > 0
              ? WorkflowTab.LEGACY
              : filteredAssistantWorkflowInfo.length > 0
              ? WorkflowTab.ASSISTANT
              : undefined,
        }
      }

      return {
        doesSearchHaveResultsInCurrentTab:
          filteredLegacyWorkflowInfo.length > 0,
        otherTabWithSearchResults:
          filteredVaultWorkflowInfo.length > 0
            ? WorkflowTab.VAULT
            : filteredAssistantWorkflowInfo.length > 0
            ? WorkflowTab.ASSISTANT
            : undefined,
      }
    }, [
      isShowingAllWorkflows,
      selectedTab,
      filteredLegacyWorkflowInfo,
      filteredVaultWorkflowInfo,
      filteredAssistantWorkflowInfo,
    ])

  const tabsToShow = useMemo(() => {
    const tabsToShow = []
    // Reorder based on Discover perm, can remove after GA
    if (userInfo.isDiscoverTabUser) {
      if (assistantWorkflowInfo.length > 0) {
        tabsToShow.push(WorkflowTab.ASSISTANT)
      }
      if (vaultWorkflowInfo.length > 0) {
        tabsToShow.push(WorkflowTab.VAULT)
      }
      if (legacyWorkflowInfo.length > 0) {
        tabsToShow.push(WorkflowTab.LEGACY)
      }
    } else {
      if (legacyWorkflowInfo.length > 0) {
        tabsToShow.push(WorkflowTab.LEGACY)
      }
      if (vaultWorkflowInfo.length > 0) {
        tabsToShow.push(WorkflowTab.VAULT)
      }
    }

    // Only show the ALL tab if there are more than one type of workflow
    if (tabsToShow.length > 1) {
      return [WorkflowTab.ALL, ...tabsToShow]
    }
    return tabsToShow
  }, [
    legacyWorkflowInfo,
    vaultWorkflowInfo,
    assistantWorkflowInfo,
    userInfo.isDiscoverTabUser,
  ])

  if (isFetchingVaultWorkflows || isLoadingAssistantWorkflows) {
    return <FullscreenLoading isLoading zIndex="z-50" />
  }

  const handleVaultWorkflowClick = (workflow: WorkflowInfo) => {
    trackEvent('Workflow Clicked', {
      workflow_id: workflow.id,
      workflow_name: workflow.name,
      workflow_type: workflow.category,
    })
    setWorkflowModalState(WorkflowModalState.Builder)
    setSelectedWorkflow(vaultWorkflows[workflow.id] ?? null)
  }

  const handleTabChange = (newTab: string) => {
    setSelectedTab(newTab as WorkflowTab)
    setSearchParams({ tab: newTab })
    trackEvent('Workflows Tab Changed', { tab: capitalize(newTab) })
  }

  const shouldShowTabs = tabsToShow.length > 1

  return (
    <div className="flex flex-col gap-y-10 pb-8 pt-2">
      <div className="flex items-center gap-x-3">
        {shouldShowTabs && (
          <Tabs value={selectedTab} onValueChange={handleTabChange}>
            <TabsList className="h-9">
              {tabsToShow.map((tab) => (
                <TabsTrigger
                  key={tab}
                  value={tab}
                  className="h-7 text-xs font-medium"
                >
                  {getWorkflowTabLabel(
                    tab as WorkflowTab,
                    userInfo.isDiscoverTabUser,
                    shouldShowTabs
                  )}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        )}
        <SearchInput
          value={searchValue}
          setValue={setSearchValue}
          withIcon
          className="h-9"
        />
      </div>
      {doesSearchHaveResultsInCurrentTab ? (
        <div
          className={cn(
            'flex flex-col gap-y-8',
            // Reorder based on Discover perm, can remove after GA
            userInfo.isDiscoverTabUser ? 'flex-col' : 'flex-col-reverse'
          )}
        >
          {((isShowingAllWorkflows && userInfo.isDiscoverTabUser) ||
            selectedTab === WorkflowTab.ASSISTANT) &&
            filteredAssistantWorkflowInfo.length > 0 && (
              <WorkflowsSection
                title={getWorkflowTabLabel(
                  WorkflowTab.ASSISTANT,
                  userInfo.isDiscoverTabUser,
                  shouldShowTabs
                )}
                workflowInfo={filteredAssistantWorkflowInfo}
                category={WorkflowCategory.ASSISTANT}
              />
            )}
          {(isShowingAllWorkflows || selectedTab === WorkflowTab.VAULT) &&
            filteredVaultWorkflowInfo.length > 0 && (
              <WorkflowsSection
                title={getWorkflowTabLabel(
                  WorkflowTab.VAULT,
                  userInfo.isDiscoverTabUser,
                  shouldShowTabs
                )}
                workflowInfo={filteredVaultWorkflowInfo}
                category={WorkflowCategory.VAULT}
                onWorkflowClick={handleVaultWorkflowClick}
              />
            )}
          {(isShowingAllWorkflows || selectedTab === WorkflowTab.LEGACY) &&
            filteredLegacyWorkflowInfo.length > 0 && (
              <WorkflowsSection
                title={getWorkflowTabLabel(
                  WorkflowTab.LEGACY,
                  userInfo.isDiscoverTabUser,
                  shouldShowTabs
                )}
                workflowInfo={filteredLegacyWorkflowInfo}
                category={WorkflowCategory.LEGACY}
              />
            )}
          <VaultWorkflowBuilder source={WorkflowSource.WORKFLOWS_PAGE} />
        </div>
      ) : (
        <NoWorkflowFound
          otherTabWithSearchResults={otherTabWithSearchResults}
          setSelectedTab={handleTabChange}
        />
      )}
    </div>
  )
}

type WorkflowsSectionProps = {
  title?: string
  workflowInfo: WorkflowInfo[]
  category: WorkflowCategory
  onWorkflowClick?: (workflow: WorkflowInfo) => void
}

const WorkflowsSection: React.FC<WorkflowsSectionProps> = ({
  title,
  workflowInfo,
  category,
  onWorkflowClick,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <div className="flex flex-col gap-4">
      {title && <p className="text-sm font-medium">{title}</p>}
      <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 xl:grid-cols-4">
        {workflowInfo.map((workflow) => {
          if (category === WorkflowCategory.ASSISTANT) {
            return (
              <PreviewModal
                workflow={workflow as AssistantWorkflowInfo}
                key={workflow.id}
              >
                <WorkflowCard
                  workflowInfo={workflow}
                  onClick={() => {}}
                  source={WorkflowSource.WORKFLOWS_PAGE}
                />
              </PreviewModal>
            )
          } else if (category === WorkflowCategory.LEGACY) {
            return (
              <Link
                className="group rounded-lg"
                key={workflow.name}
                to={`${(workflow as LegacyWorkflowInfo).path}${
                  location.search
                }`}
                onClick={() => {
                  trackEvent('Workflow Clicked', {
                    workflow_id: workflow.id,
                    workflow_name: workflow.name,
                    workflow_type: workflow.category,
                  })
                }}
              >
                <WorkflowCard
                  key={workflow.id}
                  workflowInfo={workflow}
                  onClick={() => {}}
                  source={WorkflowSource.WORKFLOWS_PAGE}
                />
              </Link>
            )
          } else {
            return (
              <WorkflowCard
                key={workflow.id}
                workflowInfo={workflow}
                onClick={onWorkflowClick ?? (() => {})}
                source={WorkflowSource.WORKFLOWS_PAGE}
              />
            )
          }
        })}
      </div>
    </div>
  )
}

const NoWorkflowFound = ({
  otherTabWithSearchResults,
  setSelectedTab,
}: {
  otherTabWithSearchResults?: WorkflowTab
  setSelectedTab: (tab: WorkflowTab) => void
}) => {
  return (
    <div className="flex h-full flex-col items-center gap-y-3 px-6 py-12 text-sm">
      <Icon icon={Search} className="size-6 text-muted" />
      <div className="flex flex-col items-center">
        <div className="text-sm font-semibold">No workflow found</div>
        {otherTabWithSearchResults ? (
          <p className="max-w-72 text-center text-xs text-muted">
            No workflows found in this category, but we found results in{' '}
            <Button
              variant="text"
              onClick={() => setSelectedTab(otherTabWithSearchResults)}
              className="h-auto px-0 text-xs text-primary underline transition hover:underline"
            >
              {capitalize(otherTabWithSearchResults)}
            </Button>
            .
          </p>
        ) : (
          <p className="max-w-72 text-center text-xs text-muted">
            We couldn‘t find any workflows matching your search. Try a different
            keyword.
          </p>
        )}
      </div>
      {otherTabWithSearchResults && (
        <Button
          variant="outline"
          onClick={() => setSelectedTab(otherTabWithSearchResults)}
        >
          Show {capitalize(otherTabWithSearchResults)} workflows
        </Button>
      )}
    </div>
  )
}

export default WorkflowsList
