import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useInterval, useMount } from 'react-use'
import { useUnmount } from 'react-use'

import {
  Row,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
  Table,
} from '@tanstack/react-table'
import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { DataTable } from 'components/ui/data-table/data-table'
import {
  createGetPathForItem,
  createProjectDataPoller,
  createRowSelectionHandler,
  createShiftSelectionHandler,
  createVaultTableColumns,
  getHiddenColumns,
  handleVaultRowClick,
  prepareVaultExplorerData,
  renderWarningMessages,
} from 'components/vault/components/file-explorer/vault-file-explorer-utils'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useDocumentClassificationStore } from 'components/vault/utils/use-document-classification-store'
import {
  VaultItem,
  VaultItemType,
  VaultItemWithIndex,
} from 'components/vault/utils/vault'
import { isProjectShared } from 'components/vault/utils/vault-sharing-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { DEFAULT_FILES_WARNING_THRESHOLD } from './vault-file-explorer-warning-message'

const VaultFileExplorerInModal = ({
  projectId,
  selectedRows,
  setSelectedRows,
  existingSelectedFileIds,
  preselectFileIds,
  numberOfFilesLimit,
  numberOfFilesWarningThreshold = DEFAULT_FILES_WARNING_THRESHOLD,
  showDocumentClassificationMismatchWarning = false,
  isDisplayingFilesProgress = false,
  shouldHideAllFiles = false,
  useModalStyling = false,
  className,
}: {
  projectId: string | undefined
  selectedRows: VaultItemWithIndex[]
  setSelectedRows: (rows: VaultItemWithIndex[]) => void
  existingSelectedFileIds?: Set<string>
  preselectFileIds?: Set<string>
  numberOfFilesLimit?: number
  numberOfFilesWarningThreshold?: number
  showDocumentClassificationMismatchWarning?: boolean
  isDisplayingFilesProgress?: boolean
  shouldHideAllFiles?: boolean
  useModalStyling?: boolean
  className?: string
}) => {
  const [
    allFolderIdToVaultFolder,
    allParentIdToVaultFolderIds,
    allFileIdToVaultFile,
    allFolderIdToVaultFileIds,
    foldersMetadata,
    requiresProjectDataRefetch,
    exampleProjectIds,
    sharedProjectIds,
    projectIdToFolderIds,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    setRequiresProjectDataRefetch,
    deleteVaultFiles,
    deleteVaultFolders,
    setError,
  ] = useVaultStore(
    useShallow((state) => [
      state.allFolderIdToVaultFolder,
      state.allParentIdToVaultFolderIds,
      state.allFileIdToVaultFile,
      state.allFolderIdToVaultFileIds,
      state.foldersMetadata,
      state.requiresProjectDataRefetch,
      state.exampleProjectIds,
      state.sharedProjectIds,
      state.projectIdToFolderIds,
      state.upsertVaultFolders,
      state.upsertVaultFiles,
      state.addToProjectsMetadata,
      state.setRequiresProjectDataRefetch,
      state.deleteVaultFiles,
      state.deleteVaultFolders,
      state.setError,
    ])
  )

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const permissionsByProjectId = useVaultSharingStore(
    useShallow((state) => state.permissionsByProjectId)
  )

  const setOpenFileId = useDocumentClassificationStore(
    useShallow((s) => s.setOpenFileId)
  )

  const [lastSelectedRow, setLastSelectedRow] =
    useState<VaultItemWithIndex | null>(null)

  const isSharedProject = userInfo.IsVaultViewSharesUser
    ? isProjectShared(sharedProjectIds, permissionsByProjectId, projectId)
    : false

  const data = useMemo(() => {
    if (!projectId) return []

    return prepareVaultExplorerData({
      projectId,
      currentFolderId: projectId,
      folderIdToVaultFolder: allFolderIdToVaultFolder,
      parentIdToVaultFolderIds: allParentIdToVaultFolderIds,
      fileIdToVaultFile: allFileIdToVaultFile,
      folderIdToVaultFileIds: allFolderIdToVaultFileIds,
      foldersMetadata,
      userId: userInfo.dbId,
      exampleProjectIds,
      existingSelectedFileIds,
      isAddingFilesToQuery: true,
      isSharedProject,
      shouldHideAllFiles,
    })
  }, [
    projectId,
    allFolderIdToVaultFolder,
    allParentIdToVaultFolderIds,
    allFileIdToVaultFile,
    allFolderIdToVaultFileIds,
    foldersMetadata,
    userInfo.dbId,
    exampleProjectIds,
    existingSelectedFileIds,
    isSharedProject,
    shouldHideAllFiles,
  ])

  const getPathForItem = useMemo(() => {
    return createGetPathForItem({
      data,
      foldersMetadata,
      folderIdToVaultFolder: allFolderIdToVaultFolder,
      currentFolderId: projectId || null,
      projectId,
    })
  }, [data, foldersMetadata, allFolderIdToVaultFolder, projectId])

  const tableRef = useRef<Table<VaultItem> | null>(null)

  const createShiftSelectionHandlerFn = useCallback(
    (row: Row<VaultItem>) => {
      if (!tableRef.current) return
      return createShiftSelectionHandler({
        table: tableRef.current,
        selectedRows,
        lastSelectedRow,
        getPathForItem,
        setSelectedRows,
      })(row)
    },
    [selectedRows, lastSelectedRow, getPathForItem, setSelectedRows]
  )

  const columns = useMemo(() => {
    return createVaultTableColumns({
      trackEvent,
      isAddingFilesToQuery: true,
      setLastSelectedRow,
      handleShiftSelection: createShiftSelectionHandlerFn,
      fileIdToVaultFile: allFileIdToVaultFile,
      shouldHideAllFiles,
    })
  }, [
    trackEvent,
    setLastSelectedRow,
    createShiftSelectionHandlerFn,
    allFileIdToVaultFile,
    shouldHideAllFiles,
  ])

  const isExampleProject = useMemo(
    () => !!projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )

  const sharingPermissionsOptions = useMemo(
    () => ({
      projectId: projectId,
    }),
    [projectId]
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions(
    sharingPermissionsOptions
  )

  const canCurrentUserSelectFiles =
    doesCurrentUserHaveEditPermission || userInfo.IsKnowledgeBaseProjectUser

  const hideColumns = useMemo(() => {
    return getHiddenColumns({
      isExampleProject,
      canCurrentUserSelectFiles,
      isAddingFilesToQuery: true,
      isDisplayingFilesProgress,
    })
  }, [isExampleProject, canCurrentUserSelectFiles, isDisplayingFilesProgress])

  const [sorting, setSorting] = useState<SortingState>([])

  const rowSelection = useMemo(() => {
    return selectedRows.reduce(
      (acc, row) => {
        const path = getPathForItem(row)

        if (path && path.length > 0) {
          acc[path] = true
        }
        return acc
      },
      {} as Record<string, boolean>
    )
  }, [selectedRows, getPathForItem])

  const table = useReactTable<VaultItem>({
    columns,
    data,
    autoResetPageIndex: false,
    getSubRows: (row) =>
      row.type !== VaultItemType.file ? row.children : undefined,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: (
      updaterOrValue:
        | Record<string, boolean>
        | ((old: Record<string, boolean>) => Record<string, boolean>)
    ) => {
      if (!tableRef.current) return
      return createRowSelectionHandler({
        table: tableRef.current,
        rowSelection,
        setSelectedRows,
      })(updaterOrValue)
    },
    state: {
      sorting,
      rowSelection,
      columnVisibility: _.mapValues(_.keyBy(hideColumns), () => false),
    },
    enableSorting: true,
    enableSortingRemoval: true,
  })

  useEffect(() => {
    tableRef.current = table
  }, [table])

  useMount(() => {
    if (preselectFileIds && preselectFileIds.size > 0) {
      const preselectedRows: VaultItemWithIndex[] = []

      const processRow = (row: Row<VaultItem>) => {
        if (
          row.original.type === VaultItemType.file &&
          preselectFileIds.has(row.original.id)
        ) {
          preselectedRows.push({
            ...row.original,
            index: getPathForItem(row.original),
          })
        }
      }
      table.getExpandedRowModel().flatRows.forEach((row) => processRow(row))

      setSelectedRows(preselectedRows)
    }
  })

  const onRowClick = useCallback(
    async (row: Row<VaultItem>, e: React.MouseEvent | React.KeyboardEvent) => {
      handleVaultRowClick(row, e, {
        selectedRows,
        setSelectedRows,
        getPathForItem,
        lastSelectedRow,
        setLastSelectedRow,
        handleShiftSelection: createShiftSelectionHandlerFn,
        isAddingFilesToQuery: true,
        existingSelectedFileIds,
        shouldHideAllFiles,
      })
    },
    [
      selectedRows,
      setSelectedRows,
      getPathForItem,
      lastSelectedRow,
      setLastSelectedRow,
      createShiftSelectionHandlerFn,
      existingSelectedFileIds,
      shouldHideAllFiles,
    ]
  )

  const entity = 'project'
  const emptyStateText = isSharedProject
    ? `No files have been uploaded to this ${entity} yet`
    : `You haven’t uploaded any files to this ${entity} yet`

  const projectMetadata = useMemo(
    () => (projectId ? foldersMetadata[projectId] : undefined),
    [projectId, foldersMetadata]
  )
  const isFilesProcessing = projectMetadata
    ? projectMetadata.completedFiles !== projectMetadata.totalFiles
    : false

  const pollProjectData = useCallback(async () => {
    return await createProjectDataPoller({
      projectId,
      upsertVaultFolders,
      upsertVaultFiles,
      addToProjectsMetadata,
      userId: userInfo.dbId,
      exampleProjectIds,
      deleteVaultFiles,
      deleteVaultFolders,
      setError,
      projectIdToFolderIds,
      folderIdToVaultFolder: allFolderIdToVaultFolder,
    })
  }, [
    projectId,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    userInfo.dbId,
    exampleProjectIds,
    deleteVaultFiles,
    deleteVaultFolders,
    setError,
    projectIdToFolderIds,
    allFolderIdToVaultFolder,
  ])

  useInterval(pollProjectData, isFilesProcessing ? 10_000 : null)

  useEffect(() => {
    const refetchProjectData = async () => {
      await pollProjectData()
      setRequiresProjectDataRefetch(false)
    }
    if (requiresProjectDataRefetch) {
      void refetchProjectData()
    }
  }, [
    requiresProjectDataRefetch,
    pollProjectData,
    setRequiresProjectDataRefetch,
  ])

  useUnmount(() => {
    setSelectedRows([])
    setOpenFileId(null)
  })

  return (
    <div className={className}>
      <DataTable
        caption={`${entity} files`}
        table={table}
        onRowClick={onRowClick}
        className={cn('rounded-none px-6', {
          'max-h-[308px] rounded-lg': isDisplayingFilesProgress,
        })}
        hideTableBorder={!isDisplayingFilesProgress}
        tableCellClassName={cn(
          'px-1 py-2.5 first:px-0 first:py-1 last:px-0 last:py-1 font-normal select-none bg-primary'
        )}
        emptyStateText={emptyStateText}
        useVirtual
        virtualEstimateSize={41}
        marginTop={256}
        hideHeaders={isDisplayingFilesProgress}
        useModalStyling={useModalStyling}
        rowFilter={
          shouldHideAllFiles
            ? (row) => row.original.type !== VaultItemType.file
            : undefined
        }
      />
      {renderWarningMessages(selectedRows, {
        numberOfFilesLimit,
        numberOfFilesWarningThreshold,
        showDocumentClassificationMismatchWarning,
        isAddingFilesToQuery: true,
      })}
    </div>
  )
}

export default VaultFileExplorerInModal
