import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { TaskType } from 'types/task'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import * as AssistantAPI from 'components/assistant/utils/assistant-api'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { MAX_RECENT_QUERIES } from 'components/vault/components/vault-recent-queries'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  MAX_CAPTION_LENGTH,
  NUM_ALL_QUERIES_TO_FETCH,
} from 'components/vault/utils/vault'
import { UpdateReviewQuery } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultQueryEditTitleDialog = () => {
  const queryClient = useQueryClient()

  const [isUpdatingTitle, setIsUpdatingTitle] = useState(false)
  const [editedCaption, setEditedCaption] = useState('')
  const [
    currentProject,
    isEditQueryTitleDialogOpen,
    editQueryTaskType,
    setIsEditQueryTitleDialogOpen,
    updateQueryIdToStateTitle,
  ] = useVaultStore(
    useShallow((s) => [
      s.currentProject,
      s.isEditQueryTitleDialogOpen,
      s.editQueryTaskType,
      s.setIsEditQueryTitleDialogOpen,
      s.updateQueryIdToStateTitle,
    ])
  )
  const [gridApi, queryTitle, queryId, updateQueryTitle, setHistoryItem] =
    useVaultQueryDetailStore(
      useShallow((s) => [
        s.gridApi,
        s.queryTitle,
        s.queryId,
        s.updateQueryTitle,
        s.setHistoryItem,
      ])
    )

  const onUpdateTitle = async (newTitle: string) => {
    if (!newTitle.trim().length || !queryId) return
    if (!currentProject) return
    try {
      setIsUpdatingTitle(true)
      updateQueryTitle(newTitle)

      if (editQueryTaskType === TaskType.VAULT_REVIEW) {
        await UpdateReviewQuery({ queryId, title: newTitle })
        // we need to update the title in queryIdToState (for the recent queries list)
        updateQueryIdToStateTitle(queryId, newTitle)

        // if we are not on the query-detail page we want to reset our query-detail state
        const doesNotHaveGridApi = !gridApi
        if (doesNotHaveGridApi) {
          setHistoryItem(null)
        }
        displaySuccessMessage('Query title updated')
      } else {
        await AssistantAPI.setCaption(queryId, newTitle)
        updateQueryIdToStateTitle(queryId, newTitle)
      }

      // we need to invalidate the query to get the latest title
      await queryClient.invalidateQueries({
        queryKey: [
          HarvQueryKeyPrefix.VaultHistoryQuery,
          currentProject.id,
          MAX_RECENT_QUERIES,
        ],
      })
      await queryClient.invalidateQueries({
        queryKey: [
          HarvQueryKeyPrefix.VaultHistoryQuery,
          currentProject.id,
          NUM_ALL_QUERIES_TO_FETCH,
        ],
      })
      await queryClient.invalidateQueries({
        queryKey: [
          HarvQueryKeyPrefix.VaultHistoryItemQuery,
          queryId,
          { type: 'full' },
        ],
      })
      setIsEditQueryTitleDialogOpen(false, null)
    } catch (e) {
      displayErrorMessage('Failed to update title')
    }
    setIsUpdatingTitle(false)
  }

  const handleCancel = () => {
    setIsEditQueryTitleDialogOpen(false, null)
    setEditedCaption('')
  }

  return (
    <Dialog
      open={isEditQueryTitleDialogOpen}
      onOpenChange={setIsEditQueryTitleDialogOpen}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit title</DialogTitle>
        </DialogHeader>
        <Input
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onUpdateTitle(e.currentTarget.value)
            }
          }}
          placeholder="New title"
          defaultValue={queryTitle}
          onChange={(e) => setEditedCaption(e.target.value)}
          maxLength={MAX_CAPTION_LENGTH}
        />
        <DialogFooter>
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button
            isLoading={isUpdatingTitle}
            disabled={!editedCaption.length}
            onClick={() => {
              onUpdateTitle(editedCaption)
            }}
          >
            Update
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultQueryEditTitleDialog
