import React, { memo } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useUnmount } from 'react-use'

import RedirectWithQuery from 'components/common/redirects/redirect-with-query'
import VaultAssistantModal from 'components/vault/dialogs/vault-assistant-modal'
import VaultCreateFolderDialog from 'components/vault/dialogs/vault-create-folder-dialog'
import VaultDeleteDialog from 'components/vault/dialogs/vault-delete-dialog'
import VaultMoveDialog from 'components/vault/dialogs/vault-move-dialog'
import VaultQueryEditTitleDialog from 'components/vault/dialogs/vault-query-edit-title-dialog'
import VaultRenameDialog from 'components/vault/dialogs/vault-rename-dialog'
import VaultUploadFilesDialog from 'components/vault/dialogs/vault-upload-files-dialog'
import {
  newProjectPath,
  projectsPath,
  projectsPathRaw,
} from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'
import VaultHome from 'components/vault/vault-home'
import VaultLayout from 'components/vault/vault-layout'
import VaultNewProject from 'components/vault/vault-new-project'
import VaultProjectLayout from 'components/vault/vault-project-layout'

const Vault = (): JSX.Element => {
  const setError = useVaultStore((state) => state.setError)

  useUnmount(() => {
    setError(null)
  })

  return (
    <>
      <VaultCreateFolderDialog />
      <VaultUploadFilesDialog />
      <VaultDeleteDialog />
      <VaultRenameDialog />
      <VaultMoveDialog />
      <VaultQueryEditTitleDialog />
      <VaultAssistantModal />
      <Routes>
        <Route path="/" element={<VaultLayout />}>
          <Route index element={<RedirectWithQuery to={projectsPathRaw} />} />
          <Route path={projectsPath} element={<VaultHome />} />
          <Route path={newProjectPath} element={<VaultNewProject />} />
          <Route
            path={`${projectsPath}:projectId/*`}
            element={<VaultProjectLayout />}
          />
        </Route>
      </Routes>
    </>
  )
}

export default memo(Vault)
