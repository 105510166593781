import React from 'react'

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getGroupedRowModel,
} from '@tanstack/react-table'
import { Row } from '@tanstack/react-table'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'
import { TaskType } from 'types/task'

import { getRouteForLibraryEvent } from 'utils/routing'
import { useAllTaskLabelLookup } from 'utils/task-definitions'
import { getPracticeAreaText } from 'utils/user-profile-helpers'
import { cn } from 'utils/utils'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { LibraryItem, LibraryItemKind } from 'components/library/library-types'
import { DataTable } from 'components/ui/data-table/data-table'

import LibraryItemDeleteDialog from './library-delete-dialog'
import { LibraryFilterKey, FilterFunction } from './library-filter-store'
import {
  useGetLibraryItemDeleted,
  useLibraryMetadataStore,
} from './library-metadata-store'
import { getNavigateOptionsState } from './library.helpers'

interface LibraryTableProps {
  isLoading: boolean
  items: LibraryItem[]
  getColumns: (
    onDeleteItem: (item: LibraryItem) => void,
    onHideItem: (item: LibraryItem) => void
  ) => any[]
  filterFunctions: FilterFunction<LibraryItem>[]
  kind: LibraryItemKind
  visibilityScope: LibraryVisbilityScope
}

export const LibraryTable: React.FC<LibraryTableProps> = ({
  isLoading,
  items,
  getColumns,
  filterFunctions,
  kind,
  visibilityScope,
}) => {
  const userInfo = useAuthUser()
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const userPracticeAreas = React.useMemo(
    () =>
      new Set(
        userInfo.userProfile?.practiceAreas?.map((pa) =>
          getPracticeAreaText(pa.name)
        ) || []
      ),
    [userInfo.userProfile?.practiceAreas]
  )

  const { trackEvent } = useAnalytics()

  const [
    getFavoriteStatus,
    getHiddenStatus,
    updateLibraryItemHidden,
    deleteLibraryItem,
  ] = useLibraryMetadataStore(
    useShallow((s) => [
      s.getFavoriteStatus,
      s.getHiddenStatus,
      s.updateLibraryItemHidden,
      s.deleteLibraryItem,
    ])
  )
  const getLibraryItemDeleted = useGetLibraryItemDeleted()

  const [selectedItemForDelete, setSelectedItemForDelete] =
    React.useState<LibraryItem | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

  React.useMemo(() => {
    if (!deleteModalOpen) {
      setSelectedItemForDelete(null)
    }
  }, [deleteModalOpen])

  const onDeleteItem = React.useCallback(
    (item: LibraryItem) => {
      setSelectedItemForDelete(item)
      Services.HoneyComb.Record({
        metric: `ui.library_table_delete_${kind.toLowerCase()}_dialog_opened`,
        task_type: item.eventKind,
        item_id: item.id,
        starred: item.starred,
        workspace_id: item.workspaceId,
        user_id: item.userId,
        visibility_scope: item.visibilityScope,
        categories: item.categories,
        practice_areas: item.practiceAreas,
        document_types: item.documentTypes,
      })
      trackEvent('Library Table Delete Dialog Opened', {
        item_id: item.id,
        event_id: item.eventId,
        kind: kind,
        task_type: item.eventKind,
        categories: item.categories,
        practice_areas: item.practiceAreas,
        document_types: item.documentTypes,
        visibility_scope: item.visibilityScope,
        starred: item.starred,
      })
      setDeleteModalOpen(true)
    },
    [kind, trackEvent]
  )

  const onHideItem = React.useCallback(
    async (item: LibraryItem) => {
      const newValue = !getHiddenStatus(item)
      Services.HoneyComb.Record({
        metric: `ui.library_table_hide_${item.kind.toLowerCase()}`,
        task_type: taskLabelLookup[item.eventKind],
        item_id: item.id,
        starred: item.starred,
        hidden: newValue,
        workspace_id: item.workspaceId,
        user_id: item.userId,
        visibility_scope: item.visibilityScope,
        categories: item.categories,
        practice_areas: item.practiceAreas,
        document_types: item.documentTypes,
      })
      trackEvent('Library Table Item Hidden', {
        item_id: item.id,
        event_id: item.eventId,
        kind: kind,
        task_type: item.eventKind,
        categories: item.categories,
        practice_areas: item.practiceAreas,
        document_types: item.documentTypes,
        visibility_scope: item.visibilityScope,
        starred: item.starred,
        hidden: newValue,
      })
      await updateLibraryItemHidden(item.id, newValue)
    },
    [
      getHiddenStatus,
      kind,
      taskLabelLookup,
      updateLibraryItemHidden,
      trackEvent,
    ]
  )

  const columns = React.useMemo(
    () => getColumns(onDeleteItem, onHideItem),
    [getColumns, onDeleteItem, onHideItem]
  )

  const filteredItems = React.useMemo(() => {
    const combinedSort = (a: LibraryItem, b: LibraryItem): number => {
      const aStarred = getFavoriteStatus(a)
      const bStarred = getFavoriteStatus(b)

      // Get practice area match counts
      const aMatches = !a.practiceAreas.length
        ? 0
        : a.practiceAreas.filter((paStr) => userPracticeAreas.has(paStr)).length
      const bMatches = !b.practiceAreas.length
        ? 0
        : b.practiceAreas.filter((paStr) => userPracticeAreas.has(paStr)).length

      // Convert to boolean for easier comparison
      const aHasMatch = aMatches > 0
      const bHasMatch = bMatches > 0

      // Priority groups:
      // 1. Starred + Practice Match
      // 2. Starred only
      // 3. Practice Match only
      // 4. Neither
      if (aStarred !== bStarred || aHasMatch !== bHasMatch) {
        const aScore = (aStarred ? 2 : 0) + (aHasMatch ? 1 : 0)
        const bScore = (bStarred ? 2 : 0) + (bHasMatch ? 1 : 0)
        return bScore - aScore // Higher score first
      }

      // Within same group, sort by number of practice area matches
      if (aMatches !== bMatches) {
        return bMatches - aMatches
      }

      // Finally sort by update date if everything else is equal
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    }

    return items
      .sort(combinedSort)
      .filter((record: LibraryItem) => !getLibraryItemDeleted(record))
      .filter((record: LibraryItem) =>
        filterFunctions.every((filterFunc) =>
          filterFunc({
            ...record,
            kind: record.eventKind,
          } as LibraryItem & {
            kind: TaskType
          })
        )
      )
  }, [
    items,
    getFavoriteStatus,
    userPracticeAreas,
    getLibraryItemDeleted,
    filterFunctions,
  ])

  const tableInstance = useReactTable({
    data: filteredItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
  })

  const visibilityScopeText =
    visibilityScope == LibraryVisbilityScope.WORKSPACE
      ? 'team'
      : visibilityScope == LibraryVisbilityScope.HARVEY
      ? 'Harvey'
      : 'private'

  const trackRowEvent = (row: Row<LibraryItem>, inNewTab: boolean) => {
    trackEvent(
      'Library Item Loaded',
      {
        kind: kind,
        starred: row.original.starred,
        visibility_scope: row.original.visibilityScope,
        entry_point: `${kind}_TABLE`,
        in_new_tab: inNewTab,
      },
      /* suppressPageView */ true
    )
  }

  function getModeFromEventKind(eventKind: string) {
    if (eventKind === EventKind.ASSISTANT_DRAFT) {
      return AssistantMode.DRAFT
    } else if (eventKind === EventKind.ASSISTANT_CHAT) {
      return AssistantMode.ASSIST
    }
    return undefined
  }

  return (
    <div className={cn(`library-table-${kind.toLowerCase()}`, 'min-h-0')}>
      <DataTable
        caption="Library Items"
        className="h-full"
        table={tableInstance}
        isLoading={isLoading}
        useVirtual={items.length > 15}
        virtualEstimateSize={65}
        onRowClick={() => {}}
        hrefForRow={(row) =>
          getRouteForLibraryEvent({
            event: {
              kind: row.original.eventKind,
              id: row.original.eventId,
              libraryItemKind: row.original.kind,
              workflowSlug: row.original.workflowSlug,
            },
            userInfo,
            libraryItemKind: row.original.kind,
            navigateOptions:
              // Only set navigate options for Assistant V2 users and non-workflow events
              userInfo.IsAssistantV2User &&
              row.original.eventKind !== EventKind.ASSISTANT
                ? getNavigateOptionsState(row.original)
                : undefined,
          })
        }
        hrefOptions={(row) => ({
          removeParams: Object.values(LibraryFilterKey),
          navigateOptions: {
            state: {
              mode: getModeFromEventKind(row.original.eventKind),
            },
          },
        })}
        trackRowEvent={trackRowEvent}
        emptyStateText={`No ${visibilityScopeText} ${kind.toLowerCase()}s found`}
      />
      {selectedItemForDelete && (
        <LibraryItemDeleteDialog
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          libraryItem={selectedItemForDelete}
          taskLabelLookup={taskLabelLookup}
          deleteItem={deleteLibraryItem}
          kind={kind}
        />
      )}
    </div>
  )
}
