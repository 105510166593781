import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import type { DocumentClassificationTag } from 'openapi/models/DocumentClassificationTag'
import { DocumentClassificationTagChild } from 'openapi/models/DocumentClassificationTagChild'
import { DocumentClassificationTagParent } from 'openapi/models/DocumentClassificationTagParent'

import { PillColor } from 'components/ui/pill/pill'

export const DOCUMENT_CLASSIFICATION_PARENT_COLOR_MAPPING = {
  [DocumentClassificationTagParent.CONTRACT]: PillColor.COLOR1,
  [DocumentClassificationTagParent.LITIGATION_DOCUMENT]: PillColor.COLOR2,
  [DocumentClassificationTagParent.REGULATORY_DOCUMENT]: PillColor.COLOR3,
  [DocumentClassificationTagParent.FINANCIAL_DOCUMENT]: PillColor.COLOR4,
  [DocumentClassificationTagParent.STATUTORY_LAW]: PillColor.COLOR5,
  [DocumentClassificationTagParent.EMAIL]: PillColor.COLOR6,
  [DocumentClassificationTagParent.OTHER]: PillColor.COLOR7,
}

export const DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING = {
  [DocumentClassificationTagChild.ASSIGNMENT_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.COLLABORATION_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.COLLECTIVE_BARGAINING_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.COMMERCIAL_CONTRACT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.CONTRACT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.CONTRIBUTION_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.CREDIT_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.DATA_PROCESSING_ADDENDUM]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.DEED]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.DISCLOSURE_SCHEDULE]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.EMPLOYEE_BENEFITS_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.EMPLOYMENT_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.ESCROW_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.GUARANTY]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.INDENTURE]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.INSURANCE_POLICY]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.INVESTMENT_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.INVESTOR_RIGHTS_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.JOINDER]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.JOINT_VENTURE_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.LEASE]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.LETTER_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.LETTER_OF_TRANSMITTAL]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.LICENSE_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.LIMITED_PARTNERSHIP_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.LOAN_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.LOCKUP_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.MERGER_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.MORTGAGE_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.NON_DISCLOSURE_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.OPERATING_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.ORDER_FORM]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.PARTNERSHIP_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.PLAN_OF_REORGANIZATION]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.PURCHASE_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.REGISTRATION_RIGHTS_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.SECURITY_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.SEVERANCE_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.SHAREHOLDER_RIGHTS_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.SIDE_LETTER]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.STOCK_OPTION_GRANTS]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.STOCK_PURCHASE_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.TERMS_OF_SERVICE]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.TRANSITION_SERVICES_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.VOTING_AGREEMENT]:
    DocumentClassificationTagParent.CONTRACT,
  [DocumentClassificationTagChild.ANSWER]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.BRIEF]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.COMPLAINT]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.COURT_DECISION]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.DISCOVERY]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.EXPERT_REPORT]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.MOTION]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.TRANSCRIPT]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.OTHER_COURT_FILING]:
    DocumentClassificationTagParent.LITIGATION_DOCUMENT,
  [DocumentClassificationTagChild.AGENCY_COMPLAINT]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.AGENCY_CORRESPONDENCE]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.AGENCY_OPINION]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.REGULATORY_FILING]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.REGULATORY_LAW]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.PATENT]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.PATENT_APPLICATION]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.TAX_RETURN]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.TRADEMARK_APPLICATION]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.TRADEMARK_REGISTRATION]:
    DocumentClassificationTagParent.REGULATORY_DOCUMENT,
  [DocumentClassificationTagChild.BALANCE_SHEET]:
    DocumentClassificationTagParent.FINANCIAL_DOCUMENT,
  [DocumentClassificationTagChild.CAPITALIZATION_TABLE]:
    DocumentClassificationTagParent.FINANCIAL_DOCUMENT,
  [DocumentClassificationTagChild.FINANCIAL_STATEMENT]:
    DocumentClassificationTagParent.FINANCIAL_DOCUMENT,
  [DocumentClassificationTagChild.STATUTORY_LAW]:
    DocumentClassificationTagParent.STATUTORY_LAW,
  [DocumentClassificationTagChild.EMAIL]: DocumentClassificationTagParent.EMAIL,
  [DocumentClassificationTagChild.ADVISORY_OPINION]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.ANNUAL_REPORT]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.ARTICLES_OF_INCORPORATION]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.ARTICLES_OF_ORGANIZATION]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.BOARD_MINUTES]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.BOND]: DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.BYLAWS]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.COMPANY_POLICY]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.EMPLOYEE_STOCK_PURCHASE_PLAN]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.INSURANCE_CERTIFICATE]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.INVOICE]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.LICENSE]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.MEMORANDUM]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.OTHER]: DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.PROSPECTUS]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.RECEIPT]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.SURVEY]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.TRUST]: DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.WILL]: DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.WRITTEN_CONSENT]:
    DocumentClassificationTagParent.OTHER,
  [DocumentClassificationTagChild.CERTIFICATE_OF_DESIGNATION]:
    DocumentClassificationTagParent.OTHER,
}

export const DOCUMENT_CLASSIFICATION_PARENT_TAG_MAPPING = {
  [DocumentClassificationTagParent.CONTRACT]: [
    DocumentClassificationTagChild.ASSIGNMENT_AGREEMENT,
    DocumentClassificationTagChild.COLLABORATION_AGREEMENT,
    DocumentClassificationTagChild.COLLECTIVE_BARGAINING_AGREEMENT,
    DocumentClassificationTagChild.COMMERCIAL_CONTRACT,
    DocumentClassificationTagChild.CONTRACT,
    DocumentClassificationTagChild.CONTRIBUTION_AGREEMENT,
    DocumentClassificationTagChild.CREDIT_AGREEMENT,
    DocumentClassificationTagChild.DATA_PROCESSING_ADDENDUM,
    DocumentClassificationTagChild.DEED,
    DocumentClassificationTagChild.DISCLOSURE_SCHEDULE,
    DocumentClassificationTagChild.EMPLOYEE_BENEFITS_AGREEMENT,
    DocumentClassificationTagChild.EMPLOYMENT_AGREEMENT,
    DocumentClassificationTagChild.ESCROW_AGREEMENT,
    DocumentClassificationTagChild.GUARANTY,
    DocumentClassificationTagChild.INDENTURE,
    DocumentClassificationTagChild.INSURANCE_POLICY,
    DocumentClassificationTagChild.INVESTMENT_AGREEMENT,
    DocumentClassificationTagChild.INVESTOR_RIGHTS_AGREEMENT,
    DocumentClassificationTagChild.JOINDER,
    DocumentClassificationTagChild.JOINT_VENTURE_AGREEMENT,
    DocumentClassificationTagChild.LEASE,
    DocumentClassificationTagChild.LETTER_AGREEMENT,
    DocumentClassificationTagChild.LETTER_OF_TRANSMITTAL,
    DocumentClassificationTagChild.LICENSE_AGREEMENT,
    DocumentClassificationTagChild.LIMITED_PARTNERSHIP_AGREEMENT,
    DocumentClassificationTagChild.LOAN_AGREEMENT,
    DocumentClassificationTagChild.LOCKUP_AGREEMENT,
    DocumentClassificationTagChild.MERGER_AGREEMENT,
    DocumentClassificationTagChild.MORTGAGE_AGREEMENT,
    DocumentClassificationTagChild.NON_DISCLOSURE_AGREEMENT,
    DocumentClassificationTagChild.OPERATING_AGREEMENT,
    DocumentClassificationTagChild.ORDER_FORM,
    DocumentClassificationTagChild.PARTNERSHIP_AGREEMENT,
    DocumentClassificationTagChild.PLAN_OF_REORGANIZATION,
    DocumentClassificationTagChild.PURCHASE_AGREEMENT,
    DocumentClassificationTagChild.REGISTRATION_RIGHTS_AGREEMENT,
    DocumentClassificationTagChild.SECURITY_AGREEMENT,
    DocumentClassificationTagChild.SEVERANCE_AGREEMENT,
    DocumentClassificationTagChild.SHAREHOLDER_RIGHTS_AGREEMENT,
    DocumentClassificationTagChild.SIDE_LETTER,
    DocumentClassificationTagChild.STOCK_OPTION_GRANTS,
    DocumentClassificationTagChild.STOCK_PURCHASE_AGREEMENT,
    DocumentClassificationTagChild.TERMS_OF_SERVICE,
    DocumentClassificationTagChild.TRANSITION_SERVICES_AGREEMENT,
    DocumentClassificationTagChild.VOTING_AGREEMENT,
  ],
  [DocumentClassificationTagParent.LITIGATION_DOCUMENT]: [
    DocumentClassificationTagChild.ANSWER,
    DocumentClassificationTagChild.BRIEF,
    DocumentClassificationTagChild.COMPLAINT,
    DocumentClassificationTagChild.COURT_DECISION,
    DocumentClassificationTagChild.DISCOVERY,
    DocumentClassificationTagChild.EXPERT_REPORT,
    DocumentClassificationTagChild.MOTION,
    DocumentClassificationTagChild.OTHER_COURT_FILING,
    DocumentClassificationTagChild.TRANSCRIPT,
  ],
  [DocumentClassificationTagParent.REGULATORY_DOCUMENT]: [
    DocumentClassificationTagChild.AGENCY_COMPLAINT,
    DocumentClassificationTagChild.AGENCY_CORRESPONDENCE,
    DocumentClassificationTagChild.AGENCY_OPINION,
    DocumentClassificationTagChild.REGULATORY_FILING,
    DocumentClassificationTagChild.REGULATORY_LAW,
    DocumentClassificationTagChild.PATENT,
    DocumentClassificationTagChild.PATENT_APPLICATION,
    DocumentClassificationTagChild.TAX_RETURN,
    DocumentClassificationTagChild.TRADEMARK_APPLICATION,
    DocumentClassificationTagChild.TRADEMARK_REGISTRATION,
  ],
  [DocumentClassificationTagParent.FINANCIAL_DOCUMENT]: [
    DocumentClassificationTagChild.BALANCE_SHEET,
    DocumentClassificationTagChild.CAPITALIZATION_TABLE,
    DocumentClassificationTagChild.FINANCIAL_STATEMENT,
  ],
  [DocumentClassificationTagParent.STATUTORY_LAW]: [
    DocumentClassificationTagChild.STATUTORY_LAW,
  ],
  [DocumentClassificationTagParent.EMAIL]: [
    DocumentClassificationTagChild.EMAIL,
  ],
  [DocumentClassificationTagParent.OTHER]: [
    DocumentClassificationTagChild.ADVISORY_OPINION,
    DocumentClassificationTagChild.ANNUAL_REPORT,
    DocumentClassificationTagChild.ARTICLES_OF_INCORPORATION,
    DocumentClassificationTagChild.ARTICLES_OF_ORGANIZATION,
    DocumentClassificationTagChild.BOARD_MINUTES,
    DocumentClassificationTagChild.BOND,
    DocumentClassificationTagChild.BYLAWS,
    DocumentClassificationTagChild.CERTIFICATE_OF_DESIGNATION,
    DocumentClassificationTagChild.COMPANY_POLICY,
    DocumentClassificationTagChild.EMPLOYEE_STOCK_PURCHASE_PLAN,
    DocumentClassificationTagChild.INSURANCE_CERTIFICATE,
    DocumentClassificationTagChild.INVOICE,
    DocumentClassificationTagChild.LICENSE,
    DocumentClassificationTagChild.MEMORANDUM,
    DocumentClassificationTagChild.OTHER,
    DocumentClassificationTagChild.PROSPECTUS,
    DocumentClassificationTagChild.RECEIPT,
    DocumentClassificationTagChild.SURVEY,
    DocumentClassificationTagChild.TRUST,
    DocumentClassificationTagChild.WILL,
    DocumentClassificationTagChild.WRITTEN_CONSENT,
  ],
}

interface DocumentClassificationState {
  documentClassificationTags: DocumentClassificationTag[]
  openFileId: string | null
  bulkOpen: boolean
}

interface DocumentClassificationAction {
  setDocumentClassificationTags: (
    documentClassificationTags: DocumentClassificationTag[]
  ) => void
  setOpenFileId: (fileId: string | null) => void
  setBulkOpen: (bulkOpen: boolean) => void
}

export const useDocumentClassificationStore = create(
  devtools(
    immer<DocumentClassificationState & DocumentClassificationAction>(
      (set) => ({
        documentClassificationTags: [],
        openFileId: null,
        bulkOpen: false,
        setDocumentClassificationTags: (documentClassificationTags) =>
          set({ documentClassificationTags }),
        setOpenFileId: (fileId) => set({ openFileId: fileId }),
        setBulkOpen: (bulkOpen) => set({ bulkOpen }),
      })
    )
  )
)
