import { HeadingLevel, convertMillimetersToTwip } from 'docx'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { WordSection, WordSectionType } from 'utils/docx'
import { exportWordWithSections, replaceSpanWithCitation } from 'utils/markdown'
import { Source, TaskType } from 'utils/task'

import { AssistantMessage } from 'components/assistant/types'
import { getMessageDiff } from 'components/assistant/utils/assistant-message-diff'

import { getMessageQuery } from './assistant-helpers'

export const handleExport = async (params: {
  eventId: string
  messages: AssistantMessage[]
  documents?: UploadedFile[]
  includeAnnotation: boolean
  taskType: TaskType
  exportTitle?: string
  additionalSections?: WordSection[]
  exportMessageIndex?: number
  showEdits?: boolean
  includeFileNames?: boolean
}) => {
  const {
    messages,
    documents,
    includeAnnotation,
    taskType,
    exportTitle,
    additionalSections,
    exportMessageIndex,
    showEdits,
    includeFileNames = true,
  } = params

  const taskPrefix = taskType === TaskType.ASSISTANT_DRAFT ? 'Draft' : 'Assist'
  const filesSections =
    documents && documents.length > 0
      ? ([
          {
            content: `## Files\n\n${documents
              .map((doc) => `- ${doc.name}`)
              .join('\n')}`,
            type: 'markdown',
          },
          { content: `<br/>`, type: 'html' },
        ] as WordSection[])
      : []

  await exportWordWithSections({
    filePrefixOverride: `${taskPrefix}${exportTitle ? '_' + exportTitle : ''}`,
    title: `${taskPrefix} – ${
      exportTitle || messages[0]?.caption || getMessageQuery(messages[0])
    }`,
    taskType: taskType,
    includeAnnotation,
    queryId: params.eventId,
    useRemark: true,
    sections: [
      ...messages.flatMap((message, index) => {
        if (
          !message ||
          (exportMessageIndex !== undefined && index !== exportMessageIndex)
        )
          return []
        let queryContent = `## Query\n\n${getMessageQuery(message)}`
        if (exportMessageIndex) {
          const anchorQuery = exportMessageIndex
            ? getMessageQuery(messages[0])
            : getMessageQuery(message)
          const numIntermediateRevisions = exportMessageIndex - 1
          const selectedRevisionQuery = getMessageQuery(
            messages[exportMessageIndex]
          )
          queryContent = `## Query\n\n- ${anchorQuery}`
          if (numIntermediateRevisions > 0) {
            queryContent += `\n- [${numIntermediateRevisions} Revision${
              numIntermediateRevisions !== 1 ? 's' : ''
            }]`
          }
          queryContent += `\n- ${selectedRevisionQuery}`
        }

        const response = getMessageDiff(messages, message, !!showEdits)
        const responseWithCitation = replaceSpanWithCitation(response)
        // In the diff view, we replace the del tags with tildes as that is standard markdown for strikethrough
        // The remarkHTML plugin (more specifically the remark-rehype plugin) will remove all html tags, so we need to replace them with tildes instead
        const responseWithDiff = responseWithCitation
          .replace(/<\/?del>/g, '~')
          .replace(/<\/?ins>/g, '++')
        const sections: WordSection[] = [
          {
            content: queryContent,
            type: WordSectionType.MARKDOWN,
          },
          { content: `<br/>`, type: WordSectionType.HTML },
          {
            content: `## Response\n\n${responseWithDiff}`,
            type: WordSectionType.MARKDOWN,
          },
          { content: `<br/>`, type: WordSectionType.HTML },
        ]

        if (includeFileNames) {
          sections.push(...filesSections)
        }

        if (includeAnnotation) {
          sections.push({
            content: message.sources as Source[],
            options: {
              heading: HeadingLevel.HEADING_3,
              spacing: {
                before: convertMillimetersToTwip(1),
                after: convertMillimetersToTwip(1),
              },
            },
            type: WordSectionType.SOURCES,
          })
        }
        sections.push({ content: `<br/>`, type: WordSectionType.HTML })

        return sections
      }),
      ...(additionalSections || []),
    ],
  })
}
