import { LoadingState } from 'openapi/models/LoadingState'
import { LoadingStateStatus } from 'openapi/models/LoadingStateStatus'
import { WorkflowDefinition } from 'openapi/models/WorkflowDefinition'
import { WorkflowEventStatusStepsInner } from 'openapi/models/WorkflowEventStatusStepsInner'

import {
  AssistantWorkflowSourceExtractor,
  AssistantWorkflowStepNameToDefinition,
  BlockTypeMap,
} from 'components/assistant/workflows'

export interface Step {
  stepIdx: number
  [key: string]: any
}

/**
 * Merges `newSteps` into `existingSteps` by matching on `stepIdx`.
 * - Existing steps are updated if they share the same `stepIdx`.
 * - New steps that are not in existingSteps are appended.
 * - The result is sorted by `stepIdx`.
 */
export const mergeSteps = (existingSteps: Step[], newSteps: Step[]): Step[] => {
  // Update existing steps if there's a matching step
  const mergedSteps = existingSteps.map((oldStep) => {
    const updatedStep = newSteps.find((s) => s.stepIdx === oldStep.stepIdx)
    return updatedStep ? { ...oldStep, ...updatedStep } : oldStep
  })

  // Append any new steps that don't exist yet
  newSteps.forEach((step) => {
    const exists = mergedSteps.some((s) => s.stepIdx === step.stepIdx)
    if (!exists) {
      mergedSteps.push({ ...step })
    }
  })

  // Sort by stepIdx
  mergedSteps.sort((a, b) => (a.stepIdx ?? 0) - (b.stepIdx ?? 0))

  return mergedSteps
}

/**
 * Heuristic for if we're in an active loading state that will generate citations.
 */
export const inActiveCitationsLoadingState = (
  loadingStates: LoadingState[]
): boolean => {
  if (loadingStates.length === 0) {
    return false
  }
  const lastState = loadingStates[loadingStates.length - 1]
  return (
    /(citations|sources)/i.test(lastState.name) &&
    lastState.status !== LoadingStateStatus.DONE
  )
}

export const getSourcesFromStep = (
  step: WorkflowEventStatusStepsInner | undefined,
  workflowDefinition: WorkflowDefinition | null
) => {
  if (!step || !workflowDefinition) return []
  const blockParams = step.blockParams as any
  const sourceExtractor = AssistantWorkflowStepNameToDefinition[
    workflowDefinition.steps.find((s) => s.id === step.blockId)
      ?.blockType as keyof BlockTypeMap
  ]().sourceExtractor as
    | AssistantWorkflowSourceExtractor<keyof BlockTypeMap>
    | undefined
  if (!sourceExtractor) return []
  const sources = sourceExtractor(blockParams)
  return sources
}
