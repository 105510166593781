import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { CirclePlusIcon, FileIcon, LoaderCircleIcon, Trash } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { uploadFile } from 'api'
import { EventStatus } from 'openapi/models/EventStatus'
import { KnowledgeSourceType } from 'openapi/models/KnowledgeSourceType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { useFileCache } from 'stores/file-cache'

import { onDrop } from 'utils/dropzone'
import {
  bytesToReadable,
  getFileIconFromUploadedFile,
  mbToBytes,
} from 'utils/file-utils'
import { displayErrorMessage, displayWarningMessage } from 'utils/toast'

import { useLocationChatQuery } from 'components/assistant/hooks/use-location-chat-query'
import {
  FileKnowledgeSource,
  FileSource,
  KnowledgeSourceItem,
} from 'components/assistant/utils/assistant-knowledge-sources'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MAX_ZIP_FILE_SIZE,
  NUM_MAX_FILES,
} from 'components/assistant/utils/constants'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import { EditExistingQueryInput } from 'components/assistant/workflows/components/edit-existing-query'
import FollowUps from 'components/assistant/workflows/components/follow-ups/follow-ups'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import { QueryToolbar } from 'components/assistant/workflows/components/query-toolbar'
import TextInput from 'components/assistant/workflows/components/text-input/text-input'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useWorkflowAnalytics } from 'components/assistant/workflows/hooks/use-workflow-analytics'
import {
  emitter,
  useWorkflowEmitterEvents,
} from 'components/assistant/workflows/hooks/use-workflow-emitter-events'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import { Table, TableCell, TableRow } from 'components/ui/table'

import {
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowLayoutContainer,
  AssistantWorkflowLayoutIconComponent,
  AssistantWorkflowThreadBlock,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowFollowUpsThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FOLLOW_UPS
> = ({
  blockParams,
  outputData,
  completionStatus,
  onUpdated,
  isEditing,
  setIsEditing,
}) => {
  const [followUpListenerRegistered, setFollowUpListenerRegistered] =
    useState(false)
  const { relatedQuestions } = blockParams
  const initLocationState = useLocationChatQuery()
  const isCompleted = !!outputData || !!initLocationState.query
  const hasFollowupsReady = (relatedQuestions?.length || 0) > 1
  const listeners = emitter.all.get('followUpClicked')
  const hasListeners = listeners && listeners.length > 0
  const query = outputData?.value || initLocationState.query
  const streamInProgress = useAssistantWorkflowStore(
    useShallow((state) => state.streamInProgress)
  )

  const followUpsDisabled = React.useMemo(() => {
    return !hasFollowupsReady || !hasListeners || streamInProgress
  }, [hasFollowupsReady, hasListeners, streamInProgress])

  const handleSelectQuestion = (text: string) => {
    if (followUpsDisabled) return
    emitter.emit('followUpClicked', text)
  }

  const handleEdit = (text: string) => {
    if (!text.length) return
    const output: any = { value: text }
    if (outputData?.knowledgeSources)
      output.knowledgeSources = outputData.knowledgeSources
    onUpdated(output)
    setIsEditing(false)
  }

  useEffect(() => {
    // Forces a rerender after the followUpHandlerRegistered event is emitted,
    // which guarantees that hasListeners will have up-to-date state.
    emitter.on('followUpHandlerRegistered', () => {
      setFollowUpListenerRegistered(true)
    })
    return () => {
      emitter.off('followUpHandlerRegistered')
    }
  }, [hasListeners, followUpListenerRegistered])

  const submitEditDisabled = streamInProgress

  return (
    <AssistantWorkflowThreadBlock>
      {hasFollowupsReady && (
        <AssistantWorkflowLayoutContainer className="border-none">
          <AssistantWorkflowLayoutIconComponent />

          <FollowUps
            followUps={relatedQuestions!}
            onSelectQuestion={handleSelectQuestion}
            disabled={followUpsDisabled}
          />
        </AssistantWorkflowLayoutContainer>
      )}

      {isCompleted && (
        <>
          <AssistantWorkflowYouComponent>
            {isEditing ? (
              <EditExistingQueryInput
                isSubmitDisabled={submitEditDisabled}
                onSubmit={handleEdit}
                onCancel={() => setIsEditing(false)}
                existingQuery={query || ''}
              />
            ) : (
              <div className="flex flex-col">
                <AssistantWorkflowThreadText
                  completionStatus={completionStatus}
                  text={(query?.length || 0) > 0 ? query! : 'N/A'}
                  skipTypewriter
                />

                {/* Toolbar */}
                {query && (
                  <QueryToolbar
                    query={query}
                    onEdit={() => setIsEditing(true)}
                  />
                )}
              </div>
            )}
          </AssistantWorkflowYouComponent>

          {initLocationState.isLoading && (
            <AssistantWorkflowHarveyComponent>
              <PresubmitLoadingState />
            </AssistantWorkflowHarveyComponent>
          )}
        </>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowFollowUpsInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FOLLOW_UPS
> = ({ blockParams, onCompleted, workflowName, stepIdx }) => {
  const {
    placeholder,
    optional,
    canModifyKnowledgeSources,
    previousKnowledgeSources,
  } = blockParams
  const [knowledgeSources, setKnowledgeSources] = React.useState<
    KnowledgeSourceItem[] | null
  >((previousKnowledgeSources || null) as KnowledgeSourceItem[] | null)
  const [text, setText] = React.useState('')
  const trackEvent = useWorkflowAnalytics()
  const initLocationState = useLocationChatQuery()
  const isSubmitted = React.useRef(false)
  const onSubmit = useCallback(
    (
      passedText?: string,
      passedKnowledgeSource?: KnowledgeSourceItem | null
    ) => {
      if (isSubmitted.current) return
      isSubmitted.current = true

      const value = passedText ? passedText : text
      const kss = passedKnowledgeSource
        ? [passedKnowledgeSource]
        : knowledgeSources
      const res: any = { value }
      if (kss) res.knowledgeSources = kss
      onCompleted(res)
      setText('')
      trackEvent('Workflow Follow Up', {
        type: passedText ? 'clicking_follow_up' : 'typing_follow_up',
        workflow_name: workflowName,
        step_idx: stepIdx,
      })
    },
    [
      onCompleted,
      stepIdx,
      text,
      trackEvent,
      workflowName,
      isSubmitted,
      knowledgeSources,
    ]
  )

  useEffect(() => {
    // Fires the onSubmit function if the query is already present in the location state
    // once ready.
    if (!initLocationState.isLoading && initLocationState.query) {
      onSubmit(initLocationState.query, initLocationState.knowledgeSource)
    }
  }, [
    initLocationState.query,
    initLocationState.isLoading,
    initLocationState.knowledgeSource,
    onSubmit,
  ])

  useWorkflowEmitterEvents('followUpClicked', (text) => {
    onSubmit(text)
  })

  return (
    <WorkflowInput>
      <TextInput
        isLoading={initLocationState.isLoading}
        onSubmit={onSubmit}
        value={text}
        placeholder={placeholder}
        optional={optional}
        onChange={setText}
        additionalLeftButtons={
          canModifyKnowledgeSources && (
            <KnowledgeSourceModifier
              knowledgeSources={knowledgeSources}
              setKnowledgeSources={setKnowledgeSources}
            />
          )
        }
      />
    </WorkflowInput>
  )
}

/**
 * Modify existing knowledge sources (only files for now)
 * Currently uses file constraints from Assistant Composer
 */
const KnowledgeSourceModifier: React.FC<{
  knowledgeSources: KnowledgeSourceItem[] | null
  setKnowledgeSources: (knowledgeSource: KnowledgeSourceItem[] | null) => void
}> = ({ knowledgeSources, setKnowledgeSources }) => {
  const getFile = useFileCache((state) => state.getFile)
  const addFile = useFileCache((state) => state.addFile)
  const [existingFiles, setExistingFiles] = React.useState<UploadedFile[]>([])
  const [getEventId] = useAssistantWorkflowStore(
    useShallow((state) => [state.getEventId])
  )
  const [isFilesLoading, setIsFilesLoading] = React.useState(false)
  const [uploadingFiles, setUploadingFiles] = React.useState<File[]>([])

  const getFileIdsFromKnowledgeSource = useCallback(() => {
    if (!knowledgeSources) return []

    const fileIds = knowledgeSources.reduce<string[]>((acc, ks) => {
      if (Object.values(FileSource).includes(ks.type as FileSource)) {
        acc.push(...(ks as FileKnowledgeSource).fileIds)
      }
      return acc
    }, [])
    return fileIds
  }, [knowledgeSources])

  const updateFilesKnowledgeSource = useCallback(
    (cb: (ks: FileKnowledgeSource) => FileKnowledgeSource) => {
      let fileKnowledgeSource = knowledgeSources?.find(
        (ks) => ks.type === KnowledgeSourceType.FILES
      ) as FileKnowledgeSource | undefined

      if (!fileKnowledgeSource) {
        fileKnowledgeSource = {
          type: KnowledgeSourceType.FILES as any,
          fileIds: [],
        }
      }

      const updatedFileKnowledgeSource = cb(fileKnowledgeSource)

      setKnowledgeSources([updatedFileKnowledgeSource])
    },
    [knowledgeSources, setKnowledgeSources]
  )

  const handleAcceptedFiles = useCallback(
    async (files: File[]) => {
      if (!files.length) {
        displayErrorMessage('No files were accepted')
        setIsFilesLoading(false)
        return
      }

      setUploadingFiles(files)
      const eventId = await getEventId()

      Promise.all(
        files.map((file) =>
          uploadFile(file, false, {
            eventId: eventId ? String(eventId) : undefined,
          }).then((uploaded) => {
            addFile(uploaded)
            return uploaded
          })
        )
      )
        .then((newUploadedFiles) => {
          updateFilesKnowledgeSource((ks) => {
            return {
              ...ks,
              fileIds: ks.fileIds.concat(newUploadedFiles.map((f) => f.id)),
            }
          })
        })
        .catch((e) => {
          displayErrorMessage('Failed to upload file')
          console.error(e)
        })
        .finally(() => {
          setIsFilesLoading(false)
          setUploadingFiles([])
        })
    },
    [getEventId, addFile, updateFilesKnowledgeSource]
  )

  const onFileDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (isFilesLoading) {
        displayWarningMessage('Other files are still uploading')
        return
      }

      setIsFilesLoading(true)
      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: getFileIdsFromKnowledgeSource().length,
        maxFiles: NUM_MAX_FILES,
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(MAX_FILE_SIZE),
        maxZipFileSize: mbToBytes(MAX_ZIP_FILE_SIZE),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE),
          currentTotalFileSize: existingFiles.reduce(
            (total, file) => total + (file.size ?? 0),
            0
          ),
        },
        handleAcceptedFiles,
      })
    },
    [
      isFilesLoading,
      handleAcceptedFiles,
      getFileIdsFromKnowledgeSource,
      existingFiles,
    ]
  )

  useEffect(() => {
    const fileIds = getFileIdsFromKnowledgeSource()

    Promise.all(fileIds.map(getFile))
      .then((files) =>
        setExistingFiles(
          files.filter((f): f is UploadedFile => f !== undefined)
        )
      )
      .catch((e) => {
        console.error(e)
      })
  }, [
    getFile,
    getFileIdsFromKnowledgeSource,
    knowledgeSources,
    setExistingFiles,
  ])

  const { open } = useDropzone({ onDrop: onFileDrop })

  // If there are multiple knowledge sources, don't support adding files
  if ((knowledgeSources?.length || 0) > 1) return null
  // If there's only one knowledge source and it's not FILES, don't support adding files
  if (
    knowledgeSources?.length == 1 &&
    (knowledgeSources[0] as KnowledgeSourceItem).type !==
      KnowledgeSourceType.FILES
  )
    return null

  if (!existingFiles.length && !uploadingFiles.length) {
    return (
      <Button
        variant="ghost"
        className="pointer-events-auto justify-start whitespace-nowrap"
        onClick={open}
        size="sm"
        data-testid="add-files-button"
      >
        <CirclePlusIcon className="mr-1" size={16} />
        Add Files
      </Button>
    )
  } else {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="pointer-events-auto justify-start whitespace-nowrap"
            onClick={() => {}}
            size="sm"
          >
            {isFilesLoading ? (
              <LoaderCircleIcon className="mr-1 animate-spin" size={16} />
            ) : (
              <FileIcon className="mr-1" size={16} />
            )}
            {existingFiles.length + uploadingFiles.length}{' '}
            {pluralize('file', existingFiles.length)} added
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-96 space-y-4">
          <div className="flex w-full justify-between">
            <p className="font-semibold">Files</p>
            <div className="flex space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={open}
                disabled={isFilesLoading}
              >
                Attach more
              </Button>
              <Button
                data-testid="remove-all-files-button"
                variant="outline"
                size="sm"
                onClick={() => setKnowledgeSources(null)}
              >
                Remove all
              </Button>
            </div>
          </div>
          <ScrollArea className="mt-2 flex h-64 w-full flex-col">
            <Table className="w-full table-fixed">
              {existingFiles.filter(Boolean).map((file) => {
                const FileIcon = getFileIconFromUploadedFile(file)

                return (
                  <TableRow key={file.id}>
                    <TableCell className="w-[50%] p-2">
                      <div className="flex">
                        <FileIcon className="mr-2 size-5 shrink-0" />
                        <p className="truncate text-sm">{file.name}</p>
                      </div>
                    </TableCell>
                    <TableCell className="w-[30%] p-2">
                      <p className="text-sm">
                        {file.size ? bytesToReadable(file.size) : 'N/A'}
                      </p>
                    </TableCell>
                    <TableCell className="w-1/5 p-2">
                      <Button
                        data-testid="remove-file-button"
                        variant="ghost"
                        size="smIcon"
                        onClick={() =>
                          updateFilesKnowledgeSource((ks) => ({
                            ...ks,
                            fileIds: ks.fileIds.filter((id) => id !== file.id),
                          }))
                        }
                      >
                        <Icon icon={Trash} size="small" />
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}

              {uploadingFiles.map((file) => (
                <TableRow key={file.name}>
                  <TableCell className="w-[50%] p-2">
                    <div className="flex">
                      <FileIcon className="mr-2 size-5 shrink-0 animate-pulse" />
                      <p className="text-muted-foreground truncate text-sm">
                        {file.name} (uploading...)
                      </p>
                    </div>
                  </TableCell>
                  <TableCell className="w-[30%] p-2">
                    <p className="text-muted-foreground text-sm">
                      {bytesToReadable(file.size)}
                    </p>
                  </TableCell>
                  <TableCell className="w-1/5 p-2">
                    {/* Empty cell while uploading */}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </ScrollArea>
        </PopoverContent>
      </Popover>
    )
  }
}

export const AssistantWorkflowFollowUpsExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.FOLLOW_UPS
> = ({ outputData }) => {
  if (!outputData) return null
  return <Markdown content={outputData.value} />
}

const PresubmitLoadingState = () => {
  return (
    <LoadingState
      isCompleted={false}
      paramStatus={EventStatus.IN_PROGRESS}
      states={[
        {
          uuid: '1',
          name: 'Waiting to send query',
          status: 'IN_PROGRESS',
          text: 'Working…',
        },
      ]}
    />
  )
}
