import React, { useEffect, useState, useMemo } from 'react'

import _ from 'lodash'
import { Loader2 } from 'lucide-react'
import pluralize from 'pluralize'

import {
  BulkManagePermConfigsForWorkspaces,
  FetchAllPermBundlesInternalAdmin,
  PermBundle,
  PermEnablementResults,
} from 'models/perms'
import { Workspace } from 'models/workspace'
import { instanceOfInternalOnlyPermission } from 'openapi/models/InternalOnlyPermission'
import { Permission } from 'openapi/models/Permission'
import { instanceOfSensitiveCustomerDataPermission } from 'openapi/models/SensitiveCustomerDataPermission'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import WorkspaceMultiSelect from 'components/common/workspace-multi-select'
import useWorkspaces from 'components/settings/hooks/use-workspaces'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from 'components/ui/dialog'
import { Label } from 'components/ui/label'
import { ScrollArea } from 'components/ui/scroll-area'
import { Switch } from 'components/ui/switch'
import { TagGroup, TagInput } from 'components/ui/tag-input/tag-input'

interface ManagePermConfigsForWorkspacesProps {
  type: 'enable' | 'disable'
}

const ManagePermConfigsForWorkspaces: React.FC<
  ManagePermConfigsForWorkspacesProps
> = ({ type }) => {
  const { workspaces, isLoadingWorkspaces } = useWorkspaces()
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<Workspace[]>([])
  const [invertWorkspaceSelection, setInvertWorkspaceSelection] =
    useState<boolean>(false)
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  )
  const [permBundles, setPermBundles] = useState<PermBundle[]>([])
  const [confirmingAction, setConfirmingAction] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [results, setResults] = useState<PermEnablementResults | null>(null)

  const targetedWorkspacesCount = useMemo(() => {
    if (isLoadingWorkspaces) return
    if (invertWorkspaceSelection) {
      return workspaces.length - selectedWorkspaces.length
    }
    return selectedWorkspaces.length
  }, [
    invertWorkspaceSelection,
    selectedWorkspaces,
    workspaces,
    isLoadingWorkspaces,
  ])

  useEffect(() => {
    const fetchPermBundles = async () => {
      const permBundles = await FetchAllPermBundlesInternalAdmin()
      setPermBundles(permBundles)
    }
    void fetchPermBundles()
  }, [])

  const validSelection =
    (selectedWorkspaces.length > 0 || invertWorkspaceSelection) &&
    selectedPermissions.length > 0

  const resetSelections = () => {
    setSelectedWorkspaces([])
    setInvertWorkspaceSelection(false)
    setSelectedPermissions([])
    setResults(null)
  }

  const handleConfirmAction = async () => {
    try {
      setIsLoading(true)
      const result = await BulkManagePermConfigsForWorkspaces({
        isDisable: type === 'disable',
        workspaceIds: selectedWorkspaces.map((w) => w.id),
        invertWorkspaceSelection,
        permIds: selectedPermissions,
      })

      setResults(result)

      const successCount = _.values(result).filter((r) => r.success).length
      if (successCount > 0) {
        displaySuccessMessage(
          `Successfully ${
            type === 'enable' ? 'enabled' : 'disabled'
          } permissions for ${successCount} workspaces`
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('An error occurred while processing your request')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseDialog = () => {
    setConfirmingAction(false)
    if (results) {
      resetSelections()
    }
  }

  const sortedGroups = useMemo<TagGroup[]>(() => {
    return permBundles
      .map((bundle) => ({
        label: bundle.name,
        tags: bundle.permissions
          .filter((permission) => {
            if (
              instanceOfInternalOnlyPermission(permission) ||
              instanceOfSensitiveCustomerDataPermission(permission)
            ) {
              return false
            }
            return true
          })
          .map((permission) => ({
            value: permission,
            badgeDisplayText: permission,
          })),
      }))
      .filter((group) => group.tags.length > 0) // Don't show empty bundles
  }, [permBundles])

  const successCount = results
    ? Object.values(results).filter((r) => r.success).length
    : 0
  const failedCount = results
    ? Object.values(results).filter((r) => !r.success).length
    : 0

  return (
    <>
      <Accordion type="single" collapsible className="rounded-md border">
        <AccordionItem value="manage-perm-configs-for-workspaces">
          <AccordionTrigger className="p-4">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">
                {type === 'enable'
                  ? 'Bulk enable permissions for workspaces'
                  : 'Bulk disable permissions for workspaces'}
              </h2>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col items-start gap-4 px-5">
              <p className="text-sm text-muted">
                {type === 'enable'
                  ? 'Enable permissions across many workspaces.'
                  : 'Disable permissions across many workspaces.'}
              </p>

              <div className="w-full">
                <h3 className="mb-2 text-sm font-medium">Workspaces</h3>
                <WorkspaceMultiSelect
                  selected={selectedWorkspaces}
                  setSelected={setSelectedWorkspaces}
                />
              </div>

              <div className="flex items-center space-x-2">
                <Switch
                  id="invert-workspace-selection"
                  checked={invertWorkspaceSelection}
                  onCheckedChange={setInvertWorkspaceSelection}
                />
                {!isLoadingWorkspaces && (
                  <Label htmlFor="invert-workspace-selection">
                    Apply to all {workspaces.length} workspaces except selected
                  </Label>
                )}
              </div>

              <div className="w-full">
                <h3 className="mb-2 text-sm font-medium">Permissions</h3>
                <TagInput
                  size="md"
                  placeholder="Start typing or select permissions"
                  sortedGroups={sortedGroups}
                  selectedTagValues={selectedPermissions}
                  setSelectedTagValues={(values) =>
                    setSelectedPermissions(values as Permission[])
                  }
                  shouldShowSuggestedTags
                  allowCreatingNewTags={false}
                />
              </div>

              <div className="mt-36 flex w-full justify-end gap-2">
                <Button variant="outline" onClick={resetSelections}>
                  Reset
                </Button>
                {type === 'enable' ? (
                  <Button
                    disabled={!validSelection}
                    onClick={() => setConfirmingAction(true)}
                  >
                    Enable permissions
                  </Button>
                ) : (
                  <Button
                    variant="destructive"
                    disabled={!validSelection}
                    onClick={() => setConfirmingAction(true)}
                  >
                    Disable permissions
                  </Button>
                )}
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      {confirmingAction && (
        <Dialog open onOpenChange={handleCloseDialog}>
          {!results ? (
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {type === 'enable'
                    ? 'Enable permissions'
                    : 'Disable permissions'}
                </DialogTitle>
                <DialogDescription className="flex items-center gap-1 text-primary">
                  {isLoading && <Loader2 className="size-4 animate-spin" />}
                  {isLoading
                    ? `Processing…`
                    : `Are you sure you want to ${
                        type === 'enable' ? 'enable' : 'disable'
                      } ${pluralize(
                        'permission',
                        selectedPermissions.length,
                        true
                      )} for ${pluralize(
                        'workspace',
                        targetedWorkspacesCount ?? 0,
                        true
                      )}? ${
                        type === 'enable'
                          ? 'This will also grant it to all roles and users that have the respective permission bundles.'
                          : 'This will also remove it from all roles and users in the selected workspaces.'
                      }`}
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-end gap-2 pt-4">
                <Button
                  variant="outline"
                  onClick={() => !isLoading && handleCloseDialog()}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant={type === 'disable' ? 'destructive' : 'default'}
                  onClick={() => !isLoading && handleConfirmAction()}
                  disabled={isLoading}
                >
                  {isLoading ? 'Processing…' : 'Confirm'}
                </Button>
              </div>
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Results</DialogTitle>
              </DialogHeader>
              <div>
                <div className="mb-2 text-sm">
                  Successfully {type === 'enable' ? 'enabled' : 'disabled'}{' '}
                  permissions for {successCount} workspaces.
                  {failedCount > 0 && ` Failed for ${failedCount} workspaces.`}
                </div>
                <div className="mb-4 flex flex-wrap gap-2">
                  {selectedPermissions.map((perm) => (
                    <Badge key={perm} variant="secondary">
                      {perm}
                    </Badge>
                  ))}
                </div>

                {failedCount > 0 && (
                  <ScrollArea maxHeight="max-h-[300px]">
                    <div className="space-y-2">
                      {Object.values(results)
                        .filter((result) => !result.success)
                        .map((result) => (
                          <div
                            key={result.workspaceId}
                            className="rounded-md border p-3"
                          >
                            <div className="flex items-center justify-between">
                              <h3 className="text-sm font-medium">
                                {result.workspaceName}
                              </h3>
                              <Badge variant="destructive">Failed</Badge>
                            </div>
                            {result.message && (
                              <p className="mt-1 text-xs text-muted">
                                {result.message}
                              </p>
                            )}
                          </div>
                        ))}
                    </div>
                  </ScrollArea>
                )}

                <div className="mt-6 flex justify-end">
                  <Button onClick={handleCloseDialog}>Close</Button>
                </div>
              </div>
            </DialogContent>
          )}
        </Dialog>
      )}
    </>
  )
}

export default ManagePermConfigsForWorkspaces
