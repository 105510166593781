import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EM_DASH } from 'utils/utils'

import { Button } from 'components/ui/button'
import { DialogHeader } from 'components/ui/dialog'
import { DialogTitle } from 'components/ui/dialog'
import { DialogFooter } from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'
import { SimpleDocumentClassificationPill } from 'components/vault/components/vault-document-classification'
import { WorkflowSource } from 'components/workflows/workflows-helpers'

import { WorkflowModalState } from './vault-workflow-store'
import useVaultWorkflowStore from './vault-workflow-store'

const VaultWorkflowPreview = ({
  handleOpenAddFiles,
  handleOpenProjectChooser,
  isShowingWorkflowAdminInfo,
  source,
  isFromSelector,
}: {
  handleOpenAddFiles: () => void
  handleOpenProjectChooser: () => void
  isShowingWorkflowAdminInfo: boolean
  source: WorkflowSource
  isFromSelector?: boolean
}) => {
  const [selectedWorkflow, setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.selectedWorkflow, state.setWorkflowModalState])
  )
  const hasTags = selectedWorkflow?.tags && selectedWorkflow.tags.length > 0

  return (
    <>
      <DialogHeader className="h-9 shrink-0 border-b border-b-primary px-6">
        <DialogTitle>{selectedWorkflow?.name}</DialogTitle>
      </DialogHeader>
      <div className="flex min-h-0 grow flex-col gap-y-4 px-6 pt-4">
        <p className="text-left text-primary">
          {selectedWorkflow?.description}
        </p>
        <div className="flex min-h-0 grow">
          <ScrollArea className="flex max-h-full min-h-0 w-full grow rounded border">
            <Table className="w-full">
              <TableHeader className="sticky top-0 bg-accent transition hover:bg-accent [&_tr]:hover:bg-accent">
                <TableRow className="transition hover:bg-accent">
                  <TableHead className="h-8 px-2 text-xs font-normal text-primary">
                    Title
                  </TableHead>
                  <TableHead className="h-8 px-2 text-xs font-normal text-primary">
                    Query
                  </TableHead>
                  {isShowingWorkflowAdminInfo && (
                    <TableHead className="h-8 px-2 text-xs font-normal text-primary">
                      User prompt
                    </TableHead>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {selectedWorkflow?.columns.map((column) => (
                  <TableRow
                    key={column.id}
                    className="transition hover:bg-primary"
                  >
                    <TableCell className="px-2 py-2 text-xs">
                      {column.header}
                    </TableCell>
                    <TableCell className="px-2 py-2 text-xs">
                      {column.fullText}
                    </TableCell>
                    {isShowingWorkflowAdminInfo && (
                      <TableCell className="px-2 py-2 text-xs">
                        {column.userPrompt ?? EM_DASH}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        </div>
        {hasTags && (
          <div className="mb-4 flex flex-col gap-1">
            <p className="text-xs text-muted">Recommended for</p>
            <div className="flex flex-wrap gap-1">
              {selectedWorkflow.tags?.map((tag) => (
                <SimpleDocumentClassificationPill
                  isPill
                  key={tag.id}
                  // tagName is the old field, name is the new field
                  // TODO: Remove this once all cached workflows have been updated
                  // @ts-expect-error temporary backwards compatibility
                  tagName={tag.name ?? tag.tagName}
                  tagId={tag.id}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <DialogFooter className="flex w-full flex-row items-center justify-between self-end border-t px-6 pt-4 sm:justify-between">
        <p className="text-xs text-muted">
          You can remove, rename, or add columns after the table is created.
        </p>
        <div className="flex justify-end gap-2">
          <Button
            variant="outline"
            onClick={
              isFromSelector
                ? () => setWorkflowModalState(WorkflowModalState.Selector)
                : () => setWorkflowModalState(WorkflowModalState.None)
            }
          >
            {isFromSelector ? 'Back' : 'Cancel'}
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault()
              if (source === WorkflowSource.VAULT_PROJECT_PAGE) {
                handleOpenAddFiles()
              } else {
                handleOpenProjectChooser()
              }
            }}
          >
            Continue
          </Button>
        </div>
      </DialogFooter>
    </>
  )
}

export default VaultWorkflowPreview
