import React, { useMemo } from 'react'

import { isNil } from 'lodash'
import { Folder } from 'lucide-react'

import { VaultFile } from 'openapi/models/VaultFile'
import { FileTypeReadableName } from 'types/file'

import { bytesToReadable } from 'utils/file-utils'
import { backendToReadable } from 'utils/utils'

import Icon from 'components/ui/icon/icon'
import VaultDocumentClassification from 'components/vault/components/vault-document-classification'
import { DOT_SEPARATOR } from 'components/vault/utils/vault'
import { getFoldersOnPath } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

type FileBreadcrumbProps = {
  currentFile: VaultFile | undefined
}

const FileBreadcrumb = ({ currentFile }: FileBreadcrumbProps) => {
  const fileIdToVaultFile = useVaultStore((state) => state.fileIdToVaultFile)
  const folderIdToVaultFolder = useVaultStore(
    (state) => state.folderIdToVaultFolder
  )
  const currentProject = useVaultStore((state) => state.currentProject)
  const isCurrentFileNotNull = !isNil(currentFile)

  const filePath = useMemo(() => {
    if (!isCurrentFileNotNull || !currentProject) return []
    const foldersOnPath = getFoldersOnPath(
      currentFile.vaultFolderId,
      folderIdToVaultFolder
    )
    const foldersOnPathNames = foldersOnPath?.map((f) => f.name) ?? []
    return foldersOnPathNames.flatMap((name, index) =>
      index < foldersOnPathNames.length - 1 ? [name, '/'] : [name]
    )
  }, [isCurrentFileNotNull, currentFile, folderIdToVaultFolder, currentProject])

  const fileName = currentFile?.name
  const fileContentType =
    FileTypeReadableName[
      currentFile?.contentType as keyof typeof FileTypeReadableName
    ]
  const fileSize = bytesToReadable(currentFile?.size as number)
  const createdAt = backendToReadable(currentFile?.createdAt ?? '')

  return (
    <div className="mr-4">
      <h1 className="truncate text-xl font-medium">
        {isCurrentFileNotNull ? fileName : 'Loading…'}
      </h1>
      {isCurrentFileNotNull && (
        <div className="flex items-center gap-1">
          <Icon icon={Folder} size="small" variant="secondary" />
          <p className="truncate text-xs text-muted">
            {[filePath.join(' '), fileContentType, fileSize, createdAt]
              .filter(Boolean)
              .join(DOT_SEPARATOR)}
          </p>
          <VaultDocumentClassification
            fileIds={[currentFile.id]}
            fileIdToVaultFile={fileIdToVaultFile}
            className="ml-2 w-auto"
          />
        </div>
      )}
    </div>
  )
}

export default FileBreadcrumb
