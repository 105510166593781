import React, { useMemo, useState } from 'react'

import { RefreshCw } from 'lucide-react'
import { Copy, Download } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { EventStatus } from 'openapi/models/EventStatus'
import { MessageFeedback } from 'openapi/models/MessageFeedback'
import { WorkflowEventStatusFeedback } from 'openapi/models/WorkflowEventStatusFeedback'
import { WorkflowEventStatusStepsInner } from 'openapi/models/WorkflowEventStatusStepsInner'

import { copyToClipboard } from 'utils/copy-to-clipboard'
import { displayErrorMessage } from 'utils/toast'
import { displaySuccessMessage } from 'utils/toast'

import { ActionIcon } from 'components/assistant/components/assistant-question'
import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { CreateMessageFeedback } from 'components/assistant/utils/assistant-fetcher'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { getSourcesFromStep } from 'components/assistant/workflows/utils/utils'
import { handleWorkflowExport } from 'components/assistant/workflows/workflow-export-container'
import { useAuthUser } from 'components/common/auth-context'
import { FeedbackButton } from 'components/common/feedback/feedback'
import ToolbarButton from 'components/common/feedback/toolbar-button'
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'

interface Props {
  content: string
  feedback: WorkflowEventStatusFeedback | undefined
  workflowStepId: string
  onRegenerate?: () => void
  exportComponents: React.MutableRefObject<HTMLDivElement[]>
}

const RenderBlockToolbar = ({
  content,
  feedback,
  workflowStepId,
  onRegenerate,
  exportComponents,
}: Props) => {
  const userInfo = useAuthUser()
  const trackEvent = useAssistantAnalytics()
  const [sentiment, setSentiment] = useState<number>(feedback?.sentiment || 0)
  const [currentEvent, getEventId, streamInProgress, workflowDefinition] =
    useAssistantWorkflowStore(
      useShallow((state) => [
        state.currentEvent,
        state.getEventId,
        state.streamInProgress,
        state.workflowDefinition,
      ])
    )
  const showFeedback = userInfo.IsFeedbackUser

  const handleSubmitFeedback = async (feedback: MessageFeedback) => {
    const eventId = await getEventId()

    trackEvent('Feedback Submitted', {
      sentiment: feedback.sentiment,
      comments: feedback.selectedComments,
      event_id: String(eventId),
      workflow_step_id: workflowStepId,
    })

    setSentiment(feedback.sentiment)

    try {
      await CreateMessageFeedback(String(eventId), workflowStepId, feedback)
      return true
    } catch {
      setSentiment(0)
      return false
    }
  }

  const hasSources = useMemo(() => {
    return (
      getSourcesFromStep(
        currentEvent?.steps.find((s) => s.stepId === workflowStepId) as
          | WorkflowEventStatusStepsInner
          | undefined,
        workflowDefinition
      ).length > 0
    )
  }, [currentEvent, workflowStepId, workflowDefinition])

  const handleExport = async (includeAnnotation: boolean) => {
    const completedSteps =
      currentEvent?.steps.filter(
        (step) => step.paramStatus === EventStatus.COMPLETED
      ) || []

    const success = await handleWorkflowExport({
      refs: exportComponents.current,
      completedSteps,
      workflowDefinition,
      stepId: workflowStepId,
      includeAnnotation,
    })

    if (success) {
      const stepBlockId = currentEvent?.steps.find(
        (s) => s.stepId === workflowStepId
      )?.blockId
      const blockId = workflowDefinition?.steps.find(
        (s) => s.id === stepBlockId
      )?.blockType

      trackEvent('Workflow Message Exported', {
        workflow_name: workflowDefinition?.name,
        format: 'word',
        include_annotation: includeAnnotation,
        block_name: blockId,
      })
    }
  }

  return (
    <div
      className="flex w-full justify-between space-x-0.5"
      data-testid="render-block-toolbar"
      id={`toolbar-${workflowStepId}`}
    >
      <div className="inline-flex items-center space-x-0.5">
        {showFeedback && (
          <>
            <FeedbackButton
              hasDocuments={false}
              onSubmit={handleSubmitFeedback}
              sentiment={sentiment}
              sentimentValue={1}
            />
            <FeedbackButton
              hasDocuments={false}
              onSubmit={handleSubmitFeedback}
              sentiment={sentiment}
              sentimentValue={-1}
            />
          </>
        )}
        {content && <CopyButton content={content} />}
        <ExportButton hasSources={hasSources} onExport={handleExport} />
      </div>
      <div className="inline-flex items-center space-x-0.5">
        {onRegenerate && (
          <ActionIcon
            onClick={onRegenerate}
            size="sm"
            tooltip="Generate a new response"
            data-testid="regenerate-button"
            aria-label="Generate a new response"
            disabled={streamInProgress}
          >
            <Icon icon={RefreshCw} />
          </ActionIcon>
        )}
      </div>
    </div>
  )
}

export default RenderBlockToolbar

const CopyButton = ({ content }: { content: string }) => {
  const handleCopy = () => {
    copyToClipboard(content)
      .then(() => displaySuccessMessage('Copied response to clipboard'))
      .catch(() => displayErrorMessage('Failed to copy response to clipboard'))
  }

  return (
    <Tooltip disableHoverableContent>
      <TooltipTrigger asChild>
        <ToolbarButton
          onClick={handleCopy}
          aria-label="Copy"
          data-testid="copy-button"
        >
          <Icon icon={Copy} />
        </ToolbarButton>
      </TooltipTrigger>
      <TooltipContent>Copy response</TooltipContent>
    </Tooltip>
  )
}

const ExportButton = ({
  hasSources,
  onExport,
}: {
  hasSources: boolean
  onExport: (includeAnnotation: boolean) => void
}) => {
  if (!hasSources) {
    return (
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          <ToolbarButton
            onClick={() => onExport(false)}
            data-testid="export-button"
          >
            <Icon icon={Download} />
          </ToolbarButton>
        </TooltipTrigger>
        <TooltipContent>Export</TooltipContent>
      </Tooltip>
    )
  }

  return (
    <DropdownMenu>
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          <DropdownMenuTrigger asChild>
            <ToolbarButton
              aria-label="Download"
              data-testid="export-button-toolbar"
            >
              <Icon icon={Download} />
            </ToolbarButton>
          </DropdownMenuTrigger>
        </TooltipTrigger>
        <TooltipContent>View export options</TooltipContent>
      </Tooltip>
      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={() => onExport(true)}>
          Export with citations
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onExport(false)}>
          Export answer only
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
