import { TableSelect as TableSelectType } from 'openapi/models/TableSelect'
import { TableSelectOrderedColumnNamesInner } from 'openapi/models/TableSelectOrderedColumnNamesInner'
import { TableSelectRow } from 'openapi/models/TableSelectRow'

import { snakeToCamel } from 'utils/utils'

export interface ValidateTableOptions {
  tableData: TableSelectType
  ignoreRowIndexes?: number[]
}

export const validateTableRow = (
  row: TableSelectRow,
  requiredColumns: TableSelectOrderedColumnNamesInner[]
): boolean => {
  return requiredColumns.every((column) => {
    const value = row.data[snakeToCamel(column.key || '')]
    return value !== undefined && value !== null && value !== ''
  })
}

export const validateTable = ({
  tableData,
  ignoreRowIndexes = [],
}: ValidateTableOptions): boolean => {
  // Get columns marked as required
  const requiredColumns =
    tableData.orderedColumnNames?.filter((col) => col.required) || []
  if (requiredColumns.length === 0) return true

  // Check each row except ignored ones
  return tableData.rows.every((row, index) => {
    if (ignoreRowIndexes.includes(index)) return true
    return validateTableRow(row, requiredColumns)
  })
}

export const getRequiredColumns = (
  tableData: TableSelectType
): TableSelectOrderedColumnNamesInner[] => {
  return tableData.orderedColumnNames?.filter((col) => col.required) || []
}
