import React, { useCallback, useEffect, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowStreamRequest } from 'openapi/models/WorkflowStreamRequest'
import { WorkflowStreamRequestDeleteStep } from 'openapi/models/WorkflowStreamRequestDeleteStep'
import { WorkflowStreamRequestInputCompletion } from 'openapi/models/WorkflowStreamRequestInputCompletion'
import { WorkflowStreamRequestInputUpdated } from 'openapi/models/WorkflowStreamRequestInputUpdated'
import { WorkflowStreamRequestRegenerateStep } from 'openapi/models/WorkflowStreamRequestRegenerateStep'
import { useGeneralStore } from 'stores/general-store'

import { ToBackendKeys } from 'utils/utils'

import { AssistantChatLocationState } from 'components/assistant/hooks/use-location-chat-query'
import { cancelStep } from 'components/assistant/utils/assistant-api'
import { CancelStepResponse } from 'components/assistant/utils/assistant-api'
import { useAssistantWorkflowStreamHandler } from 'components/assistant/workflows/hooks/use-assistant-workflow'
import { useWorkflowAnalytics } from 'components/assistant/workflows/hooks/use-workflow-analytics'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import {
  StepCompletionHandler,
  useAssistantWorkflowComponents,
} from 'components/assistant/workflows/use-workflow-components'
import { AssistantWorkflowLayout } from 'components/assistant/workflows/workflow-layout'
import { loadWorkflow } from 'components/assistant/workflows/workflow-loader'

export const AssistantWorkflowPage: React.FC = () => {
  const { id: workflowId, eventId: eventIdParam } = useParams()
  const { sendWorkflowChat, closeSocket } = useAssistantWorkflowStreamHandler()
  const [
    setCurrentWorkflow,
    workflowDefinition,
    currentEvent,
    reset,
    pendingMessage,
    restoreEventBeforeRequest,
    resetBlockStoresAfterStepId,
  ] = useAssistantWorkflowStore(
    useShallow((state) => [
      state.setCurrentWorkflow,
      state.workflowDefinition,
      state.currentEvent,
      state.reset,
      state.pendingMessage,
      state.restoreEventBeforeRequest,
      state.resetBlockStoresAfterStepId,
    ])
  )
  const navigate = useNavigate()
  const setIsSidebarOpenAndToggle = useGeneralStore(
    (state) => state.setIsSidebarOpenAndToggle
  )
  const trackEvent = useWorkflowAnalytics()
  const location = useLocation()
  const locationState = location.state as AssistantChatLocationState

  useMount(() => {
    // on mount we want to set the sidebar to false
    setIsSidebarOpenAndToggle(false)
  })

  useUnmount(() => {
    closeSocket()
    reset()
  })

  const handleExistingStepUpdated = useCallback(
    (blockId: string, stepId: string | null, result: any) => {
      if (!workflowDefinition) {
        console.error('No workflow definition found')
        return
      }

      // If we've already sent a message and are waiting for a response,
      // don't send another one.
      if (pendingMessage || !stepId) {
        return
      }

      resetBlockStoresAfterStepId(stepId)

      const request: WorkflowStreamRequestInputUpdated = {
        requestType: 'updated_input',
        updatedWorkflowStepId: stepId,
        workflowStepBlockId: blockId,
        workflowStepData: ToBackendKeys(result),
      }

      sendWorkflowChat({
        eventId:
          eventIdParam || (currentEvent?.eventId as string | undefined) || null,
        workflowId: workflowDefinition.id,
        request: request as WorkflowStreamRequest,
      })
    },
    [
      currentEvent?.eventId,
      eventIdParam,
      pendingMessage,
      sendWorkflowChat,
      workflowDefinition,
      resetBlockStoresAfterStepId,
    ]
  )

  const handleCurrentStepCompletion = useCallback<StepCompletionHandler>(
    ({ blockId, stepId, result, stepIdx }) => {
      if (!workflowDefinition) {
        console.error('No workflow definition found')
        return
      }

      // If we've already sent a message and are waiting for a response,
      // don't send another one.
      if (pendingMessage) {
        return
      }

      const request: WorkflowStreamRequestInputCompletion = {
        requestType: 'new_input',
        updatedWorkflowStepId: stepId || undefined,
        workflowStepBlockId: blockId,
        workflowStepData: ToBackendKeys(result),
      }

      trackEvent('Workflow Block Completed', {
        step_id: stepId,
        step_idx: stepIdx,
        workflow_name: workflowDefinition.name,
        block_id: blockId,
      })

      sendWorkflowChat({
        eventId:
          eventIdParam ||
          (currentEvent?.eventId as string | undefined) ||
          locationState?.eventId ||
          null,
        workflowId: workflowDefinition.id,
        request: request as WorkflowStreamRequest,
      })
    },
    [
      currentEvent?.eventId,
      eventIdParam,
      pendingMessage,
      sendWorkflowChat,
      workflowDefinition,
      trackEvent,
      locationState?.eventId,
    ]
  )

  const handleExistingStepDeletion = useCallback(
    (stepId: string | null) => {
      if (!workflowDefinition) {
        console.error('No workflow definition found')
        return
      }

      // If we've already sent a message and are waiting for a response,
      // don't send another one.
      if (pendingMessage || !stepId) {
        return
      }

      resetBlockStoresAfterStepId(stepId)

      const request: WorkflowStreamRequestDeleteStep = {
        requestType: 'delete_step',
        deleteWorkflowStepId: stepId,
      }

      sendWorkflowChat({
        eventId:
          eventIdParam || (currentEvent?.eventId as string | undefined) || null,
        workflowId: workflowDefinition.id,
        request: request as WorkflowStreamRequest,
      })
    },
    [
      currentEvent?.eventId,
      eventIdParam,
      pendingMessage,
      sendWorkflowChat,
      workflowDefinition,
      resetBlockStoresAfterStepId,
    ]
  )

  const exportComponents = useRef<HTMLDivElement[]>([])

  const handleExistingStepRegenerate = useCallback(
    (stepId: string | null) => {
      if (!workflowDefinition) {
        console.error('No workflow definition found')
        return
      }

      // If we've already sent a message and are waiting for a response,
      // don't send another one.
      if (pendingMessage || !stepId) {
        return
      }

      resetBlockStoresAfterStepId(stepId)

      const request: WorkflowStreamRequestRegenerateStep = {
        requestType: 'regenerate_step',
        regenerateWorkflowStepId: stepId,
      }

      sendWorkflowChat({
        eventId:
          eventIdParam || (currentEvent?.eventId as string | undefined) || null,
        workflowId: workflowDefinition.id,
        request: request as WorkflowStreamRequest,
      })
    },
    [
      currentEvent?.eventId,
      eventIdParam,
      pendingMessage,
      sendWorkflowChat,
      workflowDefinition,
      resetBlockStoresAfterStepId,
    ]
  )

  const handleCurrentStepCancel = useCallback(() => {
    if (
      !workflowDefinition ||
      !currentEvent ||
      !workflowId ||
      !eventIdParam ||
      pendingMessage
    ) {
      console.error('No workflow definition found')
      return
    }

    closeSocket()
    restoreEventBeforeRequest()

    cancelStep(eventIdParam)
      .then((response: CancelStepResponse) => {
        setCurrentWorkflow(
          workflowDefinition,
          response.workflow,
          /* optimistic */ true
        )
      })
      .catch((error: Error) => {
        console.error('Error canceling workflow step:', error)
      })
  }, [
    eventIdParam,
    closeSocket,
    setCurrentWorkflow,
    pendingMessage,
    currentEvent,
    workflowDefinition,
    workflowId,
    restoreEventBeforeRequest,
  ])

  const componentsToRender = useAssistantWorkflowComponents({
    handleCurrentStepCompletion,
    handleExistingStepUpdated,
    handleExistingStepDeletion,
    handleExistingStepRegenerate,
    handleCurrentStepCancel,
    exportComponents,
  })
  const {
    completedStepsComponents,
    inProgressStepsComponents,
    InputComponent,
    editingStepIdx,
  } = componentsToRender
  const threadComponents = [
    ...completedStepsComponents,
    ...inProgressStepsComponents,
  ]

  useEffect(() => {
    if (
      !currentEvent?.eventId ||
      String(currentEvent.eventId) === eventIdParam ||
      currentEvent.isPending
    )
      return
    navigate(`/assistant/workflows/${workflowId}/${currentEvent.eventId}`, {
      replace: true,
    })
  }, [currentEvent, eventIdParam, navigate, workflowId])

  useEffect(() => {
    if (!workflowId) return

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadWorkflow(workflowId, eventIdParam).then((workflow) => {
      setCurrentWorkflow(workflow.workflowDefinition, workflow.workflowStatus)
    })
  }, [workflowId, eventIdParam, setCurrentWorkflow])

  return (
    <AssistantWorkflowLayout
      input={InputComponent}
      thread={threadComponents}
      title={workflowDefinition?.name ?? 'Loading'}
      caption={currentEvent?.caption}
      exportComponents={exportComponents}
      editingStepIdx={editingStepIdx}
    />
  )
}
