import React, { useState } from 'react'

import TextInput from 'components/assistant/workflows/components/text-input/text-input'

type EditExistingQueryProps = {
  isSubmitDisabled: boolean
  onSubmit: (text: string) => void
  onCancel: () => void
  existingQuery: string
}

export const EditExistingQueryInput: React.FC<EditExistingQueryProps> = ({
  isSubmitDisabled,
  onSubmit,
  onCancel,
  existingQuery,
}) => {
  const [text, setText] = useState(existingQuery)
  return (
    <div className="rounded bg-secondary p-2">
      <TextInput
        onCancel={onCancel}
        onChange={setText}
        onSubmit={onSubmit}
        value={text}
        isSubmitDisabled={isSubmitDisabled}
      />
    </div>
  )
}
