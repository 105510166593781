import React, { useCallback, useEffect, useMemo, useState } from 'react'

import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { FileContainerType } from 'openapi/models/FileContainerType'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'
import { useIntegrationsStore } from 'stores/integrations-store'
import { Maybe } from 'types'
import { FileType } from 'types/file'
import { FileTypeToExtension } from 'types/file'

import { displayErrorMessage } from 'utils/toast'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { useAuthUser } from 'components/common/auth-context'
import {
  authenticateSynclyAuthClient,
  getSynclyTenantUrl,
  SynclyFile,
} from 'components/settings/integrations/syncly-utils'

// this will expand to more syncly integrations in the future
const integrationType = IntegrationType.I_MANAGE
// update this to use syncly discovery once they whitelist our domains
const synclyPickerUrlSuffix = 'integration-plugin/workflow_creation?'

const SynclyPicker = () => {
  const userInfo = useAuthUser()
  const [requestId, setRequestId] = useState<string>('')
  const [iframeHeight, setIframeHeight] = useState<number>(500)

  const [synclyAuth0Token, setSynclyAuth0Token] = useState<Maybe<string>>(null)

  const [integrationFilePickerOpenState, setIntegrationFilePickerOpenState] =
    useIntegrationsStore(
      useShallow((state) => [
        state.integrationFilePickerOpenState,
        state.setIntegrationFilePickerOpenState,
      ])
    )

  const containerType: FileContainerType | undefined =
    integrationFilePickerOpenState?.containerType

  const [mode, eventId, setEventId] = useAssistantStore(
    useShallow((state) => [state.mode, state.eventId, state.setEventId])
  )

  // NOTE ideally we always pass containerId into the integrationFilePickerOpenState because
  // then we won't depend on outside logic to ensure the assistant store is up to date;
  // we would just explicitly receive the parameter from product that triggers the picker
  // however we can't do this now since we need to create the assistant event here
  const containerId: Maybe<string> = useMemo(() => {
    if (_.isNil(containerType)) {
      return null
    }
    if (containerType === FileContainerType.EVENT) {
      return eventId
    } else if (containerType === FileContainerType.VAULT) {
      // Vault must pass in the currentUploadFilesFolderId as containerId
      return integrationFilePickerOpenState?.containerId
    }
    return null
  }, [containerType, eventId, integrationFilePickerOpenState?.containerId])

  const shouldShowPicker =
    !_.isNil(integrationFilePickerOpenState) &&
    integrationFilePickerOpenState.integrationType === integrationType &&
    !_.isNil(containerType)

  const cleanup = useCallback(() => {
    setIntegrationFilePickerOpenState(null)
    setSynclyAuth0Token(null)
    setRequestId('')
  }, [setIntegrationFilePickerOpenState])

  const handleIframeLoad = useCallback(
    async (instanceURL: string) => {
      if (
        !_.isNil(containerId) &&
        !_.isNil(containerType) &&
        !_.isNil(integrationFilePickerOpenState?.acceptedFileTypes)
      ) {
        const token = await authenticateSynclyAuthClient(userInfo.id)
        setSynclyAuth0Token(token)
        const newRequestId = uuidv4()
        setRequestId(newRequestId)
        const supportedFileTypes = (
          integrationFilePickerOpenState.acceptedFileTypes as FileType[]
        )
          .map((fileType) => FileTypeToExtension[fileType])
          .flat()
          .join(',')

        const iframe = document.getElementById(
          'SynclyEmbed'
        ) as HTMLIFrameElement
        if (iframe) {
          // TODO remove targetEntityId after cutover
          iframe.src = `${instanceURL}requestId=${newRequestId}&sourceType=iManage&targetEntityId=${containerId}&containerType=${containerType}&containerId=${containerId}&supportedFileTypes=${supportedFileTypes}`
        }
      }
    },
    [
      containerId,
      containerType,
      userInfo.id,
      integrationFilePickerOpenState?.acceptedFileTypes,
    ]
  )

  useEffect(() => {
    const loadIframe = async () => {
      if (shouldShowPicker && !_.isNil(containerId)) {
        if (_.isNil(userInfo.workspace.id)) {
          displayErrorMessage(`Unable to get tenant URL for ${integrationType}`)
          cleanup()
          return
        }
        let tenantUrl = userInfo.workspace.getSynclyTenantUrl()
        if (_.isNil(tenantUrl)) {
          tenantUrl = await getSynclyTenantUrl(userInfo.workspace.id)
        }
        await handleIframeLoad(`${tenantUrl}${synclyPickerUrlSuffix}`)
      }
    }
    void loadIframe()
  }, [
    handleIframeLoad,
    shouldShowPicker,
    containerId,
    cleanup,
    userInfo.workspace,
  ])

  const getLoginUserTokenObject = useCallback(
    (reqId: string, tokenType: string) => {
      if (_.isNil(synclyAuth0Token)) {
        displayErrorMessage(`Unable to authenticate with ${integrationType}`)
        cleanup()
        return null
      }
      return {
        type: `response:${tokenType}:${reqId}`,
        jwtToken: synclyAuth0Token,
      }
    },
    [cleanup, synclyAuth0Token]
  )

  useEffect(() => {
    const ensureEventForAssistantUpload = async () => {
      if (shouldShowPicker && containerType === FileContainerType.EVENT) {
        if (_.isNil(eventId)) {
          const kind =
            mode === AssistantMode.ASSIST
              ? EventKind.ASSISTANT_CHAT
              : EventKind.ASSISTANT_DRAFT
          const { id } = await Services.Backend.Post<{ id: string }>('event', {
            kind,
          })
          setEventId(id)
        }
      }
    }
    void ensureEventForAssistantUpload()
  }, [shouldShowPicker, setEventId, mode, eventId, containerType])

  useEffect(() => {
    if (_.isNil(shouldShowPicker) || _.isNil(containerId)) return
    const handleMessage = async (event: MessageEvent) => {
      const dataType = event.data?.type

      if (typeof dataType !== 'string' || !dataType.includes(requestId)) return

      if (
        dataType === `request:cancel:${requestId}` ||
        dataType === `request:close:${requestId}`
      ) {
        cleanup()
        return
      }

      if (
        dataType === `request:logintoken:${requestId}` ||
        dataType === `request:renewlogintoken:${requestId}`
      ) {
        const iframe = document.getElementById(
          'SynclyEmbed'
        ) as HTMLIFrameElement
        const tokenType = dataType.split(':')[1]
        iframe?.contentWindow?.postMessage(
          getLoginUserTokenObject(requestId, tokenType),
          '*'
        )
      } else if (dataType === `request:iframesize:${requestId}`) {
        const height = parseInt(event.data?.data?.iFrameHeight) || 0
        setIframeHeight(Math.max(height, 500))
      } else if (dataType === `request:success:${requestId}`) {
        const data = event.data?.data
        if (_.isEmpty(data) || _.isEmpty(data.files)) {
          displayErrorMessage('Unable to download files')
          cleanup()
          return
        }
        try {
          const uploadedFiles: UploadedFile[] = data.files.map(
            (file: SynclyFile) => ({
              id: file.id,
              name: file.name,
              path: file.path,
              url: file.url,
              docAsPdfUrl: file.doc_as_pdf_url,
              size: file.size,
              source: file.source,
              contentType: file.content_type,
            })
          )
          if (shouldShowPicker) {
            integrationFilePickerOpenState.onFileUploadToServer?.(uploadedFiles)
          }
        } catch (e) {
          displayErrorMessage('Unable to download files')
          console.error(e)
          cleanup()
          return
        }
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [
    cleanup,
    getLoginUserTokenObject,
    requestId,
    setIntegrationFilePickerOpenState,
    integrationFilePickerOpenState,
    shouldShowPicker,
    containerId,
  ])
  if (!shouldShowPicker) {
    return null
  }

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center">
      <div className="fixed inset-0 bg-primary/80 backdrop-blur-sm" />
      <div className="relative z-[101] w-4/5 max-w-5xl">
        <div className="flex justify-center">
          <iframe
            title="SynclyEmbed"
            id="SynclyEmbed"
            src="about:blank"
            width="100%"
            height={iframeHeight}
            style={{ border: 0 }}
            className="rounded-lg border border-primary bg-secondary shadow-lg"
          />
        </div>
      </div>
    </div>
  )
}

export default SynclyPicker
