import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { VaultFolder } from 'openapi/models/VaultFolder'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { DEFAULT_FILES_WARNING_THRESHOLD } from 'components/vault/components/file-explorer/vault-file-explorer-warning-message'
import { getReadyToQueryFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import VaultWorkflowAddFiles from 'components/vault/workflows/vault-workflow-add-files'
import { WorkflowSource } from 'components/workflows/workflows-helpers'

import VaultWorkflowCreateProject from './vault-workflow-create-project'
import VaultWorkflowFilesProgress from './vault-workflow-files-progress'
import VaultWorkflowPreview from './vault-workflow-preview'
import VaultWorkflowProjectChooser from './vault-workflow-project-chooser'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

type VaultWorkflowBuilderProps = {
  source: WorkflowSource
}

const WORKFLOW_BUILDER_MODAL_STATES = [
  WorkflowModalState.Builder,
  WorkflowModalState.AddFilesWorkflow,
  WorkflowModalState.ProjectChooser,
  WorkflowModalState.UploadFiles,
  WorkflowModalState.FilesProgress,
  WorkflowModalState.BuilderFromSelector,
]

const VaultWorkflowBuilder = ({ source }: VaultWorkflowBuilderProps) => {
  const { projectId } = useParams()
  const { trackEvent } = useAnalytics()
  const [
    currentProject,
    projectIdToFileIds,
    folderIdToVaultFolder,
    fileIdToVaultFile,
    setCurrentProject,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentProject,
      state.projectIdToFileIds,
      state.folderIdToVaultFolder,
      state.fileIdToVaultFile,
      state.setCurrentProject,
    ])
  )
  const [
    workflowModalState,
    selectedWorkflow,
    setWorkflowModalState,
    setSelectedWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.workflowModalState,
      state.selectedWorkflow,
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )

  const currentProjectId = projectId ?? currentProject?.id

  const [preselectFileIds, setPreselectFileIds] = useState<Set<string>>(
    new Set()
  )

  const userInfo = useAuthUser()
  const [isShowingWorkflowAdminInfo, setIsShowingWorkflowAdminInfo] =
    useState(false)

  useHotkeys(
    'Control+h',
    () => {
      if (userInfo.IsVaultWorkflowAdminUser) {
        setIsShowingWorkflowAdminInfo(!isShowingWorkflowAdminInfo)
      }
    },
    [isShowingWorkflowAdminInfo]
  )

  const handleOpenAddFiles = () => {
    trackEvent('Vault File Selector Viewed', {
      source: 'Vault Workflow',
      workflow_id: selectedWorkflow?.id,
      workflow_name: selectedWorkflow?.name,
    })

    const readyToQueryFileIds = currentProject
      ? getReadyToQueryFileIds(
          currentProject.id,
          projectIdToFileIds,
          null,
          folderIdToVaultFolder,
          fileIdToVaultFile
        )
      : []
    const fileIds = new Set(readyToQueryFileIds)
    if (fileIds.size <= DEFAULT_FILES_WARNING_THRESHOLD) {
      // If the number of files is less than the threshold, preselect them.
      // Otherwise, we'll show a warning in the add files dialog if user tries
      // to select all these files.
      setPreselectFileIds(fileIds)
    }
    setWorkflowModalState(WorkflowModalState.AddFilesWorkflow)
  }

  const handleOpenProjectChooser = () => {
    setWorkflowModalState(WorkflowModalState.ProjectChooser)
  }

  const handleSelectProjectForWorkflow = (project: VaultFolder) => {
    setCurrentProject(project)
    setWorkflowModalState(WorkflowModalState.AddFilesWorkflow)
  }

  const handleCreateProjectCardClick = () => {
    trackEvent('Vault New Project Button Clicked', {
      source: 'workflow-modal',
    })
    setWorkflowModalState(WorkflowModalState.UploadFiles)
  }

  const handleNavigateToFilesProgress = () => {
    setWorkflowModalState(WorkflowModalState.FilesProgress)
  }

  if (source === WorkflowSource.VAULT_PROJECT_PAGE && !currentProjectId) {
    return null
  }

  return (
    <Dialog
      open={WORKFLOW_BUILDER_MODAL_STATES.includes(workflowModalState)}
      onOpenChange={(open) => {
        setWorkflowModalState(
          open ? WorkflowModalState.Builder : WorkflowModalState.None
        )
        if (!open) {
          setSelectedWorkflow(null)
          trackEvent('Workflow Modal Closed', {
            state: workflowModalState,
            source,
          })
        }
      }}
    >
      <DialogContent
        className="h-[600px] max-w-[960px]"
        innerClassName="flex flex-col p-0 py-5 space-y-0 h-full"
        hasContainer={false}
        preventClose={
          source === WorkflowSource.WORKFLOWS_PAGE &&
          workflowModalState === WorkflowModalState.FilesProgress
        }
      >
        {workflowModalState === WorkflowModalState.Builder && (
          <VaultWorkflowPreview
            handleOpenAddFiles={handleOpenAddFiles}
            handleOpenProjectChooser={handleOpenProjectChooser}
            isShowingWorkflowAdminInfo={isShowingWorkflowAdminInfo}
            source={source}
          />
        )}
        {workflowModalState === WorkflowModalState.BuilderFromSelector && (
          <VaultWorkflowPreview
            handleOpenAddFiles={handleOpenAddFiles}
            handleOpenProjectChooser={handleOpenProjectChooser}
            isShowingWorkflowAdminInfo={isShowingWorkflowAdminInfo}
            source={source}
            isFromSelector
          />
        )}
        {workflowModalState === WorkflowModalState.AddFilesWorkflow &&
          currentProjectId && (
            <VaultWorkflowAddFiles
              projectId={currentProjectId}
              onBackButtonClicked={() => {
                if (source === WorkflowSource.VAULT_PROJECT_PAGE) {
                  setWorkflowModalState(WorkflowModalState.Builder)
                } else {
                  setWorkflowModalState(WorkflowModalState.ProjectChooser)
                }
              }}
              buttonLabel="Create table"
              preselectFileIds={preselectFileIds}
            />
          )}
        {workflowModalState === WorkflowModalState.ProjectChooser && (
          <VaultWorkflowProjectChooser
            selectedWorkflowName={selectedWorkflow?.name ?? ''}
            handleSelectProjectForWorkflow={handleSelectProjectForWorkflow}
            handleCreateProjectCardClick={handleCreateProjectCardClick}
          />
        )}
        {workflowModalState === WorkflowModalState.UploadFiles && (
          <VaultWorkflowCreateProject
            onBackButtonClicked={() => {
              setWorkflowModalState(WorkflowModalState.ProjectChooser)
            }}
            handleNavigateToFilesProgress={handleNavigateToFilesProgress}
          />
        )}
        {workflowModalState === WorkflowModalState.FilesProgress && (
          <VaultWorkflowFilesProgress
            onBackButtonClicked={() => {
              // TODO(stella): figure out where the back button is really supposed to go
              setWorkflowModalState(WorkflowModalState.ProjectChooser)
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default VaultWorkflowBuilder
