import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMount } from 'react-use'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  FileSource,
  KnowledgeSourceItem,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'

export type AssistantChatLocationState =
  | {
      query: string
      eventId: string | null
      knowledgeSource: KnowledgeSourceItem | null
    }
  | undefined

/**
 * Hook to extract the query and knowledge source from the location state
 * when the user navigates from the home page composer to the chat workflow.
 *
 * @returns {boolean} isLoading - Indicates if the data is still loading.
 * @returns {string | null} query - The extracted query from the location state.
 * @returns {KnowledgeSourceItem | null} knowledgeSource - The extracted knowledge source from the location state.
 */
export const useLocationChatQuery = (): {
  isLoading: boolean
  query: string | null
  knowledgeSource: KnowledgeSourceItem | null
} => {
  const location = useLocation()
  const locationState = location.state as AssistantChatLocationState
  const getDocumentFileIds = useAssistantStore((s) => s.getDocumentFileIds)
  const [isLoading, setIsLoading] = React.useState(false)
  const [knowledgeSource, setKnowledgeSource] =
    React.useState<KnowledgeSourceItem | null>(null)
  const [query, setQuery] = React.useState<string | null>(null)
  const setEventId = useAssistantWorkflowStore((s) => s.setPendingEventId)

  useMount(async () => {
    const { query, eventId } = locationState || {}
    let { knowledgeSource } = locationState || {}

    if (!query) return

    setIsLoading(true)
    setQuery(query)

    if (eventId) setEventId(Number(eventId))

    if (!knowledgeSource) {
      const fileIds = await getDocumentFileIds()
      if (fileIds.length > 0) {
        knowledgeSource = {
          type: FileSource.FILES,
          fileIds: fileIds,
        } as unknown as KnowledgeSourceItem
      }
    }
    setKnowledgeSource(knowledgeSource || null)

    setIsLoading(false)
  })

  return { isLoading, query, knowledgeSource }
}
