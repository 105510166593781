import React, { useEffect, useState, useMemo } from 'react'

import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { type ColumnDef } from '@tanstack/react-table'
import { useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { ChevronDown } from 'lucide-react'
import pluralize from 'pluralize'

import { PermBundle } from 'models/perms'
import {
  setUserRole,
  setUserRoleInternalAdmin,
  SetUserRoleResponse,
} from 'models/roles'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { cn } from 'utils/utils'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from 'components/ui/accordion'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { DataTable } from 'components/ui/data-table/data-table'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'

interface WorkspaceSetUserRoleDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (selectedPermissions: PermissionBundleId[]) => Promise<void>
  userId: string
  rolePk: string
  userExtraPermissions?: PermissionBundleId[]
  headerText?: string
  subHeaderText?: string
  isCustomerFacing?: boolean
}

const WorkspaceSetUserRoleDialog = ({
  isOpen,
  onClose,
  onSubmit,
  userId,
  userExtraPermissions,
  rolePk,
  headerText = 'Update role',
  subHeaderText,
  isCustomerFacing = false,
}: WorkspaceSetUserRoleDialogProps) => {
  const [previewResult, setPreviewResult] =
    useState<SetUserRoleResponse | null>(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const retainablePerms = useMemo(
    () =>
      previewResult?.permsToLose.filter(
        (perm) => userExtraPermissions?.includes(perm.id)
      ) ?? [],
    [previewResult?.permsToLose, userExtraPermissions]
  )
  const permsToGain = previewResult?.permsToGain ?? []
  const permsToLose =
    previewResult?.permsToLose.filter(
      (perm) => !retainablePerms.includes(perm)
    ) ?? []
  const isLoading = !previewResult

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const selectedPermissions = Object.entries(rowSelection)
    .filter(([, selected]) => selected)
    .map(([id]) => id as PermissionBundleId)

  useEffect(() => {
    const fetchDryRun = async () => {
      const setRoleFn = isCustomerFacing
        ? setUserRole
        : setUserRoleInternalAdmin
      const resp = await setRoleFn({
        userId,
        rolePk,
        dryRun: true,
      })
      setPreviewResult(resp)
    }
    void fetchDryRun()
  }, [rolePk, userId, isCustomerFacing])

  const handleSubmit = async () => {
    const permsToRevoke = retainablePerms
      .filter((bundle) => !selectedPermissions.includes(bundle.id)) // Invert to get perms to revoke
      .map((bundle) => bundle.id)
    await onSubmit(permsToRevoke)
    onClose()
  }

  const columns = useMemo<ColumnDef<PermBundle>[]>(
    () => [
      {
        id: 'select',
        cell: ({ row }) => (
          <Checkbox
            className="mt-0.5"
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        size: 36,
      },
      {
        accessorKey: 'name',
        cell: ({ row }) => (
          <div>
            <div className="text-xs font-medium">{row.original.name}</div>
            <div className="text-xs text-muted">{row.original.description}</div>
          </div>
        ),
        size: 9001, // Fill all space, since default 150px is too small
      },
    ],
    []
  )

  const table = useReactTable({
    data: retainablePerms,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  })

  const renderRetainablePermissions = () => {
    if (retainablePerms.length === 0) {
      return null
    }

    return (
      <div className="space-y-4">
        <div>
          <p className="text-sm font-medium">
            User will lose extra permissions
          </p>
          <p className="text-xs text-muted">
            This user has extra permissions not included in the new role. Select
            any permissions you want to retain.
          </p>
        </div>
        <DataTable
          table={table}
          hideHeaders
          tableCellClassName="py-3 align-top"
        />
      </div>
    )
  }

  const renderPermissionChanges = () => {
    if (!previewResult) {
      return null
    }

    return (
      <Accordion
        type="single"
        collapsible
        className={cn('w-full', {
          'sticky bottom-0 bg-primary': !isAccordionOpen,
          'before:absolute before:-top-4 before:left-0 before:h-4 before:w-full before:bg-gradient-to-b before:from-transparent before:to-white':
            !isAccordionOpen,
        })}
        onValueChange={(value) => setIsAccordionOpen(!!value)}
      >
        <AccordionItem value="grant">
          <AccordionPrimitive.Header className="flex">
            <AccordionPrimitive.Trigger
              className={cn(
                'flex flex-1 items-center justify-between space-x-2 rounded-md border border-primary px-4 py-3 pr-5 font-sans text-sm transition-[border-color] duration-200 [&[data-state=open]>svg]:rotate-180 [&[data-state=open]]:rounded-b-none'
              )}
            >
              <div className="flex flex-col items-start gap-1">
                <p className="text-sm font-medium leading-4">
                  {retainablePerms.length > 0
                    ? 'All other permission changes'
                    : 'All permission changes'}
                </p>
                <span className="text-xs text-muted">
                  For a comprehensive view of permissions associated with each
                  role, visit the Roles page.
                </span>
              </div>

              <div className="flex items-center gap-2">
                <p className="text-xs text-muted">
                  Grant {permsToGain.length} · Remove {permsToLose.length}
                </p>
                <ChevronDown className="size-4 shrink-0 transition-transform duration-200" />
              </div>
            </AccordionPrimitive.Trigger>
          </AccordionPrimitive.Header>

          <AccordionContent className="-mt-px rounded-b-md border [&>div:last-child]:-mb-4 [&>div:last-child]:border-b-0">
            {permsToGain.length > 0 && (
              <div className="border-b bg-secondary p-2 px-4">
                <p className="text-xs font-medium text-muted">
                  {pluralize('permission', permsToGain.length, true)} will be
                  added
                </p>
              </div>
            )}
            {permsToGain.map((bundle: PermBundle) => (
              <div key={bundle.id} className="border-b p-3 px-4">
                <p className="text-xs font-medium">{bundle.name}</p>
                <p className="text-xs text-muted">{bundle.description}</p>
              </div>
            ))}
            {permsToLose.length > 0 && (
              <div className="border-b bg-secondary p-2 px-4">
                <p className="text-xs font-medium text-destructive">
                  {pluralize('permission', permsToLose.length, true)} will be
                  removed
                </p>
              </div>
            )}
            {permsToLose.map((bundle: PermBundle) => (
              <div key={bundle.id} className="border-b p-3 px-4">
                <p className="text-xs font-medium text-muted">{bundle.name}</p>
                <p className="text-xs text-inactive">{bundle.description}</p>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    )
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent showCloseIcon className="max-w-3xl lg:max-w-4xl">
        <DialogHeader>
          <DialogTitle>{headerText}</DialogTitle>
          {subHeaderText && (
            <DialogDescription>{subHeaderText}</DialogDescription>
          )}
        </DialogHeader>

        <ScrollArea maxHeight="max-h-[400px]" className="-mr-5 pr-5">
          <div className="flex flex-col gap-4">
            {renderRetainablePermissions()}
            {renderPermissionChanges()}
          </div>
        </ScrollArea>

        <DialogFooter>
          <div className="flex w-full items-center justify-between gap-4">
            <div className="text-xs text-muted">
              {selectedPermissions.length > 0 && (
                <>
                  Retain{' '}
                  {pluralize(
                    'permission',
                    Object.values(rowSelection).filter(Boolean).length,
                    true
                  )}
                </>
              )}
            </div>
            <div className="flex gap-2">
              <DialogClose asChild>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
              </DialogClose>
              <Button
                variant="default"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Confirm
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspaceSetUserRoleDialog
