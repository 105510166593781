import * as React from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { cn } from 'utils/utils'

import {
  FileSource,
  KnowledgeSourceItem,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { FetchingSpinner } from 'components/common/vault-knowledge-source-picker'
import { Button } from 'components/ui/button'
import {
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
} from 'components/ui/dialog'
import { Dialog } from 'components/ui/dialog'
import VaultFileExplorerInModal from 'components/vault/components/file-explorer/vault-file-explorer-in-modal'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  getSortedFilesBasedOnReviewQueryOrder,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

const AssistantProjectFileSelector = ({
  showDialog,
  setShowDialog,
  onClose,
  setKnowledgeSource,
  isEmpty,
  dialogTitle,
  dialogDescription,
  isFetching,
  allVaultProjects,
  activeFileIds,
  numberOfFilesLimit,
  numberOfFilesWarningThreshold,
  emptyState,
  projectChooser,
  isCurrentProjectKnowledgeBase = false,
}: {
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
  onClose: () => void
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  isEmpty: boolean
  dialogTitle: string
  dialogDescription: string
  isFetching: boolean
  allVaultProjects: VaultFolder[]
  activeFileIds: Set<string>
  numberOfFilesLimit?: number
  numberOfFilesWarningThreshold?: number
  emptyState?: React.ReactNode
  projectChooser?: React.ReactNode
  isCurrentProjectKnowledgeBase?: boolean
}) => {
  const [
    fileIdToVaultFile,
    folderIdToVaultFolder,
    currentProject,
    setCurrentProject,
  ] = useVaultStore(
    useShallow((s) => [
      s.fileIdToVaultFile,
      s.folderIdToVaultFolder,
      s.currentProject,
      s.setCurrentProject,
    ])
  )
  const [selectedRows, setSelectedRows] = useVaultFileExplorerStore(
    useShallow((s) => [s.selectedRows, s.setSelectedRows])
  )
  const activeFiles = Array.from(activeFileIds)
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]
  const handleOpenChange = (isOpen: boolean) => {
    setShowDialog(isOpen)
    if (!isOpen && !activeFiles.length) handleClose()
  }
  const handleClose = () => {
    setKnowledgeSource(null)
    setCurrentProject(null)
    onClose()
  }

  const selectedFiles = React.useMemo(() => {
    return getSelectedFiles(selectedRows)
  }, [selectedRows])

  const handleResetFolder = () => {
    setCurrentProject(null)
  }

  const handleAddFiles = () => {
    const sortedFiles = getSortedFilesBasedOnReviewQueryOrder(
      selectedFiles,
      folderIdToVaultFolder
    )
    setKnowledgeSource({
      folderId: currentProject?.id,
      type: FileSource.VAULT,
      fileIds: sortedFiles.map((file) => file.id),
    })
    setShowDialog(false)
  }

  const tooManyFilesSelected =
    !_.isNil(numberOfFilesLimit) && selectedFiles.length > numberOfFilesLimit

  return (
    <Dialog open={showDialog} onOpenChange={handleOpenChange}>
      <DialogContent
        className={cn('max-w-[800px]', {
          'h-[65vh]': !isEmpty,
        })}
        hasContainer={!currentProject}
        innerClassName="flex flex-col p-0"
      >
        {!isEmpty && (
          <DialogHeader className="px-6 pt-6">
            <DialogTitle className="flex items-center">
              {dialogTitle}{' '}
              {isFetching && (
                <FetchingSpinner
                  delayMs={allVaultProjects.length > 0 ? 1000 : 0}
                />
              )}
            </DialogTitle>
            <DialogDescription>{dialogDescription}</DialogDescription>
          </DialogHeader>
        )}
        {currentProject ? (
          <div className="flex min-h-0 grow flex-col space-y-4">
            <VaultFileExplorerInModal
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              className="flex min-h-0 grow flex-col"
              preselectFileIds={activeFileIds}
              projectId={currentProject.id}
              numberOfFilesLimit={numberOfFilesLimit}
              numberOfFilesWarningThreshold={numberOfFilesWarningThreshold}
              shouldHideAllFiles={isCurrentProjectKnowledgeBase}
            />
            <div className="flex items-center justify-between space-x-2 px-6 pb-6">
              {selectedFiles.length > 0 ? (
                <p>{pluralizeFiles(selectedFiles.length)}</p>
              ) : (
                <div />
              )}
              <div className="flex items-center space-x-2">
                {!activeFiles.length && (
                  <Button variant="outline" onClick={handleResetFolder}>
                    Back
                  </Button>
                )}
                <Button
                  disabled={!selectedFiles.length || tooManyFilesSelected}
                  onClick={handleAddFiles}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        ) : isEmpty ? (
          emptyState
        ) : (
          projectChooser
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AssistantProjectFileSelector
