import React, { useState, useCallback } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  ColumnDef,
  Row,
  SortingState,
} from '@tanstack/react-table'
import {
  BookOpen,
  Building2,
  MoreHorizontal,
  Search,
  Users,
} from 'lucide-react'
import pluralize from 'pluralize'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { VaultFolderShareUser } from 'openapi/models/VaultFolderShareUser'
import { VaultFolderShareWorkspace } from 'openapi/models/VaultFolderShareWorkspace'
import { WorkspaceProject } from 'openapi/models/WorkspaceProject'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { bytesToReadable } from 'utils/file-utils'
import { displaySuccessMessage, displayErrorMessage } from 'utils/toast'
import { EM_DASH } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAuthUser } from 'components/common/auth-context'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { projectsPath } from 'components/vault/utils/vault'
import {
  PatchFolder,
  DeleteVaultFolder,
} from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

import { FetchWorkspaceProjects } from './workspace-projects-fetcher'

const WorkspaceProjects: React.FC = () => {
  const userInfo = useAuthUser()
  const settingsUser = useSettingsState((s) => s.settingsUser)
  const clientMatters = useClientMattersStore((s) => s.clientMatters)
  const exampleProjectIds = useVaultStore((s) => s.exampleProjectIds)
  const [selectedProject, setSelectedProject] =
    useState<WorkspaceProject | null>(null)
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false)
  const [newProjectName, setNewProjectName] = useState('')
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [globalFilterState, setGlobalFilterState] = useState({
    searchText: '',
    sharedFilter: 'all' as 'all' | 'shared' | 'unshared',
    clientMatterId: '',
  })
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'updatedAt', desc: true },
  ])

  const navigate = useNavigateWithQueryParams()

  const { data, isLoading, refetch, error } = useWrappedQuery({
    queryKey: [
      HarvQueryKeyPrefix.WorkspaceProjectsQuery,
      settingsUser?.workspace.id,
    ],
    queryFn: FetchWorkspaceProjects,
    refetchOnWindowFocus: false,
    enabled: !!settingsUser?.workspace.id,
  })

  const isKnowledgeBaseProjectUser = userInfo.IsKnowledgeBaseProjectUser

  const handleRename = async (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!selectedProject || !newProjectName.trim()) return

    try {
      await PatchFolder(selectedProject.id, { new_name: newProjectName.trim() })
      displaySuccessMessage('Project renamed successfully')
      setIsRenameModalVisible(false)
      refetch()
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('Failed to rename project')
      }
    }
  }

  const handleDelete = async () => {
    if (!selectedProject) return

    try {
      await DeleteVaultFolder(selectedProject.id)
      displaySuccessMessage('Project deleted successfully')
      setIsDeleteModalVisible(false)
      refetch()
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('Failed to delete project')
      }
    }
  }

  const getClientMatterName = useCallback(
    (clientMatterId: string | null) => {
      if (!clientMatterId) return EM_DASH
      const clientMatter = clientMatters.find((cm) => cm.id === clientMatterId)
      return clientMatter?.name || EM_DASH
    },
    [clientMatters]
  )

  const handleGlobalFilter = useCallback(
    (
      row: Row<WorkspaceProject>,
      columnId: string,
      filterValue: typeof globalFilterState
    ) => {
      const searchValue = filterValue.searchText.toLowerCase()
      const rowClientMatterName = getClientMatterName(
        row.original.clientMatterId ?? null
      ).toLowerCase()

      // Match client matter by ID
      const clientMatterMatch = filterValue.clientMatterId
        ? rowClientMatterName === filterValue.clientMatterId.toLowerCase()
        : true

      const activeWorkspaceShares =
        row.original.sharing.workspaceShares?.filter(
          (s: VaultFolderShareWorkspace) => s.deletedAt === null
        ).length ?? 0
      const activeUserShares =
        row.original.sharing.userShares?.filter(
          (s: VaultFolderShareUser) => s.deletedAt === null
        ).length ?? 0
      const isShared = activeWorkspaceShares > 0 || activeUserShares > 0

      const sharedMatch =
        filterValue.sharedFilter === 'all'
          ? true
          : filterValue.sharedFilter === 'shared'
          ? isShared
          : !isShared

      // Search in all string and number fields, including client matter name
      const searchMatch =
        searchValue === '' ||
        Object.values(row.original).some((value) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchValue)
          }
          if (typeof value === 'number') {
            return value.toString().includes(searchValue)
          }
          return false
        })

      return clientMatterMatch && searchMatch && sharedMatch
    },
    [getClientMatterName]
  )

  const handleRowClick = useCallback(
    (row: Row<WorkspaceProject>) => {
      const path = `${BaseAppPath.Vault}${projectsPath}${row.original.id}`
      navigate(path)
    },
    [navigate]
  )

  const handleOpenInNewTab = useCallback((row: Row<WorkspaceProject>) => {
    const path = `${BaseAppPath.Vault}${projectsPath}${row.original.id}`
    window.open(path, '_blank')
  }, [])

  interface ProjectActionsDropdownProps {
    row: Row<WorkspaceProject>
    isExampleProject: boolean
    onOpenInNewTab: (row: Row<WorkspaceProject>) => void
    onRename: (project: WorkspaceProject, name: string) => void
    onDelete: (project: WorkspaceProject) => void
  }

  const ProjectActionsDropdown: React.FC<ProjectActionsDropdownProps> = ({
    row,
    isExampleProject,
    onOpenInNewTab,
    onRename,
    onDelete,
  }) => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            onClick={(e) => e.stopPropagation()}
          >
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation()
              onOpenInNewTab(row)
            }}
          >
            Open in new tab
          </DropdownMenuItem>
          {!isExampleProject && (
            <>
              <DropdownMenuItem
                onClick={(e) => {
                  e.stopPropagation()
                  onRename(row.original, row.original.name)
                }}
              >
                Rename
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete(row.original)
                }}
              >
                Delete
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }

  const columns: ColumnDef<WorkspaceProject>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Name" />
      ),
      size: 300,
      cell: ({ row }) => {
        const name = row.original.name
        const showKnowledgeBaseBadge =
          isKnowledgeBaseProjectUser && row.original.isKnowledgeBaseProject
        return (
          <Tooltip>
            <TooltipTrigger className="block w-full">
              {showKnowledgeBaseBadge ? (
                <div className="flex items-center gap-1">
                  <div className="line-clamp-1 text-left text-sm" title={name}>
                    {name}
                  </div>
                  <Badge
                    variant="skeleton"
                    className="border-0 p-1.5 text-muted"
                  >
                    <div className="flex items-center gap-1">
                      <Icon icon={BookOpen} size="small" />
                      <p className="text-2xs font-medium">Knowledge base</p>
                    </div>
                  </Badge>
                </div>
              ) : (
                <div className="line-clamp-1 text-left text-sm" title={name}>
                  {name}
                </div>
              )}
            </TooltipTrigger>
            <TooltipContent className="max-w-sm">{name}</TooltipContent>
          </Tooltip>
        )
      },
    },
    {
      accessorKey: 'creatorEmail',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Creator" />
      ),
      cell: ({ row }) => row.original.creatorEmail || EM_DASH,
      size: 200,
    },
    {
      accessorKey: 'size',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Size" />
      ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.sizeBytes - rowB.original.sizeBytes
      },
      cell: ({ row }) =>
        `${pluralizeFiles(row.original.filesCount)} (${bytesToReadable(
          row.original.sizeBytes
        )})`,
      size: 150,
    },
    {
      accessorKey: 'sharing',
      header: 'Sharing',
      enableSorting: false,
      cell: ({ row }) => {
        const isKnowledgeBaseProject = row.original.isKnowledgeBaseProject
        const shouldShowEveryoneAtWorkspace =
          userInfo.IsKnowledgeBaseProjectUser && isKnowledgeBaseProject
        const { workspaceCount, userCount, userShares } = row.original.sharing
        if (workspaceCount === 0 && userCount === 0) return EM_DASH

        // Get active user shares
        const activeUserShares = userShares.filter(
          (share) => share.deletedAt === null
        )

        // Function to determine sharing information
        const getSharingInfo = () => {
          const hasWorkspaceSharing = workspaceCount > 0

          if (hasWorkspaceSharing) {
            if (shouldShowEveryoneAtWorkspace) {
              return {
                sharingText: 'Everyone in workspace',
                tooltipContent: '',
              }
            }
            return {
              sharingText: 'Anyone in workspace with link',
              tooltipContent: '',
            }
          } else if (activeUserShares.length > 0) {
            return {
              sharingText: `Shared with ${activeUserShares.length} ${pluralize(
                'user',
                activeUserShares.length
              )}`,
              tooltipContent: activeUserShares
                .map((share) => share.destUserEmail)
                .join(', '),
            }
          }

          return {
            sharingText: '',
            tooltipContent: '',
          }
        }

        const { sharingText, tooltipContent } = getSharingInfo()
        const SharingIcon = shouldShowEveryoneAtWorkspace ? Building2 : Users

        if (!sharingText) return EM_DASH

        return (
          <div className="flex items-center gap-2">
            {activeUserShares.length > 0 ? (
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="group flex cursor-help items-center gap-2">
                    <Icon
                      icon={SharingIcon}
                      size="default"
                      variant="secondary"
                      className="transition group-hover:text-primary"
                    />
                    <span className="text-sm transition group-hover:text-primary">
                      {sharingText}
                    </span>
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-[280px]">
                  <span className="line-clamp-4 max-h-[92px] overflow-hidden text-ellipsis whitespace-pre-line text-[length:inherit]">
                    {tooltipContent}
                  </span>
                </TooltipContent>
              </Tooltip>
            ) : (
              <>
                <Icon icon={SharingIcon} size="default" variant="secondary" />
                <span className="text-sm">{sharingText}</span>
              </>
            )}
          </div>
        )
      },
      size: 220,
    },
    {
      accessorKey: 'queryCount',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Queries" />
      ),
      cell: ({ row }) => {
        const count = row.original.queryCount
        return count === 0
          ? EM_DASH
          : `${count} ${count === 1 ? 'query' : 'queries'}`
      },
      size: 120,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => (
        <DataTableSortHeader
          column={column}
          header="Created"
          tooltipText="Date when this project was created"
        />
      ),
      sortingFn: (rowA, rowB) => {
        const dateA = new Date(rowA.original.createdAt).getTime()
        const dateB = new Date(rowB.original.createdAt).getTime()
        return dateA - dateB
      },
      cell: ({ row }) => {
        try {
          return new Date(row.original.createdAt).toLocaleDateString()
        } catch (e) {
          return EM_DASH
        }
      },
      size: 120,
    },
    {
      accessorKey: 'updatedAt',
      header: ({ column }) => (
        <DataTableSortHeader
          column={column}
          header="Updated"
          tooltipText="Date when this project was last updated"
        />
      ),
      sortingFn: (rowA, rowB) => {
        const dateA = new Date(rowA.original.updatedAt).getTime()
        const dateB = new Date(rowB.original.updatedAt).getTime()
        return dateA - dateB
      },
      cell: ({ row }) => {
        try {
          return new Date(row.original.updatedAt).toLocaleDateString()
        } catch (e) {
          return EM_DASH
        }
      },
      size: 120,
    },
    ...(userInfo.IsVaultProjectClientMatterUser
      ? [
          {
            accessorKey: 'clientMatterId',
            header: ({ column }: { column: any }) => (
              <DataTableSortHeader
                column={column}
                header="Client Matter"
                tooltipText="Client matter associated with this project"
              />
            ),
            sortingFn: (
              rowA: Row<WorkspaceProject>,
              rowB: Row<WorkspaceProject>
            ) => {
              const cmA = getClientMatterName(
                rowA.original.clientMatterId ?? null
              )
              const cmB = getClientMatterName(
                rowB.original.clientMatterId ?? null
              )
              return cmA.localeCompare(cmB)
            },
            cell: ({ row }: { row: Row<WorkspaceProject> }) =>
              getClientMatterName(row.original.clientMatterId ?? null),
            size: 120,
          },
        ]
      : []),
    {
      id: 'actions',
      cell: ({ row }) => {
        const isExampleProject = exampleProjectIds.has(row.original.id)
        return userInfo.isWorkspaceProjectsModifier ? (
          <ProjectActionsDropdown
            row={row}
            isExampleProject={isExampleProject}
            onOpenInNewTab={handleOpenInNewTab}
            onRename={(project, name) => {
              setSelectedProject(project)
              setNewProjectName(name)
              setIsRenameModalVisible(true)
            }}
            onDelete={(project) => {
              setSelectedProject(project)
              setIsDeleteModalVisible(true)
            }}
          />
        ) : null
      },
      size: 50,
    },
  ]

  const table = useReactTable({
    data: data?.projects ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      globalFilter: globalFilterState,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilterState,
    globalFilterFn: handleGlobalFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const renderContent = () => {
    if (error) {
      return (
        <div className="text-red-500">
          {error instanceof Error
            ? error.message
            : 'Failed to load workspace projects'}
        </div>
      )
    }

    return (
      <>
        <div className="mb-4 flex items-center justify-between">
          <Tabs
            value={globalFilterState.sharedFilter}
            onValueChange={(value) =>
              setGlobalFilterState((prev) => ({
                ...prev,
                sharedFilter: value as 'all' | 'shared' | 'unshared',
              }))
            }
          >
            <TabsList className="h-8">
              <TabsTrigger className="h-6" value="all">
                All
              </TabsTrigger>
              <TabsTrigger className="h-6" value="unshared">
                Private
              </TabsTrigger>
              <TabsTrigger className="h-6" value="shared">
                Shared
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="flex items-center gap-2">
            {userInfo.IsVaultProjectClientMatterUser && (
              <ClientMatterSelect
                className="w-40"
                selectedValue={globalFilterState.clientMatterId}
                setSelectedValue={(value: string) => {
                  setGlobalFilterState((prev) => ({
                    ...prev,
                    clientMatterId: value,
                  }))
                }}
              />
            )}
            <div className="relative">
              <div className="absolute flex h-full items-center px-3">
                <Search className="text-muted-foreground h-4 w-4" />
              </div>
              <Input
                placeholder="Search projects…"
                className="h-8 w-[216px] pl-9"
                value={globalFilterState.searchText}
                onChange={(e) =>
                  setGlobalFilterState((prev) => ({
                    ...prev,
                    searchText: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <DataTable
          table={table}
          caption="Workspace Projects"
          isLoading={isLoading}
          tableFixed
          useVirtual={false}
          marginTop={48}
          onRowClick={handleRowClick}
        />
      </>
    )
  }

  return (
    <>
      <SettingsAppHeader />
      <SettingsLayout>
        <div
          className="space-y-4 pb-8"
          data-testid="workspace-projects-container"
        >
          {renderContent()}
        </div>
      </SettingsLayout>

      <Dialog
        open={isRenameModalVisible}
        onOpenChange={setIsRenameModalVisible}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Rename Project</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <Input
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
              placeholder="Enter new project name"
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsRenameModalVisible(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleRename}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDeleteModalVisible}
        onOpenChange={setIsDeleteModalVisible}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Project</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this project? This action cannot
              be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsDeleteModalVisible(false)}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default WorkspaceProjects
