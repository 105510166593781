import React from 'react'

import { Info } from 'lucide-react'

import { cn } from 'utils/utils'

import Icon from 'components/ui/icon/icon'

export const DEFAULT_FILES_WARNING_THRESHOLD = 1000
export const MISMATCH_MESSAGE_SINGULAR =
  'The document that you’ve selected doesn’t match the recommended document type for this workflow.'
export const MISMATCH_MESSAGE_PLURAL =
  'The documents that you’ve selected don’t match the recommended document type for this workflow.'

/*
 * UI component for warnings about too many files
 */
export const WarningMessage = ({
  isDestructive,
  message,
}: {
  isDestructive?: boolean
  message: string
}) => {
  return (
    <div
      className={cn('flex items-center gap-2 px-6 py-3', {
        'bg-destructive/15': isDestructive,
        'bg-accent': !isDestructive,
      })}
    >
      <Icon
        icon={Info}
        size="small"
        className={cn({
          'text-destructive': isDestructive,
        })}
      />
      <p
        className={cn('text-xs', {
          'text-destructive': isDestructive,
        })}
      >
        {message}
      </p>
    </div>
  )
}
