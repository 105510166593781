import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { RowNode } from 'ag-grid-community'
import { Trash, Download, X } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { OrderedSet } from 'utils/ordered-set'
import { TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import Toolbelt, {
  ToolbeltButton,
  ToolbeltDivider,
  ToolbeltMenu,
} from 'components/ui/toolbelt'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { fileIdSearchParamKey } from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import {
  downloadFiles,
  exportExcelWithReviewState,
  exportWordWithReviewState,
} from 'components/vault/utils/vault-exporter'
import { DeleteReviewRow } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeDocuments } from 'components/vault/utils/vault-text-utils'

const VaultDataGridToolbelt = ({ className }: { className?: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)

  const [
    currentProject,
    fileIdToVaultFile,
    upsertVaultFiles,
    currentProjectMetadata,
  ] = useVaultStore(
    useShallow((s) => [
      s.currentProject,
      s.fileIdToVaultFile,
      s.upsertVaultFiles,
      s.currentProjectMetadata,
    ])
  )

  const [selectedRows, clearSelectedRows, bulkAddSelectedRows] =
    useVaultDataGridFilterStore(
      useShallow((s) => [
        s.selectedRows,
        s.clearSelectedRows,
        s.bulkAddSelectedRows,
      ])
    )

  const [
    queryId,
    historyItem,
    gridApi,
    pendingQueryFileIds,
    fileIdToSources,
    setPendingQueryFileIds,
    removeFileIdsFromPendingQueryFileIds,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.queryId,
      s.historyItem,
      s.gridApi,
      s.pendingQueryFileIds,
      s.fileIdToSources,
      s.setPendingQueryFileIds,
      s.removeFileIdsFromPendingQueryFileIds,
    ])
  )

  const reviewState = historyItem as ReviewHistoryItem
  const isToolbeltVisible = selectedRows.length > 0 && !fileId
  const displayText = `${pluralizeDocuments(selectedRows.length)} selected`

  if (!isToolbeltVisible) {
    return null
  }

  const onDeleteSelectedRows = async () => {
    if (!gridApi) return

    const reviewHistoryItem = historyItem as ReviewHistoryItem
    const isNewQuery = queryId === 'new'
    const gridRowsCopy: RowNode[] = []
    const selectedRowsCopy = [...selectedRows]
    const pendingQueryFileIdsCopy = pendingQueryFileIds ?? []

    // 1. delete the rows from the grid
    const newRowData: any[] = []
    gridApi.forEachNode((node) => {
      gridRowsCopy.push(node.data)
      const isGroupNode = node.group

      if (isGroupNode || selectedRows.includes(node.data.id)) {
        return
      }
      newRowData.push(node.data)
    })
    gridApi.setGridOption('rowData', newRowData)

    // 2. if row is a pending row, then remove it from the pending query file ids
    removeFileIdsFromPendingQueryFileIds(selectedRows)

    // 3. clear any selected rows
    gridApi.deselectAll()
    clearSelectedRows()

    const failedFileIds: string[] = []
    await Promise.all(
      selectedRows.map(async (fileId) => {
        try {
          // if the row is a pending row, then we don't need to delete it from the history item
          const isPendingRow = pendingQueryFileIdsCopy.includes(fileId)
          if (isPendingRow || isNewQuery) return
          // find the row in the history item to get the backingReviewRowId
          const historyItemRow = reviewHistoryItem.rows.find(
            (row) => row.fileId === fileId
          )
          if (!historyItemRow) return
          const historyRowId = historyItemRow.id
          await DeleteReviewRow(Number(queryId), historyRowId)
        } catch (e) {
          failedFileIds.push(fileId)
        }
      })
    )
    if (failedFileIds.length > 0) {
      displayErrorMessage('Failed to delete row(s)')
      // 1. revert the grid to the original state excluding the rows that succeeded to delete
      // The only rows that should be in the grid are the ones that are:
      // - a group row
      // - a previously non-selected row
      // - a row that failed to delete
      const filteredGridRowsCopy = gridRowsCopy.filter(
        (rowData) =>
          !selectedRowsCopy.includes(rowData.id ?? '') ||
          failedFileIds.includes(rowData.id ?? '')
      )
      gridApi.setGridOption('rowData', filteredGridRowsCopy)

      // 2. Add the selected rows back to the selected rows
      // The only rows that should be selected are the ones that failed to delete
      selectedRowsCopy.forEach((fileId) => {
        const node = gridApi.getRowNode(fileId)
        const shouldSelectNode = node && failedFileIds.includes(fileId)
        if (shouldSelectNode) node.setSelected(true)
      })
      bulkAddSelectedRows(selectedRowsCopy)

      // 3. Add the selected rows back to the pending query file ids
      // If the row was a pending row, then we need to add it back to the pending query file ids
      const filteredPendingQueryFileIds = pendingQueryFileIdsCopy.filter(
        (fileId) => failedFileIds.includes(fileId)
      )
      setPendingQueryFileIds(filteredPendingQueryFileIds)
    }
    // Once we've deleted the rows, we need to reset the row number cells
    gridApi.refreshCells({
      columns: ['row'],
    })
  }

  const downloadHandler = async () => {
    await downloadFiles({
      fileIdsToDownload: selectedRows,
      fileIdToVaultFile,
      downloadFileName: currentProject?.name || '',
      upsertVaultFiles,
      projectId: currentProject?.id,
      foldersInProject: currentProjectMetadata.descendantFolders ?? [],
    })
  }

  const exportWordHandler = async () => {
    if (!gridApi || !queryId) return

    const visibleRows = gridApi.getSelectedNodes()
    await exportWordWithReviewState({
      visibleRows,
      queryId,
      taskType: TaskType.VAULT_REVIEW,
      reviewState,
      shouldExportAdditionalContext: false,
      shouldIncludeQuestion: false,
      fileIdToVaultFile,
      fileIdToSources: fileIdToSources,
    })
  }

  const exportExcelHandler = async () => {
    if (!gridApi || !queryId) return
    const visibleRowIds = gridApi.getSelectedNodes().map((node) => node.data.id)
    await exportExcelWithReviewState({
      gridApi,
      queryId,
      visibleRowIds: new OrderedSet(visibleRowIds),
      taskType: TaskType.VAULT_REVIEW,
      sheetName: currentProject?.name || '',
      reviewState,
      fileIdToVaultFile,
      shouldExportAdditionalContext: false,
      shouldIncludeQuestion: false,
      isCSV: false,
    })
  }

  const onDeselectAllRows = () => {
    gridApi?.deselectAll()
    clearSelectedRows()
  }

  return (
    <Toolbelt
      className={cn(
        'flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0',
        className
      )}
    >
      <div className="flex items-center">
        <ToolbeltButton className="truncate rounded-l-md rounded-r-none border border-r-0 border-dotted border-primary transition hover:bg-interactive">
          {displayText}
        </ToolbeltButton>
        <ToolbeltButton
          icon={X}
          className="rounded-l-none rounded-r-md border border-dotted border-primary"
          onClick={onDeselectAllRows}
        />
      </div>
      <ToolbeltDivider className="hidden md:block" />
      <div className="flex flex-wrap items-center justify-center gap-1 md:flex-nowrap md:gap-2">
        <ToolbeltButton icon={Trash} onClick={onDeleteSelectedRows}>
          Delete
        </ToolbeltButton>
        <ToolbeltMenu
          items={[
            {
              label: 'Export selected rows to Word',
              onClick: exportWordHandler,
            },
            {
              label: 'Export selected rows to Excel',
              onClick: exportExcelHandler,
            },
            { label: 'Download selected files', onClick: downloadHandler },
          ]}
        >
          <ToolbeltButton id="download" data-testid="download" icon={Download}>
            Export
          </ToolbeltButton>
        </ToolbeltMenu>
      </div>
    </Toolbelt>
  )
}

export default VaultDataGridToolbelt
