import React, { ComponentProps, useEffect, useMemo, useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  Table,
  useReactTable,
  Column,
  Getter,
} from '@tanstack/react-table'
import { Alert } from 'antd'
import _ from 'lodash'
import { ListFilter, PlusIcon } from 'lucide-react'
import pluralize from 'pluralize'

import {
  getWorkspaceRoleConfigs,
  getWorkspaceRoleConfigsInternalAdmin,
  getWorkspaceRoles,
  getWorkspaceRolesInternalAdmin,
  setUserRole,
  setUserRoleInternalAdmin,
  WorkspaceRole,
  WorkspaceRoleConfig,
} from 'models/roles'
import { API_USER_SENTINEL } from 'models/user-info'
import { RawUserEnriched } from 'models/users'
import { Workspace } from 'models/workspace'
import { AuditLogType } from 'openapi/models/AuditLogType'
import { instanceOfBetaPermission } from 'openapi/models/BetaPermission'
import { BetaPermission } from 'openapi/models/BetaPermission'
import { DefaultRole } from 'openapi/models/DefaultRole'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { usePostAuditLog } from 'utils/audit-log'
import { getRelativeTableDateString } from 'utils/date-utils'
import { PWD_AUTH_CONN } from 'utils/routing'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import SettingsPageHeader from 'components/settings/settings-page-header'
import { useSettingsState } from 'components/settings/settings-store'
import AddUsersDialog from 'components/settings/user-management/add-users-dialog'
import DeleteUsersDialog from 'components/settings/user-management/delete-users-dialog'
import { PermBundlesMultiSelect } from 'components/settings/workspace/permissions/perm-bundles-multi-select'
import WorkspaceSetUserRoleDialog from 'components/settings/workspace/workspace-details/workspace-set-user-role-dialog'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import CsvDownload from 'components/ui/csv-download'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { Icon } from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import { MultiSelect } from 'components/ui/multi-select'
import SearchInput from 'components/ui/search-input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { SkeletonBlock } from 'components/ui/skeleton'
import { Switch } from 'components/ui/switch'

import ExpandablePermissionsCell from './expandable-permissions-cell'
import { RolesMultiSelect } from './roles-multi-select'
import WorkspacePermissionsDialog from './workspace-permissions-dialog'

interface Props {
  isLoading?: boolean
  isCustomerFacing?: boolean
  users: RawUserEnriched[]
  workspace: Workspace
  fetchData: () => Promise<void>
  hasPageHeader?: boolean
}

// TODO (ken): Feels like this could be in components/ui
const SelectHeader: React.FC<{ table: Table<RawUserEnriched> }> = ({
  table,
}) => {
  return (
    <div className="flex w-full justify-start">
      <Button
        variant="ghost"
        size="smIcon"
        onClick={(e) => {
          e.stopPropagation()
          table.toggleAllRowsSelected(!table.getIsAllRowsSelected())
        }}
      >
        <Checkbox
          checked={
            table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()
          }
          isIndeterminate={table.getIsSomeRowsSelected()}
          onCheckedChange={(value) => {
            table.toggleAllRowsSelected(!!value)
          }}
          aria-label="Select all"
        />
      </Button>
    </div>
  )
}

const SelectCell: React.FC<{
  row: Row<RawUserEnriched>
  className?: string
}> = ({ row, className }) => {
  return (
    <div className={cn('flex w-full justify-start', className)}>
      <Button
        variant="ghost"
        size="smIcon"
        className="ml-2 size-auto p-0"
        onClick={(e) => {
          e.stopPropagation()
          row.toggleSelected(!row.getIsSelected())
        }}
      >
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      </Button>
    </div>
  )
}

/**
 * Get the perm bundle ids that the user has through their roles.
 */
const getUserRolePermBundleIds = (
  user: RawUserEnriched,
  workspaceRoleConfigs: WorkspaceRoleConfig[]
): PermissionBundleId[] => {
  const userRoleIds = user.roles.map((role) => role.id)
  return workspaceRoleConfigs
    .filter((bundle) => {
      const bundleRoleIds = bundle.roleConfigs.map((role) => role.roleId)
      // User has bundle if any intersection between user roles and bundle roles
      return _.intersection(userRoleIds, bundleRoleIds).length > 0
    })
    .map((bundle) => bundle.permBundle.id)
}

/**
 * Get the perm bundle ids that the user has through both their roles and extra permissions.
 */
const getUserCombinedPermBundleIds = (
  user: RawUserEnriched,
  workspaceRoleConfigs: WorkspaceRoleConfig[]
): PermissionBundleId[] => {
  const userRolePermBundleIds = getUserRolePermBundleIds(
    user,
    workspaceRoleConfigs
  )
  const userExtraPermBundleIds = user.extraPermissions.map(
    (bundle) => bundle.permBundleId
  )
  return _.union(userRolePermBundleIds, userExtraPermBundleIds)
}

const WorkspaceUsersTableV2: React.FC<Props> = ({
  users,
  fetchData,
  workspace,
  isLoading = false,
  isCustomerFacing = false,
  hasPageHeader = false,
}) => {
  const subtitleText = isCustomerFacing
    ? 'View user roles, permissions, and add new users.'
    : 'Manage user roles, permissions, and add new users.'
  const settingsUser = useSettingsState((s) => s.settingsUser) // For internal admin view

  const canManageUsers = settingsUser?.isUserManagement
  const canAddUsers =
    canManageUsers ||
    (settingsUser?.IsClientAdminAddUsers &&
      (settingsUser.workspace.authConn === PWD_AUTH_CONN ||
        settingsUser.workspace.blockNonProvisionedSamlUsers))
  const canDeleteUsers =
    canManageUsers ||
    (settingsUser?.IsClientAdminRemoveUsers &&
      (settingsUser.workspace.authConn === PWD_AUTH_CONN ||
        settingsUser.workspace.blockNonProvisionedSamlUsers))
  const canSeeLastActiveAt = settingsUser?.canSeeLastActiveAt
  const canManagePerms = isCustomerFacing
    ? settingsUser?.IsClientAdminSelfServePermsWriter
    : canManageUsers

  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<SortingState>([
    ...(isCustomerFacing ? [] : [{ id: 'deletedAt', desc: false }]), // Sort deleted users to the bottom
    { id: 'email', desc: false },
  ])
  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 20,
    })

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleteErrorUsers, setDeleteErrorUsers] = useState<string[]>([])

  const [managePermissionsModalOpen, setManagePermissionsModalOpen] =
    useState<boolean>(false)

  const [roles, setRoles] = useState<WorkspaceRole[]>([])
  const [pendingUserRole, setPendingUserRole] = useState<{
    userId: string
    userEmail: string
    userExtraPermissions?: PermissionBundleId[]
    oldRoleName: string
    role: WorkspaceRole
  }>()
  const [workspaceRoleConfigs, setWorkspaceRoleConfigs] = useState<
    WorkspaceRoleConfig[]
  >([])
  const [loadingRoles, setLoadingRoles] = useState<boolean>(true)

  const [selectedPermBundleIds, setSelectedPermBundleIds] = useState<
    PermissionBundleId[]
  >([])
  const [selectedRolePks, setSelectedRolePks] = useState<string[]>([])
  const [selectedBetaPermissions, setSelectedBetaPermissions] = useState<
    string[]
  >([])
  const [showDeleted, setShowDeleted] = useState<boolean>(!isCustomerFacing)
  const numFilters =
    (selectedPermBundleIds.length > 0 ? 1 : 0) +
    (selectedRolePks.length > 0 ? 1 : 0) +
    (selectedBetaPermissions.length > 0 ? 1 : 0) +
    (showDeleted ? 1 : 0)

  const userEmailsWithViolations = useMemo(() => {
    if (isCustomerFacing) {
      return []
    }
    return users
      .filter((user) => {
        if (user.deletedAt) {
          return false // Don't include these in total count but can still show in table
        }
        if (user.roles.length !== 1) {
          return true
        }
        // Get all perm bundles user has through a role. If there are any violations
        // in extra permissions but they already have the full bundle through the role,
        // we don't need to consider the extra permission violation for now.
        const rolePermBundleIds = getUserRolePermBundleIds(
          user,
          workspaceRoleConfigs
        )
        const extraPermsWithViolations = user.extraPermissions.filter(
          (bundle) => {
            return (
              bundle.excludedPerms.some(
                (perm) => !instanceOfBetaPermission(perm) // Explicitly allow beta permissions to not follow bundles
              ) && !rolePermBundleIds.includes(bundle.permBundleId)
            )
          }
        )
        return extraPermsWithViolations.length > 0
      })
      .map((user) => user.email)
  }, [users, workspaceRoleConfigs, isCustomerFacing])
  const [onlyShowViolations, setOnlyShowViolations] = useState<boolean>(false)

  const { postAuditLog } = usePostAuditLog()

  const [addUsersDialogOpen, setAddUsersDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    const fetchRoles = async () => {
      const getRoles = isCustomerFacing
        ? getWorkspaceRoles
        : getWorkspaceRolesInternalAdmin
      const roles = await getRoles(workspace.id)

      const getRoleConfigs = isCustomerFacing
        ? getWorkspaceRoleConfigs
        : getWorkspaceRoleConfigsInternalAdmin
      const roleConfigs = await getRoleConfigs(workspace.id)

      setRoles(
        roles.filter((role) => {
          if (role.deletedAt) return false // TODO (ken): Move filter to API call
          const baseRoleId = role.roleId.slice(workspace.slug.length + 1)
          if (
            baseRoleId === DefaultRole.API ||
            baseRoleId === DefaultRole.VAULT_ADD_ON
          ) {
            // API should be restricted to svc users and Vault seats should be managed in Vault settings
            return false
          }

          return true
        })
      )
      setWorkspaceRoleConfigs(roleConfigs)
      setLoadingRoles(false)
    }
    void fetchRoles()
  }, [workspace.id, workspace.slug, isCustomerFacing])

  const selectColumn: ColumnDef<RawUserEnriched> = {
    id: 'select',
    header: ({ table }) => <SelectHeader table={table} />,
    cell: ({ row }) => <SelectCell row={row} className="mt-0.5" />,
    enableSorting: false,
    size: 40,
  }
  const showSelectColumn = isCustomerFacing
    ? canDeleteUsers || canManagePerms
    : canManageUsers

  const columns: ColumnDef<RawUserEnriched>[] = [
    ...(showSelectColumn ? [selectColumn] : []),
    {
      accessorKey: 'email',
      size: 240,
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Email" />
      ),
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
    },
    {
      accessorKey: 'roles',
      header: 'Roles',
      cell: ({ row }) => {
        const userRoles = row.original.roles
        const isSvcUser = row.original.email.startsWith(API_USER_SENTINEL)
        const hasMultipleRoles = userRoles.length > 1
        const currentRoleText = hasMultipleRoles
          ? `${userRoles.map((role) => role.name).join(', ')} ${
              userRoles.length > 3 ? `(${userRoles.length})` : ''
            }`
          : userRoles.length === 0
          ? 'None'
          : userRoles[0].name

        const selectTriggerContent = (
          <div className="flex items-center gap-1">
            {(hasMultipleRoles || userRoles.length === 0) &&
              !isCustomerFacing && <AlertIcon size="small" />}
            <div className="text-nowrap pr-2 text-sm">
              {pendingUserRole?.role.name || currentRoleText}
            </div>
          </div>
        )

        return (
          <div className="-my-1.5">
            {/* Align inner text vertically with other columns' leading text */}
            <Select
              disabled={isSvcUser || !canManagePerms}
              value={pendingUserRole?.role.roleId || (userRoles[0]?.id ?? '')}
              onValueChange={(value) => {
                const role = roles.find((role) => role.roleId === value)
                if (_.isNil(role)) return
                setPendingUserRole({
                  userId: row.original.id,
                  userEmail: row.original.email,
                  userExtraPermissions: row.original.extraPermissions.map(
                    (perm) => perm.permBundleId
                  ),
                  oldRoleName: currentRoleText,
                  role,
                })
              }}
            >
              <SelectTrigger size="sm">
                <SelectValue>{selectTriggerContent}</SelectValue>
              </SelectTrigger>
              <SelectContent className="w-48">
                {roles
                  .filter(
                    (role) =>
                      hasMultipleRoles ||
                      !userRoles.some((ur) => ur.id === role.rolePk)
                  )
                  .map((role) => (
                    <SelectItem key={role.roleId} value={role.roleId}>
                      {role.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
        )
      },
    },
    {
      accessorKey: 'extraPermissions',
      header: 'Extra permissions',
      size: 400,
      cell: ({ row }) => {
        const user = row.original
        const rolePermBundleIds = getUserRolePermBundleIds(
          user,
          workspaceRoleConfigs
        )
        if (loadingRoles) {
          return (
            <div className="space-y-2">
              <SkeletonBlock className="mx-2 h-4 w-36" />
              <SkeletonBlock className="mx-2 h-4 w-36" />
            </div>
          )
        }
        return (
          <ExpandablePermissionsCell
            permissions={row.original.extraPermissions}
            rowId={row.original.email}
            rolePermBundleIds={new Set(rolePermBundleIds)}
            isClientAdminView={isCustomerFacing}
          />
        )
      },
    },
    ...(canSeeLastActiveAt
      ? [
          {
            accessorKey: 'lastActiveAt',
            header: ({
              column,
            }: {
              column: Column<RawUserEnriched, unknown>
            }) => <DataTableSortHeader column={column} header="Last active" />,
            cell: ({ getValue }: { getValue: Getter<string> }) => {
              const value = getValue()
              let formattedValue = '-'
              if (!_.isNil(value)) {
                formattedValue = getRelativeTableDateString(value)
              }
              return <div className="ml-3 text-sm">{formattedValue}</div>
            },
          },
        ]
      : []),
    {
      accessorKey: 'createdAt',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Account created" />
      ),
      cell: ({ getValue }) => {
        const value = getValue()
        if (_.isNil(value)) {
          return '-'
        }
        return (
          <div className="ml-3 text-sm">
            {getRelativeTableDateString(String(value))}
          </div>
        )
      },
    },
  ]

  const deletedColumn: ColumnDef<RawUserEnriched> = {
    accessorKey: 'deletedAt',
    header: ({ column }) => (
      <DataTableSortHeader column={column} header="Deleted" />
    ),
    cell: ({ row }) => {
      if (row.original.deletedAt) {
        return (
          <div className="ml-3 text-sm">
            {getRelativeTableDateString(String(row.original.deletedAt))}
          </div>
        )
      }
      return '-'
    },
  }
  if (!isCustomerFacing) {
    columns.push(deletedColumn)
  }

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      if (!showDeleted && user.deletedAt) {
        return false
      }

      if (selectedPermBundleIds.length > 0) {
        const userCombinedPermBundleIds = new Set(
          getUserCombinedPermBundleIds(user, workspaceRoleConfigs)
        )
        if (
          !selectedPermBundleIds.some((permBundle) =>
            userCombinedPermBundleIds.has(permBundle)
          )
        ) {
          return false
        }
      }

      if (selectedBetaPermissions.length > 0) {
        // Beta permissions should never be part of a role, so only check extra permissions.
        const userBetaPermissions = user.extraPermissions
          .flatMap((perm) => perm.includedPerms)
          .filter((perm) => instanceOfBetaPermission(perm))
        if (
          !selectedBetaPermissions.some((betaPerm) =>
            userBetaPermissions.includes(betaPerm)
          )
        ) {
          return false
        }
      }

      if (selectedRolePks.length > 0) {
        const userRoleIds = user.roles.map((role) => role.id)
        if (!selectedRolePks.some((roleId) => userRoleIds.includes(roleId))) {
          return false
        }
      }

      if (
        onlyShowViolations &&
        !userEmailsWithViolations.includes(user.email)
      ) {
        return false
      }

      return true
    })
  }, [
    users,
    workspaceRoleConfigs,
    selectedPermBundleIds,
    selectedRolePks,
    selectedBetaPermissions,
    onlyShowViolations,
    userEmailsWithViolations,
    showDeleted,
  ])

  const table = useReactTable({
    data: filteredUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    onSortingChange: setSorting,
    enableRowSelection: (row) => !row.original.deletedAt,
    enableMultiRowSelection: true,
    onRowSelectionChange: (updater) => {
      if (typeof updater === 'function') {
        const newSelection = updater(
          Object.fromEntries(selectedRowKeys.map((key) => [key, true]))
        )
        setSelectedRowKeys(Object.keys(newSelection))
      } else {
        setSelectedRowKeys(Object.keys(updater))
      }
    },
    getRowId: (row) => row.email,
    state: {
      sorting,
      globalFilter: filter,
      pagination: tablePaginationState,
      rowSelection: Object.fromEntries(
        selectedRowKeys.map((key) => [key, true])
      ),
    },
  })

  const handleSetUserRole = async (permsToRevoke: PermissionBundleId[]) => {
    if (_.isNil(pendingUserRole)) {
      return
    }
    const setRoleFn = isCustomerFacing ? setUserRole : setUserRoleInternalAdmin
    try {
      await setRoleFn({
        userId: pendingUserRole.userId,
        rolePk: pendingUserRole.role.rolePk,
        permsToRevoke,
      })
      displaySuccessMessage('Role updated successfully')
      await fetchData()
    } catch (error) {
      displayErrorMessage('Failed to update role')
    }
  }

  const exportButton = table.getFilteredRowModel().rows.length > 0 && (
    <CsvDownload
      hasIcon
      buttonText="Export"
      filename={`${workspace.clientName}_users_internal_admin.csv`}
      data={table.getFilteredRowModel().rows.map((row) => ({
        id: row.original.id,
        email: row.original.email,
        pseudonymizedEmail: row.original.pseudonymizedEmail,
        role: row.original.roles.map((role) => role.name).join(', '),
        extraPermissions: row.original.extraPermissions
          .map((perm) => perm.permBundleName)
          .join(', '),
        ...(canSeeLastActiveAt
          ? { lastActiveAt: row.original.lastActiveAt }
          : {}),
        createdAt: row.original.createdAt,
        updatedAt: row.original.updatedAt,
      }))}
      headers={[
        { label: 'Id', key: 'id' },
        { label: 'Email', key: 'email' },
        { label: 'Pseudonymized Email', key: 'pseudonymizedEmail' },
        { label: 'Role', key: 'role' },
        { label: 'Extra Permissions', key: 'extraPermissions' },
        ...(canSeeLastActiveAt
          ? [{ label: 'Last active at', key: 'lastActiveAt' }]
          : []),
        { label: 'Created At', key: 'createdAt' },
        { label: 'Updated At', key: 'updatedAt' },
      ]}
      onClickCallback={async () => {
        await postAuditLog(
          AuditLogType.INTERNAL_ADMINCLIENT_EXPORT_WORKSPACE_USERS,
          workspace.id,
          {
            numUsers: table.getFilteredRowModel().rows.length,
          }
        )
      }}
    />
  )

  const filters = (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className={cn('justify-between', {
            'text-muted': numFilters === 0,
          })}
        >
          <ListFilter className="mr-2 size-4 opacity-50" />
          Filters
          {numFilters > 0 && (
            <Badge
              variant="secondary"
              className="ml-2 min-w-6 justify-center px-1"
            >
              {numFilters}
            </Badge>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex flex-col gap-2 p-4" align="start">
        <div className="flex items-center justify-between">
          <span className="mr-8 text-sm">Role</span>
          <RolesMultiSelect
            className="w-60"
            placeholder="Select"
            roles={roles}
            selectedRolePks={selectedRolePks}
            setSelectedRolePks={setSelectedRolePks}
          />
        </div>
        <div className="flex items-center justify-between">
          <span className="mr-8 text-sm">Permissions</span>
          <PermBundlesMultiSelect
            className="w-60"
            placeholder="Select"
            permBundles={workspaceRoleConfigs.map((roleConfig) => ({
              ...roleConfig.permBundle,
              visibility:
                roleConfig.visibility ??
                roleConfig.permBundle.defaultVisibility, // TODO: Can we move visibility to the perm bundle obj to simplify?
            }))}
            selectedValues={selectedPermBundleIds}
            setSelectedValues={setSelectedPermBundleIds}
            isClientAdminView={isCustomerFacing}
          />
        </div>
        {!isCustomerFacing && (
          <div className="flex items-center justify-between">
            <span className="mr-8 text-sm">Beta permissions</span>
            <MultiSelect
              className="w-60"
              placeholder="Select"
              sortedEntries={Object.values(BetaPermission).map((perm) => ({
                text: perm,
                value: perm,
              }))}
              selectedValues={selectedBetaPermissions}
              setSelectedValues={setSelectedBetaPermissions}
            />
          </div>
        )}
        {!isCustomerFacing && (
          <div className="flex items-center justify-between">
            <span className="mr-8 text-sm">Deleted users</span>
            <div className="flex h-8 w-60 items-center justify-end">
              <Switch
                checked={showDeleted}
                onCheckedChange={() => setShowDeleted(!showDeleted)}
              />
            </div>
          </div>
        )}
        <div className="-mx-4 flex items-center justify-between border-t px-4 pt-4 text-xs text-muted">
          Some users may have permissions already as part of their role.
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )

  const renderViolationsFilter = () => {
    if (!canManageUsers || isCustomerFacing) {
      return null
    }
    if (loadingRoles) {
      return <SkeletonBlock className="mx-2 h-6 w-24" />
    }
    if (userEmailsWithViolations.length > 0) {
      return (
        <Button
          variant="ghost"
          onClick={() => setOnlyShowViolations(!onlyShowViolations)}
        >
          <Checkbox
            // eslint-disable-next-line @typescript-eslint/no-useless-template-literals
            label={`${pluralize(
              'violation',
              userEmailsWithViolations.length,
              true
            )}`}
            checked={onlyShowViolations}
          />
          <AlertIcon size="small" className="ml-1" />
        </Button>
      )
    }
  }

  return (
    <>
      {deleteErrorUsers.length > 0 && (
        <div className="mb-4">
          <Alert
            message={`Failed to delete ${deleteErrorUsers.length} users`}
            description={
              <div className="max-h-32 overflow-y-auto">
                {deleteErrorUsers.join(', ')}
              </div>
            }
            type="error"
            showIcon
            closable
            onClose={() => setDeleteErrorUsers([])}
          />
        </div>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-2">
          {hasPageHeader ? (
            <SettingsPageHeader title="Users" subtitle={subtitleText} />
          ) : (
            <div className="text-sm">{subtitleText}</div>
          )}

          <div className="flex min-h-8 items-center gap-2">
            {exportButton}
            <Button
              onClick={() => setAddUsersDialogOpen(true)}
              disabled={!canAddUsers}
              tooltip={
                !canAddUsers
                  ? 'You do not have permission to add users'
                  : undefined
              }
            >
              <Icon icon={PlusIcon} className="mr-1" />
              Add user
            </Button>
          </div>
        </div>

        <div className="flex items-center justify-between gap-2">
          {_.isEmpty(selectedRowKeys) ? (
            <div className="flex items-center gap-2">
              <SearchInput value={filter} setValue={setFilter} withIcon />
              {filters}
              {renderViolationsFilter()}
            </div>
          ) : (
            <div className="flex space-x-2">
              {canManagePerms && (
                <Button
                  variant="outline"
                  onClick={() => setManagePermissionsModalOpen(true)}
                >
                  Manage permissions
                </Button>
              )}
              {canDeleteUsers && (
                <Button
                  variant="destructive"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  {`Delete ${pluralize('user', selectedRowKeys.length, true)}`}
                </Button>
              )}
              <Button variant="ghost" onClick={() => setSelectedRowKeys([])}>
                Clear
              </Button>
            </div>
          )}

          <div className="flex items-center gap-2 text-sm">
            {showDeleted
              ? `${pluralize(
                  'user',
                  table.getFilteredRowModel().rows.length,
                  true
                )} (includes deleted users)`
              : pluralize(
                  'active user',
                  table.getFilteredRowModel().rows.length,
                  true
                )}
          </div>
        </div>

        <DataTable
          isLoading={isLoading}
          table={table}
          hrefForRow={(row) =>
            isCustomerFacing || row.original.deletedAt
              ? ''
              : `/settings/internal_admin/user-inspector`
          }
          hrefOptions={(row) => ({
            navigateOptions: { state: { email: row.original.email } },
          })}
          isRowDisabled={(row) => Boolean(row.original.deletedAt)}
          tableCellClassName="align-top py-3 px-1"
          tableHeaderCellClassName="align-middle p-1 h-8 text-xs [&_*]:text-xs [&_*]:font-medium"
          tableRowClassName={(row) =>
            cn({
              'pointer-events-none': Boolean(row.original.deletedAt),
            })
          }
        />
        <DataTableFooter table={table} />
      </div>

      {pendingUserRole && (
        <WorkspaceSetUserRoleDialog
          isOpen
          onClose={() => setPendingUserRole(undefined)}
          onSubmit={handleSetUserRole}
          rolePk={pendingUserRole.role.rolePk}
          userId={pendingUserRole.userId}
          userExtraPermissions={pendingUserRole.userExtraPermissions}
          headerText={`Update role from ${pendingUserRole.oldRoleName} to ${pendingUserRole.role.name}?`}
          subHeaderText={`You are modifying permissions for ${pendingUserRole.userEmail}.`}
          isCustomerFacing={isCustomerFacing}
        />
      )}

      {managePermissionsModalOpen && (
        <WorkspacePermissionsDialog
          includeBetaPermissions={!isCustomerFacing}
          isInternalAdmin={
            !isCustomerFacing && settingsUser?.IsInternalAdminWriter
          }
          workspaceId={workspace.id}
          isOpen={managePermissionsModalOpen}
          onClose={() => setManagePermissionsModalOpen(false)}
          onComplete={async () => {
            setManagePermissionsModalOpen(false)
            await fetchData()
          }}
          selectedUsers={users
            .filter((user) => selectedRowKeys.includes(user.email))
            .map<
              ComponentProps<
                typeof WorkspacePermissionsDialog
              >['selectedUsers'][number]
            >((user) => {
              const permBundleIds = getUserCombinedPermBundleIds(
                user,
                workspaceRoleConfigs
              )
              return {
                email: user.email,
                permBundleIds,
                extraPermBundleIds: user.extraPermissions.map(
                  (perm) => perm.permBundleId
                ),
              }
            })}
        />
      )}
      <AddUsersDialog
        isOpen={addUsersDialogOpen}
        onClose={() => setAddUsersDialogOpen(false)}
        workspace={workspace}
        onAdd={fetchData}
        customerFacing={isCustomerFacing}
      />
      <DeleteUsersDialog
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        selectedEmails={selectedRowKeys as string[]}
        onSuccess={async () => {
          setSelectedRowKeys([])
          setFilter('')
          await fetchData()
        }}
      />
    </>
  )
}

export default WorkspaceUsersTableV2
