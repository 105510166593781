import { WorkspaceProject } from 'openapi/models/WorkspaceProject'
import Services from 'services'

export interface WorkspaceProjectsApiResponseData {
  status: string
  projects: WorkspaceProject[]
}

const FetchWorkspaceProjects =
  async (): Promise<WorkspaceProjectsApiResponseData> => {
    const requestPath = 'vault/workspace/projects'
    const response =
      await Services.Backend.Get<WorkspaceProjectsApiResponseData>(
        requestPath,
        { throwOnError: true }
      )
    return response
  }

export { FetchWorkspaceProjects }
