/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying user permissions.
 * @export
 */
export const Permission = {
    ADMINORGANIZATION: 'admin:organization',
    ADMINTERRITORY: 'admin:territory',
    AUS_BREACH_REPORTING: 'aus_breach_reporting',
    CLIENT_ADMINADD_USERS: 'client_admin:add_users',
    CLIENT_ADMINAPI_TOKEN_MGMT: 'client_admin:api_token_mgmt',
    CLIENT_ADMININTEGRATIONS: 'client_admin:integrations',
    CLIENT_ADMINMANAGE_ASSISTANT_WORKFLOWS: 'client_admin:manage_assistant_workflows',
    CLIENT_ADMINMANAGE_SHARING: 'client_admin:manage_sharing',
    CLIENT_ADMINREMOVE_USERS: 'client_admin:remove_users',
    CLIENT_ADMINSELF_SERVE_PERMS_READONLY: 'client_admin:self_serve_perms_readonly',
    CLIENT_ADMINSELF_SERVE_PERMS_WRITE: 'client_admin:self_serve_perms_write',
    CLIENT_ADMINVAULT_ADD_USERS: 'client_admin:vault_add_users',
    CLIENT_ADMINVAULT_REMOVE_USERS: 'client_admin:vault_remove_users',
    CLIENT_ADMINVIEW_USERS: 'client_admin:view_users',
    CREATEADVANCED_DOC_QA: 'create:advanced_doc_qa',
    CREATEASSISTANT_DRAFT: 'create:assistant_draft',
    CREATEASSISTANT_V2_SURVEY_FEEDBACK: 'create:assistant_v2_survey_feedback',
    CREATEASYNC_FIELD_EXTRACTION_API: 'create:async_field_extraction_api',
    CREATEAUSTRALIA_TAX_QA: 'create:australia_tax_qa',
    CREATECALL_SUMMARIZATION: 'create:call_summarization',
    CREATECOMPANY_PROFILE: 'create:company_profile',
    CREATECOMPLETION: 'create:completion',
    CREATECONTRACTS: 'create:contracts',
    CREATECORPUS_QA: 'create:corpus_qa',
    CREATECUATRECASAS: 'create:cuatrecasas',
    CREATEDILIGENCE_TRANSCRIPTS: 'create:diligence_transcripts',
    CREATEDILIGENCE_UK_TAX: 'create:diligence_uk_tax',
    CREATEDOCUMENT_QA: 'create:document_qa',
    CREATEDRAFTING: 'create:drafting',
    CREATEEDGAR_ALL_FILINGS: 'create:edgar_all_filings',
    CREATEEURLEX_QA: 'create:eurlex_qa',
    CREATEEVENT_SHARES: 'create:event_shares',
    CREATEFEEDBACK: 'create:feedback',
    CREATEFRANCE_CASE_LAW: 'create:france_case_law',
    CREATEFROM_COUNSEL: 'create:from_counsel',
    CREATEHARVEY_LIBRARY_ITEMS: 'create:harvey_library_items',
    CREATEHMRC_QA: 'create:hmrc_qa',
    CREATEHONG_KONG_TAX_QA: 'create:hong_kong_tax_qa',
    CREATEICERTIS_FIELD_EXTRACTION_API: 'create:icertis_field_extraction_api',
    CREATEICERTIS_REVIEW_API: 'create:icertis_review_api',
    CREATEINDIA_TAX_QA: 'create:india_tax_qa',
    CREATEINTERNET_BROWSING: 'create:internet_browsing',
    CREATEIRELAND_TAX_QA: 'create:ireland_tax_qa',
    CREATEITALY_TAX_QA: 'create:italy_tax_qa',
    CREATEJAPAN_TAX_QA: 'create:japan_tax_qa',
    CREATEKOREA_TAX_QA: 'create:korea_tax_qa',
    CREATEKNOWLEDGE_BASE_PROJECT: 'create:knowledge_base_project',
    CREATEKSA_TAX_QA: 'create:ksa_tax_qa',
    CREATEMEMOS_QA: 'create:memos_qa',
    CREATEMULTI_DOC_QA: 'create:multi_doc_qa',
    CREATENETHERLANDS_TAX_QA: 'create:netherlands_tax_qa',
    CREATEOGC_REVIEW: 'create:ogc_review',
    CREATEPILLAR_TWO_TAX_QA: 'create:pillar_two_tax_qa',
    CREATEPLAYBOOK_REVIEW: 'create:playbook_review',
    CREATEPRIVATE_LIBRARY_PROMPTS: 'create:private_library_prompts',
    CREATEPWC_HR_WORKFORCE_API: 'create:pwc_hr_workforce_api',
    CREATEPWC_TAX_FEEDBACK_EXPORT: 'create:pwc_tax_feedback_export',
    CREATEREDLINES: 'create:redlines',
    CREATERESPONSE_COMPARISON: 'create:response_comparison',
    CREATESEC_EDGAR_QA: 'create:sec_edgar_qa',
    CREATESWEDEN_TAX_QA: 'create:sweden_tax_qa',
    CREATESWITZERLAND_TAX_QA: 'create:switzerland_tax_qa',
    CREATETRANSLATION: 'create:translation',
    CREATETRANSLATION_API: 'create:translation_api',
    CREATEUAE_TAX_QA: 'create:uae_tax_qa',
    CREATEUSA_CASE_LAW: 'create:usa_case_law',
    CREATEUSER_LIBRARY_ITEMS: 'create:user_library_items',
    CREATEUSER_PROFILES: 'create:user_profiles',
    CREATEUSER_PROFILES_OPTIONAL: 'create:user_profiles_optional',
    CREATEUSER_PROFILES_OPTIONAL_STEPS: 'create:user_profiles_optional_steps',
    CREATEVALS_API: 'create:vals_api',
    CREATEVAULT: 'create:vault',
    CREATEVAULT_API: 'create:vault_api',
    CREATEVAULT_INTERNAL_ONLY: 'create:vault_internal_only',
    CREATEVAULT_SANDBOX_PROJECT: 'create:vault_sandbox_project',
    CREATEVAULT_SHARES: 'create:vault_shares',
    CREATEVAULT_WORKFLOW_ADMIN: 'create:vault_workflow_admin',
    CREATEVAULT_WORKFLOW_LOAN_PORTFOLIO_REVIEW: 'create:vault_workflow_loan_portfolio_review',
    CREATEVAULT_WORKFLOW_REPS_WARRANTIES: 'create:vault_workflow_reps_warranties',
    CREATEWORKSPACE_LIBRARY_ITEMS: 'create:workspace_library_items',
    CREATEWWTS_QA: 'create:wwts_qa',
    DECIDERESPONSE_COMPARISON: 'decide:response_comparison',
    DELETEUSER_HISTORY: 'delete:user_history',
    DELETEWORKSPACE_HISTORY: 'delete:workspace_history',
    DELETEWORKSPACE_HISTORY_BULK: 'delete:workspace_history_bulk',
    DOCUMENT_COMPARISON: 'document_comparison',
    LEGACY_OPEN_ENDED: 'legacy_open_ended',
    MANAGEUSERS: 'manage:users',
    MODIFYWORKSPACE_PROJECTS: 'modify:workspace_projects',
    VIEWVAULT_WORKSPACE_PROJECTS: 'view:vault_workspace_projects',
    PWC_DEALS: 'pwc_deals',
    READALL_SENSITIVE: 'read:all_sensitive',
    READALL_WORKSPACES: 'read:all_workspaces',
    READCLIENT_MATTERS: 'read:client_matters',
    READENG_INTERNAL: 'read:eng_internal',
    READEXAMPLES: 'read:examples',
    READHISTORY: 'read:history',
    READMESSAGE_LEVEL_CREATED_TIMESTAMP: 'read:message_level_created_timestamp',
    READSOME_SENSITIVE_DATA: 'read:some_sensitive_data',
    READSTATUS_BAR: 'read:status_bar',
    READWORKSPACE_HISTORY: 'read:workspace_history',
    READWORKSPACE_VAULT: 'read:workspace_vault',
    TRANSCRIPTS: 'transcripts',
    USEASSISTANT_COMPOSER: 'use:assistant_composer',
    USEASSISTANT_LOCALIZATION: 'use:assistant_localization',
    USEASSISTANT_RESEARCH_KS: 'use:assistant_research_ks',
    USEASSISTANT_TAX_KS: 'use:assistant_tax_ks',
    USEASSISTANT_V2: 'use:assistant_v2',
    USECOMPETITIVE_ANALYSIS: 'use:competitive_analysis',
    USEDEALS_DISCOVERY_ASYNC_IMPL: 'use:deals_discovery_async_impl',
    USEDEALS_DISCOVERY_FROM_VAULT: 'use:deals_discovery_from_vault',
    USEDIARIZED_TRANSCRIPTION: 'use:diarized_transcription',
    USEDISALLOWED_CLIENT_MATTERS: 'use:disallowed_client_matters',
    USEDISCOVER_TAB: 'use:discover_tab',
    USEDOC_PROCESSING_RPC: 'use:doc_processing_rpc',
    USEEVENT_SHARING: 'use:event_sharing',
    USEGOOGLE_DRIVE_INTEGRATION: 'use:google_drive_integration',
    USEHARVEY_FOR_COPILOT: 'use:harvey_for_copilot',
    USEHARVEY_FOR_CUSTOM_ENGINE_COPILOT: 'use:harvey_for_custom_engine_copilot',
    USEHARVEY_FOR_WORD: 'use:harvey_for_word',
    USEIMANAGE_INTEGRATION: 'use:imanage_integration',
    USEKNOWLEDGE_BASE_PROJECT: 'use:knowledge_base_project',
    USELANCE_ENTERPRISE_VAULT: 'use:lance_enterprise_vault',
    USELEFEBVRE_KS: 'use:lefebvre_ks',
    USEBREDIN_PRAT_KS: 'use:bredin_prat_ks',
    USELIBRARY_EXAMPLES: 'use:library_examples',
    USEMACFARLANES_KS: 'use:macfarlanes_ks',
    USENETDOCS_EXTENSION: 'use:netdocs_extension',
    USEOPEN_ENDED_BRIDGE_ROUTING: 'use:open_ended_bridge_routing',
    USEOPEN_ENDED_BRIDGE_ROUTING_FULL: 'use:open_ended_bridge_routing_full',
    USEPASSWORD_PROTECTED_DOCS: 'use:password_protected_docs',
    USEPERMISSION_BUNDLES: 'use:permission_bundles',
    USESHAREPOINT_INTEGRATION: 'use:sharepoint_integration',
    USESINGAPORE_CASE_LAW: 'use:singapore_case_law',
    USESKIP_RATE_LIMIT: 'use:skip_rate_limit',
    USESWEDEN_CASE_LAW: 'use:sweden_case_law',
    USEVAULT_PROJECT_CLIENT_MATTER: 'use:vault_project_client_matter',
    USEVAULT_SHARING: 'use:vault_sharing',
    USEVAULT_WORKFLOW_BROWSER: 'use:vault_workflow_browser',
    VIEWEXPORT_TIMEZONE_SELECTION: 'view:export_timezone_selection',
    VIEWHELP_CENTER: 'view:help_center',
    VIEWLAST_ACTIVITY_DATE: 'view:last_activity_date',
    VIEWTOUR_CLIENT_MATTER: 'view:tour_client_matter',
    VIEWUSAGE_DASHBOARD: 'view:usage_dashboard',
    VIEWUSAGE_DASHBOARD_V2: 'view:usage_dashboard_v2',
    VIEWUSER_INSPECTOR: 'view:user_inspector',
    VIEWWORD_ADD_IN_ANNOUNCEMENT: 'view:word_add_in_announcement',
    VIEWGDRIVE_ANNOUNCEMENT: 'view:gdrive_announcement',
    VIEWWORKSPACE_INFO: 'view:workspace_info',
    WRITEALL_WORKSPACES: 'write:all_workspaces',
    WRITECLIENT_MATTERS: 'write:client_matters',
    WRITEENG_INTERNAL: 'write:eng_internal',
    WRITEEXAMPLES: 'write:examples'
} as const;
export type Permission = typeof Permission[keyof typeof Permission];


export function instanceOfPermission(value: any): boolean {
    for (const key in Permission) {
        if (Object.prototype.hasOwnProperty.call(Permission, key)) {
            if (Permission[key as keyof typeof Permission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    return json as Permission;
}

export function PermissionToJSON(value?: Permission | null): any {
    return value as any;
}

export function PermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): Permission {
    return value as Permission;
}

