import { useCallback, useEffect, useRef } from 'react'

import { Maybe } from 'types'

/**
 * NOTE: We must ensure the hook doesn't unmount before the file upload is complete.
 */

export const useNetdocsExtensionSupport = ({
  onUploadFromNetdocs,
  // requires onUpuloadFromComputer because we have to route all clicks to the input through here
  // that way if extension is not enabled, we can still trigger the regular file upload logic
  onUploadFromComputer,
}: {
  onUploadFromNetdocs: (files: File[]) => Promise<void>
  onUploadFromComputer: (files: File[]) => Promise<void>
}) => {
  const prevFilesLengthRef = useRef<number>(0)

  const isNetdocsEnabled = useRef<Maybe<boolean>>(false)

  const observer = useRef<MutationObserver | null>(null)

  /**
   * The Netdocs extension is designed to work with the native file picker,
   * not react-dropzone. The basic strategy here is to intercept the files
   * that the extension injects into a hidden input element and then call
   * the APIs we actually use for dropzone (i.e. onAssistantDrop).
   */
  const handleChange = useCallback(
    async (event: Event) => {
      const input = event.target as HTMLInputElement

      const currFilesLength = input.files?.length ?? 0

      // Only process if the number of files has changed
      if (
        input.files &&
        currFilesLength > 0 &&
        currFilesLength !== prevFilesLengthRef.current
      ) {
        const files = Array.from(input.files).slice(prevFilesLengthRef.current)
        prevFilesLengthRef.current = currFilesLength

        if (isNetdocsEnabled.current) {
          await onUploadFromNetdocs(files)
        } else {
          await onUploadFromComputer(files)
        }
        // Reset the ref after processing files so we'll process all files next time.
        // Needed because the extension replaces the input's files instead of appending.
        prevFilesLengthRef.current = 0
      }
    },
    [onUploadFromNetdocs, onUploadFromComputer]
  )

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFileUploadLocalOrNetdocsExtension = (
    allowMultipleFiles: boolean = true
  ) => {
    /**
     * The Netdocs extension only works when the a click event is triggered
     * on an <input type="file" multiple /> element. To reliably do this,
     * we spawn the input element into the DOM and redirect the click onto it.
     * Upon investigation of how Google Drive is compatible with Netdocs, they
     * also use a similar strategy of injecting the adhoc input.
     */
    const input = document.createElement('input')
    input.type = 'file'
    if (allowMultipleFiles) {
      // Netdocs extension checks e.target.multiple to determine if it can select multiple files in picker
      input.multiple = true
    }
    input.style.display = 'none'
    input.addEventListener('change', handleChange)
    document.body.appendChild(input)
    inputRef.current = input

    observer.current = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          if (!isNetdocsEnabled.current) {
            // netdocs extension will add a class name based on this logic
            // '_' + Math.random().toString(36).substr(2, 9)
            isNetdocsEnabled.current =
              (inputRef.current?.className.length ?? 0) > 0
            if (
              isNetdocsEnabled.current &&
              !inputRef.current?.className.includes('_')
            ) {
              console.warn(
                '[enterprise] Netdocs extension classname change detected'
              )
            }
          }
        }
      })
    })

    observer.current.observe(input, { attributeFilter: ['class'] })

    inputRef.current.click()
  }

  useEffect(() => {
    return () => {
      if (inputRef.current) {
        if (inputRef.current.parentElement) {
          inputRef.current.parentElement.removeChild(inputRef.current)
        }
        inputRef.current = null
      }
      if (observer.current) {
        observer.current.disconnect()
        observer.current = null
      }
    }
  }, [])

  return {
    handleFileUploadLocalOrNetdocsExtension,
  }
}
