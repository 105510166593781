/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInner } from './WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInner';
import {
    WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInnerFromJSON,
    WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInnerFromJSONTyped,
    WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInnerToJSON,
    WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInnerToJSONTyped,
} from './WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInner';

/**
 * 
 * @export
 * @interface WorkflowFollowUpsInputBlockBlockParams
 */
export interface WorkflowFollowUpsInputBlockBlockParams {
    /**
     * 
     * @type {Array<WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInner>}
     * @memberof WorkflowFollowUpsInputBlockBlockParams
     */
    relatedQuestions?: Array<WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInner>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowFollowUpsInputBlockBlockParams
     */
    placeholder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowFollowUpsInputBlockBlockParams
     */
    optional?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof WorkflowFollowUpsInputBlockBlockParams
     */
    previousKnowledgeSources?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowFollowUpsInputBlockBlockParams
     */
    canModifyKnowledgeSources?: boolean;
}

/**
 * Check if a given object implements the WorkflowFollowUpsInputBlockBlockParams interface.
 */
export function instanceOfWorkflowFollowUpsInputBlockBlockParams(value: object): value is WorkflowFollowUpsInputBlockBlockParams {
    return true;
}

export function WorkflowFollowUpsInputBlockBlockParamsFromJSON(json: any): WorkflowFollowUpsInputBlockBlockParams {
    return WorkflowFollowUpsInputBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowFollowUpsInputBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowFollowUpsInputBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'relatedQuestions': json['related_questions'] == null ? undefined : ((json['related_questions'] as Array<any>).map(WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInnerFromJSON)),
        'placeholder': json['placeholder'] == null ? undefined : json['placeholder'],
        'optional': json['optional'] == null ? undefined : json['optional'],
        'previousKnowledgeSources': json['previous_knowledge_sources'] == null ? undefined : json['previous_knowledge_sources'],
        'canModifyKnowledgeSources': json['can_modify_knowledge_sources'] == null ? undefined : json['can_modify_knowledge_sources'],
    };
}

export function WorkflowFollowUpsInputBlockBlockParamsToJSON(json: any): WorkflowFollowUpsInputBlockBlockParams {
    return WorkflowFollowUpsInputBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowFollowUpsInputBlockBlockParamsToJSONTyped(value?: WorkflowFollowUpsInputBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'related_questions': value['relatedQuestions'] == null ? undefined : ((value['relatedQuestions'] as Array<any>).map(WorkflowTableSelectInputBlockBlockParamsSourcesInnerBadgesInnerToJSON)),
        'placeholder': value['placeholder'],
        'optional': value['optional'],
        'previous_knowledge_sources': value['previousKnowledgeSources'],
        'can_modify_knowledge_sources': value['canModifyKnowledgeSources'],
    };
}

