import React, { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'

import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import { EditExistingQueryInput } from 'components/assistant/workflows/components/edit-existing-query'
import { QueryToolbar } from 'components/assistant/workflows/components/query-toolbar'
import TextInput from 'components/assistant/workflows/components/text-input/text-input'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowTextThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TEXT
> = ({
  blockParams,
  completionStatus,
  outputData,
  isEditing,
  setIsEditing,
  onUpdated,
}) => {
  const { agentText } = blockParams
  const isCompleted = !!outputData
  const streamInProgress = useAssistantWorkflowStore(
    useShallow((state) => state.streamInProgress)
  )
  const submitEditDisabled = streamInProgress
  const query = outputData?.value || ''

  const handleEdit = (text: string) => {
    if (!text.length) return
    const output: any = { value: text }
    onUpdated(output)
    setIsEditing(false)
  }

  return (
    <AssistantWorkflowThreadBlock>
      {agentText && (
        <AssistantWorkflowHarveyComponent>
          <AssistantWorkflowThreadText
            completionStatus={completionStatus}
            text={agentText}
          />
        </AssistantWorkflowHarveyComponent>
      )}

      {isCompleted && (
        <AssistantWorkflowYouComponent>
          {isEditing ? (
            <EditExistingQueryInput
              isSubmitDisabled={submitEditDisabled}
              onSubmit={handleEdit}
              onCancel={() => setIsEditing(false)}
              existingQuery={query}
            />
          ) : (
            <div className="flex flex-col">
              <AssistantWorkflowThreadText
                completionStatus={completionStatus}
                text={query.length > 0 ? query : 'N/A'}
              />

              {/* Add toolbar */}
              {isCompleted && (
                <QueryToolbar
                  query={query || 'N/A'}
                  onEdit={() => setIsEditing(true)}
                />
              )}
            </div>
          )}
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTextInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TEXT
> = ({ blockParams, onCompleted, outputData }) => {
  const { placeholder, optional } = blockParams
  const [value, setValue] = useState(outputData?.value ?? '')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)
    onCompleted({ value })
    setIsLoading(false)
  }

  return (
    <WorkflowInput>
      <TextInput
        onSubmit={handleSubmit}
        isLoading={isLoading}
        value={value}
        placeholder={placeholder}
        optional={optional}
        onChange={setValue}
        isCompleted={!!outputData}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    </WorkflowInput>
  )
}

export const AssistantWorkflowTextInputExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.TEXT
> = ({ blockParams, outputData }) => {
  const { agentText } = blockParams
  const output =
    (outputData?.value ?? '').length > 0 ? outputData?.value : 'N/A'
  return (
    <>
      <div>{agentText}</div>
      <div>{output}</div>
    </>
  )
}
